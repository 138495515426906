import { jsx as _jsx } from "react/jsx-runtime";
import { ToggleButtonGroup, ToggleButton, Typography, styled, } from "@mui/material";
const StyledToggleButtonGroup = styled(ToggleButtonGroup, {
    shouldForwardProp: (prop) => prop !== "borderRadius",
})(({ theme, borderRadius }) => ({
    backgroundColor: theme.palette.grey[200],
    borderRadius: borderRadius ?? 32,
    "& .MuiToggleButtonGroup-grouped": {
        margin: 2,
        border: 0,
        paddingBlock: 4,
        flexGrow: 1,
        "&.Mui-disabled": {
            border: 0,
        },
        "&:not(:first-of-type)": {
            borderRadius: borderRadius ?? 32,
        },
        "&:first-of-type": {
            borderRadius: borderRadius ?? 32,
        },
        "&.Mui-selected": {
            color: "#fff",
            backgroundColor: theme.palette.primary.main,
        },
        "&.Mui-selected:hover": {
            backgroundColor: theme.palette.primary.light,
        },
    },
}));
export default function ToggleGroup(props) {
    const { value, options, onChange, fullWidth, borderRadius } = props;
    return (_jsx(StyledToggleButtonGroup, { exclusive: true, fullWidth: fullWidth, value: value, onChange: (event, value) => onChange(value, event), borderRadius: borderRadius, children: options.map((button) => (_jsx(ToggleButton, { value: button.value, disabled: options.length === 1, children: _jsx(Typography, { fontSize: 14, fontWeight: 500, textTransform: "none", children: button.label }) }, button.value))) }));
}
