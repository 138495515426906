import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { usePaymentsContext } from '../PaymentsContext';
import { CURRENCIES } from '../../../constants/currencies';
import { getStatusStyles } from '../../UtilityFunctions';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { grey, green, red, blue, orange } from '@mui/material/colors';
import SideDrawer from '../../components/SideDrawer';
import DisplayField from '../../components/DisplayField';
import VerificationPhoto from './VerificationPhoto';
import TaxAndOtherFeesTooltip from './TaxAndOtherFeesTooltip';
import ExpandableAlert from '../../components/ExpandableAlert';
export default function OrderDrawer() {
    const [t] = useTranslation('financials');
    const { isOrderDrawerOpen, setIsOrderDrawerOpen, selectedOrder } = usePaymentsContext();
    const phoneNumber = selectedOrder?.customer
        ? selectedOrder?.customer.telephone.substring(selectedOrder?.customer.country_code.length)
        : null;
    if (!selectedOrder)
        return null;
    const { severity } = getStatusStyles(selectedOrder.status);
    const taxAndOtherFees = selectedOrder.bill.tax +
        selectedOrder.bill.transaction_fee +
        selectedOrder.bill.platform_fee;
    return (_jsxs(SideDrawer, { isOpen: isOrderDrawerOpen, onClose: () => setIsOrderDrawerOpen(false), primaryText: selectedOrder?.identifier, secondaryText: t('drawers.orderDetails.title'), chip: _jsx(Chip, { size: 'small', variant: 'filled', label: t(`orders.statusFilter.options.${selectedOrder.status}`), color: severity, sx: (theme) => ({
                borderRadius: 2,
                '&.MuiChip-colorSuccess': {
                    backgroundColor: green[50],
                    color: theme.palette.success.main
                },
                '&.MuiChip-colorError': {
                    backgroundColor: red[50],
                    color: theme.palette.error.main
                },
                '&.MuiChip-colorInfo': {
                    backgroundColor: blue[50],
                    color: theme.palette.info.main
                },
                '&.MuiChip-colorWarning': {
                    backgroundColor: orange[50],
                    color: theme.palette.warning.main
                }
            }) }), children: [_jsxs(Box, { px: 4, pt: 4, display: 'flex', flexDirection: 'column', gap: 2, children: [_jsxs(Box, { display: 'flex', gap: 2, sx: (theme) => ({
                            [theme.breakpoints.down('md')]: {
                                flexDirection: 'column'
                            }
                        }), children: [_jsx(DisplayField, { icon: _jsx(ShoppingBagOutlinedIcon, { color: 'action' }), primaryText: _.upperCase(t(`orders.type.${selectedOrder?.type}`)), secondaryText: t('drawers.orderDetails.fields.type') }), _jsx(DisplayField, { icon: _jsx(AccessTimeOutlinedIcon, { color: 'action' }), primaryText: `(${moment(selectedOrder.created_at).format('MM/DD/yyyy')}) ${moment(selectedOrder.created_at).format('h:mm a')}`, secondaryText: t('drawers.orderDetails.fields.createdAt') })] }), _jsx(DisplayField, { icon: _jsx(LocationOnOutlinedIcon, { color: 'action' }), primaryText: `${selectedOrder?.address.street}, ${selectedOrder?.address.zip}`, secondaryText: t('drawers.orderDetails.fields.address') }), _jsx(DisplayField, { icon: _jsx(PersonOutlineOutlinedIcon, { color: 'action' }), primaryText: selectedOrder?.customer
                            ? `${selectedOrder.customer.firstname} ${selectedOrder.customer.lastname}`
                            : t('orders.deletedUser'), secondaryText: t('drawers.orderDetails.fields.name') }), _jsx(DisplayField, { icon: _jsx(CallOutlinedIcon, { color: 'action' }), primaryText: phoneNumber
                            ? `(${phoneNumber?.substring(0, 3)}) ${phoneNumber?.substring(3, 6)}-${phoneNumber?.substring(6)}`
                            : t('orders.deletedUser'), secondaryText: t('drawers.orderDetails.fields.phone') }), _jsx(DisplayField, { icon: _jsx(EmailOutlinedIcon, { color: 'action' }), primaryText: selectedOrder.customer
                            ? selectedOrder?.customer.email
                            : t('orders.deletedUser'), secondaryText: t('drawers.orderDetails.fields.email') }), selectedOrder.documents.some((doc) => doc.document_type === 'order_age_verification_document') && _jsx(Alert, { severity: 'info', children: t('drawers.orderDetails.idAlert') }), selectedOrder.documents.some((doc) => doc.document_type === 'drop_off_proof_document') && (_jsx(Alert, { severity: 'info', children: t('drawers.orderDetails.dropOffAlert') })), selectedOrder.documents.map((doc) => (_jsx(VerificationPhoto, { type: doc.document_type, path: doc.path }, doc.path)))] }), _jsx(Divider, { sx: {
                    my: 3,
                    height: '8px',
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    borderTop: 1,
                    borderColor: 'divider'
                } }), _jsx(Box, { px: 4, pb: 4, children: _jsxs(Paper, { elevation: 3, sx: { py: 4 }, children: [selectedOrder?.bill.payment.type !== 'cash' && (_jsx(Alert, { severity: 'info', sx: { mx: 4, mb: 2 }, children: _jsxs(AlertTitle, { sx: { fontWeight: 600, mb: 0 }, children: [t('drawers.orderDetails.paidAlert.primary'), " ", _jsx("br", {}), ' ', t('drawers.orderDetails.paidAlert.secondary')] }) })), _jsx(Box, { sx: {
                                px: 4,
                                mb: 2,
                                height: 40,
                                background: grey[100],
                                display: 'flex',
                                alignItems: 'center'
                            }, children: _jsx(Typography, { fontWeight: 600, children: t('drawers.orderDetails.details.title') }) }), _jsx(Stack, { component: 'ul', gap: 1, sx: { px: 4, mb: 2 }, children: selectedOrder?.bookmark.map((item) => {
                                const groupedSelectedOptions = _.groupBy(item.selectedOptions, 'parentModifierGroup');
                                return (_jsxs(Stack, { gap: 2, py: 1, component: 'li', children: [_jsxs(Box, { gap: 2, display: 'flex', children: [_jsx(Avatar, { sx: { bgcolor: '#000', width: 28, height: 28 }, children: _jsxs(Typography, { fontSize: 14, children: [item.quantity, "x"] }) }), _jsxs(Box, { flexGrow: 1, children: [_jsx(Typography, { fontWeight: 500, fontSize: 14, children: item.name }), Object.keys(groupedSelectedOptions).map((group) => (_jsxs(Typography, { variant: 'body2', color: 'text.secondary', fontSize: 12, children: [group, ":", ' ', _jsx(Typography, { component: 'span', fontSize: 12, children: groupedSelectedOptions[group]
                                                                        .map((option) => {
                                                                        let str = '';
                                                                        if (option.quantity > 1) {
                                                                            str += `${option.quantity}x `;
                                                                        }
                                                                        str += option.name;
                                                                        if (option.price > 0) {
                                                                            str += ` (${CURRENCIES[selectedOrder?.bill.currency]}${(option.quantity + option.price).toFixed(2)})`;
                                                                        }
                                                                        return str;
                                                                    })
                                                                        .join(', ') })] }, group)))] }), _jsxs(Typography, { fontSize: 14, fontWeight: 600, children: [CURRENCIES[selectedOrder?.bill.currency], (item.price +
                                                            item.selectedOptions.reduce((a, c) => a + c.quantity * c.price, 0)).toFixed(2)] })] }), !!item.special_instructions?.length && (_jsx(ExpandableAlert, { description: item.special_instructions }))] }));
                            }) }), _jsx(Box, { sx: {
                                px: 4,
                                mb: 2,
                                height: 40,
                                background: grey[100],
                                display: 'flex',
                                alignItems: 'center'
                            }, children: _jsx(Typography, { fontWeight: 600, children: t('drawers.orderDetails.details.bill.title') }) }), _jsxs(Box, { px: 4, display: 'flex', justifyContent: 'space-between', color: 'text.secondary', fontSize: 16, children: [_jsx("span", { children: t('drawers.orderDetails.details.bill.subtotal') }), _jsxs("span", { children: [CURRENCIES[selectedOrder?.bill.currency], selectedOrder?.bill.subtotal.toFixed(2)] })] }), _jsxs(Box, { px: 4, display: 'flex', justifyContent: 'space-between', color: 'text.secondary', fontSize: 16, children: [_jsxs("span", { children: [t('drawers.orderDetails.details.bill.taxOthers'), ' ', _jsx(TaxAndOtherFeesTooltip, { currency: CURRENCIES[selectedOrder?.bill.currency], tax: selectedOrder.bill.tax, platformFee: selectedOrder.bill.platform_fee, transactionFee: selectedOrder.bill.transaction_fee })] }), _jsxs("span", { children: [CURRENCIES[selectedOrder?.bill.currency], taxAndOtherFees.toFixed(2)] })] }), _jsxs(Box, { px: 4, display: 'flex', justifyContent: 'space-between', color: 'text.secondary', fontSize: 16, children: [_jsx("span", { children: t('drawers.orderDetails.details.bill.delivery') }), _jsxs("span", { children: [CURRENCIES[selectedOrder?.bill.currency], selectedOrder?.bill.delivery_price.toFixed(2)] })] }), _jsxs(Box, { px: 4, display: 'flex', justifyContent: 'space-between', color: 'text.secondary', fontSize: 16, children: [_jsx("span", { children: t('drawers.orderDetails.details.bill.tip') }), _jsxs("span", { children: [CURRENCIES[selectedOrder?.bill.currency], selectedOrder?.bill.tip.toFixed(2)] })] }), _jsxs(Box, { px: 4, display: 'flex', justifyContent: 'space-between', fontSize: 16, fontWeight: 600, children: [_jsx("span", { children: t('drawers.orderDetails.details.bill.total') }), _jsxs("span", { children: [CURRENCIES[selectedOrder?.bill.currency], selectedOrder?.bill.total.toFixed(2)] })] })] }) })] }));
}
