import { globalToast } from './UtilityFunctions';
import '../i18n/config';
const paths = [
    '/store',
    '/menu',
    '/users',
    '/employees',
    '/payout',
    '/gallery',
    '/settings',
    '/profile',
    '/store/activity-logs'
];
class LocationHelper {
    constructor() {
        Object.defineProperty(this, "baseUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pathName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.baseUrl = window.location.origin;
        this.pathName = window.location.pathname;
    }
    handleScriptImport() {
        window.globalToast = globalToast;
        // require("./MenuSlideOutController");
        if (paths.includes(this.pathName)) {
            require('./layout/initNetworkSidebar_React');
            require('./layout/initLayout_React');
        }
        if (this.pathName.includes('get-your-plan')) {
            require('./getYourPlan/initGetYourPlanScreen_React');
        }
        if (this.pathName.includes('menu')) {
            require('./menu/initMenuScreen_React');
        }
        if (this.pathName === '/store') {
            require('./store/initStore_React');
        }
        // if (this.pathName.includes("tables")) {
        //   require("./TablesController");
        // }
        if (this.pathName.includes('gallery')) {
            require('./gallery/initGallery_React');
        }
        if (this.pathName.includes('users')) {
            require('./user/initCustomers_React');
        }
        if (this.pathName.includes('employees')) {
            require('./employer/initEmployer_React');
        }
        if (this.pathName.includes('payout')) {
            require('./payments/initPayments_React');
        }
        // if (this.pathName.includes("events")) {
        //   require("./EventsController");
        // }
        if (this.pathName.includes('profile')) {
            require('./profile/initProfile_React');
        }
        if (this.pathName.includes('create-restaurant')) {
            require('./newBranch/initNewBranch_React');
        }
        if (this.pathName.includes('settings')) {
            require('./settings/initSettings_React');
        }
        // if (this.pathName.includes("subscription")) {
        //   require("./subscriptions/initSubscription_React");
        // }
        if (this.pathName === '/store/activity-logs') {
            require('./activity-logs/initActivityLogsScreen_React');
        }
        // if (
        //   this.pathName.includes("select-restaurant") ||
        //   this.pathName.includes("create-restaurant") ||
        //   this.pathName.includes("select-network")
        // ) {
        // require("./RestaurantController");
        // if (this.pathName.includes("select-restaurant")) {
        //   require("./selectBranch/initSelectBranch_React");
        // }
        // }
        if (this.pathName.includes('select-network')) {
            require('./selectNetwork/initSelectNetwork_React');
        }
        if (this.pathName.includes('login') ||
            this.pathName.includes('superadmin')) {
            require('./login/initLoginScreen_React');
        }
    }
}
let LH = new LocationHelper();
export default LH;
