import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { StoreContext } from '../StoreContext';
import Storage from '../../../config/Storage';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import { useMediaQuery, useTheme } from '@mui/material';
import { AppBar } from './styled';
import SelectRestaurant from './SelectRestaurant';
import ProfileDropdown from './ProfileDropdown';
import LanguageSelect from '../../components/common/LanguageSelect';
export default function TopBar() {
    const { isMenuOpen, setIsMenuOpen } = useContext(StoreContext);
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const handleClick = () => {
        setIsMenuOpen(!isMenuOpen);
        Storage.setItem('isMenuOpen', (!isMenuOpen).toString());
    };
    return (_jsx(AppBar, { open: isMenuOpen, sx: { bgcolor: '#fff' }, children: _jsxs(Toolbar, { component: Stack, direction: 'row', alignItems: 'center', px: 3, justifyContent: 'space-between', children: [isMdDown ? (_jsxs(Stack, { direction: 'row', gap: 4, alignItems: 'center', children: [_jsx(IconButton, { onClick: handleClick, children: _jsx(MenuOutlinedIcon, {}) }), _jsx(SelectRestaurant, {})] })) : (_jsx(IconButton, { sx: {
                        boxShadow: 3,
                        bgcolor: 'white',
                        width: 24,
                        height: 24,
                        left: -36,
                        '&:hover': {
                            bgcolor: 'white'
                        }
                    }, onClick: handleClick, children: isMenuOpen ? (_jsx(ArrowLeftOutlinedIcon, {})) : (_jsx(ArrowRightOutlinedIcon, {})) })), _jsxs("div", { className: 'space-x-2 flex items-center', children: [_jsx(LanguageSelect, {}), _jsx(ProfileDropdown, {})] })] }) }));
}
