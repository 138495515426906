import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useCustomersContext } from '../CustomerContext';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import { useTheme, useMediaQuery } from '@mui/material';
import CustomerRow from './CustomerRow';
import Pagination from '../../components/Pagination';
import { RoundedButton } from '../../components/styled';
export default function CustomersTable() {
    const [t] = useTranslation('customers');
    const { customers, isLoadingCustomers, pagination, setPage, perPage, setPerPage, getCustomers, isFetching } = useCustomersContext();
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up(1024));
    const showPagination = !isLoadingCustomers && !!customers.length && pagination.total > 10 && isMdUp;
    const showSeeMoreBtn = !isLoadingCustomers &&
        !isMdUp &&
        pagination.current_page !== pagination.last_page;
    return (_jsxs(_Fragment, { children: [_jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { sx: { fontWeight: 600 }, children: t('tableColumns.1') }), _jsx(TableCell, { sx: (theme) => ({
                                        fontWeight: 600,
                                        whiteSpace: 'nowrap',
                                        [theme.breakpoints.down('md')]: { px: 0 },
                                        [theme.breakpoints.down(400)]: { display: 'none' }
                                    }), children: t('tableColumns.1') }), isMdUp && (_jsxs(_Fragment, { children: [_jsx(TableCell, { sx: { fontWeight: 600 }, children: t('tableColumns.3') }), _jsx(TableCell, { sx: { fontWeight: 600 }, children: t('tableColumns.4') }), _jsx(TableCell, { sx: { fontWeight: 600 }, children: t('tableColumns.5') }), _jsx(TableCell, { sx: { fontWeight: 600 }, children: t('tableColumns.6') }), _jsx(TableCell, { sx: { fontWeight: 600 }, children: t('tableColumns.7') }), _jsx(TableCell, { sx: { fontWeight: 600 }, children: t('tableColumns.8') })] })), _jsx(TableCell, { sx: (theme) => ({
                                        fontWeight: 600,
                                        [theme.breakpoints.down('md')]: { px: 0 }
                                    }) }), !isMdUp && (_jsxs(_Fragment, { children: [_jsx(TableCell, { sx: (theme) => ({
                                                fontWeight: 600,
                                                [theme.breakpoints.down('md')]: { px: 0 }
                                            }) }), _jsx(TableCell, { sx: (theme) => ({
                                                fontWeight: 600,
                                                [theme.breakpoints.down('md')]: { px: 0 }
                                            }) })] }))] }) }), !isLoadingCustomers && !isFetching && (_jsx(TableBody, { children: customers.map((customer) => (_jsx(CustomerRow, { customer: customer }, customer.id))) }))] }), (isLoadingCustomers || !customers.length) && (_jsx(Box, { sx: {
                    mt: 18,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column'
                }, children: isLoadingCustomers || isFetching ? (_jsx(CircularProgress, {})) : (_jsxs(_Fragment, { children: [_jsx(PersonSearchOutlinedIcon, { color: 'action', fontSize: 'large', sx: { mb: 2 } }), _jsx(Typography, { mb: 1, fontWeight: 600, children: t('emptyText.primary') }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', children: t('emptyText.secondary') })] })) })), showPagination && (_jsx(Pagination, { pagination: pagination, perPage: perPage, setPerPage: setPerPage, setPage: setPage })), showSeeMoreBtn && (_jsx(Box, { sx: { mx: 4, my: 2 }, children: _jsx(RoundedButton, { fullWidth: true, variant: 'contained', onClick: () => getCustomers(undefined, pagination.current_page + 1), children: isFetching ? (_jsx(CircularProgress, { size: '24px' })) : (t('moreBtnLabel')) }) }))] }));
}
