import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchApi } from '../../config/Api';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import ConfirmationDialog from './ConfirmationDialog';
export default function CreateBranchButton(props) {
    const { user, selectedNetwork } = props;
    const { t } = useTranslation('network');
    const dialogRef = useRef();
    const getYourPlanDialogRef = useRef();
    const isAdmin = user.roles.some((role) => role.name === 'admin');
    const handleClick = () => {
        if (selectedNetwork.current_subscription === null) {
            getYourPlanDialogRef.current.open();
            return;
        }
        if (selectedNetwork.current_subscription !== null &&
            selectedNetwork.restaurants_availability === 0) {
            dialogRef.current.open();
            return;
        }
        location.replace(`/create-restaurant?network=${selectedNetwork.id}`);
    };
    const handleConfirm = () => {
        if (isAdmin &&
            selectedNetwork.current_subscription !== null &&
            selectedNetwork.restaurants_availability === 0) {
            const returnUrl = 'https://ordersmile.com/select-network';
            const encodedReturnUrl = encodeURIComponent(returnUrl);
            fetchApi({
                method: 'GET',
                url: `/network/${selectedNetwork.id}/billing_portal/sessions?return_url=${encodedReturnUrl}`
            }).then((res) => {
                if ('url' in res.data) {
                    window.location.href = res.data.url;
                }
            });
        }
        else {
            dialogRef.current?.close();
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { sx: {
                    px: 2,
                    height: 34,
                    bgcolor: 'grey.200',
                    borderRadius: '17px',
                    fontSize: 14,
                    fontWeight: 600,
                    color: 'GrayText',
                    textTransform: 'capitalize'
                }, startIcon: _jsx(AddIcon, { color: 'action', fontSize: 'small' }), onClick: handleClick, children: t('restaurantDrawer.primaryBtnLabel', { ns: 'common' }) }), _jsx(ConfirmationDialog, { ref: dialogRef, severity: 'info', title: t('dialogs.upgradePlan.title'), description: t('dialogs.upgradePlan.description'), primaryButtonLabel: isAdmin
                    ? t('dialogs.upgradePlan.primaryBtnLabelAdmin')
                    : t('dialogs.upgradePlan.primaryBtnLabel'), onPrimaryButtonClick: handleConfirm, icon: _jsx(StorefrontOutlinedIcon, { color: 'info', fontSize: 'large' }), onClose: () => dialogRef.current.close() }), _jsx(ConfirmationDialog, { ref: getYourPlanDialogRef, severity: 'info', title: t('dialogs.noActivePlan.title'), description: t('dialogs.noActivePlan.description', {
                    name: selectedNetwork.title
                }), primaryButtonLabel: isAdmin ? t('dialogs.noActivePlan.primaryBtnLabel') : undefined, onPrimaryButtonClick: () => location.replace(`/get-your-plan?id=${selectedNetwork.id}`), secondaryButtonLabel: isAdmin ? t('dialogs.noActivePlan.secondaryBtnLabel') : undefined, onSecondaryButtonClick: () => getYourPlanDialogRef.current?.close(), icon: _jsx(UploadOutlinedIcon, { color: 'info', fontSize: 'large' }), onClose: () => getYourPlanDialogRef.current.close() })] }));
}
