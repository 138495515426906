import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEmployeesContext } from '../EmployeeProvider';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeliveryDiningOutlinedIcon from '@mui/icons-material/DeliveryDiningOutlined';
import TabletOutlinedIcon from '@mui/icons-material/TabletOutlined';
import { useTheme, useMediaQuery } from '@mui/material';
import DropdownMenu from '../../components/DrodownMenu';
import CustomTooltip from '../../components/CustomTooltip';
const roleIcons = {
    Delivery: _jsx(DeliveryDiningOutlinedIcon, { fontSize: 'small', color: 'action' }),
    Kitchen: _jsx(TabletOutlinedIcon, { fontSize: 'small', color: 'action' }),
    Manager: _jsx(PersonOutlineIcon, { fontSize: 'small', color: 'action' })
};
export default function EmployeeRow({ employee }) {
    const [t] = useTranslation('employees');
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { setIsDrawerOpen, setSelectedEmployee, getEmployeePermissions, getEmployeeRestaurantList } = useEmployeesContext();
    const status = employee.blocked
        ? t('tableRow.status.inactive')
        : t('tableRow.status.active');
    const role = employee.roles
        .map((role) => t(`tableRow.role.${role.name}`))
        .join(', ');
    const created = moment(employee.created_at).format('MM/DD/YYYY');
    const handleEdit = () => {
        setSelectedEmployee(employee);
        if (employee.roles.some((role) => role.name === 'manager')) {
            getEmployeePermissions(employee.id);
            getEmployeeRestaurantList(employee.id);
        }
        setIsDrawerOpen(true);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(TableRow, { hover: true, onClick: handleEdit, children: [_jsxs(TableCell, { children: [_jsxs(Typography, { variant: 'body2', children: [employee.firstname, " ", employee.lastname, isMdDown && (_jsxs(Typography, { component: 'span', variant: 'body2', color: status === 'Active' ? 'success.main' : 'error.main', children: [' ', "\u2022 ", status] }))] }), isMdDown && (_jsx(Typography, { variant: 'body2', children: employee.email }))] }), isMdUp && (_jsxs(_Fragment, { children: [_jsx(TableCell, { children: employee.email }), _jsx(TableCell, { children: created }), _jsx(TableCell, { children: status })] })), _jsx(TableCell, { sx: (theme) => ({ [theme.breakpoints.down('md')]: { px: 0 } }), children: _jsx(Chip, { variant: 'outlined', label: role, icon: roleIcons[role], sx: { px: 1, height: 24 } }) }), isMdDown && (_jsx(TableCell, { sx: (theme) => ({ [theme.breakpoints.down('md')]: { px: 0 } }), children: _jsx(CustomTooltip, { tooltipProps: {
                                title: (_jsxs(_Fragment, { children: ["Created \u2022 ", created, _jsx("br", {}), "Status \u2022 ", status] }))
                            }, children: _jsx(InfoOutlinedIcon, { color: 'action' }) }) })), _jsx(TableCell, { sx: (theme) => ({ [theme.breakpoints.down('md')]: { px: 0 } }), children: _jsx(IconButton, { onClick: (e) => {
                                e.stopPropagation();
                                setAnchorEl(e.currentTarget);
                            }, children: _jsx(MoreVertIcon, {}) }) })] }, employee.id), _jsx(DropdownMenu, { anchorEl: anchorEl, onClose: () => setAnchorEl(null), options: [
                    {
                        disabled: true,
                        label: t('drowdownMenu.resetBtnLabel'),
                        icon: _jsx(KeyOutlinedIcon, {}),
                        action: () => { }
                    },
                    {
                        divider: true,
                        disabled: true,
                        label: t('drowdownMenu.suspendBtnLabel'),
                        icon: _jsx(PersonOffOutlinedIcon, {}),
                        action: () => { }
                    },
                    {
                        label: t('drowdownMenu.editBtnLabel'),
                        icon: _jsx(EditOutlinedIcon, {}),
                        action: handleEdit
                    },
                    {
                        disabled: true,
                        label: t('drowdownMenu.deleteBtnLabel'),
                        icon: _jsx(DeleteOutlinedIcon, {}),
                        action: () => { }
                    }
                ] })] }));
}
