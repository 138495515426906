import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useGalleryContext } from '../GalleryProvider';
import { generateImageUrl } from '../../UtilityFunctions';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import { useTheme, useMediaQuery } from '@mui/material';
import { RoundedButton } from '../../components/styled';
export default function GalleryImages(props) {
    const { showCoversOnly } = props;
    const [t] = useTranslation('gallery');
    const { setIsDrawerOpen, images, setSelectedImage } = useGalleryContext();
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    return (_jsxs(Grid, { container: true, spacing: 0.5, sx: { px: { xs: 2, md: 5 } }, children: [_jsx(Grid, { item: true, xs: 6, md: 6, lg: 4, xl: 3, children: _jsxs(Stack, { gap: 1, border: 1, alignItems: 'center', justifyContent: 'center', borderColor: 'divider', sx: { borderStyle: 'dashed', height: { xs: 190, md: 300 } }, children: [_jsx(PhotoOutlinedIcon, { fontSize: 'large', color: 'action' }), _jsx(RoundedButton, { color: 'inherit', variant: 'contained', startIcon: _jsx(AddIcon, {}), onClick: () => setIsDrawerOpen(true), children: t('addBtnLabel') })] }) }), images
                .filter((image) => (showCoversOnly ? image.is_cover : image))
                .map((image) => (_jsxs(Grid, { item: true, xs: 6, md: 6, lg: 4, xl: 3, sx: { position: 'relative' }, onClick: () => {
                    setSelectedImage(image);
                    setIsDrawerOpen(true);
                }, children: [_jsx(Box, { component: 'img', alt: '', src: generateImageUrl(image.path), sx: {
                            width: '100%',
                            display: 'block',
                            objectFit: 'cover',
                            height: { xs: 190, md: 300 }
                        } }), image.is_cover && (_jsxs(Box, { p: 1, sx: {
                            p: 1,
                            position: 'absolute',
                            color: '#fff',
                            borderRadius: '4px',
                            fontSize: '14px',
                            fontWeight: 600,
                            backgroundColor: 'primary.light',
                            left: {
                                xs: 8,
                                md: 16
                            },
                            bottom: {
                                xs: 8,
                                md: 16
                            }
                        }, children: [_jsx(PhotoAlbumIcon, { fontSize: 'small' }), " ", t('photoBadges.cover')] })), _jsxs(Box, { p: 1, bgcolor: 'rgba(0,0,0,0.6)', sx: {
                            position: 'absolute',
                            color: '#fff',
                            borderRadius: '4px',
                            fontSize: '14px',
                            fontWeight: 600,
                            right: {
                                xs: 8,
                                md: 16
                            },
                            bottom: {
                                xs: 8,
                                md: 16
                            }
                        }, children: [_jsx(FavoriteIcon, { fontSize: 'small' }), " ", image.likes, ' ', isMdUp && t('photoBadges.likes')] })] }, image.id)))] }));
}
