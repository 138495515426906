import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, useEffect } from "react";
import moment from "moment";
import { fetchApi } from "../../config/Api";
import { getRestaurantId } from "../UtilityFunctions";
const initialState = {
    searchTerm: "",
    setSearchTerm: () => { },
    orders: [],
    isLoadingOrders: false,
    selectedOrder: null,
    setSelectedOrder: () => { },
    isOrderDrawerOpen: false,
    setIsOrderDrawerOpen: () => { },
    dateFilter: {
        from: "",
        to: "",
    },
    setDateFilter: () => { },
    statusFilter: "last30Days",
    setStatusFilter: () => { },
    statistics: {
        totalOrdersCount: 0,
        averageOrderSpend: 0,
        totalTipsAmount: 0,
        totalOrdersSum: 0,
    },
    pagination: {
        total: 0,
        per_page: 0,
        current_page: 0,
        last_page: 0,
        from: 0,
        to: 0,
    },
    page: 1,
    setPage: () => { },
    perPage: 10,
    setPerPage: () => { },
    getOrders: () => { },
    isFetching: false,
};
const PaymentsContext = createContext(initialState);
export const usePaymentsContext = () => {
    const context = useContext(PaymentsContext);
    if (!context) {
        throw new Error("usePaymentsContext must be used within a PaymentsProvider");
    }
    return context;
};
export default function PaymentsProvider({ children }) {
    const [searchTerm, setSearchTerm] = useState("");
    // Orders
    const [orders, setOrders] = useState([]);
    const [isLoadingOrders, setIsLoadingOrders] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isOrderDrawerOpen, setIsOrderDrawerOpen] = useState(false);
    const [dateFilter, setDateFilter] = useState({
        from: moment().subtract(30, "day").format("DD/MM/yyyy"),
        to: moment().format("DD/MM/yyyy"),
    });
    const [statusFilter, setStatusFilter] = useState("all");
    const [statistics, setStatistics] = useState({
        totalOrdersCount: 0,
        averageOrderSpend: 0,
        totalTipsAmount: 0,
        totalOrdersSum: 0,
    });
    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 10,
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 0,
    });
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    function getOrders({ mergeData, nextPage, }) {
        let restaurantId = getRestaurantId();
        const params = new URLSearchParams({
            restaurant_id: restaurantId?.toString() ?? "",
            fromDate: dateFilter.from,
            toDate: dateFilter.to,
            page: nextPage?.toString() ?? page.toString(),
            perPage: perPage.toString(),
            ...(statusFilter !== "all" && { status: statusFilter }),
        });
        if (mergeData) {
            setIsFetching(true);
        }
        else {
            setIsLoadingOrders(true);
        }
        fetchApi({
            method: "GET",
            url: `/payments/list?${params}`,
        })
            .then((r) => {
            if (mergeData) {
                setOrders((prev) => [...prev, ...r.data.orders]);
            }
            else {
                setOrders(r.data.orders);
            }
            setStatistics(r.data.statistics);
            setPagination(r.data.pagination);
        })
            .catch(() => { })
            .finally(() => {
            setIsFetching(false);
            setIsLoadingOrders(false);
        });
    }
    useEffect(() => {
        getOrders({});
    }, [dateFilter, statusFilter, page, perPage]);
    const value = {
        searchTerm,
        setSearchTerm,
        orders,
        isLoadingOrders,
        selectedOrder,
        setSelectedOrder,
        isOrderDrawerOpen,
        setIsOrderDrawerOpen,
        dateFilter,
        setDateFilter,
        statusFilter,
        setStatusFilter,
        statistics,
        pagination,
        page,
        setPage,
        perPage,
        setPerPage,
        getOrders,
        isFetching,
    };
    return (_jsx(PaymentsContext.Provider, { value: value, children: children }));
}
