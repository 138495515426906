import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useCallback } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useCustomersContext } from './CustomerContext';
import { fetchApi } from '../../config/Api';
import { getNetworkId, getRestaurantId } from '../UtilityFunctions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DownloadIcon from '@mui/icons-material/Download';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTheme, useMediaQuery } from '@mui/material';
import SearchField from '../components/SearchField';
import CustomersTable from './components/CustomersTable';
import CustomerDrawer from './components/CustomerDrawer';
import CustomTooltip from '../components/CustomTooltip';
import { RoundedButton } from '../components/styled';
export default function CustomersContainer() {
    const [t] = useTranslation('customers');
    const [search, setSearch] = useState('');
    const [isExporting, setIsExporting] = useState(false);
    const { setSearchTerm, thisBranchOnly, setThisBranchOnly } = useCustomersContext();
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const debouncedSearch = useCallback(_.debounce((query) => {
        setSearchTerm(query);
    }, 500), []);
    const handleSearch = (value) => {
        setSearch(value);
        debouncedSearch(value);
    };
    const handleExport = () => {
        let networkId = getNetworkId();
        let restaurantId = getRestaurantId();
        const params = new URLSearchParams({
            network: networkId?.toString() ?? '',
            restaurant: restaurantId?.toString() ?? ''
        });
        setIsExporting(true);
        fetchApi({
            method: 'GET',
            url: `/usersList?${params}`
        })
            .then((res) => {
            const data = res.data.users.map((user) => ({
                Name: `${user.firstname} ${user.lastname}`,
                'Last Order': user.metadata.latestOrderDate,
                Phone: user.telephone,
                Email: user.email,
                'Order Count': user.metadata.totalOrdersCount,
                'Avg Order': user.metadata.averageOrderSpend,
                'Total Spend': user.metadata.totalOrdersSum,
                Status: user.blocked ? 'Blocked' : 'Active'
            }));
            const csvHeaders = Object.keys(data[0]);
            const csvRows = data.map((row) => csvHeaders.map((header) => row[header]).join(','));
            const csvContent = [csvHeaders.join(','), ...csvRows].join('\n');
            const blob = new Blob([csvContent], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'customers.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setIsExporting(false);
        })
            .catch((e) => {
            window.globalToast({
                type: 'error',
                title: 'Error',
                description: e.message
            });
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(CustomerDrawer, {}), _jsxs(Box, { sx: {
                    px: 4,
                    pt: 4,
                    pb: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 1600
                }, children: [_jsxs(Box, { sx: (theme) => ({
                            mb: 8,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            [theme.breakpoints.down('md')]: {
                                mb: 3,
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }
                        }), children: [_jsxs("div", { children: [_jsx(Typography, { fontSize: 20, fontWeight: 600, children: t('title') }), _jsx(Typography, { mb: 'auto', fontSize: 12, color: 'text.secondary', sx: (theme) => ({
                                            [theme.breakpoints.down('md')]: { maxWidth: 180 }
                                        }), children: t('subtitle') })] }), _jsxs(Box, { sx: (theme) => ({
                                    px: 2,
                                    py: 0.5,
                                    border: 1,
                                    borderRadius: 3,
                                    borderColor: 'divider',
                                    [theme.breakpoints.down('md')]: {
                                        mt: 3,
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }
                                }), children: [_jsx(FormControlLabel, { control: _jsx(Switch, { checked: thisBranchOnly, onChange: (e) => setThisBranchOnly(e.target.checked) }), label: t('switch.label') }), _jsx(CustomTooltip, { tooltipProps: {
                                            title: t('switch.tooltip'),
                                            placement: 'bottom'
                                        }, children: _jsx(InfoOutlinedIcon, { color: 'action' }) })] })] }), _jsxs(Box, { display: 'flex', justifyContent: 'space-between', gap: 3, children: [_jsx(SearchField, { fullWidth: isMdDown, placeholder: t('searchFieldPlaceholder'), value: search, onChange: handleSearch, onClear: () => handleSearch('') }), _jsx(RoundedButton, { color: 'inherit', variant: 'contained', startIcon: isExporting ? (_jsx(CircularProgress, { size: '24px' })) : (_jsx(DownloadIcon, {})), onClick: handleExport, children: t('exportBtnLabel') })] })] }), _jsx(Box, { sx: (theme) => ({
                    borderTop: 1,
                    borderColor: 'divider',
                    maxWidth: 1600,
                    [theme.breakpoints.up('md')]: {
                        mx: 4
                    }
                }), children: _jsx(CustomersTable, {}) })] }));
}
