import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { usePaymentsContext } from '../PaymentsContext';
import { CURRENCIES } from '../../../constants/currencies';
import { getStatusStyles } from '../../UtilityFunctions';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import TakeoutDiningOutlinedIcon from '@mui/icons-material/TakeoutDiningOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
export default function OrderRow(props) {
    const { order } = props;
    const [t] = useTranslation('financials');
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down(1024));
    const { setIsOrderDrawerOpen, setSelectedOrder } = usePaymentsContext();
    const handleClick = () => {
        setIsOrderDrawerOpen(true);
        setSelectedOrder(order);
    };
    const { color } = getStatusStyles(order.status);
    return (_jsxs(TableRow, { hover: true, onClick: handleClick, children: [isMdDown && (_jsx(TableCell, { children: order.type === 'delivery' ? (_jsx(DirectionsCarFilledOutlinedIcon, { color: 'info' })) : (_jsx(TakeoutDiningOutlinedIcon, { color: 'action' })) })), _jsxs(TableCell, { sx: (theme) => ({
                    [theme.breakpoints.down('md')]: { px: 0 }
                }), children: [_jsx(Typography, { component: 'span', fontSize: 14, sx: { textDecoration: 'underline' }, children: order.identifier }), isMdDown && (_jsxs(_Fragment, { children: [' ', "\u2022", ' ', _jsx(Typography, { component: 'span', color: color, fontSize: 12, children: t(`orders.statusFilter.options.${order.status}`) }), _jsx(Typography, { fontSize: 12, children: order.customer
                                    ? `${order.customer.firstname} ${order.customer.lastname}`
                                    : t('orders.deletedUser') })] }))] }), _jsxs(TableCell, { color: 'text.secondary', children: [_jsx(Typography, { component: 'span', color: 'text.secondary', fontSize: 14, children: moment(order.created_at).format('MM/DD/yyyy') }), _jsx("br", {}), _jsx(Typography, { component: 'span', color: 'text.secondary', fontSize: 12, children: moment(order.created_at).format('h:mm a') })] }), isMdDown ? (_jsxs(_Fragment, { children: [_jsx(TableCell, { sx: (theme) => ({ [theme.breakpoints.down('md')]: { px: 0 } }), children: _jsx(Tooltip, { arrow: true, placement: 'top', title: _jsxs(_Fragment, { children: [_jsxs(Typography, { children: [t('orders.tableColumns.5'), " =", ' ', order.bill?.subtotal.toFixed(2), ","] }), _jsxs(Typography, { children: [t('orders.tableColumns.6'), " = ", order.bill?.tax, ","] }), _jsxs(Typography, { children: [t('orders.tableColumns.7'), " = ", order.bill?.tip.toFixed(2), ","] }), _jsxs(Typography, { children: [t('orders.tableColumns.8'), " =", ' ', order.bill?.total.toFixed(2)] })] }), componentsProps: {
                                tooltip: {
                                    sx: {
                                        zIndex: 10,
                                        borderRadius: '8px',
                                        bgcolor: 'rgba(33,33,33,0.9)'
                                    }
                                }
                            }, slotProps: {
                                popper: {
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -10]
                                            }
                                        }
                                    ]
                                }
                            }, open: open, onClose: () => setOpen(false), children: _jsx(IconButton, { onClick: (e) => {
                                    e.stopPropagation();
                                    setOpen(true);
                                }, children: _jsx(InfoOutlinedIcon, {}) }) }) }), _jsx(TableCell, { sx: (theme) => ({ [theme.breakpoints.down('md')]: { px: 0 } }), children: _jsx(IconButton, { children: _jsx(MoreVertOutlinedIcon, {}) }) })] })) : (_jsxs(_Fragment, { children: [_jsx(TableCell, { children: order.customer
                            ? `${order.customer.firstname} ${order.customer.lastname}`
                            : t('orders.deletedUser') }), _jsxs(TableCell, { children: [order.type === 'delivery' ? (_jsx(DirectionsCarFilledOutlinedIcon, { color: 'info' })) : (_jsx(TakeoutDiningOutlinedIcon, { color: 'action' })), _.startCase(order.type)] }), _jsxs(TableCell, { children: [CURRENCIES[order.bill.currency], order.bill?.subtotal.toFixed(2)] }), _jsxs(TableCell, { children: [CURRENCIES[order.bill.currency], order.bill?.tax] }), _jsxs(TableCell, { children: [CURRENCIES[order.bill.currency], order.bill?.tip.toFixed(2)] }), _jsxs(TableCell, { children: [CURRENCIES[order.bill.currency], order.bill?.total.toFixed(2)] }), _jsx(TableCell, { children: _jsx(Typography, { component: 'span', fontSize: 14, color: color, children: t(`orders.statusFilter.options.${order.status}`) }) })] }))] }));
}
