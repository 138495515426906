import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltip from '../../components/CustomTooltip';
import { RoundedTextField, PrimaryButton } from '../../components/styled';
import { Alert, AlertTitle } from '@mui/material';
export default function LoginForm() {
    const { t } = useTranslation('auth');
    const [errors, setErrors] = useState([]);
    const [showPass, setShowPass] = useState(false);
    let token = document.head
        .querySelector('meta[name="csrf-token"]')
        ?.getAttribute('content');
    useEffect(() => {
        if ('serverErrors' in window && Array.isArray(window.serverErrors)) {
            setErrors([...window.serverErrors]);
        }
    }, []);
    return (_jsxs(Stack, { gap: 3, width: '100%', component: 'form', action: '/login', method: 'post', children: [_jsx("input", { type: 'hidden', name: '_token', value: token }), _jsxs(Stack, { direction: 'row', alignItems: 'center', gap: 2, children: [_jsxs(RoundedTextField, { required: true, select: true, label: t('form.fields.role.label'), defaultValue: '', name: 'role', sx: { flexGrow: 1 }, children: [_jsx(MenuItem, { value: 'admin', children: t('form.fields.role.options', { returnObjects: true })[0] }), _jsx(MenuItem, { value: 'manager', children: t('form.fields.role.options', { returnObjects: true })[1] })] }), _jsx(CustomTooltip, { tooltipProps: {
                            title: t('form.fields.role.tooltip')
                        }, children: _jsx(InfoOutlinedIcon, { color: 'action' }) })] }), _jsx(RoundedTextField, { required: true, fullWidth: true, label: t('form.fields.email.label'), name: 'email' }), _jsx(RoundedTextField, { required: true, fullWidth: true, label: t('form.fields.password.label'), name: 'password', type: showPass ? 'text' : 'password', slotProps: {
                    input: {
                        endAdornment: (_jsx(IconButton, { edge: 'end', onClick: () => setShowPass((prev) => !prev), children: showPass ? (_jsx(VisibilityOff, { sx: { color: 'text.secondary' } })) : (_jsx(Visibility, { sx: { color: 'text.secondary' } })) }))
                    }
                } }), _jsx(Link, { href: '/password/reset', sx: { alignSelf: 'flex-end' }, children: t('form.forgotPasswordLink') }), _jsx(PrimaryButton, { type: 'submit', size: 'large', variant: 'contained', sx: { height: 54, textTransform: 'none' }, children: t('form.submitBtn') }), errors.length > 0 && (_jsxs(Alert, { severity: 'error', sx: { borderRadius: 3 }, children: [_jsx(AlertTitle, { sx: { fontWeight: 700 }, children: t('form.error.title') }), t('form.error.message')] }))] }));
}
