import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomersContext } from '../CustomerContext';
import { getCurrency, formatDate } from '../../UtilityFunctions';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { useTheme, useMediaQuery } from '@mui/material';
import DropdownMenu from '../../components/DrodownMenu';
export default function CustomerRow({ customer }) {
    const [t] = useTranslation('customers');
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { setIsDrawerOpen, setSelectedCustomer, getCustomers, updateCustomer } = useCustomersContext();
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up(1024));
    const isMdDown = useMediaQuery(theme.breakpoints.down(1024));
    const handleEdit = () => {
        setSelectedCustomer(customer);
        setIsDrawerOpen(true);
    };
    const status = customer.blocked ? 'blocked' : 'active';
    const { country_code, telephone } = customer;
    const formattedNumber = country_code === '+355'
        ? `${country_code} ${telephone.slice(0, 2)} ${telephone.slice(2, 5)} ${telephone.slice(5)}`
        : `${country_code} (${telephone.slice(0, 3)}) ${telephone.slice(3, 6)}-${telephone.slice(6)}`;
    const handleStatus = async () => {
        await updateCustomer(customer.id, { blocked: !customer.blocked })
            .then(() => {
            window.globalToast({
                title: 'Success',
                type: 'success',
                description: `Customer ${customer.blocked ? 'unblocked' : 'blocked'}  succesfully`
            });
            setAnchorEl(null);
            getCustomers();
        })
            .catch(() => {
            window.globalToast({
                title: 'Error',
                type: 'error',
                description: 'Something went wrong!'
            });
        });
    };
    const currency = getCurrency();
    return (_jsxs(_Fragment, { children: [_jsxs(TableRow, { hover: true, onClick: handleEdit, children: [_jsxs(TableCell, { children: [_jsxs(Typography, { variant: 'body2', children: [customer.firstname, " ", customer.lastname, isMdDown && (_jsxs(Typography, { component: 'span', variant: 'body2', color: status === 'active' ? 'success.main' : 'error.main', children: [' ', "\u2022 ", t(`status.${status}`)] }))] }), isMdDown && (_jsx(Typography, { variant: 'body2', sx: { textDecoration: 'underline', color: 'primary.main' }, children: customer.email }))] }), _jsx(TableCell, { sx: (theme) => ({
                            [theme.breakpoints.down('md')]: { px: 0 },
                            [theme.breakpoints.down(400)]: { display: 'none' }
                        }), children: customer.metadata.latestOrderDate
                            ? formatDate(customer.metadata.latestOrderDate)
                            : '-' }), isMdUp && (_jsxs(_Fragment, { children: [_jsx(TableCell, { children: formattedNumber }), _jsx(TableCell, { sx: { textDecoration: 'underline', color: 'primary.main' }, children: customer.email }), _jsx(TableCell, { children: customer.metadata.totalOrdersCount }), _jsxs(TableCell, { children: [currency, customer.metadata.averageOrderSpend.toFixed(2)] }), _jsxs(TableCell, { children: [currency, customer.metadata.totalOrdersSum.toFixed(2)] }), _jsx(TableCell, { children: t(`status.${status}`) })] })), isMdDown && (_jsxs(_Fragment, { children: [_jsx(TableCell, { sx: (theme) => ({ [theme.breakpoints.down('md')]: { px: 0 } }), children: _jsx(IconButton, { onClick: (e) => {
                                        e.stopPropagation();
                                        window.open(`tel:${customer.telephone}`);
                                    }, children: _jsx(CallOutlinedIcon, {}) }) }), _jsx(TableCell, { sx: (theme) => ({ [theme.breakpoints.down('md')]: { px: 0 } }), children: _jsx(Tooltip, { arrow: true, placement: 'top', title: _jsxs(_Fragment, { children: [_jsxs(Typography, { children: [t('tableColumns.5'), " =", ' ', customer.metadata.totalOrdersCount, ","] }), _jsxs(Typography, { children: [t('tableColumns.6'), " = ", currency, customer.metadata.averageOrderSpend.toFixed(2), ","] }), _jsxs(Typography, { children: [t('tableColumns.7'), "= ", currency, customer.metadata.totalOrdersSum.toFixed(2)] })] }), componentsProps: {
                                        tooltip: {
                                            sx: {
                                                zIndex: 10,
                                                borderRadius: '8px',
                                                bgcolor: 'rgba(33,33,33,0.9)'
                                            }
                                        }
                                    }, slotProps: {
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -10]
                                                    }
                                                }
                                            ]
                                        }
                                    }, open: open, onClose: () => setOpen(false), children: _jsx(IconButton, { onClick: (e) => {
                                            e.stopPropagation();
                                            setOpen(true);
                                        }, children: _jsx(InfoOutlinedIcon, {}) }) }) })] })), _jsx(TableCell, { sx: (theme) => ({ [theme.breakpoints.down('md')]: { px: 0 } }), children: _jsx(IconButton, { onClick: (e) => {
                                e.stopPropagation();
                                setAnchorEl(e.currentTarget);
                            }, children: _jsx(MoreVertIcon, {}) }) })] }, customer.id), _jsx(DropdownMenu, { anchorEl: anchorEl, onClose: () => setAnchorEl(null), options: [
                    {
                        label: customer.blocked
                            ? t('rowButtons.unblockBtnLabel')
                            : t('rowButtons.blockBtnLabel'),
                        icon: customer.blocked ? (_jsx(PersonOutlineIcon, {})) : (_jsx(PersonOffOutlinedIcon, {})),
                        action: handleStatus
                    }
                ] })] }));
}
