import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { arrayMove } from '@dnd-kit/sortable';
import { useTranslation } from 'react-i18next';
import { MenuContext } from '../context';
import { ActionType } from '../context/types';
import { fetchApi } from '../../../config/Api';
import { getErrorMessage } from '../../UtilityFunctions';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import AddOutlined from '@mui/icons-material/AddOutlined';
import CategoryOutlined from '@mui/icons-material/CategoryOutlined';
import CategoriesListItem from './CategoriesListItem';
import Count from './Count';
import SearchField from './SearchField';
import { RoundedButton } from './styled';
import SortableContainer from '../../components/SortableContainer';
export default function CategoriesList() {
    const [t] = useTranslation('menu');
    const [searchTerm, setSearchTerm] = useState('');
    const { categories, loadingCategories, selectedMenu, toggleDrawer, dispatch } = useContext(MenuContext);
    function handleDragEnd(event) {
        const { active, over } = event;
        if (active.id !== over?.id) {
            const oldIndex = categories.findIndex((item) => item.id === active.id);
            const newIndex = categories.findIndex((item) => item.id === over?.id);
            const sortedItems = arrayMove(categories, oldIndex, newIndex);
            dispatch({
                type: ActionType.GET_CATEGORIES,
                payload: { categories: sortedItems }
            });
            fetchApi({
                method: 'POST',
                url: '/menu/reorder-categories',
                data: { categories: sortedItems.map(({ id }) => id) }
            }).catch((error) => {
                window.globalToast({
                    type: 'error',
                    title: 'Error',
                    description: getErrorMessage(error.response)
                });
            });
        }
    }
    const openCategoryDrawer = () => {
        toggleDrawer('isCategoryDrawerOpen');
    };
    return (_jsx(Box, { sx: (theme) => ({
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('lg')]: {
                gridArea: '2 / 1 / 3 / 2'
            }
        }), children: loadingCategories ? (_jsx(Stack, { mt: 16, alignItems: 'center', children: _jsx(CircularProgress, {}) })) : !categories.length ? (_jsxs(Stack, { mt: 16, alignItems: 'center', children: [_jsx(CategoryOutlined, { sx: { fontSize: 32 }, color: 'disabled' }), _jsx(Typography, { fontSize: 16, fontWeight: 600, mb: 1, mt: 2, children: t('overview.categories.emptyText.primary') }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', mb: 3, children: t('overview.categories.emptyText.secondary') }), selectedMenu && (_jsx(RoundedButton, { variant: 'contained', startIcon: _jsx(AddOutlined, {}), onClick: openCategoryDrawer, children: t('overview.categories.btnLabels.newCategoryBtnLabel') }))] })) : (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: (theme) => ({
                        [theme.breakpoints.up('md')]: {
                            overflowY: 'auto'
                        }
                    }), children: [_jsxs(Box, { sx: (theme) => ({
                                p: 3,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 2,
                                [theme.breakpoints.down('md')]: {
                                    flexDirection: 'column',
                                    alignItems: 'flex-start'
                                }
                            }), children: [_jsx(Count, { label: t('overview.categories.title'), count: categories.length }), _jsx(SearchField, { value: searchTerm, onChange: setSearchTerm, placeholder: t('overview.categories.searchFieldPlaceholder') })] }), _jsxs(List, { children: [_jsxs(ListItem, { divider: true, sx: { paddingLeft: '89px' }, children: [_jsx(ListItemAvatar, { children: _jsx(Typography, { fontSize: 14, fontWeight: 600, children: t('overview.categories.tableColumns.photo') }) }), _jsx(ListItemText, { children: _jsx(Typography, { fontSize: 14, fontWeight: 600, children: t('overview.categories.tableColumns.name') }) })] }), _jsx(SortableContainer, { items: categories, onDragEnd: handleDragEnd, children: categories
                                        .filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                        .map((item) => (_jsx(CategoriesListItem, { ...item }, item.id))) })] })] }), _jsx(RoundedButton, { variant: 'contained', startIcon: _jsx(AddOutlined, {}), onClick: openCategoryDrawer, sx: {
                        mx: 4,
                        my: 2,
                        alignSelf: 'flex-start'
                    }, children: t('overview.categories.btnLabels.newCategoryBtnLabel') })] })) }));
}
