import { styled } from '@mui/system';
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import FormControl from '@mui/material/FormControl';
export const OSOuterContainer = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "1rem 0"
}));
export const OSInnerContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    padding: "0 1rem",
    [theme.breakpoints.down('sm')]: {
        padding: "0 0",
    }
    // xs: "0 0.25rem",
    ,
}));
export const OSContentBox = styled(Box)(() => ({
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    flexDirection: "column",
    // paddingTop: "0.5rem",
}));
export const OSDelPickContentBox = styled(Box)(({ theme }) => ({
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    [theme.breakpoints.down('sm')]: {
        width: "90%",
    }
}));
export const OSStepperTitleBox = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "80%",
    flexWrap: "wrap",
    [theme.breakpoints.down('sm')]: {
        position: "relative",
        left: "2.5rem",
    }
}));
export const OSStepperTitle = styled(Typography)(({ theme }) => ({
    fontSize: "1.5rem",
    fontWeight: "600",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    fontFamily: "inherit",
    [theme.breakpoints.down('sm')]: {
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
    }
    // paddingTop: "0.5rem",
}));
export const OSSwitchBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'item'
})(({ theme, item: item }) => ({
    display: "flex",
    width: "100%",
    marginTop: "1rem",
    marginBottom: "1rem",
    position: "relative",
    ".MuiFormControlLabel-labelPlacementStart": {
        justifyContent: "space-between",
        width: "100%",
        padding: "5px 14px",
        borderRadius: "0.5rem",
        boxSizing: "border-box",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: item ? "#42A5F5" : "#dcdcdc",
        marginLeft: 0,
    },
    ".MuiBox-root": {
        width: "100%"
    },
    ".MuiCheckbox-root": {
        display: "flex"
    },
    ".MuiInputBase-formControl": {
        borderRadius: "0.5rem !important",
    },
}));
export const OSSwitch = styled(FormControlLabel)(() => ({}));
export const OSTextBox = styled(Box)(({ theme }) => ({
    display: "flex",
    position: "relative",
    justifyItems: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: "0.5rem",
    marginTop: "1rem",
    marginBottom: "1rem",
    marginHorizontal: "0",
    ".MuiInputBase-formControl": {
        borderRadius: "0.5rem",
    },
    [theme.breakpoints.down('sm')]: {
        display: "block"
    }
}));
export const OSText = styled(TextField)(() => ({}));
export const OSTextWithSelectBox = styled(Box)(({ theme }) => ({
    display: "flex",
    position: "relative",
    marginTop: "1rem",
    marginBottom: "1rem",
    justifyItems: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: "0.5rem",
    padding: "0",
    ".MuiInputBase-inputAdornedEnd": {
        padding: "13px 14px !important",
        borderRadius: "0.5rem",
    },
    ".MuiInputBase-formControl": {
        borderRadius: "0.5rem",
    },
    ".MuiBox-root": {
        width: "100%"
    },
    ".MuiCheckbox-root": {
        display: "flex"
    },
    [theme.breakpoints.down('sm')]: {
    // display: "block",
    }
}));
export const OSSelect = styled(Select)(() => ({
    borderRadius: "0.5rem"
}));
export const OSStepTitleBox = styled(Box)(() => ({
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
}));
export const OSStepTitle = styled(Typography)(() => ({
    fontSize: "1.125rem",
    fontWeight: "600"
}));
export const OSUploadImageBox = styled(Box)(() => ({
    display: "flex",
    width: "100%",
    paddingTop: "1rem",
    paddingBottom: "1rem",
}));
export const OSMapBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    // width: "100%",
    margin: "1rem 0.5rem",
    flex: "1 1 0%",
}));
export const OSMapButtonsBox = styled(Box)(() => ({
    display: "flex",
    padding: "0.75rem"
}));
export const OSTimelineDay = styled(Box)(() => ({
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    width: "48% !important",
    textTransform: "capitalize",
    ".MuiBox-root": {
    // width: "48%",
    }
}));
export const OSTimelineSwitchBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'item'
})(({ theme, item: item }) => ({
    display: "flex",
    padding: "5px 14px",
    borderRadius: "0.5rem",
    boxSizing: "border-box",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: item ? "#42A5F5" : "#dcdcdc",
    // marginBottom: "2rem",
    marginRight: "0.5rem",
    minWidth: "244px",
    ".MuiFormControlLabel-labelPlacementStart": {
        justifyContent: "space-between",
        marginLeft: 0,
    },
    ".MuiBox-root": {
        width: "100%"
    },
    ".MuiCheckbox-root": {
        display: "flex"
    },
    ".MuiInputBase-formControl": {
        borderRadius: "0.5rem !important",
    },
    [theme.breakpoints.down('sm')]: {
        width: "100%",
        justifyContent: "space-between",
    }
}));
export const OSTimelineSwitch = styled(FormControlLabel)(() => ({
    pl: "0.75rem",
    ".MuiFormControlLabel-labelPlacementStart": {
        justifyContent: "space-between",
        marginLeft: 0,
        width: "50% !important"
    },
    ".MuiBox-root": {
    // width: "80%"
    },
    ".MuiCheckbox-root": {
        display: "flex"
    }
}));
export const OSTimelineRangesBox = styled(Box)(({ theme }) => ({
    display: "contents",
    [theme.breakpoints.down('md')]: {
        display: "flex",
        marginTop: "1rem",
        marginLeft: "-0.5rem",
        justifyContent: "space-between"
    },
}));
export const OSTimelineRow = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        width: "100%",
    }
}));
export const OSStepperContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    [theme.breakpoints.down('md')]: {
        display: "flex",
        flexDirection: "column",
        width: "auto",
        marginTop: "1.25rem",
        marginBottom: "1.25rem",
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
    },
    // [theme.breakpoints.down('md')]: {
    flexDirection: "row",
    marginTop: "4rem",
    marginBottom: "4rem",
    paddingLeft: "4rem",
    paddingRight: "4rem",
    // },
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 0,
        paddingRight: 0,
    }
    // "flex flex-col md:flex-row w-auto my-5 sm:my-16 xl:px-0 px-2 sm:px-16"
}));
export const OSStepperBox = styled(Box)(({ theme }) => ({
    //w-full sm:w-1/3 sm:h-screen transition-transform -translate-x-full sm:translate-x-0
    width: "33.3%",
    // height: "100vh",
    transitionProperty: "transform",
    transformTranslateX: "-100%",
    ".Mui-disabled": {
        display: "flex"
    },
    ".Mui-completed": {
        display: "flex"
    },
    ".MuiStepConnector-line": {
        display: "flex"
    },
    ".MuiStepLabel-labelContainer": {
        width: "full"
    },
    ".MuiStepLabel-vertical": {
        justifyContent: "inherit"
    },
    [theme.breakpoints.down('md')]: {
        width: "100%",
        ".Mui-disabled": {
            display: "none"
        },
        ".Mui-completed": {
            display: "none"
        },
        ".MuiStepConnector-line": {
            display: "none"
        },
        ".MuiStepLabel-labelContainer": {
            width: "auto",
            display: "none",
        },
        ".MuiStepLabel-vertical": {
            justifyContent: "center",
            paddingTop: "1.5rem",
            paddingBottom: "1.5rem"
        },
        ".MuiStepLabel-iconContainer:before, .MuiStepLabel-iconContainer:after": {
            content: "''",
            borderBottom: "lightgray solid",
            display: "inline-block",
            alignSelf: "center",
            width: "1rem"
        },
        ".MuiStepLabel-iconContainer:before": {
            marginLeft: "auto",
        },
        ".MuiStepLabel-iconContainer:after": {
            marginRight: "auto",
        },
        ".MuiStepLabel-iconContainer svg": {
            marginLeft: "0.6rem",
            marginRight: "0.6rem"
        },
    }
}));
export const OSContentContainerBox = styled(Box)(({ theme }) => ({
    width: "33.3%",
    display: "flex",
    [theme.breakpoints.down('md')]: {
        width: "100%",
        justifyContent: "center",
    }
}));
export const OSTimeRangeBox = styled(Box)(({ theme }) => ({
    ".MuiOutlinedInput-root": {
        width: 138,
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
    },
    ".MuiOutlinedInput-input": {
        padding: "13px 5px",
    },
    ".MuiOutlinedInput-notchedOutline": {
        borderRadius: "0.5rem",
        boxSizing: "border-box",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#42A5F5"
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: "1rem",
        ".MuiOutlinedInput-root": {
            marginLeft: "0",
            marginRight: "1rem",
        }
    },
    "label#time-label": {
        padding: "0 0.65rem",
    }
}));
export const OSDiscardBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    marginLeft: "-1.5rem",
    [theme.breakpoints.down('sm')]: {
        marginLeft: "0",
    }
}));
export const OSInlineSelect = styled(FormControl)(({ theme }) => ({
    width: "70%",
    paddingLeft: "0.5rem",
    top: "-0.125rem",
    [theme.breakpoints.down('sm')]: {
        width: "50%",
        top: "-0.05rem"
    }
}));
export const OSInlineText = styled(TextField)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        width: "50%",
    }
}));
export const OSInlineTooltip = styled(Tooltip)(({ theme }) => ({
    display: "flex",
    position: "absolute",
    height: "1.5rem",
    alignItems: "center",
    color: "#00000061",
    right: "-2.2rem",
    top: "0.65rem",
    [theme.breakpoints.down('sm')]: {}
}));
