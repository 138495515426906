import auth from './auth.json';
import network from './network.json';
import common from './common.json';
import store from './store.json';
import menu from './menu.json';
import financials from './financials.json';
import customers from './customers.json';
import employees from './employees.json';
import gallery from './gallery.json';
import settings from './settings.json';
const enResources = {
    auth,
    network,
    common,
    store,
    menu,
    financials,
    customers,
    employees,
    gallery,
    settings
};
export default enResources;
