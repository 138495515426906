import i18next from '../../i18n/config';
const mapActivity = (activity) => {
    let title, color;
    switch (activity) {
        case 'pending':
            title = i18next.t(`activity.legend.statistics.${activity}`, {
                ns: 'store'
            });
            color = '#4FC3F7';
            break;
        case 'in_preparation':
            title = i18next.t(`activity.legend.statistics.${activity}`, {
                ns: 'store'
            });
            color = '#1E88E5';
            break;
        case 'scheduled':
            title = i18next.t(`activity.legend.statistics.${activity}`, {
                ns: 'store'
            });
            color = '#FDD835';
            break;
        case 'ready_for_delivery':
            title = i18next.t(`activity.legend.statistics.${activity}`, {
                ns: 'store'
            });
            color = '#66BB6A';
            break;
        case 'ready_for_pickup':
            title = i18next.t(`activity.legend.statistics.${activity}`, {
                ns: 'store'
            });
            color = '#388E3C';
            break;
        case 'need_to_be_claimed':
            title = i18next.t(`activity.legend.statistics.${activity}`, {
                ns: 'store'
            });
            color = '#FBC02D';
            break;
        case 'collected_by_drivers':
            title = i18next.t(`activity.legend.statistics.${activity}`, {
                ns: 'store'
            });
            color = '#FF9800';
            break;
        case 'in_delivery':
            title = i18next.t(`activity.legend.statistics.${activity}`, {
                ns: 'store'
            });
            color = '#EF6C00';
            break;
        case 'canceled_by_customer':
            title = i18next.t(`activity.legend.statistics.${activity}`, {
                ns: 'store'
            });
            color = '#C62828';
            break;
        case 'collected_by_customers':
            title = i18next.t(`activity.legend.statistics.${activity}`, {
                ns: 'store'
            });
            color = '#7E57C2';
            break;
        case 'delivered':
            title = i18next.t(`activity.legend.statistics.${activity}`, {
                ns: 'store'
            });
            color = '#512DA8';
            break;
        case 'no_showed':
            title = i18next.t(`activity.legend.statistics.${activity}`, {
                ns: 'store'
            });
            color = '#757575';
            break;
        case 'rejected_by_kitchen':
            title = i18next.t(`activity.legend.statistics.${activity}`, {
                ns: 'store'
            });
            color = '#E57373';
            break;
        default:
            break;
    }
    return { title, color };
};
export const mapActivityData = (data) => {
    const mappedData = Object.keys(data)
        .filter((key) => key !== 'metadata')
        .map((key) => {
        const { title, color } = mapActivity(key);
        return { title, color, ...data[key].metadata };
    });
    return mappedData;
};
