import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, useEffect } from 'react';
import { fetchApi } from '../../config/Api';
import { getNetworkId, getRestaurantId } from '../UtilityFunctions';
const initialState = {
    customers: [],
    isLoadingCustomers: false,
    searchTerm: '',
    setSearchTerm: () => { },
    isDrawerOpen: false,
    setIsDrawerOpen: () => { },
    selectedCustomer: null,
    setSelectedCustomer: () => { },
    getCustomers: () => { },
    updateCustomer: () => new Promise(() => { }),
    pagination: {
        total: 0,
        per_page: 0,
        current_page: 0,
        last_page: 0,
        from: 0,
        to: 0
    },
    page: 1,
    setPage: () => { },
    perPage: 10,
    setPerPage: () => { },
    thisBranchOnly: true,
    setThisBranchOnly: () => { },
    isFetching: false
};
const CustomersContext = createContext(initialState);
export const useCustomersContext = () => {
    const context = useContext(CustomersContext);
    if (!context) {
        throw new Error('useEmployeesContext must be used within a UserProvider');
    }
    return context;
};
export default function CustomersProvider({ children }) {
    const [customers, setCustomers] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 10,
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 0
    });
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [thisBranchOnly, setThisBranchOnly] = useState(true);
    const getCustomers = (query, nextPage) => {
        let networkId = getNetworkId();
        let restaurantId = getRestaurantId();
        const params = new URLSearchParams({
            network: networkId?.toString() ?? '',
            page: nextPage?.toString() ?? page.toString(),
            perPage: perPage.toString(),
            ...(thisBranchOnly && { restaurant_id: restaurantId?.toString() ?? '' }),
            ...(query && { search: query })
        });
        if (nextPage) {
            setIsFetching(true);
        }
        else {
            setIsLoadingCustomers(true);
        }
        fetchApi({
            method: 'GET',
            url: `/usersList?${params}`
        })
            .then((res) => {
            if (nextPage) {
                setCustomers((prev) => [...prev, ...res.data.users]);
            }
            else {
                setCustomers(res.data.users);
            }
            setPagination(res.data.pagination);
        })
            .catch((e) => {
            window.globalToast({
                type: 'error',
                title: 'Error',
                description: e.message
            });
        })
            .finally(() => {
            setIsFetching(false);
            setIsLoadingCustomers(false);
        });
    };
    const updateCustomer = (id, { notes, blocked }) => {
        let networkId = getNetworkId();
        let restaurantId = getRestaurantId();
        const params = new URLSearchParams({
            network: networkId?.toString() ?? '',
            restaurant: restaurantId?.toString() ?? '',
            user_id: id.toString(),
            ...(notes && { guest_notes: notes }),
            ...(blocked !== undefined && { blocked: blocked.toString() })
        });
        return fetchApi({
            method: 'PUT',
            url: `/user/update?${params}`
        });
    };
    useEffect(() => {
        getCustomers(searchTerm);
    }, [page, perPage, thisBranchOnly, searchTerm]);
    const value = {
        customers,
        selectedCustomer,
        setSelectedCustomer,
        isLoadingCustomers,
        searchTerm,
        setSearchTerm,
        isDrawerOpen,
        setIsDrawerOpen,
        updateCustomer,
        getCustomers,
        pagination,
        page,
        setPage,
        perPage,
        setPerPage,
        thisBranchOnly,
        setThisBranchOnly,
        isFetching
    };
    return (_jsx(CustomersContext.Provider, { value: value, children: children }));
}
