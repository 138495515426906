import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import List from '@mui/material/List';
import RestaurantItem from './RestaurantItem';
export default function RestaurantList(props) {
    const { restaurants, status, setStatus, selectedNetwork, refetchRestaurants } = props;
    const { t } = useTranslation('common');
    return (_jsxs(_Fragment, { children: [status === 'deleted' && (_jsx(Box, { p: 2, children: _jsxs(Alert, { severity: 'warning', children: [_jsx(AlertTitle, { sx: { fontWeight: 600 }, children: t('restaurantDrawer.warningAlert.title') }), t('restaurantDrawer.warningAlert.description')] }) })), _jsx(List, { disablePadding: true, children: restaurants.map((restaurant) => (_jsx(RestaurantItem, { restaurant: restaurant, status: status, setStatus: setStatus, selectedNetwork: selectedNetwork, refetchRestaurants: refetchRestaurants }, restaurant.id))) })] }));
}
