import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { fetchApi } from '../../../config/Api';
import { getRestaurantId } from '../../UtilityFunctions';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ToggleGroup from '../../components/ToggleGroup';
import LiveActivity from './LiveActivity';
import PeakHours from './PeakHours';
function Activity() {
    const [t] = useTranslation('store');
    const [activityData, setActivityData] = useState(null);
    const [peakHoursData, setPeakHoursData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState('live-activity');
    async function fetchLiveAcitivity() {
        try {
            setIsLoading(true);
            const restaurantId = getRestaurantId();
            const res = await fetchApi({
                url: `/restaurant/${restaurantId}/live-activity`
            });
            setActivityData(res.data.activity);
        }
        catch (error) {
            console.log(error);
        }
        finally {
            setIsLoading(false);
        }
    }
    async function fetchPeakHours() {
        try {
            const restaurantId = getRestaurantId();
            const fromDate = moment().format('DD/MM/YYYY');
            const toDate = moment().format('DD/MM/YYYY');
            const res = await fetchApi({
                url: `/restaurant/${restaurantId}/peak-hours?fromDate=${fromDate}&toDate=${toDate}`
            });
            setPeakHoursData(res.data);
        }
        catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchLiveAcitivity();
        fetchPeakHours();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Box, { my: 4, children: _jsx(ToggleGroup, { value: value, onChange: setValue, options: [
                        {
                            label: t('activity.toggleOptions', { returnObjects: true })[0],
                            value: 'live-activity'
                        },
                        {
                            label: t('activity.toggleOptions', { returnObjects: true })[1],
                            value: 'peak-hours'
                        }
                    ], borderRadius: 8 }) }), _jsx(Box, { border: 1, borderRadius: 3, borderColor: 'divider', display: 'grid', minHeight: 520, mb: 4, overflow: 'hidden', children: isLoading || !activityData || !peakHoursData ? (_jsx(CircularProgress, { sx: { m: 'auto' } })) : (_jsxs(_Fragment, { children: [value === 'live-activity' && _jsx(LiveActivity, { data: activityData }), value === 'peak-hours' && _jsx(PeakHours, { data: peakHoursData })] })) })] }));
}
export default Activity;
