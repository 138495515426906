import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
const Input = styled("input")(({ theme }) => ({
    height: "34px",
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    flexGrow: 1,
    color: theme.palette.text.secondary,
    fontSize: "14px",
}));
export default function SearchField(props) {
    const { value, onChange, placeholder } = props;
    return (_jsxs(Box, { px: 2, maxWidth: '340px', width: '100%', height: '36.5px', sx: (theme) => ({
            display: "flex",
            alignItems: "center",
            backgroundColor: "grey.50",
            borderRadius: "50px",
            gap: 1,
            [theme.breakpoints.down('md')]: {
                maxWidth: 'unset'
            }
        }), children: [_jsx(SearchOutlined, {}), _jsx(Input, { placeholder: placeholder, value: value, onChange: e => onChange(e.target.value) })] }));
}
