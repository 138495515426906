import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useRef } from 'react';
import { z } from 'zod';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n/config';
import { fetchApi } from '../../../config/Api';
import { getNetworkId, getRestaurantId, getErrorMessage } from '../../UtilityFunctions';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import TabletOutlinedIcon from '@mui/icons-material/TabletOutlined';
import DeliveryDiningOutlinedIcon from '@mui/icons-material/DeliveryDiningOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SideDrawer from '../../components/SideDrawer';
import CloseDrawerDialog from '../../menu/components/CloseDrawerDialog';
import { RoundedTextField, RoundedButton, PrimaryButton, SecondaryButton } from '../../components/styled';
const roles = ['manager', 'online', 'delivery'];
const roleOptions = [
    {
        value: roles[0],
        label: i18n.t('drawers.inviteEmployee.roleExplanation.role1.title', {
            ns: 'employees'
        }),
        icon: _jsx(PersonOutlineIcon, {})
    },
    {
        value: roles[1],
        label: i18n.t('drawers.inviteEmployee.roleExplanation.role3.title', {
            ns: 'employees'
        }),
        icon: _jsx(TabletOutlinedIcon, {})
    },
    {
        value: roles[2],
        label: i18n.t('drawers.inviteEmployee.roleExplanation.role2.title', {
            ns: 'employees'
        }),
        icon: _jsx(DeliveryDiningOutlinedIcon, {})
    }
];
const Schema = z.object({
    email: z
        .string()
        .min(1, 'This field is required!')
        .email('Enter a valid email!'),
    role: z.string().min(1, 'This field is required!'),
    hours_per_day: z.string().refine((v) => {
        let n = Number(v);
        return !isNaN(n) && v?.length > 0;
    }, { message: 'Enter a posiive number' })
});
const defaultValues = {
    email: '',
    role: '',
    hours_per_day: ''
};
export default function NewEmployeeModal(props) {
    const { open, onClose } = props;
    const [t] = useTranslation('employees');
    const closeDialogRef = useRef();
    const [view, setView] = useState('form');
    const [isLoading, setIsLoading] = useState(false);
    const { reset, control, setValue, register, handleSubmit, formState: { errors, isDirty } } = useForm({
        defaultValues,
        resolver: zodResolver(Schema)
    });
    const handleClose = () => {
        if (isDirty) {
            closeDialogRef.current?.open();
        }
        else {
            onClose();
            setView('form');
            reset(defaultValues);
        }
    };
    const handleLeave = () => {
        onClose();
        setView('form');
        reset(defaultValues);
    };
    const onSubmit = (data) => {
        const networkId = getNetworkId();
        const restaurantId = getRestaurantId();
        if (networkId === null || restaurantId === null)
            return;
        setIsLoading(true);
        const formData = new FormData();
        formData.append('role', data.role);
        formData.append('email', data.email);
        formData.append('hours_per_day', data.hours_per_day.toString());
        formData.append('restaurantId', restaurantId.toString());
        formData.append('networkId', networkId.toString());
        fetchApi({
            url: '/employee/invite',
            method: 'POST',
            data: formData
        })
            .then(() => {
            handleLeave();
            window.globalToast({
                title: 'Success',
                description: 'Invitation sent successfully!',
                type: 'success'
            });
        })
            .catch((error) => {
            console.log('here');
            window.globalToast({
                type: 'error',
                title: 'Error',
                description: getErrorMessage(error.response)
            });
        })
            .finally(() => setIsLoading(false));
    };
    return (_jsxs(_Fragment, { children: [_jsx(CloseDrawerDialog, { ref: closeDialogRef, onLeave: handleLeave }), _jsxs(SideDrawer, { isOpen: open, onClose: handleLeave, primaryText: t('drawers.inviteEmployee.subtitle'), secondaryText: t('drawers.inviteEmployee.title'), children: [view === 'form' && (_jsxs(_Fragment, { children: [_jsxs(Box, { id: 'invite-form', component: 'form', sx: { p: 4, display: 'grid', gap: 4 }, onSubmit: handleSubmit(onSubmit), children: [_jsx(Alert, { severity: 'info', sx: { borderRadius: 2 }, children: t('drawers.inviteEmployee.infoAlert') }), _jsx(RoundedButton, { variant: 'contained', color: 'inherit', endIcon: _jsx(ArrowForward, { color: 'action' }), sx: {
                                            height: 56,
                                            boxShadow: 0,
                                            borderRadius: 3,
                                            justifyContent: 'space-between'
                                        }, onClick: () => setView('roles'), children: t('drawers.inviteEmployee.roleExplanation.btnLabel') }), _jsx(Controller, { name: 'role', control: control, render: ({ field: { value, onChange }, fieldState: { error } }) => (_jsx(RoundedTextField, { select: true, fullWidth: true, label: t('drawers.inviteEmployee.fields.role.label'), 
                                            // slotProps={{ select: { displayEmpty: true } }}
                                            value: value, onChange: (e) => onChange(e.target.value), error: !!error, helperText: errors.role?.message, children: roleOptions.map((option) => (_jsxs(MenuItem, { value: option.value, sx: (theme) => ({
                                                    '& .MuiSvgIcon-root': {
                                                        marginRight: theme.spacing(1.5)
                                                    }
                                                }), children: [option.icon, " ", option.label] }, option.value))) })) }), _jsx(RoundedTextField, { fullWidth: true, label: t('drawers.inviteEmployee.fields.email.label'), placeholder: t('drawers.inviteEmployee.fields.email.placeholder'), error: !!errors.email, ...register('email'), helperText: errors.email?.message }), _jsx(RoundedTextField, { fullWidth: true, label: t('drawers.inviteEmployee.fields.hours.label'), placeholder: t('drawers.inviteEmployee.fields.hours.placeholder'), error: !!errors.hours_per_day, helperText: errors.hours_per_day?.message, slotProps: {
                                            input: {
                                                endAdornment: t('drawers.inviteEmployee.fields.hours.helperText')
                                            }
                                        }, ...register('hours_per_day', {
                                            onChange(e) {
                                                const value = e.target.value.replace(/[^0-9]/g, '');
                                                setValue('hours_per_day', value, {
                                                    shouldDirty: true
                                                });
                                            }
                                        }) })] }), _jsxs(Box, { sx: {
                                    p: 2,
                                    mt: 'auto',
                                    position: 'sticky',
                                    bottom: 0,
                                    bgcolor: 'white',
                                    boxShadow: '0px -4px 10px 0px #00000026',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gap: 2
                                }, children: [_jsx(SecondaryButton, { type: 'button', variant: 'outlined', sx: { ml: 'auto' }, onClick: handleClose, children: t('drawers.inviteEmployee.secondaryBtnLabel') }), _jsx(PrimaryButton, { type: 'submit', form: 'invite-form', variant: 'contained', disabled: !isDirty || isLoading, children: isLoading ? (_jsx(CircularProgress, { size: '24px' })) : (t('drawers.inviteEmployee.primaryBtnLabel')) })] })] })), view === 'roles' && (_jsxs(Box, { sx: { p: 4, display: 'grid', gap: 4 }, children: [_jsxs(Box, { display: 'flex', alignItems: 'center', gap: 1, children: [_jsx(IconButton, { onClick: () => setView('form'), children: _jsx(ArrowBackIcon, { fontSize: 'large' }) }), _jsx(Typography, { fontWeight: 600, fontSize: 14, children: t('drawers.inviteEmployee.roleExplanation.btnLabel') })] }), _jsxs(Alert, { icon: _jsx(PersonOutlineIcon, { color: 'action' }), sx: { backgroundColor: 'grey.100', color: 'text.secondary' }, children: [_jsx(AlertTitle, { sx: { fontWeight: 600, color: 'black' }, children: t('drawers.inviteEmployee.roleExplanation.role1.title') }), t('drawers.inviteEmployee.roleExplanation.role1.description')] }), _jsxs(Alert, { icon: _jsx(DeliveryDiningOutlinedIcon, { color: 'action' }), sx: { backgroundColor: 'grey.100', color: 'text.secondary' }, children: [_jsx(AlertTitle, { sx: { fontWeight: 600, color: 'black' }, children: t('drawers.inviteEmployee.roleExplanation.role2.title') }), t('drawers.inviteEmployee.roleExplanation.role2.description')] }), _jsxs(Alert, { icon: _jsx(TabletOutlinedIcon, { color: 'action' }), sx: { backgroundColor: 'grey.100', color: 'text.secondary' }, children: [_jsx(AlertTitle, { sx: { fontWeight: 600, color: 'black' }, children: t('drawers.inviteEmployee.roleExplanation.role3.title') }), t('drawers.inviteEmployee.roleExplanation.role3.description')] })] }))] })] }));
}
