import jQuery from "jquery";
import LocationHelper from "./module/LocationHelper";
require("./bootstrap");

(function ($) {
  $(document).ready(function () {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };

    require("popper.js");
    require("moment");
    // require("window-date-picker");
    // require("simplebar");
    // require("simplebar/dist/simplebar.css");
    //
    LocationHelper.handleScriptImport();
  });
})(jQuery);
