import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useContext, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useTranslation } from 'react-i18next';
import { MenuContext } from '../context';
import { ActionType } from '../context/types';
import { getNetworkId, getErrorMessage, generateImageUrl } from '../../UtilityFunctions';
import { fetchApi } from '../../../config/Api';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import AddOutlined from '@mui/icons-material/AddOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import AssignMenuModal from './AssignMenuDialog';
import DragNDrop from '../../components/DragNDrop';
import DropdownMenu from './DropdownMenu';
import CloseDrawerDialog from './CloseDrawerDialog';
import { RoundedTextfield, PrimaryButton, SecondaryButton, RoundedButton } from './styled';
const Schema = z.object({
    category_name: z.string().min(1, 'This field is required!'),
    category_image: z.array(z.instanceof(File)).or(z.string().optional()),
    menus: z.array(z.number()).min(1)
});
const defaultValues = {
    category_name: '',
    menus: []
};
export default function CategoryDrawer() {
    const [t] = useTranslation('menu');
    const [assignMenuModal, setAssignMenuModal] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { isCategoryDrawerOpen, toggleDrawer, menus, draftCategory, getCategories, dispatch, getItems } = useContext(MenuContext);
    const { watch, reset, setValue, register, handleSubmit, formState: { errors, isDirty } } = useForm({
        resolver: zodResolver(Schema),
        defaultValues
    });
    const closeDialogRef = useRef();
    const handleClose = () => {
        if (isDirty) {
            closeDialogRef.current?.open();
        }
        else {
            toggleDrawer('isCategoryDrawerOpen');
            dispatch({ type: ActionType.SET_DRAFT_CATEGORY, payload: null });
            reset(defaultValues);
        }
    };
    const handleLeave = () => {
        toggleDrawer('isCategoryDrawerOpen');
        dispatch({ type: ActionType.SET_DRAFT_CATEGORY, payload: null });
        reset(defaultValues);
    };
    const networkId = getNetworkId();
    const onSubmit = (data) => {
        const url = draftCategory
            ? `/menu/category/update/${draftCategory.id}`
            : '/menu/category/new';
        const formData = new FormData();
        formData.append('network_id', networkId);
        formData.append('category_name', data.category_name);
        if (data.category_image) {
            formData.append('category_image', data.category_image[0]);
        }
        else {
            formData.append('category_image', 'null');
        }
        data.menus.forEach((menuId, idx) => formData.append(`menus[${idx}]`, menuId.toString()));
        setIsSaving(true);
        fetchApi({
            method: 'POST',
            url,
            data: formData
        })
            .then(() => {
            window.globalToast({
                title: 'Success',
                type: 'success',
                description: 'Menu category added successfully!'
            });
            reset(defaultValues);
            dispatch({ type: ActionType.SET_DRAFT_CATEGORY, payload: null });
            toggleDrawer('isCategoryDrawerOpen');
            getCategories();
            getItems();
        })
            .catch((e) => {
            window.globalToast({
                type: 'error',
                title: 'Error',
                description: getErrorMessage(e.response)
            });
        })
            .finally(() => setIsSaving(false));
    };
    const categoryImage = watch('category_image');
    const imgSrc = typeof categoryImage === 'string'
        ? generateImageUrl(categoryImage)
        : categoryImage
            ? URL.createObjectURL(categoryImage[0])
            : '';
    useEffect(() => {
        if (draftCategory) {
            reset({
                category_name: draftCategory.name,
                category_image: draftCategory.image ?? undefined,
                menus: draftCategory.new_menus.map((menu) => menu.id)
            });
        }
    }, [draftCategory]);
    return (_jsxs(_Fragment, { children: [_jsx(CloseDrawerDialog, { ref: closeDialogRef, onLeave: handleLeave }), _jsx(AssignMenuModal, { isOpen: assignMenuModal, setIsOpen: setAssignMenuModal, selectedMenus: watch('menus'), setValue: setValue }), _jsxs(Drawer, { open: isCategoryDrawerOpen, onClose: handleClose, anchor: 'right', variant: 'temporary', PaperProps: {
                    sx: {
                        width: '100%',
                        maxWidth: '512px'
                    }
                }, slotProps: {
                    backdrop: {
                        sx: {
                            backgroundColor: 'transparent'
                        }
                    }
                }, sx: { zIndex: 1300 }, children: [_jsxs(Box, { py: 3, px: 4, borderBottom: 1, borderColor: 'divider', sx: {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            backgroundColor: '#fff'
                        }, children: [_jsxs(Box, { children: [_jsx(Typography, { component: 'span', color: 'text.secondary', children: t('drawers.category.title') }), _jsx(Typography, { component: 'h1', fontSize: 24, fontWeight: 600, children: draftCategory
                                            ? draftCategory.name
                                            : "t('drawers.category.subtitle')" })] }), _jsx(IconButton, { onClick: handleClose, children: _jsx(CloseOutlined, {}) })] }), _jsxs("form", { onSubmit: handleSubmit(onSubmit), style: {
                            display: 'grid',
                            overflowY: 'auto',
                            gridTemplateRows: '1fr auto',
                            flexGrow: 1
                        }, children: [_jsxs(Box, { overflow: 'auto', children: [_jsxs(Box, { py: 3, px: 4, children: [_jsx(DragNDrop, { imgSrc: imgSrc, error: errors.category_image?.message, onChange: (files) => setValue('category_image', files, { shouldDirty: true }), onDelete: () => setValue('category_image', undefined, { shouldDirty: true }) }), _jsx(RoundedTextfield, { fullWidth: true, label: t('drawers.category.form.name.label'), error: !!errors.category_name, ...register('category_name') })] }), _jsx(Divider, { sx: {
                                            height: '8px',
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                            borderTop: 1,
                                            borderColor: 'divider'
                                        } }), _jsxs(Box, { py: 3, px: 4, children: [_jsx(Typography, { mb: 1, component: 'h2', fontSize: 22, fontWeight: 600, children: t('drawers.category.form.menu.title') }), _jsx(Typography, { mb: 2, fontSize: 14, color: 'text.secondary', children: t('drawers.category.form.menu.description') }), errors.menus && (_jsx(Alert, { severity: 'error', sx: { mb: 2 }, children: t('drawers.category.form.menu.errorAlert') })), !!watch('menus').length && (_jsx(List, { children: menus
                                                    .filter((menu) => watch('menus').includes(menu.id))
                                                    .map((menu) => (_jsx(AssignedMenu, { menu: menu, handleRemove: () => setValue('menus', watch('menus').filter((menuId) => menuId !== menu.id), { shouldDirty: true }) }, menu.id))) })), _jsx(RoundedButton, { variant: 'contained', startIcon: _jsx(AddOutlined, {}), onClick: () => setAssignMenuModal(true), children: t('drawers.category.form.menu.assignMenuBtnLabel') })] })] }), _jsxs(Box, { py: 2, px: 4, gap: 2, display: 'flex', justifyContent: 'flex-end', boxShadow: '0px -4px 10px 0px rgba(0, 0, 0, 0.07)', children: [_jsx(SecondaryButton, { type: 'button', variant: 'outlined', onClick: handleClose, children: t('drawers.category.form.secondaryBtnLabel') }), _jsx(PrimaryButton, { type: 'submit', variant: 'contained', disabled: isSaving || !isDirty, children: isSaving ? (_jsx(CircularProgress, { size: '24px' })) : (t('drawers.category.form.secondaryBtnLabel')) })] })] })] })] }));
}
const AssignedMenu = (props) => {
    const { menu, handleRemove } = props;
    const [t] = useTranslation('menu');
    const [anchorEl, setAnchorEl] = useState(null);
    const { toggleDrawer, dispatch } = useContext(MenuContext);
    const handleEdit = () => {
        dispatch({
            type: ActionType.SET_EDIT_MENU,
            payload: menu
        });
        toggleDrawer('isMenuDrawerOpen');
        setAnchorEl(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx(ListItem, { divider: true, secondaryAction: _jsx(IconButton, { edge: 'end', "aria-label": 'delete', onClick: (e) => setAnchorEl(e.currentTarget), children: _jsx(MoreVertOutlined, {}) }), children: _jsx(ListItemText, { primary: menu.name }) }), _jsx(DropdownMenu, { anchorEl: anchorEl, onClose: () => setAnchorEl(null), options: [
                    {
                        icon: _jsx(EditOutlined, {}),
                        label: t('drawers.category.form.menu.dropdownOptions.edit'),
                        action: handleEdit
                    },
                    {
                        icon: _jsx(DeleteOutline, {}),
                        label: t('drawers.category.form.menu.dropdownOptions.remove'),
                        action: handleRemove
                    }
                ] })] }));
};
