import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNetworkContext } from './SelectNetworkProvider';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Header from '../components/Header';
import NetworkList from './components/NetworkList';
import WelcomeHeader from './components/WelcomeHeader';
import RestaurantDrawer from './components/RestaurantDrawer';
import NetworkDrawer from './components/NetworkDrawer';
import ConfirmationDialog from '../components/ConfirmationDialog';
const drawerWidth = 448;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: -drawerWidth,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginRight: 0
    }),
    [theme.breakpoints.down('lg')]: {
        marginRight: 0
    }
}));
export default function SelectNetworkContainer() {
    const { t } = useTranslation('network');
    const { selectedNetwork, networks } = useNetworkContext();
    const dialogRef = useRef();
    const handleDismiss = () => {
        const url = new URL(window.location.href);
        url.searchParams.delete('show_success_payment_popup');
        history.pushState(null, '', url);
        dialogRef.current?.close();
    };
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const showPopup = Boolean(searchParams.get('show_success_payment_popup'));
        if (showPopup) {
            dialogRef.current?.open();
        }
    }, []);
    const latestInvoice = networks
        .map((network) => network.current_subscription?.latest_invoice)
        .filter((invoice) => invoice !== undefined)
        .reduce((a, b) => (new Date(a.created_at) > new Date(b.created_at) ? a : b), {});
    return (_jsxs(_Fragment, { children: [_jsx(ConfirmationDialog, { ref: dialogRef, severity: 'info', title: t('dialogs.planPurchased.title', {
                    count: latestInvoice.quantity
                }), description: t('dialogs.planPurchased.description'), primaryButtonLabel: t('dialogs.planPurchased.primaryBtnLabel'), onPrimaryButtonClick: handleDismiss, icon: _jsx(CheckOutlinedIcon, { color: 'info', fontSize: 'large' }), onClose: () => dialogRef.current.close() }), _jsx(Header, {}), _jsxs(Box, { display: 'flex', children: [_jsx(Main, { open: Boolean(selectedNetwork), children: _jsxs(Box, { maxWidth: 448, mx: 'auto', mt: 8, children: [_jsx(WelcomeHeader, {}), _jsx(NetworkList, {})] }) }), _jsx(RestaurantDrawer, {}), _jsx(NetworkDrawer, {})] })] }));
}
