import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useImperativeHandle, useState, } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { blue, red, orange, green } from "@mui/material/colors";
import { PrimaryButton, SecondaryButton } from "./styled";
const color = {
    error: red,
    info: blue,
    warning: orange,
    success: green,
};
const ConfirmationDialog = forwardRef((props, ref) => {
    const { title, description, severity, primaryButtonLabel, secondaryButtonLabel, onPrimaryButtonClick, onSecondaryButtonClick, icon = _jsx(ErrorOutline, { color: severity, fontSize: "large" }), onClose, } = props;
    const [isOpen, setIsOpen] = useState(false);
    useImperativeHandle(ref, () => ({
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
    }), []);
    return (_jsxs(Dialog, { open: isOpen, fullWidth: true, maxWidth: "sm", PaperProps: { sx: { borderRadius: "12px" } }, onClose: () => setIsOpen(false), children: [_jsx(DialogTitle, { component: "div", display: "flex", justifyContent: "flex-end", children: onClose && (_jsx(IconButton, { onClick: onClose, children: _jsx(CloseOutlined, {}) })) }), _jsxs(DialogContent, { sx: {
                    display: "grid",
                    justifyItems: "center",
                }, children: [_jsx(Box, { sx: {
                            mb: "1.5rem",
                            width: "48px",
                            height: "48px",
                            borderRadius: "50%",
                            display: "grid",
                            placeContent: "center",
                            backgroundColor: color[severity][50],
                        }, children: icon }), _jsx(Typography, { mb: "0.5rem", fontSize: "20px", fontWeight: 600, textAlign: "center", children: title }), _jsx(Typography, { color: "text.secondary", maxWidth: "476px", textAlign: "center", mb: 2, children: description })] }), _jsxs(DialogActions, { sx: { px: 4, pb: 4 }, children: [onSecondaryButtonClick && secondaryButtonLabel && (_jsx(SecondaryButton, { variant: "contained", color: "inherit", onClick: onSecondaryButtonClick, children: secondaryButtonLabel })), onPrimaryButtonClick && primaryButtonLabel && (_jsx(PrimaryButton, { variant: "contained", onClick: onPrimaryButtonClick, children: primaryButtonLabel }))] })] }));
});
export default ConfirmationDialog;
