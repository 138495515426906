import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PayoutsTable from './PayoutsTable';
import StripeConnect from './StripeConnect';
export default function Statements() {
    const [t] = useTranslation('financials');
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: {
                    px: 4,
                    pt: 4,
                    pb: 2,
                    display: 'flex',
                    flexDirection: 'column'
                }, children: _jsx(Box, { sx: (theme) => ({
                        mb: 8,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        [theme.breakpoints.down('md')]: {
                            mb: 3
                        }
                    }), children: _jsxs("div", { children: [_jsx(Typography, { fontSize: 20, fontWeight: 600, children: t('statements.title') }), _jsx(Typography, { mb: 'auto', fontSize: 12, color: 'text.secondary', children: t('statements.subtitle') })] }) }) }), _jsx(Box, { sx: (theme) => ({
                    flexGrow: 1,
                    borderTop: 1,
                    borderColor: 'divider',
                    [theme.breakpoints.up('md')]: {
                        mx: 4
                    }
                }), children: _jsx(PayoutsTable, {}) }), _jsx(Box, { sx: (theme) => ({
                    px: 4,
                    pt: 4,
                    pb: 2,
                    [theme.breakpoints.down('md')]: { px: 2, display: 'grid', gap: 2 }
                }), children: _jsx(StripeConnect, {}) })] }));
}
