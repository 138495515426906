import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsContext } from '../SettingsProvider';
import Alert from '@mui/material/Alert';
import { OSOuterContainer, OSContentBox, OSInnerContainer, OSTextBox, OSText } from './OSCustomComponents';
import { validationMessages } from './utilities';
import { AlertTitle } from '@mui/material';
import { fetchApi } from '../../../config/Api';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
function DeleteRestaurant() {
    const [t] = useTranslation('settings');
    const { restaurantId, validationErrors, filterValidation, setHideStepperButtons, setValidationErrors } = useContext(SettingsContext);
    const [delete_restaurant, setDeleteRestaurant] = useState('');
    const [showConfirm, setShowConfirm] = useState(false);
    useEffect(() => {
        setHideStepperButtons(true);
        return () => {
            setHideStepperButtons(false);
        };
    }, []);
    function handleDelete() {
        fetchApi({
            method: 'POST',
            url: '/restaurant/soft-delete',
            data: {
                restaurant_id: restaurantId,
                step: 6,
                delete_restaurant: delete_restaurant
            }
        })
            .then(() => {
            window.location.replace(`/select-network`);
        })
            .catch((e) => {
            setValidationErrors([]);
            console.log(e.response?.data);
            if (e.response?.status == 422) {
                setValidationErrors(e?.response?.data?.errors);
                return e;
            }
        });
    }
    return (_jsx(OSOuterContainer, { children: _jsx(OSInnerContainer, { children: _jsxs(OSContentBox, { children: [_jsx(OSTextBox, { children: _jsxs(Alert, { sx: {
                                width: '100%',
                                borderRadius: '0.5rem'
                            }, severity: 'warning', children: [_jsx(AlertTitle, { children: t('delete.warning.title') }), t('delete.warning.description')] }) }), _jsx(OSTextBox, { children: _jsx(OSText, { required: true, error: !!validationErrors?.delete_restaurant?.length, id: 'outlined-required', label: t('delete.confirmField'), fullWidth: true, defaultValue: delete_restaurant, helperText: validationMessages(validationErrors?.delete_restaurant), onChange: (e) => {
                                setDeleteRestaurant(e.target.value);
                                filterValidation('delete_restaurant');
                                setShowConfirm(true);
                            } }) }), _jsx(Box, { sx: {
                            display: 'flex'
                        }, children: _jsx(Button, { variant: 'contained', disabled: !showConfirm, sx: { borderRadius: '0.5rem' }, component: 'label', onClick: handleDelete, children: t('delete.btnLabel') }) })] }) }) }));
}
export default DeleteRestaurant;
