import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from "moment";
import { usePDF } from "@react-pdf/renderer";
import { getCurrency, getRestaurantId } from "../../UtilityFunctions";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import Pdf from "./Pdf";
export default function PayoutRow(props) {
    const { data } = props;
    const currency = getCurrency();
    const restaurantId = getRestaurantId();
    const restaurantsInLocalStorage = localStorage.getItem("restaurants");
    const restaurants = typeof restaurantsInLocalStorage === "string"
        ? JSON.parse(restaurantsInLocalStorage)
        : null;
    const foundRestaurant = restaurants?.find((restaurant) => restaurant.id.toString() === restaurantId);
    const [instance] = usePDF({
        document: _jsx(Pdf, { data: { ...data, currency, restaurant: foundRestaurant } }),
    });
    return (_jsx(_Fragment, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { sx: { textDecoration: "underline" }, children: data.id }), _jsx(TableCell, { color: "text.secondary", children: moment(data.created_at).format("DD/MM/yyy") }), _jsxs(TableCell, { children: [currency, " ", data.net_total_sales_credit_card.toFixed(2)] }), _jsx(TableCell, { sx: (theme) => ({ [theme.breakpoints.down("md")]: { px: 0 } }), children: instance.loading ? ("Loading...") : instance.error || !instance.url ? ("Error") : (_jsx("a", { href: instance.url, download: "test.pdf", children: _jsx(IconButton, { children: _jsx(DownloadIcon, {}) }) })) })] }) }));
}
