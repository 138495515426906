import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState, useEffect, useContext } from 'react';
import { fetchApi } from '../../config/Api';
import { request } from '../../lib/axios';
import { useLocalStorage } from '../../hooks/use-local-storage';
const SELECT_NETWORK_CONTEXT_INITIAL_VALUES = {
    selectedNetwork: null,
    setSelectedNetwork: () => { },
    isLoadingRestaurants: false,
    setIsLoadingRestaurants: () => { },
    getRestaurants: () => { },
    restaurants: {
        active: [],
        deleted: []
    },
    setRestaurants: () => { },
    status: 'active',
    setStatus: () => { },
    isSuperAdmin: false,
    networks: [],
    user: null,
    userCanCreateBranch: false,
    isNetworkDrawerOpen: false,
    setIsNetworkDrawerOpen: () => { },
    getNetworks: () => { }
};
export const SelectNetworkContext = createContext({
    ...SELECT_NETWORK_CONTEXT_INITIAL_VALUES
});
export const useNetworkContext = () => {
    const context = useContext(SelectNetworkContext);
    if (!context) {
        throw new Error('useNetworkContext must be used within a NetworkProvider');
    }
    return context;
};
function SelectNetworkProvider({ children }) {
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [isLoadingRestaurants, setIsLoadingRestaurants] = useState(false);
    const [restaurants, setRestaurants] = useState({
        active: [],
        deleted: []
    });
    const [status, setStatus] = useState('active');
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [networks, setNetworks] = useState([]);
    const [user, setUser] = useState(null);
    const [userCanCreateBranch, setUserCanCreateBranch] = useState(false);
    const [isNetworkDrawerOpen, setIsNetworkDrawerOpen] = useState(false);
    const [, setStoredValue] = useLocalStorage('user', null);
    const [locale] = useLocalStorage('locale', 'en_US');
    const getUserInfo = () => {
        const metaTag = document.head.querySelector('meta[name="csrf-token"]') ??
            document.head.querySelector('meta[name="_token"]');
        return fetchApi({
            method: 'GET',
            url: `/user?_token=${
            // @ts-ignore
            metaTag.content}`
        }).then(async (r) => {
            setUser(r.data.user);
            setStoredValue(r.data.user);
            if (r.data.user.locale !== locale) {
                await request('/web/user/locale', 'PUT', {
                    locale
                });
                setStoredValue({ ...r.data.user, locale });
            }
        });
    };
    const getRestaurants = ({ networkId }) => {
        setIsLoadingRestaurants(true);
        fetchApi({
            method: 'GET',
            url: `/active-deleted-restaurants/${networkId}`
        })
            .then((res) => {
            setRestaurants(res.data.network.allRestaurants);
            setUserCanCreateBranch(res.data.userCanCreateBranch);
            setIsLoadingRestaurants(false);
        })
            .catch(() => {
            setIsLoadingRestaurants(false);
        });
    };
    const getNetworks = () => {
        fetchApi({
            method: 'GET',
            url: '/network/list'
        }).then((res) => {
            setNetworks(res.data.networks);
            setIsSuperAdmin(res.data.isSuperAdmin);
        });
    };
    useEffect(() => {
        getNetworks();
        getUserInfo();
    }, []);
    const providerValue = {
        isLoadingRestaurants,
        setIsLoadingRestaurants,
        getRestaurants,
        setRestaurants,
        restaurants,
        selectedNetwork,
        setSelectedNetwork,
        status,
        setStatus,
        networks,
        isSuperAdmin,
        user,
        userCanCreateBranch,
        isNetworkDrawerOpen,
        setIsNetworkDrawerOpen,
        getNetworks
    };
    return (_jsx(SelectNetworkContext.Provider, { value: providerValue, children: children }));
}
export default SelectNetworkProvider;
