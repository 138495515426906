import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
export default function Pagination(props) {
    const { pagination, setPage, setPerPage, perPage } = props;
    return (_jsxs(Box, { py: 1, display: "flex", justifyContent: "flex-end", alignItems: "center", position: "sticky", bottom: 0, bgcolor: "white", children: [_jsx(Typography, { mr: 1, component: "span", variant: "caption", color: "text.secondary", children: "Rows per page:" }), _jsxs(Select, { size: "small", variant: "standard", value: perPage, onChange: (e) => setPerPage(+e.target.value), sx: { fontSize: 12 }, children: [_jsx(MenuItem, { value: 10, children: "10" }), _jsx(MenuItem, { value: 20, children: "20" }), _jsx(MenuItem, { value: 30, children: "30" })] }), _jsxs(Typography, { mx: 3, component: "span", variant: "caption", children: [pagination.from, "-", pagination.to, " of ", pagination.total] }), _jsx(IconButton, { disabled: pagination.current_page === 1, onClick: () => setPage((prev) => prev - 1), children: _jsx(ChevronLeftOutlinedIcon, {}) }), _jsx(IconButton, { disabled: pagination.current_page === pagination.last_page, onClick: () => setPage((prev) => prev + 1), children: _jsx(ChevronRightOutlinedIcon, {}) })] }));
}
