import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useContext, useRef } from 'react';
import { arrayMove } from '@dnd-kit/sortable';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { MenuContext } from '../context';
import { ActionType } from '../context/types';
import { fetchApi } from '../../../config/Api';
import { getNetworkId, getRestaurantId } from '../../UtilityFunctions';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import Alert from '@mui/material/Alert';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import CheckOutlined from '@mui/icons-material/CheckOutlined';
import AddOutlined from '@mui/icons-material/AddOutlined';
import ModifierOption from './ModifierOption';
import ModifierOptionForm from './ModifierOptionForm';
import MultipleSelectChip from './MultiSelect';
import AssignModifierDialog from './AssignModifierDialog';
import ModifierTree from './ModifierTree';
import CloseDrawerDialog from './CloseDrawerDialog';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import EditModifierDialog from './EditModifierDialog';
import SortableContainer from '../../components/SortableContainer';
import { RoundedButton, PrimaryButton, RoundedTextfield, SecondaryButton } from './styled';
const Schema = z.object({
    is_available: z.string().min(1),
    name: z.string().min(1),
    item_ids: z.array(z.object({ name: z.string(), id: z.number() })),
    rules: z.object({
        is_required: z.boolean(),
        min_selection: z.string(),
        max_selection: z.string(),
        max_selection_per_option: z.string()
    }),
    options: z
        .array(z.object({
        id: z.number().optional(),
        name: z.string(),
        price: z.number(),
        contain_alcohol: z.boolean(),
        modifiersIds: z
            .array(z.object({ id: z.number(), name: z.string() }))
            .optional()
    }))
        .min(1)
});
const selectOptions = Array.from({ length: 99 }, (_, i) => String(i + 1));
const defaultValues = {
    is_available: 'true',
    name: '',
    item_ids: [],
    options: [],
    rules: {
        is_required: false,
        min_selection: '1',
        max_selection: '1',
        max_selection_per_option: '1'
    }
};
export default function ModifierDrawer() {
    const [t] = useTranslation('menu');
    const [isLoading, setIsLoading] = useState(false);
    const [showOptionFields, setShowOptionFields] = useState(false);
    const [isModifierDialogOpen, setIsModifierDialogOpen] = useState(false);
    const [optionIndex, setOptionIndex] = useState(null);
    const [allowMultipleSelection, setAllowMultipleSelction] = useState(false);
    const [draftOption, setDraftOption] = useState(null);
    const [modifierTree, setModifierTree] = useState(null);
    const { isModifierDrawerOpen, toggleDrawer, items, getModifiers, draftModifier, dispatch, getItems } = useContext(MenuContext);
    const methods = useForm({
        resolver: zodResolver(Schema),
        defaultValues
    });
    const { watch, reset, setValue, getValues, control, register, handleSubmit, formState: { errors, isDirty } } = methods;
    const maxSelectionPerOption = watch('rules.max_selection_per_option');
    const options = watch('options');
    const closeDialogRef = useRef();
    const removeSubModifiersRef = useRef();
    const handleReset = () => {
        reset(defaultValues);
        dispatch({
            type: ActionType.SET_DRAFT_MODIFIER,
            payload: null
        });
        toggleDrawer('isModifierDrawerOpen');
    };
    const handleClose = () => {
        if (isDirty) {
            closeDialogRef.current?.open();
        }
        else {
            handleReset();
        }
    };
    const onSubmit = (data) => {
        setIsLoading(true);
        const networkId = getNetworkId();
        const restaurantId = getRestaurantId();
        let body = {
            ...data,
            is_available: data.is_available === 'true',
            item_ids: data.item_ids.map((item) => item.id),
            options: data.options.map((option) => ({
                ...option,
                modifiersIds: option.modifiersIds
                    ? option.modifiersIds.map((modifier) => modifier.id)
                    : []
            }))
        };
        const url = draftModifier
            ? `/modifier-groups/${draftModifier.id}?network_id=${networkId}&restaurant_id=${restaurantId}`
            : `/modifier-groups?network_id=${networkId}&restaurant_id=${restaurantId}`;
        fetchApi({
            url,
            method: 'POST',
            data: body
        })
            .then((res) => {
            setIsLoading(false);
            if (data.item_ids.length)
                getItems();
            handleReset();
            getModifiers();
            getItems();
            window.globalToast({
                type: 'success',
                title: 'Success',
                description: res.data.message
            });
        })
            .catch((error) => {
            setIsLoading(false);
            let errorMessage = 'Something went wrong!';
            // Check if the error response exists and contains the expected format
            if (error.response &&
                error.response.data &&
                error.response.data.error) {
                errorMessage = error.response.data.error;
            }
            window.globalToast({
                type: 'error',
                title: 'Error',
                description: errorMessage
            });
        });
    };
    useEffect(() => {
        if (draftModifier) {
            reset({
                is_available: draftModifier.is_available.toString(),
                name: draftModifier.name,
                item_ids: draftModifier.items ?? [],
                rules: {
                    is_required: Boolean(draftModifier.rules.is_required),
                    min_selection: draftModifier.rules.min_selection.toString(),
                    max_selection: draftModifier.rules.max_selection.toString(),
                    max_selection_per_option: draftModifier.rules.max_selection_per_option.toString()
                },
                options: draftModifier.options.flat().map((option) => ({
                    ...option,
                    modifiersIds: option.modifierGroups
                        .flat()
                        .map((modifier) => ({ id: modifier.id, name: modifier.name }))
                }))
            });
            if (draftModifier.rules.min_selection !== 1 ||
                draftModifier.rules.max_selection !== 1) {
                setAllowMultipleSelction(true);
            }
            else {
                setAllowMultipleSelction(false);
            }
        }
    }, [draftModifier]);
    function onSort(event) {
        const { active, over } = event;
        if (active.id !== over?.id) {
            const options = getValues('options');
            const oldIndex = options.findIndex((item) => item.id === active.id);
            const newIndex = options.findIndex((item) => item.id === over?.id);
            const sortedOptions = arrayMove(options, oldIndex, newIndex);
            setValue('options', sortedOptions);
            fetchApi({
                method: 'POST',
                url: `/modifier-groups/${draftModifier?.id}/sort`,
                data: { sort: sortedOptions.map(({ id }) => id) }
            }).catch(() => {
                window.globalToast({
                    type: 'error',
                    title: 'Error',
                    description: "Something went wrong. Couldn't reorder items."
                });
            });
        }
    }
    const getModifierByIndex = (index) => {
        const option = draftModifier?.options.flat()[index];
        if (option) {
            setModifierTree(option);
        }
    };
    return (_jsxs(FormProvider, { ...methods, children: [_jsx(CloseDrawerDialog, { ref: closeDialogRef, onLeave: handleReset }), _jsx(ConfirmationDialog, { ref: removeSubModifiersRef, severity: 'error', title: t('dialogs.removeSubmodifier.title'), description: t('dialogs.removeSubmodifier.description'), primaryButtonLabel: t('dialogs.removeSubmodifier.primaryBtnLabel'), onPrimaryButtonClick: () => removeSubModifiersRef.current?.close() }), Boolean(modifierTree) && (_jsx(ModifierTree, { data: [modifierTree], isOpen: Boolean(modifierTree), setIsOpen: () => setModifierTree(null) })), _jsx(AssignModifierDialog, { isOpen: isModifierDialogOpen, setIsOpen: setIsModifierDialogOpen, optionIndex: optionIndex, setOptionIndex: setOptionIndex }), draftOption && (_jsx(EditModifierDialog, { option: draftOption, optionIndex: optionIndex, close: () => {
                    setDraftOption(null);
                    setOptionIndex(null);
                } })), _jsxs(Drawer, { open: isModifierDrawerOpen, onClose: handleClose, anchor: 'right', variant: 'temporary', sx: {
                    zIndex: 1300
                }, PaperProps: {
                    sx: {
                        width: '100%',
                        maxWidth: '512px'
                    }
                }, slotProps: {
                    backdrop: {
                        sx: {
                            backgroundColor: 'transparent'
                        }
                    }
                }, children: [_jsxs(Box, { py: 3, px: 4, borderBottom: 1, borderColor: 'divider', sx: {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            backgroundColor: '#fff'
                        }, children: [_jsxs(Box, { children: [_jsx(Typography, { component: 'span', color: 'text.secondary', children: t('drawers.modifier.title') }), _jsx(Typography, { component: 'h1', fontSize: 24, fontWeight: 600, children: draftModifier
                                            ? draftModifier.name
                                            : t('drawers.modifier.subtitle') })] }), _jsx(IconButton, { onClick: handleClose, children: _jsx(CloseOutlined, {}) })] }), _jsxs("form", { onSubmit: handleSubmit(onSubmit), style: {
                            display: 'grid',
                            gridAutoColumns: '1fr auto',
                            overflowY: 'auto'
                        }, children: [_jsxs(Box, { sx: { overflowY: 'auto' }, children: [_jsxs(Box, { py: 3, gap: 3, display: 'flex', flexDirection: 'column', children: [_jsxs(Box, { sx: { mx: 4 }, children: [_jsx(Typography, { fontWeight: 600, children: t('drawers.modifier.form.status.title') }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', children: t('drawers.modifier.form.status.description') })] }), _jsx(Box, { px: 3, children: _jsx(Controller, { name: 'is_available', control: control, render: ({ field: { value, onChange } }) => (_jsxs(RoundedTextfield, { select: true, fullWidth: true, label: t('drawers.modifier.form.status.label'), error: !!errors.is_available, value: value, onChange: (e) => onChange(e.target.value), children: [_jsxs(MenuItem, { value: 'true', sx: (theme) => ({
                                                                    '& .MuiSvgIcon-root': {
                                                                        marginRight: theme.spacing(1.5)
                                                                    }
                                                                }), children: [_jsx(CheckOutlined, {}), ' ', t('drawers.modifier.form.status.options.1')] }), _jsxs(MenuItem, { value: 'false', sx: (theme) => ({
                                                                    '& .MuiSvgIcon-root': {
                                                                        marginRight: theme.spacing(1.5)
                                                                    }
                                                                }), children: [_jsx(CloseOutlined, {}), ' ', t('drawers.modifier.form.status.options.2')] })] })) }) }), _jsx(Divider, {}), _jsxs(Stack, { px: 3, gap: 3, children: [_jsx(RoundedTextfield, { fullWidth: true, error: !!errors.name, label: t('drawers.modifier.form.name.label'), ...register('name') }), _jsx(MultipleSelectChip, { control: control, error: !!errors.item_ids, options: items })] })] }), _jsx(Divider, { sx: {
                                            height: '8px',
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                            borderTop: 1,
                                            borderColor: 'divider'
                                        } }), _jsxs(Box, { sx: { mx: 4 }, py: 3, children: [!!errors.options && (_jsx(Alert, { severity: 'error', sx: { mb: 2 }, children: t('drawers.modifier.form.options.errorAlert') })), _jsx(Typography, { fontWeight: 600, fontSize: 22, children: t('drawers.modifier.form.options.title') }), _jsx(Typography, { mb: 3, fontSize: 14, color: 'text.secondary', children: t('drawers.modifier.form.options.description') }), !!watch('options').length && (_jsx(List, { children: _jsx(SortableContainer, { items: watch('options'), onDragEnd: onSort, children: watch('options').map((option, index) => (_jsx(ModifierOption, { index: index, option: option, isAssignDisabled: +maxSelectionPerOption > 1, showDragHandle: watch('options').every((option) => option.id), handleEdit: () => {
                                                            setOptionIndex(index);
                                                            setDraftOption(option);
                                                        }, handleViewTree: () => getModifierByIndex(index), openAssignModifierModal: () => {
                                                            setOptionIndex(index);
                                                            setIsModifierDialogOpen(true);
                                                        } }, option.name))) }) })), !showOptionFields && (_jsx(RoundedButton, { variant: 'contained', startIcon: _jsx(AddOutlined, {}), onClick: () => setShowOptionFields(true), children: t('drawers.modifier.form.options.addOptionBtnLabel') })), _jsx(ModifierOptionForm, { showOptionFields: showOptionFields, setShowOptionFields: setShowOptionFields })] }), _jsx(Divider, { sx: {
                                            height: '8px',
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                            borderTop: 1,
                                            borderColor: 'divider'
                                        } }), _jsxs(Box, { mx: 4, py: 3, children: [_jsx(Typography, { fontWeight: 600, fontSize: 22, children: t('drawers.modifier.form.rules.title') }), _jsx(Typography, { mb: 3, fontSize: 14, color: 'text.secondary', children: t('drawers.modifier.form.rules.description') }), _jsxs(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', children: [_jsxs(Stack, { children: [_jsx(Typography, { fontWeight: 600, children: t('drawers.modifier.form.rules.requireSelection.title') }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', maxWidth: 350, children: t('drawers.modifier.form.rules.requireSelection.description') })] }), _jsx(Controller, { name: 'rules.is_required', control: control, render: ({ field: { onChange, value } }) => (_jsx(Switch, { checked: value, onChange: onChange })) })] }), _jsx(Divider, { sx: { my: 2 } }), _jsxs(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', children: [_jsxs(Stack, { children: [_jsx(Typography, { fontWeight: 600, children: t('drawers.modifier.form.rules.allowMultiple.title') }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', maxWidth: 350, children: t('drawers.modifier.form.rules.allowMultiple.description') })] }), _jsx(Switch, { checked: allowMultipleSelection, onChange: (e) => {
                                                            setAllowMultipleSelction(e.target.checked);
                                                            if (e.target.checked) {
                                                                setValue('rules.min_selection', '1', {
                                                                    shouldDirty: true
                                                                });
                                                                setValue('rules.max_selection', '2', {
                                                                    shouldDirty: true
                                                                });
                                                            }
                                                            else {
                                                                setValue('rules.min_selection', '1', {
                                                                    shouldDirty: true
                                                                });
                                                                setValue('rules.max_selection', '1', {
                                                                    shouldDirty: true
                                                                });
                                                            }
                                                        } })] }), allowMultipleSelection && (_jsxs(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem', mt: 2, children: [_jsx(Controller, { name: 'rules.min_selection', control: control, render: ({ field: { value, onChange } }) => (_jsx(RoundedTextfield, { select: true, fullWidth: true, label: t('drawers.modifier.form.rules.allowMultiple.minField.label'), value: value, onChange: (e) => {
                                                                onChange(e.target.value);
                                                                if (+getValues('rules.max_selection') < +e.target.value) {
                                                                    setValue('rules.max_selection', e.target.value);
                                                                }
                                                            }, children: selectOptions.map((option) => (_jsx(MenuItem, { value: option, children: option }, option))) })) }), _jsx(Controller, { name: 'rules.max_selection', control: control, render: ({ field: { value, onChange } }) => (_jsx(RoundedTextfield, { select: true, fullWidth: true, label: t('drawers.modifier.form.rules.allowMultiple.maxField.label'), value: value, onChange: (e) => onChange(e.target.value), children: selectOptions
                                                                .filter((opt) => +opt >= +watch('rules.min_selection'))
                                                                .map((option) => (_jsx(MenuItem, { value: option, children: option }, option))) })) })] })), _jsx(Divider, { sx: { my: 2 } }), _jsxs(Box, { children: [_jsx(Typography, { fontSize: 14, color: 'text.secondary', maxWidth: 350, mb: 2, children: t('drawers.modifier.form.rules.maxSelection.title') }), _jsx(Controller, { name: 'rules.max_selection_per_option', control: control, render: ({ field: { value, onChange } }) => (_jsx(RoundedTextfield, { select: true, fullWidth: true, label: t('drawers.modifier.form.rules.maxSelection.label'), value: value, onChange: (e) => {
                                                                if (options.some((option) => option.modifiersIds && option.modifiersIds.length)) {
                                                                    removeSubModifiersRef.current?.open();
                                                                }
                                                                else {
                                                                    onChange(e.target.value);
                                                                }
                                                            }, children: selectOptions.map((option) => (_jsx(MenuItem, { value: option, children: option }, option))) })) })] })] })] }), _jsxs(Box, { py: 2, px: 4, gap: 2, display: 'flex', boxShadow: '0px -4px 10px 0px rgba(0, 0, 0, 0.07)', children: [_jsx(SecondaryButton, { type: 'button', variant: 'outlined', sx: { ml: 'auto' }, onClick: handleClose, children: t('drawers.modifier.secondaryBtnLabel') }), _jsx(PrimaryButton, { type: 'submit', variant: 'contained', disabled: isLoading || !isDirty, children: isLoading ? (_jsx(CircularProgress, { size: '24px' })) : (t('drawers.modifier.primaryBtnLabel')) })] })] })] })] }));
}
