import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import ChartItem from './ChartItem';
const charts = [
    {
        name: 'Total sales',
        endpoint: '/restaurant/:restaurantId/statistics/total-sales',
        type: 'currency'
    },
    {
        name: 'Sales from your website',
        endpoint: '/restaurant/:restaurantId/statistics/total-sales',
        query: 'platform_type=web',
        type: 'currency'
    },
    {
        showPlatformSelector: true,
        name: 'Sales from your app',
        endpoint: '/restaurant/:restaurantId/statistics/total-sales',
        type: 'currency'
    },
    {
        name: 'Your website visitors',
        endpoint: '/restaurant/:restaurantId/statistics/web-visitors',
        type: 'count'
    },
    {
        name: 'New customers from your website',
        endpoint: '/restaurant/:restaurantId/statistics/new-customers',
        query: 'platform_type=web',
        type: 'count'
    },
    {
        showPlatformSelector: true,
        name: 'New customers from your app',
        endpoint: '/restaurant/:restaurantId/statistics/new-customers',
        type: 'count'
    },
    {
        name: 'First time orders',
        endpoint: '/restaurant/:restaurantId/statistics/first-time-orders',
        type: 'count'
    },
    {
        name: 'Returning customers',
        endpoint: '/restaurant/:restaurantId/statistics/returning-customers',
        type: 'count'
    },
    {
        name: 'Tips',
        endpoint: '/restaurant/:restaurantId/statistics/total-tips',
        type: 'currency'
    }
];
const ChartGrid = ({ dateRange }) => {
    const [t] = useTranslation('store');
    return (_jsx(Box, { display: 'grid', gridTemplateColumns: { sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr' }, children: charts.map((chart, i) => (_jsx(Box, { px: 2, borderRight: 1, borderColor: 'divider', sx: {
                borderColor: { sm: 'transparent', md: 'divider' },
                '&:nth-of-type(2n)': {
                    borderColor: { md: 'transparent', lg: 'divider' }
                },
                '&:nth-of-type(3n)': { borderColor: { lg: 'transparent' } }
            }, children: _jsx(ChartItem, { name: t('activity.overview.otherCharts', { returnObjects: true })[i], type: chart.type, endpoint: chart.endpoint, query: chart.query, showPlatformSelector: chart.showPlatformSelector, dateRange: dateRange }, chart.name) }, chart.name))) }));
};
export default ChartGrid;
