import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
export default function ProfileTabs(props) {
    const { value, onChange } = props;
    return (_jsxs(Tabs, { value: value, onChange: (_e, value) => onChange(value), sx: (theme) => ({
            px: 2,
            top: 0,
            position: "sticky",
            backgroundColor: "#fff",
            boxShadow: "inset 0px -2px #F6F2F8",
            [theme.breakpoints.up("md")]: {
                px: 4,
            },
        }), children: [_jsx(Tab, { label: "Account Settings", value: "settings", sx: { height: 56, fontSize: "14px", textTransform: "none" } }), _jsx(Tab, { label: "Change Password", value: "password", sx: { height: 56, fontSize: "14px", textTransform: "none" } })] }));
}
