import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEmployeesContext } from '../EmployeeProvider';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { useTheme, useMediaQuery } from '@mui/material';
import EmployeeRow from './EmployeeRow';
import Pagination from '../../components/Pagination';
import { RoundedButton } from '../../components/styled';
export default function EmployeesTable() {
    const [t] = useTranslation('employees');
    const { employees, pagination, perPage, setPerPage, setPage, loadingEmployees, getEmployees, isFetching } = useEmployeesContext();
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const showPagination = !loadingEmployees && !!employees.length && pagination.total > 10 && isMdUp;
    const showSeeMoreBtn = !loadingEmployees &&
        !isMdUp &&
        pagination.current_page !== pagination.last_page;
    return (_jsxs(_Fragment, { children: [_jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { sx: { top: 180, fontWeight: 600 }, children: t('tableColumns.1') }), isMdUp && (_jsxs(_Fragment, { children: [_jsx(TableCell, { sx: { top: 180, fontWeight: 600 }, children: t('tableColumns.2') }), _jsx(TableCell, { sx: { top: 180, fontWeight: 600 }, children: t('tableColumns.3') }), _jsx(TableCell, { sx: { top: 180, fontWeight: 600 }, children: t('tableColumns.4') })] })), _jsx(TableCell, { sx: { top: 180, fontWeight: 600 }, children: t('tableColumns.5') }), _jsx(TableCell, { sx: { top: 180, fontWeight: 600 } }), !isMdUp && _jsx(TableCell, { sx: { top: 180, fontWeight: 600 } })] }) }), !loadingEmployees && (_jsx(TableBody, { children: employees.map((employee) => (_jsx(EmployeeRow, { employee: employee }, employee.id))) }))] }), (loadingEmployees || !employees.length) && (_jsx(Box, { sx: {
                    mt: 18,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column'
                }, children: loadingEmployees ? (_jsx(CircularProgress, {})) : (_jsxs(_Fragment, { children: [_jsx(PersonOutlinedIcon, { color: 'action', fontSize: 'large', sx: { mb: 2 } }), _jsx(Typography, { mb: 1, fontWeight: 600, children: t('emptyText.primary') }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', children: t('emptyText.secondary') })] })) })), showPagination && (_jsx(Pagination, { pagination: pagination, perPage: perPage, setPerPage: setPerPage, setPage: setPage })), showSeeMoreBtn && (_jsx(Box, { sx: { mx: 4, my: 2 }, children: _jsx(RoundedButton, { fullWidth: true, variant: 'contained', onClick: () => getEmployees(undefined, pagination.current_page + 1), children: isFetching ? (_jsx(CircularProgress, { size: '24px' })) : (t('moreBtnLabel')) }) }))] }));
}
