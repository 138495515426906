import { styled } from "@mui/material";
import MUIButton from "@mui/material/Button";
import MUITextField from "@mui/material/TextField";
export const PrimaryButton = styled(MUIButton)(({ theme }) => ({
    textTransform: "none",
    boxShadow: "none",
    backgroundColor: theme.palette.primary.light,
    fontSize: 16,
    borderRadius: 12,
}));
export const SecondaryButton = styled(MUIButton)(({ theme }) => ({
    textTransform: "none",
    boxShadow: "none",
    fontSize: 16,
    borderRadius: 12,
    borderColor: theme.palette.grey[300],
    color: theme.palette.text.secondary,
}));
export const RoundedButton = styled(MUIButton)(({ theme }) => ({
    borderRadius: "50px",
    textTransform: "none",
    boxShadow: "none",
    fontWeight: 600,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.secondary,
    "&:hover": {
        backgroundColor: theme.palette.grey[200],
        boxShadow: "none",
    },
}));
export const RoundedTextField = styled(MUITextField)(() => ({
    "& .MuiOutlinedInput-input": {
        borderRadius: "12px !important",
    },
    fieldset: {
        borderRadius: "12px",
    },
}));
