import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SettingsContext } from '../SettingsProvider';
import ListItem from '@mui/material/ListItem';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { OSList, OSListItemButton, OSListItemText } from './OSCustomComponents';
export default function SettingsNavigation() {
    const [t] = useTranslation('settings');
    const { activeStep, setActiveStep, skipped } = useContext(SettingsContext);
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    useEffect(() => {
        if (isMdDown) {
            setActiveStep(-1);
        }
        else {
            setActiveStep(0);
        }
    }, [isMdDown]);
    if (isMdDown && activeStep !== -1) {
        return null;
    }
    return (_jsx(OSList, { disablePadding: true, children: t('steps', { returnObjects: true }).map((label, index) => (_jsx(ListItem, { divider: isMdDown, disableGutters: true, disablePadding: true, secondaryAction: isMdDown ? _jsx(ArrowForwardIosOutlinedIcon, { fontSize: 'small' }) : null, children: _jsx(OSListItemButton, { disabled: index === skipped, selected: index === activeStep, onClick: () => setActiveStep(index), children: _jsx(OSListItemText, { primary: label }) }) }, label))) }));
}
