import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from 'react-i18next';
import { MenuContext } from '../context';
import { ActionType } from '../context/types';
import { fetchApi } from '../../../config/Api';
import { getRestaurantId, getCurrency, generateImageUrl, formatPrice } from '../../UtilityFunctions';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import DragIndicatorOutlined from '@mui/icons-material/DragIndicatorOutlined';
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutline';
import CheckOutlined from '@mui/icons-material/CheckOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { red, green } from '@mui/material/colors';
import { useTheme, useMediaQuery } from '@mui/material';
import ConfirmationDialog from './ConfirmationDialog';
import DropdownMenu from './DropdownMenu';
import Placeholder from '../assets/Placeholder';
import { TableCell } from './styled';
export default function ItemTableRow(props) {
    const { item } = props;
    const [t] = useTranslation('menu');
    const [isDeleting, setIsDeleting] = useState(false);
    const [isAvailableReq, setIsAvailableReq] = useState(false);
    const [isNotAvailableReq, setIsNotAvailableReq] = useState(false);
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { dispatch, toggleDrawer, getItems, draftItem, getCategories } = useContext(MenuContext);
    const currency = getCurrency();
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMoreOptions = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleEdit = () => {
        dispatch({
            type: ActionType.SET_DRAFT_ITEM,
            payload: item
        });
        handleClose();
        toggleDrawer('isItemDrawerOpen');
    };
    const handleDelete = () => {
        handleClose();
        setIsDeleting(true);
        fetchApi({
            method: 'DELETE',
            url: `/menu/item/delete/${item.id}`
        })
            .then((res) => {
            getItems();
            getCategories();
            window.globalToast({
                title: 'Success',
                type: 'success',
                description: res.data.message
            });
        })
            .catch((e) => {
            window.globalToast({
                type: 'error',
                title: 'Error',
                description: e.message
            });
        })
            .finally(() => {
            setIsDeleting(false);
            setConfirmDeleteDialog(false);
        });
    };
    const restaurantId = getRestaurantId();
    const status = item.restaurants.some((res) => res.id === Number(restaurantId));
    const toggleItemStatus = () => {
        status ? setIsNotAvailableReq(true) : setIsAvailableReq(true);
        fetchApi({
            method: 'POST',
            url: `/restaurant/${restaurantId}/manage-item`,
            data: {
                item_id: item.id,
                enable: status ? 0 : 1
            }
        })
            .then(() => {
            setIsAvailableReq(false);
            setIsNotAvailableReq(false);
            getItems();
            window.globalToast({
                type: 'success',
                title: 'Success',
                description: 'Item changes saved successfully.'
            });
        })
            .catch(() => {
            window.globalToast({
                type: 'error',
                title: 'Could not save item changes',
                description: ''
            });
        })
            .finally(() => handleClose());
    };
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.item.id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };
    return (_jsxs(_Fragment, { children: [_jsx(ConfirmationDialog, { isLoading: isDeleting, primaryText: t('dialogs.deleteItem.title'), secondaryText: t('dialogs.deleteItem.description'), isOpen: confirmDeleteDialog, setIsOpen: setConfirmDeleteDialog, confirmBtnLabel: t('dialogs.deleteItem.primaryBtnLabel'), confirmBtnCallback: handleDelete }), _jsxs(TableRow, { hover: true, selected: draftItem?.id === item.id, onClick: handleEdit, sx: {
                    display: 'grid !important',
                    gridTemplateColumns: '0.3fr 0.5fr 1fr 0.5fr 0.5fr 0.5fr 0.3fr',
                    bgcolor: '#fff'
                }, ref: setNodeRef, style: style, ...attributes, children: [_jsx(TableCell, { children: _jsx(IconButton, { ...listeners, children: _jsx(DragIndicatorOutlined, {}) }) }), _jsx(TableCell, { children: _jsx(Box, { width: 50, height: 42, borderRadius: '4px', overflow: 'hidden', children: item.image_path ? (_jsx("img", { src: generateImageUrl(item.image_path), style: {
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '4px'
                                } })) : (_jsx(Placeholder, {})) }) }), _jsxs(TableCell, { sx: { flexDirection: isMdDown ? 'column' : 'row' }, children: [_jsx(Tooltip, { title: item.name.length > (isMdDown ? 10 : 30) ? item.name : '', children: _jsx(Box, { whiteSpace: 'nowrap', children: item.name.length > (isMdDown ? 10 : 30)
                                        ? `${item.name.substring(0, isMdDown ? 10 : 30)}...`
                                        : item.name }) }), isMdDown && (_jsx(Tooltip, { title: item.category.name.length > 10 ? item.category.name : '', children: _jsx(Box, { whiteSpace: 'nowrap', color: 'text.secondary', children: `${item.category.name.substring(0, 10)}...` }) }))] }), _jsx(TableCell, { children: _jsx(Chip, { size: 'small', variant: 'filled', label: status ? 'Available' : 'Out of stock', color: status ? 'success' : 'error', sx: (theme) => ({
                                '&.MuiChip-colorSuccess': {
                                    backgroundColor: green[50],
                                    color: theme.palette.success.main
                                },
                                '&.MuiChip-colorError': {
                                    backgroundColor: red[50],
                                    color: theme.palette.error.main
                                }
                            }) }) }), !isMdDown && (_jsx(TableCell, { children: _jsx(Tooltip, { title: item.category.name.length > 12 ? item.category.name : '', children: _jsx(Chip, { size: 'small', variant: 'filled', icon: _jsx(InfoOutlined, {}), label: item.category.name.length > 12
                                    ? `${item.category.name.substring(0, 12)}...`
                                    : item.category.name }) }) })), _jsxs(TableCell, { children: [currency, formatPrice(item.price.toString())] }), _jsx(TableCell, { children: _jsx(IconButton, { onClick: handleMoreOptions, children: _jsx(MoreVertOutlined, {}) }) })] }), _jsx(DropdownMenu, { anchorEl: anchorEl, onClose: handleClose, options: [
                    {
                        label: t('overview.items.btnLabels.available'),
                        action: toggleItemStatus,
                        icon: isAvailableReq ? (_jsx(CircularProgress, { size: '20px' })) : (_jsx(CheckOutlined, { color: 'info' })),
                        disabled: status || isAvailableReq
                    },
                    {
                        label: t('overview.items.btnLabels.outOfStock'),
                        action: toggleItemStatus,
                        icon: isNotAvailableReq ? (_jsx(CircularProgress, { size: '20px' })) : (_jsx(CloseOutlined, { color: 'error' })),
                        disabled: !status || isAvailableReq
                    },
                    {
                        label: t('overview.items.btnLabels.editBtnLabel'),
                        action: handleEdit,
                        icon: _jsx(EditOutlined, {})
                    },
                    {
                        label: t('overview.items.btnLabels.deleteBtnLabel'),
                        action: () => {
                            setAnchorEl(null);
                            setConfirmDeleteDialog(true);
                        },
                        icon: _jsx(DeleteOutlined, {})
                    }
                ] })] }));
}
