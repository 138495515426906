import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewBranchContext } from '../NewBranchProvider';
import { InputAdornment, InputLabel, MenuItem, Select, Switch, TextField, FormControl, ClickAwayListener, Box } from '@mui/material';
import { validationMessages } from './utilities';
import { OSInnerContainer, OSSwitch, OSSelect, OSSwitchBox, OSText, OSTextBox, OSTextWithSelectBox, OSStepTitleBox, OSStepTitle, OSInlineSelect, OSInlineText, OSInlineTooltip, OSDelPickContentBox } from './OSCustomComponents';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
function DeliverySettings() {
    const [t] = useTranslation('settings');
    const { deliverySettings, setDeliverySettings, validationErrors, filterValidation } = useContext(NewBranchContext);
    const [tooltipItems, setTooltipItems] = useState([
    // item1: true,
    ]);
    const handleTooltipToggle = (key, bool = false) => {
        setTooltipItems({
            ...tooltipItems,
            [key]: bool
        });
    };
    const OSTooltipComponent = (key, title) => {
        return (_jsx(ClickAwayListener, { onClickAway: () => {
                handleTooltipToggle(key, false);
            }, children: _jsx(Box, { sx: {
                    height: '0 !important',
                    width: '0 !important',
                    maxHeight: '0 !important',
                    maxWidth: '0 !important'
                }, children: _jsx(OSInlineTooltip
                // @ts-ignore
                , { 
                    // @ts-ignore
                    open: !!tooltipItems[key], PopperProps: {
                        disablePortal: true
                    }, onClose: () => {
                        handleTooltipToggle(key, false);
                    }, disableFocusListener: true, disableTouchListener: true, title: title, children: _jsx(InfoOutlined, { onClick: () => {
                            handleTooltipToggle(key, true);
                        }, onMouseOver: () => {
                            handleTooltipToggle(key, true);
                        }, onMouseLeave: () => {
                            handleTooltipToggle(key, false);
                        } }) }) }) }));
    };
    // @ts-ignore
    return (deliverySettings && (_jsxs(OSInnerContainer, { children: [_jsx(OSStepTitleBox, { children: _jsx(OSStepTitle, { children: t('deliveryPickup.title') }) }), _jsxs(OSDelPickContentBox, { children: [_jsxs(OSSwitchBox, { item: deliverySettings.new_restaurant_delivery_asap, children: [_jsx(OSSwitch, { checked: deliverySettings.new_restaurant_delivery_asap ? true : false, 
                                // value={deliverySettings.new_restaurant_delivery_asap}
                                control: _jsx(Switch, {}), value: deliverySettings.new_restaurant_delivery_asap, id: 'new-restaurant-cash-payment', label: t('deliveryPickup.delivery.asapSwitch.label'), labelPlacement: 'start', onChange: () => {
                                    setDeliverySettings({
                                        ...deliverySettings,
                                        new_restaurant_delivery_asap: !deliverySettings.new_restaurant_delivery_asap
                                    });
                                    filterValidation('delivery_pickup_option');
                                    // checkIfSkipped();
                                } }), OSTooltipComponent('new_restaurant_delivery_asap', t('deliveryPickup.delivery.asapSwitch.tooltip'))] }), _jsxs(OSSwitchBox, { item: deliverySettings.new_restaurant_delivery_late, children: [_jsx(OSSwitch, { checked: deliverySettings.new_restaurant_delivery_late ? true : false, 
                                // value={deliverySettings.new_restaurant_delivery_late}
                                control: _jsx(Switch, {}), value: deliverySettings.new_restaurant_delivery_late, id: 'new-restaurant-cash-payment', label: t('deliveryPickup.delivery.lateSwitch.label'), labelPlacement: 'start', onChange: () => {
                                    setDeliverySettings({
                                        ...deliverySettings,
                                        new_restaurant_delivery_late: !deliverySettings.new_restaurant_delivery_late
                                    });
                                    filterValidation('delivery_pickup_option');
                                    // checkIfSkipped();
                                } }), OSTooltipComponent('new_restaurant_delivery_late', t('deliveryPickup.delivery.lateSwitch.tooltip'))] }), (deliverySettings.new_restaurant_delivery_late ||
                        deliverySettings.new_restaurant_delivery_asap) && (_jsxs(_Fragment, { children: [_jsxs(OSTextBox, { sx: { alignItems: 'start !important' }, children: [_jsx(OSInlineText, { required: true, error: deliverySettings.delivery_fixed_price === true
                                            ? !!validationErrors?.static_price?.length
                                            : validationErrors?.price_per_unit?.length, id: 'outlined-required', label: deliverySettings.delivery_fixed_price === true
                                            ? t('deliveryPickup.delivery.ppu.label.static')
                                            : t('deliveryPickup.delivery.ppu.label.dynamic'), fullWidth: true, sx: {
                                            paddingRight: '0.5rem'
                                        }, value: deliverySettings.static_price, helperText: deliverySettings.delivery_fixed_price === true
                                            ? validationMessages(validationErrors?.static_price)
                                            : validationMessages(validationErrors?.price_per_unit), onChange: (e) => {
                                            setDeliverySettings({
                                                ...deliverySettings,
                                                // @ts-ignore
                                                static_price: e?.target?.value
                                            });
                                            filterValidation('static_price');
                                            filterValidation('price_per_unit');
                                        } }), _jsx(OSInlineSelect, { sx: { top: '0 !important' }, children: _jsxs(OSSelect, { labelId: 'demo-simple-select-label', id: 'demo-simple-select', error: !!validationErrors?.delivery_fixed_price?.length, value: deliverySettings.delivery_fixed_price, 
                                            // defaultValue="true"
                                            // value={
                                            //     deliverySettings.new_restaurant_delivery_range_unit
                                            // }
                                            // label="Delivery Range"
                                            onChange: (e) => {
                                                setDeliverySettings({
                                                    ...deliverySettings,
                                                    delivery_fixed_price: e.target.value
                                                });
                                                filterValidation('delivery_fixed_price');
                                            }, children: [_jsxs(MenuItem, { value: true, children: [' ', t('deliveryPickup.delivery.ppu.options', {
                                                            returnObjects: true
                                                        })[1]] }), _jsxs(MenuItem, { value: false, children: [' ', t('deliveryPickup.delivery.ppu.options', {
                                                            returnObjects: true
                                                        })[0]] })] }) }), OSTooltipComponent('delivery_fixed_price', t('deliveryPickup.delivery.ppu.tooltip'))] }), _jsxs(OSTextWithSelectBox, { children: [_jsx(TextField, { InputProps: {
                                            // className: "rounded-lg",
                                            endAdornment: (_jsx(InputAdornment, { position: 'start', children: _jsx(FormControl, { fullWidth: true, children: _jsxs(Select, { sx: { minWidth: 10 }, variant: 'standard', labelId: 'demo-simple-select-label', id: 'demo-simple-select', error: !!validationErrors
                                                            ?.new_restaurant_delivery_range_unit?.length, value: deliverySettings.new_restaurant_delivery_range_unit, defaultValue: 'km', 
                                                        // value={
                                                        //     deliverySettings.new_restaurant_delivery_range_unit
                                                        // }
                                                        // label="Delivery Range"
                                                        onChange: (e) => {
                                                            setDeliverySettings({
                                                                ...deliverySettings,
                                                                new_restaurant_delivery_range_unit: e.target.value
                                                            });
                                                            filterValidation('new_restaurant_delivery_range_unit');
                                                        }, children: [_jsx(MenuItem, { value: 'km', children: "km" }), _jsx(MenuItem, { value: 'mil', children: "mil" })] }) }) }))
                                        }, required: true, error: !!validationErrors?.new_restaurant_delivery_range_value
                                            ?.length, id: 'outlined-required_', label: t('deliveryPickup.delivery.range.label'), fullWidth: true, defaultValue: deliverySettings.new_restaurant_delivery_range_value, helperText: validationMessages(validationErrors?.new_restaurant_delivery_range_value), onChange: (e) => {
                                            setDeliverySettings({
                                                ...deliverySettings,
                                                new_restaurant_delivery_range_value: e.target.value
                                            });
                                            filterValidation('new_restaurant_delivery_range_value');
                                        } }), OSTooltipComponent('new_restaurant_delivery_range_value', t('deliveryPickup.delivery.range.tooltip'))] }), _jsxs(OSTextBox
                            // TODO replace sx with stepper_box_text_inputs
                            , { 
                                // TODO replace sx with stepper_box_text_inputs
                                sx: {
                                    display: 'flex',
                                    // marginTop: "0.5rem",
                                    // marginBottom: "0.5rem",
                                    // marginLeft: '1rem',
                                    marginBottom: '1rem',
                                    marginHorizontal: '0',
                                    justifyItems: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    borderRadius: '0.5rem',
                                    '.MuiInputBase-formControl': {
                                        borderRadius: '0.5rem'
                                    }
                                }, children: [_jsx(OSText, { sx: {
                                            borderRadius: '0.5rem'
                                        }, required: true, error: !!validationErrors?.new_restaurant_delivery_min_order_value
                                            ?.length, id: 'outlined-required', label: t('deliveryPickup.delivery.minOrder.label'), fullWidth: true, type: 'number', defaultValue: deliverySettings.new_restaurant_delivery_min_order_value, helperText: validationMessages(validationErrors?.new_restaurant_delivery_min_order_value), onChange: (e) => {
                                            setDeliverySettings({
                                                ...deliverySettings,
                                                new_restaurant_delivery_min_order_value: e?.target?.value
                                            });
                                            filterValidation('new_restaurant_delivery_min_order_value');
                                        } }), OSTooltipComponent('new_restaurant_delivery_min_order_value', t('deliveryPickup.delivery.minOrder.tooltip'))] }), deliverySettings.new_restaurant_delivery_late && (_jsxs(OSTextBox, { children: [_jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { id: 'demo-simple-select-label', children: t('deliveryPickup.delivery.dateRange.label') }), _jsx(OSSelect, { labelId: 'demo-simple-select-label', id: 'demo-simple-select', error: !!validationErrors?.new_restaurant_delivery_data_range
                                                    ?.length, value: deliverySettings.new_restaurant_delivery_data_range, label: t('deliveryPickup.delivery.dateRange.label'), onChange: (e) => {
                                                    setDeliverySettings({
                                                        ...deliverySettings,
                                                        // @ts-ignore
                                                        new_restaurant_delivery_data_range: e.target.value
                                                    });
                                                    filterValidation('new_restaurant_delivery_data_range');
                                                }, defaultValue: '1', children: ['1', '2', '3', '4', '5'].map((item, i) => (_jsx(MenuItem, { value: item, children: t('deliveryPickup.delivery.dateRange.options', {
                                                        returnObjects: true
                                                    })[i] }, item))) })] }), OSTooltipComponent('new_restaurant_delivery_data_range', t('deliveryPickup.delivery.dateRange.tooltip'))] }))] }))] })] })));
}
export default DeliverySettings;
