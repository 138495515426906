import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { ProfileContext } from "../../profile/ProfileContext";
import { StoreContext } from "../../layout/StoreContext";
import Stack from "@mui/material/Stack";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import { useTheme, useMediaQuery } from "@mui/material";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import LocationOnOutlined from "@mui/icons-material/LocationOnOutlined";
import RestaurantDrawerHeader from "../../components/RestaurantDrawerHeader";
import RestaurantList from "../../components/RestaurantList";
import CreateBranchButton from "../../components/CreateBranchButton";
export default function SelectRestaurant() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [status, setStatus] = useState("active");
    const [searchTerm, setSearchTerm] = useState("");
    const { restaurant, userInfo } = useContext(ProfileContext);
    const { isMenuOpen, selectedNetwork, restaurants, getRestaurants, userCanCreateBranch, } = useContext(StoreContext);
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
    const restaurantList = status === "active" ? restaurants.active : restaurants.deleted;
    return (_jsxs(Stack, { flexGrow: 1, children: [_jsxs(ListItemButton, { disableGutters: true, sx: { borderRadius: 3, px: isMenuOpen ? "12px" : 0, py: 0 }, onClick: (e) => setAnchorEl(e.currentTarget), children: [_jsx(ListItemText, { primary: (isMdDown ? true : isMenuOpen) ? restaurant?.name : null, primaryTypographyProps: {
                            mb: 1,
                            fontSize: 14,
                            fontWeight: 600,
                            color: "black",
                            lineHeight: 1,
                        }, secondary: _jsxs(_Fragment, { children: [_jsx(LocationOnOutlined, { sx: { fontSize: (isMdDown ? true : isMenuOpen) ? 14 : 24 }, color: "error" }), (isMdDown ? true : isMenuOpen) && restaurant?.address.street] }), secondaryTypographyProps: {
                            noWrap: true,
                            maxWidth: 170,
                            fontSize: 12,
                            color: "text.secondary",
                            lineHeight: 1,
                        } }), _jsx(ArrowDropDownOutlinedIcon, {})] }), _jsx(Popover, { onClose: () => setAnchorEl(null), open: Boolean(anchorEl), anchorEl: anchorEl, PaperProps: {
                    sx: (theme) => ({
                        [theme.breakpoints.up("md")]: { maxWidth: 448, borderRadius: 4 },
                        [theme.breakpoints.down("md")]: {
                            width: "100vw",
                            height: "100vh",
                            margin: 0,
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: "100%",
                            maxHeight: "100%",
                            top: "0 !important",
                            left: "0 !important",
                            borderRadius: 0,
                        },
                    }),
                }, children: selectedNetwork && (_jsxs(_Fragment, { children: [_jsx(RestaurantDrawerHeader, { top: 0, onClose: () => setAnchorEl(null), selectedNetwork: selectedNetwork, status: status, setStatus: setStatus, user: userInfo, searchTerm: searchTerm, setSearchTerm: setSearchTerm }), _jsx(RestaurantList, { restaurants: restaurantList.filter((restaurant) => restaurant.name.toLowerCase().includes(searchTerm.toLowerCase())), selectedNetwork: selectedNetwork, status: status, setStatus: setStatus, refetchRestaurants: getRestaurants }), status !== "deleted" && userCanCreateBranch && (_jsx(Box, { sx: {
                                p: 2,
                                mt: "auto",
                                position: "sticky",
                                bottom: 0,
                                bgcolor: "white",
                                boxShadow: "0px -4px 10px 0px #00000026",
                            }, children: userInfo && (_jsx(CreateBranchButton, { user: userInfo, selectedNetwork: selectedNetwork })) }))] })) })] }));
}
