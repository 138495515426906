import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useState, useRef } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { MenuContext } from '../context';
import { ActionType } from '../context/types';
import { fetchApi } from '../../../config/Api';
import { getNetworkId, getErrorMessage } from '../../UtilityFunctions';
import timePickerOptions from '../../../constants/time-picker-options';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import CheckOutlined from '@mui/icons-material/CheckOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ConfirmationDialog from './ConfirmationDialog';
import CloseDrawerDialog from './CloseDrawerDialog';
import { RoundedTextfield, PrimaryButton, SecondaryButton } from './styled';
const Schema = z
    .object({
    name: z.string().min(4),
    is_available: z.string().min(1),
    menu_hours: z
        .array(z.object({
        day: z.string(),
        start_time: z.string().min(1),
        end_time: z.string()
    }))
        .min(1)
})
    .superRefine(({ menu_hours }, ctx) => {
    const errIdx = menu_hours.findIndex((day) => day.start_time !== '23:59:59' && day.end_time === '');
    if (errIdx !== -1) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [`menu_hours.${errIdx}.end_time`]
        });
    }
});
const defaultValues = {
    is_available: '',
    name: '',
    menu_hours: []
};
export default function MenuDrawer() {
    const [t] = useTranslation('menu');
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
    const { isMenuDrawerOpen, toggleDrawer, editMenu, dispatch, getMenus, getCategories, getItems } = useContext(MenuContext);
    const { watch, reset, setValue, control, register, handleSubmit, formState: { errors, isDirty } } = useForm({
        resolver: zodResolver(Schema),
        defaultValues
    });
    const closeDialogRef = useRef();
    const handleClose = () => {
        if (isDirty) {
            closeDialogRef.current?.open();
        }
        else {
            toggleDrawer('isMenuDrawerOpen');
            dispatch({
                type: ActionType.SET_EDIT_MENU,
                payload: null
            });
            reset(defaultValues);
        }
    };
    const handleLeave = () => {
        dispatch({
            type: ActionType.SET_EDIT_MENU,
            payload: null
        });
        reset(defaultValues);
        toggleDrawer('isMenuDrawerOpen');
    };
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'menu_hours'
    });
    const network_id = getNetworkId();
    const handleDelete = () => {
        setIsDeleting(true);
        fetchApi({
            method: 'DELETE',
            url: `/new-menus/${editMenu?.id}`,
            data: { network_id }
        })
            .then((res) => {
            setConfirmDeleteDialog(false);
            handleClose();
            getMenus();
            getCategories();
            getItems();
            window.globalToast({
                type: 'success',
                title: 'Success',
                description: res.data.message
            });
        })
            .catch((e) => {
            window.globalToast({
                type: 'success',
                title: 'Error',
                description: e.response.data.error_message
            });
        })
            .finally(() => setIsDeleting(false));
    };
    const onSubmit = (data) => {
        setIsSaving(true);
        const url = editMenu ? `/new-menus/${editMenu.id}` : '/new-menus';
        const newMenuHours = data.menu_hours.map((day) => ({
            ...day,
            start_time: day.start_time === '23:59:59' ? '00:00:00' : day.start_time,
            end_time: day.end_time === '' ? '23:59:59' : day.end_time
        }));
        fetchApi({
            method: 'POST',
            url,
            data: { ...data, menu_hours: newMenuHours, network_id }
        })
            .then((res) => {
            getMenus();
            dispatch({
                type: ActionType.SET_SELECTED_MENU,
                payload: editMenu
            });
            dispatch({
                type: ActionType.SET_EDIT_MENU,
                payload: null
            });
            reset(defaultValues);
            toggleDrawer('isMenuDrawerOpen');
            getCategories();
            window.globalToast({
                type: 'success',
                title: 'Success',
                description: res.data.message
            });
        })
            .catch((e) => {
            window.globalToast({
                type: 'error',
                title: 'Error',
                description: getErrorMessage(e.response)
            });
        })
            .finally(() => setIsSaving(false));
    };
    useEffect(() => {
        if (editMenu) {
            const newMenuHours = editMenu.new_menu_hours.map((day) => {
                if (day.start_time === '00:00:00' && day.end_time === '23:59:59') {
                    return {
                        ...day,
                        start_time: '23:59:59',
                        end_time: ''
                    };
                }
                return day;
            });
            reset({
                is_available: editMenu.is_available.toString(),
                name: editMenu.name,
                menu_hours: newMenuHours
            });
        }
    }, [editMenu]);
    const findTimelineIndex = (day) => fields.findIndex((item) => item.day === day);
    return (_jsxs(_Fragment, { children: [_jsx(CloseDrawerDialog, { ref: closeDialogRef, onLeave: handleLeave }), _jsx(ConfirmationDialog, { isLoading: isDeleting, primaryText: t('dialogs.deleteMenu.title'), secondaryText: t('dialogs.deleteMenu.description'), isOpen: confirmDeleteDialog, setIsOpen: setConfirmDeleteDialog, confirmBtnLabel: t('dialogs.deleteMenu.primaryBtnLabel'), confirmBtnCallback: handleDelete }), _jsxs(Drawer, { open: isMenuDrawerOpen, onClose: handleClose, anchor: 'right', variant: 'temporary', PaperProps: {
                    sx: {
                        width: '100%',
                        maxWidth: '512px'
                    }
                }, slotProps: {
                    backdrop: {
                        sx: {
                            backgroundColor: 'transparent'
                        }
                    }
                }, sx: { zIndex: 1300 }, children: [_jsxs(Box, { py: 3, px: 4, borderBottom: 1, borderColor: 'divider', sx: {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            backgroundColor: '#fff'
                        }, children: [_jsxs(Box, { children: [_jsx(Typography, { component: 'span', color: 'text.secondary', children: t('drawers.menu.title') }), _jsx(Typography, { component: 'h1', fontSize: 24, fontWeight: 600, children: editMenu ? editMenu.name : t('drawers.menu.subtitle') })] }), _jsx(IconButton, { onClick: handleClose, children: _jsx(CloseOutlined, {}) })] }), _jsxs("form", { onSubmit: handleSubmit(onSubmit), style: {
                            display: 'grid',
                            overflowY: 'auto',
                            gridTemplateRows: '1fr auto',
                            flexGrow: 1
                        }, children: [_jsxs(Box, { sx: { overflowY: 'auto' }, children: [_jsxs(Box, { py: 3, gap: 3, display: 'flex', flexDirection: 'column', overflow: 'auto', flexShrink: 0, children: [_jsxs(Box, { mx: 4, children: [_jsx(Typography, { fontWeight: 600, children: "Menus" }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', children: t('drawers.menu.description') })] }), _jsx(Box, { px: 3, children: _jsx(Controller, { name: 'is_available', control: control, render: ({ field: { value, onChange } }) => (_jsxs(RoundedTextfield, { select: true, fullWidth: true, label: t('drawers.menu.form.status.label'), error: !!errors.is_available, value: value, onChange: (e) => onChange(e.target.value), children: ["4", _jsxs(MenuItem, { value: '1', sx: (theme) => ({
                                                                    '& .MuiSvgIcon-root': {
                                                                        marginRight: theme.spacing(1.5)
                                                                    }
                                                                }), children: [_jsx(CheckOutlined, {}), ' ', t('drawers.menu.form.status.options.1')] }), _jsxs(MenuItem, { value: '0', sx: (theme) => ({
                                                                    '& .MuiSvgIcon-root': {
                                                                        marginRight: theme.spacing(1.5)
                                                                    }
                                                                }), children: [_jsx(CloseOutlined, {}), t('drawers.menu.form.status.options.2')] })] })) }) }), _jsx(Divider, {}), _jsx(Box, { px: 3, children: _jsx(RoundedTextfield, { fullWidth: true, label: t('drawers.menu.form.name.label'), error: !!errors.name, ...register('name') }) })] }), _jsx(Divider, { sx: {
                                            height: '8px',
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                            borderTop: 1,
                                            borderColor: 'divider'
                                        } }), _jsxs(Stack, { gap: 2, py: 3, px: 4, overflow: 'auto', children: [errors.menu_hours && (_jsx(Alert, { severity: 'error', children: t('drawers.menu.form.errorAlert') })), [
                                                'monday',
                                                'tuesday',
                                                'wednesday',
                                                'thursday',
                                                'friday',
                                                'saturday',
                                                'sunday'
                                            ].map((day) => (_jsxs(Stack, { gap: 2, children: [_jsxs(Box, { sx: {
                                                            height: '56px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            border: 1,
                                                            borderRadius: 3,
                                                            borderColor: 'divider',
                                                            gap: 4,
                                                            px: 2
                                                        }, children: [_jsx(Typography, { children: t(`drawers.menu.form.days.${day}`) }), _jsx(FormControlLabel, { checked: fields.map((item) => item.day).includes(day), control: _jsx(Switch, { onChange: (e) => {
                                                                        if (e.target.checked) {
                                                                            append({ day, start_time: '', end_time: '' });
                                                                        }
                                                                        else {
                                                                            remove(findTimelineIndex(day));
                                                                        }
                                                                    } }), label: fields.map((item) => item.day).includes(day)
                                                                    ? t(`drawers.menu.form.open`)
                                                                    : t(`drawers.menu.form.closed`), labelPlacement: 'end' })] }), fields.map((item) => item.day).includes(day) && (_jsxs(Stack, { direction: 'row', spacing: 2, children: [_jsx(Controller, { control: control, name: `menu_hours.${findTimelineIndex(day)}.start_time`, render: ({ field: { value, onChange }, fieldState: { error } }) => (_jsx(RoundedTextfield, { select: true, fullWidth: true, label: t(`drawers.menu.form.opensAt`), placeholder: 'Select Time', slotProps: {
                                                                        input: {
                                                                            endAdornment: (_jsx(InputAdornment, { position: 'start', children: _jsx(AccessTimeIcon, {}) }))
                                                                        },
                                                                        select: {
                                                                            IconComponent: () => null
                                                                        }
                                                                    }, value: value, onChange: (e) => {
                                                                        setValue(`menu_hours.${findTimelineIndex(day)}.end_time`, '');
                                                                        onChange(e.target.value);
                                                                    }, error: !!error, children: timePickerOptions.map((option) => (_jsx(MenuItem, { value: option.value, children: option.label }, option.label))) })) }), _jsx(Controller, { control: control, name: `menu_hours.${findTimelineIndex(day)}.end_time`, render: ({ field: { value, onChange }, fieldState: { error } }) => (_jsx(RoundedTextfield, { select: true, fullWidth: true, label: t(`drawers.menu.form.closesAt`), placeholder: 'Select Time', slotProps: {
                                                                        input: {
                                                                            endAdornment: (_jsx(InputAdornment, { position: 'start', children: _jsx(AccessTimeIcon, {}) }))
                                                                        },
                                                                        select: {
                                                                            IconComponent: () => null
                                                                        }
                                                                    }, value: value, onChange: onChange, error: !!error, disabled: !watch(`menu_hours.${findTimelineIndex(day)}.start_time`) ||
                                                                        watch(`menu_hours.${findTimelineIndex(day)}.start_time`) === '23:59:59', children: timePickerOptions
                                                                        .filter((_, i) => i !== 0)
                                                                        .filter((option) => {
                                                                        const startTime = watch(`menu_hours.${findTimelineIndex(day)}.start_time`);
                                                                        const startTimeObj = moment(startTime, 'HH:mm:ss');
                                                                        const endTimeObj = moment(option.value, 'HH:mm:ss');
                                                                        return endTimeObj > startTimeObj;
                                                                    })
                                                                        .map((option) => (_jsx(MenuItem, { value: option.value, children: option.label }, option.label))) })) })] }))] }, day)))] })] }), _jsxs(Box, { py: 2, px: 4, gap: 2, display: 'flex', boxShadow: '0px -4px 10px 0px rgba(0, 0, 0, 0.07)', children: [editMenu && (_jsx(Button, { type: 'button', variant: 'outlined', color: 'error', sx: {
                                            textTransform: 'none',
                                            boxShadow: 'none',
                                            fontSize: 16,
                                            borderRadius: '12px'
                                        }, onClick: () => setConfirmDeleteDialog(true), children: t('drawers.menu.form.primaryBtnLabel2') })), _jsx(SecondaryButton, { type: 'button', variant: 'outlined', onClick: handleClose, sx: { ml: 'auto' }, children: t('drawers.menu.form.secondaryBtnLabel') }), _jsx(PrimaryButton, { type: 'submit', variant: 'contained', disabled: isSaving || !isDirty, children: isSaving ? (_jsx(CircularProgress, { size: '24px' })) : (t('drawers.menu.form.primaryBtnLabel1')) })] })] })] })] }));
}
