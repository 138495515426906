import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
export const validationDiv = (errors) => {
    return errors ? (_jsx("div", { className: "mb-2", children: errors?.map((item, index) => (_jsxs("div", { className: "mb-1 text-normal text-red-600 ", children: ["* ", item] }, index))) })) : (_jsx(_Fragment, {}));
};
export const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday"
];
export function validationMessages(errors) {
    if (!errors?.length) {
        return [];
    }
    let messages = [];
    errors?.forEach((error) => {
        messages.push(_jsxs(_Fragment, { children: [error, _jsx("br", {})] }));
    });
    return messages;
}
export function validationBorder(errors) {
    return errors ? "mb-1 border-red-600 rounded-md border-2 border-red-600 rounded-xl" : "mb-3";
}
export function validationColor(errors) {
    return errors ? "text-red-600" : " ";
}
