import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { format, eachDayOfInterval, parse, eachHourOfInterval, startOfDay, endOfDay, } from "date-fns";
import { fetchApi } from "../../../config/Api";
import { getRestaurantId, getCurrency } from "../../UtilityFunctions";
import { AreaChart, Area, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { Box, Paper, Typography, MenuItem, CircularProgress, } from "@mui/material";
import Select from "@mui/material/Select";
const renderToolTip = (props, type) => {
    const { payload } = props;
    const currency = getCurrency();
    const data = payload[0];
    return (_jsx(Paper, { sx: { p: 2 }, children: _jsxs(Typography, { children: [data?.payload.slot, " \u2022 ", type === "currency" ? currency : "", type === "currency"
                    ? data?.payload.value.toFixed(2)
                    : data?.payload.value] }) }));
};
const ChartItem = (props) => {
    const { name, endpoint, type, query, dateRange, showPlatformSelector } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [platform, setPlatform] = useState("app");
    const handleChange = (event) => {
        setPlatform(event.target.value);
    };
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const { fromDate, toDate } = dateRange;
            const resturantId = getRestaurantId();
            if (!resturantId)
                return;
            try {
                const url = endpoint.replace(":restaurantId", resturantId);
                const res = await fetchApi({
                    method: "GET",
                    url: `${url}?fromDate=${fromDate}&toDate=${toDate}${query ? `&${query}` : ""}${showPlatformSelector ? `&platform_type=${platform}` : ""}`,
                });
                if (fromDate !== toDate) {
                    const dateArray = eachDayOfInterval({
                        end: parse(dateRange.toDate, "dd/MM/yyyy", new Date()),
                        start: parse(dateRange.fromDate, "dd/MM/yyyy", new Date()),
                    });
                    const mappedData = dateArray.map((date) => {
                        const formattedDate = format(date, "yyyy-MM-dd");
                        const foundData = res.data.result.find((item) => item.slot === formattedDate);
                        if (!foundData) {
                            return {
                                slot: format(date, "MMM dd"),
                                value: 0,
                            };
                        }
                        const value = foundData.total_sales ??
                            foundData.total_tips_amount ??
                            foundData.count;
                        return {
                            slot: format(date, "MMM dd"),
                            value,
                        };
                    });
                    setChartData(mappedData);
                }
                else if (fromDate === toDate) {
                    const NOW = new Date();
                    const dateArray = eachHourOfInterval({
                        end: endOfDay(NOW),
                        start: startOfDay(NOW),
                    });
                    const mappedData = dateArray.map((date) => {
                        const formattedDate = format(date, "HH:mm");
                        const foundData = res.data.result.find((item) => item.slot === formattedDate);
                        if (!foundData) {
                            return {
                                slot: format(date, "MMM dd"),
                                value: 0,
                            };
                        }
                        const value = foundData.total_sales ??
                            foundData.total_tips_amount ??
                            foundData.count;
                        return {
                            slot: format(date, "hh a"),
                            value: value,
                        };
                    });
                    setChartData(mappedData);
                }
            }
            catch (error) {
                console.log(error);
            }
            finally {
                setIsLoading(false);
            }
        };
        if (endpoint)
            fetchData();
    }, [dateRange.fromDate, dateRange.toDate, platform]);
    const currency = getCurrency();
    const from = format(parse(dateRange.fromDate, "dd/MM/yyyy", new Date()), "MMM dd");
    const to = format(parse(dateRange.toDate, "dd/MM/yyyy", new Date()), "MMM dd");
    const total = chartData.reduce((acc, curr) => acc + curr.value, 0);
    return (_jsxs(Box, { borderTop: 1, borderColor: "divider", position: "relative", children: [isLoading && (_jsx(Box, { sx: (theme) => ({
                    width: "100%",
                    height: "100%",
                    zIndex: theme.zIndex.drawer + 1,
                    position: "absolute",
                    display: "grid",
                    placeContent: "center",
                }), children: _jsx(CircularProgress, {}) })), _jsxs(Box, { p: 3, display: "flex", justifyContent: "space-between", alignItems: "center", children: [_jsxs("div", { children: [_jsx(Typography, { mb: 1, fontSize: 14, fontWeight: 600, children: name }), _jsxs(Typography, { fontSize: 18, fontWeight: 600, children: [type === "currency" ? currency : "", type === "currency" ? total.toFixed(2) : total] })] }), showPlatformSelector && (_jsxs(Select, { value: platform, onChange: handleChange, size: "small", variant: "standard", disableUnderline: true, children: [_jsx(MenuItem, { value: "app", children: "iOS & Android" }), _jsx(MenuItem, { value: "ios", children: "iOS" }), _jsx(MenuItem, { value: "android", children: "Android" })] }))] }), _jsx(ResponsiveContainer, { width: "100%", height: 150, children: _jsxs(AreaChart, { width: 380, height: 150, data: chartData, children: [_jsx(Tooltip, { content: (props) => renderToolTip(props, type) }), _jsx(XAxis, { hide: true, dataKey: "slot" }), _jsx(Area, { dataKey: "value", stroke: "#2196F3", strokeWidth: 2, fillOpacity: 1, fill: "#2196F31F" })] }) }), _jsxs(Box, { display: "flex", justifyContent: "space-between", children: [_jsx(Typography, { fontSize: 14, fontWeight: 600, color: "text.secondary", children: from }), _jsx(Typography, { fontSize: 14, fontWeight: 600, color: "text.secondary", children: to })] })] }));
};
export default ChartItem;
