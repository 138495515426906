import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState, useEffect, } from "react";
import { fetchApi } from "../../config/Api";
import Storage from "../../config/Storage";
import { getNetworkId } from "../UtilityFunctions";
export const StoreContext = createContext({
    isMenuOpen: true,
    setIsMenuOpen: () => { },
    selectedNetwork: null,
    restaurants: {
        active: [],
        deleted: [],
    },
    getRestaurants: () => { },
    userCanCreateBranch: false,
});
const StoreProvider = ({ children }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(Storage.getItem("isMenuOpen") === "true");
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [restaurants, setRestaurants] = useState({
        active: [],
        deleted: [],
    });
    const [userCanCreateBranch, setUserCanCreateBranch] = useState(false);
    const networkId = getNetworkId();
    const getRestaurants = () => {
        fetchApi({
            method: "GET",
            url: `/active-deleted-restaurants/${networkId}`,
        }).then((res) => {
            setRestaurants(res.data.network.allRestaurants);
            setUserCanCreateBranch(res.data.userCanCreateBranch);
            const restaurants = res.data.network.allRestaurants;
            Storage.setItem("restaurants", JSON.stringify([...restaurants.active, ...restaurants.deleted]));
        });
    };
    const getNetworks = () => {
        fetchApi({
            method: "GET",
            url: "/network/list",
        }).then((res) => {
            const foundNetwork = res.data.networks.find((network) => network.id === Number(networkId));
            if (foundNetwork) {
                setSelectedNetwork(foundNetwork);
            }
        });
    };
    useEffect(() => {
        getNetworks();
        getRestaurants();
    }, []);
    return (_jsx(StoreContext.Provider, { value: {
            isMenuOpen,
            setIsMenuOpen,
            selectedNetwork,
            restaurants,
            getRestaurants,
            userCanCreateBranch,
        }, children: children }));
};
export default StoreProvider;
