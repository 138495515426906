import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { usePaymentsContext } from '../PaymentsContext';
import { getCurrency } from '../../UtilityFunctions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FilterByDate from './FilterByDate';
import FilterByStatus from './FilterByStatus';
import OrdersTable from './OrdersTable';
import OrderDrawer from './OrderDrawer';
export default function Orders() {
    const { statistics } = usePaymentsContext();
    const [t] = useTranslation('financials');
    const currency = getCurrency();
    return (_jsxs(_Fragment, { children: [_jsx(OrderDrawer, {}), _jsxs(Box, { sx: (theme) => ({
                    mb: 3,
                    pt: 4,
                    px: 4,
                    gap: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    [theme.breakpoints.down('md')]: {
                        px: 2,
                        gap: 3
                    }
                }), children: [_jsxs(Box, { sx: (theme) => ({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            [theme.breakpoints.down('md')]: {
                                gap: 3,
                                flexDirection: 'column',
                                alignItems: 'stretch'
                            }
                        }), children: [_jsxs("div", { children: [_jsx(Typography, { fontSize: 20, fontWeight: 600, children: t('orders.title') }), _jsx(Typography, { mb: 'auto', fontSize: 12, color: 'text.secondary', children: t('orders.subtitle') })] }), _jsx(FilterByDate, {})] }), _jsx(Box, { display: 'flex', alignItems: 'center', justifyContent: 'space-between', sx: (theme) => ({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            [theme.breakpoints.down('md')]: {
                                alignItems: 'flex-start',
                                flexDirection: 'column-reverse'
                            }
                        }), children: _jsx(FilterByStatus, {}) }), _jsxs(Box, { sx: (theme) => ({
                            gap: 3,
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr 1fr',
                            [theme.breakpoints.down('md')]: {
                                gridTemplateColumns: '1fr 1fr'
                            }
                        }), children: [_jsx(Statistic, { label: t('orders.stats.1'), value: `${statistics.totalOrdersCount}` }), _jsx(Statistic, { label: t('orders.stats.2'), value: `${currency}${statistics.averageOrderSpend.toFixed(2)}` }), _jsx(Statistic, { label: t('orders.stats.3'), value: `${currency}${statistics.totalTipsAmount.toFixed(2)}` }), _jsx(Statistic, { label: t('orders.stats.4'), value: `${currency}${statistics.totalOrdersSum.toFixed(2)}` })] })] }), _jsx(Box, { sx: (theme) => ({
                    flexGrow: 1,
                    borderTop: 1,
                    borderColor: 'divider',
                    [theme.breakpoints.up('md')]: {
                        mx: 4
                    }
                }), children: _jsx(OrdersTable, {}) })] }));
}
function Statistic({ label, value }) {
    return (_jsxs(Box, { p: 2, border: 1, borderColor: 'divider', borderRadius: 2, flexGrow: 1, children: [_jsx(Typography, { fontWeight: 600, color: 'GrayText', whiteSpace: 'nowrap', children: label }), _jsx(Typography, { fontSize: 26, fontWeight: 700, children: value })] }));
}
