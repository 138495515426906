import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
export default function SideDrawer(props) {
    const { isOpen, onClose, secondaryText, primaryText, children, chip } = props;
    return (_jsxs(Drawer, { open: isOpen, onClose: onClose, anchor: "right", variant: "temporary", sx: {
            zIndex: 1300,
        }, PaperProps: {
            sx: {
                width: "100%",
                maxWidth: "512px",
            },
        }, slotProps: {
            backdrop: {
                sx: {
                    backgroundColor: "transparent",
                },
            },
        }, children: [_jsxs(Box, { sx: {
                    py: 3,
                    px: 4,
                    top: 0,
                    position: "sticky",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#fff",
                    borderBottom: 1,
                    borderColor: "divider",
                    zIndex: 2,
                }, children: [_jsxs(Box, { children: [secondaryText && (_jsx(Typography, { component: "span", color: "text.secondary", children: secondaryText })), _jsxs(Box, { display: "flex", alignItems: "center", gap: 2, children: [_jsx(Typography, { component: "h1", fontSize: 24, fontWeight: 600, children: primaryText }), chip] })] }), _jsx(IconButton, { onClick: onClose, children: _jsx(CloseOutlinedIcon, {}) })] }), children] }));
}
