import { useState, useEffect } from 'react';
export function useLocalStorage(key, initialValue) {
    // Get stored value from localStorage or use initialValue
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        }
        catch (error) {
            console.error('Error reading localStorage key:', key, error);
            return initialValue;
        }
    });
    // Update localStorage when state changes
    useEffect(() => {
        try {
            localStorage.setItem(key, JSON.stringify(storedValue));
        }
        catch (error) {
            console.error('Error setting localStorage key:', key, error);
        }
    }, [key, storedValue]);
    // Remove item from localStorage
    const removeItem = () => {
        try {
            localStorage.removeItem(key);
            setStoredValue(initialValue);
        }
        catch (error) {
            console.error('Error removing localStorage key:', key, error);
        }
    };
    return [storedValue, setStoredValue, removeItem];
}
