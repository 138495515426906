import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import QuestionMark from '@mui/icons-material/QuestionMark';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { PrimaryButton, SecondaryButton } from './styled';
export default function ConfirmationDialog(props) {
    const { isOpen, setIsOpen, primaryText, secondaryText, confirmBtnCallback, confirmBtnLabel, isLoading } = props;
    const handleClose = () => {
        setIsOpen(false);
    };
    return (_jsxs(Dialog, { onClose: handleClose, open: isOpen, PaperProps: { sx: { borderRadius: '12px' } }, children: [_jsx(DialogTitle, { component: "div", display: "flex", justifyContent: "flex-end", children: _jsx(IconButton, { onClick: handleClose, children: _jsx(CloseOutlined, {}) }) }), _jsxs(DialogContent, { component: 'div', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', sx: { py: '2rem', px: '4rem' }, children: [_jsx(QuestionMark, { color: 'warning', fontSize: 'large' }), _jsxs(Box, { textAlign: 'center', children: [_jsx(Typography, { fontSize: 20, fontWeight: 500, mb: '0.5rem', children: primaryText }), _jsx(Typography, { color: 'text.secondary', children: secondaryText })] })] }), _jsxs(DialogActions, { sx: { pb: 4, px: 4 }, children: [_jsx(SecondaryButton, { variant: "outlined", onClick: handleClose, children: "Cancel" }), _jsx(PrimaryButton, { disabled: isLoading, variant: "contained", onClick: confirmBtnCallback, children: isLoading ? _jsx(CircularProgress, { size: '24px' }) : confirmBtnLabel })] })] }));
}
