import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Storage from '../../../config/Storage';
import { CURRENCIES } from '../../../constants/currencies';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
export default function CustomerOrders(props) {
    const { orders } = props;
    const [t] = useTranslation('customers');
    const [showAllItems, setShowAllItems] = useState(false);
    const storedRestaurants = Storage.getItem('restaurants');
    const restaurants = storedRestaurants
        ? JSON.parse(storedRestaurants)
        : [];
    return (_jsx(_Fragment, { children: orders.map((order) => (_jsxs(Box, { px: 3, py: 3, border: 1, borderColor: 'divider', borderRadius: 2, display: 'grid', gridTemplateColumns: '1fr 1fr', rowGap: 3, columnGap: 6, children: [_jsxs(Box, { children: [_jsx(Typography, { fontWeight: 600, children: restaurants.find((restaurant) => restaurant.id === order.restaurant_id)?.name ?? 'N/A' }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', children: restaurants.find((restaurant) => restaurant.id === order.restaurant_id)?.address.street ?? 'N/A' })] }), _jsxs(Box, { children: [_jsx(Typography, { fontWeight: 600, children: t('drawer.orderCard.createdAt') }), _jsxs(Typography, { fontSize: 14, color: 'text.secondary', children: [moment(order.created_at).format('MM/DD/yyyy'), ' ', moment(order.created_at).format('HH:mm a')] })] }), _jsxs(Box, { children: [_jsx(Typography, { fontWeight: 600, children: t('drawer.orderCard.source') }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', children: "Online" })] }), _jsxs(Box, { children: [_jsx(Typography, { fontWeight: 600, children: t('drawer.orderCard.type.label') }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', textTransform: 'capitalize', children: t(`drawer.orderCard.type.${order.type}`) })] }), _jsxs(Box, { children: [_jsx(Typography, { fontWeight: 600, children: t(`drawer.orderCard.items`) }), order.bookmark
                            .slice(0, showAllItems ? order.bookmark.length : 3)
                            .map((item) => (_jsxs(Typography, { fontSize: 14, color: 'text.secondary', children: [item.quantity, " \u2022 ", item.name] }, item.id + item.name + item.quantity))), order.bookmark.length > 3 && (_jsx(Typography, { fontSize: 14, color: 'text.secondary', sx: { textDecoration: 'underline', cursor: 'pointer' }, onClick: () => setShowAllItems(!showAllItems), children: showAllItems
                                ? t(`drawer.orderCard.condenseBtnLabel`)
                                : t(`drawer.orderCard.expandBtnLabel`) }))] }), _jsxs(Box, { children: [_jsx(Typography, { fontWeight: 600, children: t(`drawer.orderCard.total`) }), _jsxs(Typography, { fontSize: 14, color: 'text.secondary', children: [CURRENCIES[order.bill?.currency], " ", order.bill.total.toFixed(2)] })] })] }, order.id))) }));
}
