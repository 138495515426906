import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../StoreContext';
import { fetchApi } from '../../../config/Api';
import Storage from '../../../config/Storage';
import { getRestaurantId, getNetworkId } from '../../UtilityFunctions';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import Popover from '@mui/material/Popover';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useMediaQuery, useTheme } from '@mui/material';
import { Drawer, DrawerHeader } from './styled';
import SelectRestaurant from './SelectRestaurant';
import OnOffSwitch from './OnOffSwitch';
const links = [
    {
        label: 'Store',
        permission: 'store',
        href: '/store',
        icon: _jsx(StorefrontOutlinedIcon, { sx: { width: 20, height: 20 } })
    },
    {
        label: 'Menu',
        permission: 'menu',
        href: '/menu',
        icon: _jsx(RestaurantOutlinedIcon, { sx: { width: 20, height: 20 } })
    },
    {
        label: 'Financials',
        permission: 'payout',
        icon: _jsx(MonetizationOnOutlinedIcon, { sx: { width: 20, height: 20 } }),
        children: [
            {
                label: 'Orders',
                href: '/payout',
                params: '&view=orders'
            },
            {
                label: 'Statements & Payouts',
                href: '/payout',
                params: '&view=statements'
            }
        ]
    },
    {
        label: 'Customers',
        permission: 'users',
        href: '/users',
        icon: _jsx(PeopleAltOutlinedIcon, { sx: { width: 20, height: 20 } })
    },
    {
        label: 'Employees',
        permission: 'employee',
        href: '/employees',
        icon: _jsx(PersonOutlinedIcon, { sx: { width: 20, height: 20 } })
    },
    {
        label: 'Gallery',
        permission: 'edit-gallery',
        selected: 'gallery',
        href: '/gallery',
        icon: _jsx(PhotoOutlinedIcon, { sx: { width: 20, height: 20 } })
    },
    {
        label: 'Settings',
        permission: 'settings',
        href: '/settings',
        icon: _jsx(SettingsOutlinedIcon, { sx: { width: 20, height: 20 } })
    }
];
function SideMenu() {
    const { isMenuOpen, setIsMenuOpen } = useContext(StoreContext);
    const restaurantId = getRestaurantId();
    const [permissions, setPermissions] = useState([]);
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    useEffect(() => {
        const getPermission = async () => {
            const res = await fetchApi({
                method: 'POST',
                url: '/user-has-permission',
                data: { restaurant_id: restaurantId }
            });
            setPermissions(res.data.result);
        };
        getPermission();
    }, []);
    useEffect(() => {
        if (isLgDown) {
            setIsMenuOpen(false);
        }
    }, [isLgDown]);
    const filteredLinks = links.filter((link) => permissions.includes(link.permission));
    return (_jsxs(Drawer, { anchor: 'left', variant: isMdUp ? 'permanent' : 'temporary', open: isMenuOpen, PaperProps: { sx: { zIndex: 1 } }, children: [_jsx(DrawerHeader, { children: isMdUp && _jsx(SelectRestaurant, {}) }), _jsx(Divider, {}), _jsx(List, { disablePadding: true, sx: { py: 3, px: 2, mb: 'auto', display: 'grid', gap: 0.5 }, children: filteredLinks.map((link) => (_jsx(Item, { link: link }, link.label))) }), _jsx(OnOffSwitch, {})] }));
}
function Item({ link }) {
    const [t] = useTranslation('common');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isCollapseOpen, setIsCollapseOpen] = useState(window.location.search.includes('statements') ||
        window.location.search.includes('orders'));
    const { isMenuOpen, setIsMenuOpen } = useContext(StoreContext);
    const handleClick = (event) => {
        setIsCollapseOpen(!isCollapseOpen);
        if (anchorEl) {
            setAnchorEl(null);
        }
        else {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = isMenuOpen ? isCollapseOpen : Boolean(anchorEl);
    const networkId = getNetworkId();
    const restaurantId = getRestaurantId();
    const query = `?network=${networkId}&restaurant=${restaurantId}`;
    const location = window.location.pathname;
    const isActive = location.includes(link.permission) ||
        (link.selected ? location.includes(link.selected) : false);
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const handleNavigate = () => {
        if (isMdDown) {
            setIsMenuOpen(false);
            Storage.setItem('isMenuOpen', (!isMenuOpen).toString());
        }
        window.location.href = link.href + query;
    };
    return (_jsxs(_Fragment, { children: [link.permission === 'settings' && _jsx(Divider, { sx: { my: 1.5 } }), _jsx(Tooltip, { arrow: true, placement: 'right', title: isMenuOpen ? '' : t(`sidemenu.links.${link.label}`), slotProps: {
                    tooltip: {
                        sx: {
                            zIndex: 10,
                            borderRadius: '8px',
                            bgcolor: 'rgba(33,33,33,0.9)'
                        }
                    }
                }, children: _jsxs(ListItemButton, { disableGutters: true, onClick: (e) => (link.children ? handleClick(e) : handleNavigate()), selected: isActive, sx: {
                        px: '18px',
                        minHeight: 56,
                        fontWeight: 600,
                        borderRadius: 2,
                        justifyContent: isMenuOpen ? 'initial' : 'center',
                        color: isActive ? 'primary.dark' : 'black'
                    }, children: [_jsx(ListItemIcon, { sx: {
                                minWidth: 0,
                                mr: isMenuOpen ? 1 : 'auto',
                                justifyContent: 'center',
                                color: isActive ? 'primary.dark' : 'black'
                            }, children: link.icon }), _jsx(ListItemText, { disableTypography: true, primary: t(`sidemenu.links.${link.label}`), sx: { opacity: isMenuOpen ? 1 : 0 } }), link.children && isMenuOpen && (_jsx(_Fragment, { children: open ? (_jsx(KeyboardArrowUpOutlinedIcon, {})) : (_jsx(KeyboardArrowDownOutlinedIcon, {})) }))] }) }), link.children && isMenuOpen && (_jsx(Collapse, { in: open, timeout: 'auto', unmountOnExit: true, children: _jsx(List, { component: 'div', disablePadding: true, sx: { display: 'grid', gap: 0.5 }, children: link.children.map((child) => (_jsxs(ListItemButton, { selected: window.location.search.includes(child.params), onClick: () => (window.location.href = child.href + query + child.params), sx: {
                            px: '18px',
                            minHeight: 40,
                            fontWeight: 600,
                            borderRadius: 2,
                            justifyContent: isMenuOpen ? 'initial' : 'center',
                            color: window.location.search.includes(child.params)
                                ? 'primary.dark'
                                : 'inherit'
                        }, children: [_jsx(ListItemIcon, { sx: {
                                    mr: 1,
                                    width: 20,
                                    height: 20,
                                    display: 'grid',
                                    placeContent: 'center',
                                    minWidth: 0,
                                    color: window.location.search.includes(child.params)
                                        ? 'primary.dark'
                                        : 'inherit'
                                }, children: _jsx(FiberManualRecordIcon, { sx: { width: 5, height: 5 } }) }), _jsx(ListItemText, { disableTypography: true, primary: t(`sidemenu.links.${child.label}`) })] }, child.label))) }) })), link.children && !isMenuOpen && (_jsx(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                }, slotProps: { paper: { sx: { borderRadius: 3 } } }, children: _jsx(List, { component: 'div', disablePadding: true, sx: { p: 2, display: 'grid', gap: 1 }, children: link.children.map((child) => (_jsxs(ListItemButton, { onClick: () => (window.location.href = child.href + query + child.params), selected: window.location.search.includes(child.params), sx: {
                            px: '18px',
                            width: 224,
                            minHeight: 40,
                            fontWeight: 600,
                            borderRadius: 2,
                            justifyContent: isMenuOpen ? 'initial' : 'center',
                            color: window.location.search.includes(child.params)
                                ? 'primary.dark'
                                : 'inherit'
                        }, children: [_jsx(ListItemIcon, { sx: {
                                    mr: 1,
                                    width: 20,
                                    height: 20,
                                    display: 'grid',
                                    placeContent: 'center',
                                    minWidth: 0,
                                    color: window.location.search.includes(child.params)
                                        ? 'primary.dark'
                                        : 'inherit'
                                }, children: _jsx(FiberManualRecordIcon, { sx: { width: 5, height: 5 } }) }), _jsx(ListItemText, { disableTypography: true, primary: t(`sidemenu.links.${child.label}`) })] }, child.label))) }) }))] }));
}
export default SideMenu;
