import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useContext } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from 'react-i18next';
import { MenuContext } from '../context';
import { ActionType } from '../context/types';
import { fetchApi } from '../../../config/Api';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import EditOutlined from '@mui/icons-material/EditOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import DragIndicatorOutlined from '@mui/icons-material/DragIndicatorOutlined';
import DropdownMenu from './DropdownMenu';
import { TableCell } from './styled';
export default function ModifierTableRow(props) {
    const { modifier } = props;
    const [t] = useTranslation('menu');
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const { getModifiers, dispatch, toggleDrawer, draftModifier, getItems } = useContext(MenuContext);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDelete = () => {
        setIsDeleting(true);
        fetchApi({
            method: 'DELETE',
            url: `/modifier-groups/${props.modifier.id}`
        })
            .then((res) => {
            window.globalToast({
                type: 'success',
                title: 'Success',
                description: res.data.message
            });
            if (modifier.items.length)
                getItems();
            getModifiers();
        })
            .catch(() => {
            handleClose();
            window.globalToast({
                type: 'error',
                title: 'Error',
                description: "Something went wrong! Couldn't delete this modifier"
            });
        })
            .finally(() => {
            setIsDeleting(false);
            handleClose();
        });
    };
    const handleEdit = () => {
        dispatch({
            type: ActionType.SET_DRAFT_MODIFIER,
            payload: props.modifier
        });
        handleClose();
        toggleDrawer('isModifierDrawerOpen');
    };
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.modifier.id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };
    return (_jsxs(_Fragment, { children: [_jsxs(TableRow, { hover: true, selected: draftModifier?.id === modifier.id, onClick: handleEdit, sx: {
                    display: 'grid !important',
                    gridTemplateColumns: '0.5fr 1fr 1fr 1.5fr 0.5fr',
                    bgcolor: '#fff'
                }, ref: setNodeRef, style: style, ...attributes, children: [_jsx(TableCell, { children: _jsx(IconButton, { ...listeners, children: _jsx(DragIndicatorOutlined, {}) }) }), _jsx(TableCell, { children: _jsx(Tooltip, { title: modifier.name.length > 35 ? modifier.name : '', children: _jsx("span", { children: modifier.name.length > 35
                                    ? `${modifier.name.substring(0, 30)}...`
                                    : modifier.name }) }) }), _jsx(TableCell, { children: modifier.options
                            .flat()
                            .map((option) => option.name)
                            .join(', ') }), _jsx(TableCell, { children: modifier.items?.map((item) => item.name).join(', ') }), _jsx(TableCell, { children: _jsx(IconButton, { onClick: (e) => {
                                e.stopPropagation();
                                setAnchorEl(e.currentTarget);
                            }, children: _jsx(MoreVertOutlined, {}) }) })] }), _jsx(DropdownMenu, { anchorEl: anchorEl, onClose: handleClose, options: [
                    {
                        label: t('modifiers.dropdownOptions.edit'),
                        icon: _jsx(EditOutlined, {}),
                        action: handleEdit
                    },
                    {
                        label: t('modifiers.dropdownOptions.delete'),
                        icon: isDeleting ? (_jsx(CircularProgress, { size: '24px' })) : (_jsx(DeleteOutlined, {})),
                        disabled: isDeleting,
                        action: handleDelete
                    }
                ] })] }));
}
