import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getCurrency } from '../../UtilityFunctions';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import AttachMoneyOutlined from '@mui/icons-material/AttachMoneyOutlined';
import EuroOutlined from '@mui/icons-material/EuroOutlined';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { RoundedTextfield } from './styled';
import CustomTooltip from '../../components/CustomTooltip';
export default function ModifierOptionForm(props) {
    const { showOptionFields, setShowOptionFields } = props;
    const [t] = useTranslation('menu');
    const [option, setOption] = useState({
        name: '',
        price: '',
        contain_alcohol: false
    });
    const [errors, setErrors] = useState({ name: false, price: false });
    const { getValues, setValue } = useFormContext();
    const reset = () => {
        setOption({ name: '', price: '', contain_alcohol: false });
        setShowOptionFields(false);
    };
    const handleConfirmOption = () => {
        if (option.name === '' || option.price === '') {
            setErrors({ name: option.name === '', price: option.price === '' });
        }
        else {
            const prevOptions = getValues('options');
            setValue('options', [
                ...prevOptions,
                {
                    name: option.name,
                    price: +option.price,
                    contain_alcohol: option.contain_alcohol
                }
            ], { shouldValidate: true, shouldDirty: true });
            reset();
        }
    };
    const currency = getCurrency();
    const currencyIcon = currency === '$' ? (_jsx(AttachMoneyOutlined, { color: 'action' })) : currency === '€' ? (_jsx(EuroOutlined, { color: 'action' })) : ('');
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (errors[name] && value !== '')
            setErrors((prev) => ({ ...prev, [name]: false }));
        setOption((prev) => ({ ...prev, [name]: value }));
    };
    const handleSwitch = (e) => {
        const { checked } = e.target;
        setOption((prev) => ({
            ...prev,
            contain_alcohol: checked
        }));
    };
    return (_jsx(_Fragment, { children: showOptionFields && (_jsxs(Box, { mt: 3, children: [_jsxs(Box, { mb: 3, display: 'flex', gap: 2, children: [_jsx(RoundedTextfield, { name: 'name', label: t('drawers.modifier.form.options.name.label'), placeholder: t('drawers.modifier.form.options.name.placeholder'), slotProps: {
                                inputLabel: { shrink: true }
                            }, value: option.name, onChange: handleChange, error: errors.name }), _jsx(RoundedTextfield, { type: 'number', name: 'price', label: t('drawers.modifier.form.options.price.label'), placeholder: '0.00', slotProps: {
                                input: { startAdornment: currencyIcon }
                            }, value: option.price, onChange: handleChange, error: errors.price })] }), _jsx(FormControlLabel, { control: _jsx(Switch, { checked: option.contain_alcohol, onChange: handleSwitch }), label: _jsxs(Box, { gap: 1, display: 'flex', alignItems: 'center', children: [t('drawers.modifier.form.options.alcohol.label'), _jsx(CustomTooltip, { tooltipProps: {
                                    placement: 'top',
                                    title: t('drawers.modifier.form.options.alcohol.tooltip')
                                }, children: _jsx(InfoOutlined, { fontSize: 'small', color: 'error' }) })] }), labelPlacement: 'start', sx: {
                        m: 0,
                        mb: 3,
                        px: 1.5,
                        border: 1,
                        width: '100%',
                        borderColor: 'grey.400',
                        borderRadius: 3,
                        paddingY: '7px',
                        justifyContent: 'space-between'
                    } }), _jsxs(Box, { display: 'flex', justifyContent: 'flex-end', gap: 2, children: [_jsx(Button, { variant: 'outlined', color: 'inherit', sx: {
                                borderRadius: '50px',
                                textTransform: 'none',
                                fontWeight: 600,
                                color: 'text.secondary',
                                px: 2
                            }, onClick: reset, children: t('drawers.modifier.form.options.dropdownOptions.remove') }), _jsx(Button, { variant: 'contained', sx: {
                                borderRadius: '50px',
                                textTransform: 'none',
                                fontWeight: 600,
                                px: 2
                            }, onClick: handleConfirmOption, children: t('drawers.modifier.form.options.addOptionBtnLabel') })] })] })) }));
}
