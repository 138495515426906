import { styled } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MUITableCell from "@mui/material/TableCell";
export const RoundedButton = styled(Button)(({ theme }) => ({
    borderRadius: "50px",
    textTransform: "none",
    boxShadow: "none",
    fontWeight: 600,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.secondary,
    "&:hover": {
        backgroundColor: theme.palette.grey[200],
        boxShadow: "none",
    },
}));
export const PrimaryButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    boxShadow: "none",
    backgroundColor: theme.palette.primary.light,
    fontSize: 16,
    borderRadius: 12,
}));
export const SecondaryButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    boxShadow: "none",
    fontSize: 16,
    borderRadius: 12,
    borderColor: theme.palette.grey[300],
    color: theme.palette.text.secondary,
}));
export const RoundedTextfield = styled(TextField)(() => ({
    fieldset: {
        borderRadius: "12px",
    },
}));
export const TableCell = styled(MUITableCell)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        paddingInline: 4,
    },
}));
