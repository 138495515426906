import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { NewBranchContext } from '../NewBranchProvider';
import { validationDiv, days } from './utilities';
import timePickerOptions from '../../../constants/time-picker-options';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import AccessTime from '@mui/icons-material/AccessTime';
import { RoundedTextField } from '../../components/styled';
import { OSOuterContainer, OSInnerContainer, OSContentBox, OSTextBox, OSTimelineSwitchBox, OSTimelineDay, OSTimelineSwitch, OSTimelineRangesBox, OSTimelineRow } from './OSCustomComponents';
function DeliveryTimelines() {
    const [t] = useTranslation('settings');
    const { deliveryTimelines, setDeliveryTimelines, validationErrors, filterValidation } = useContext(NewBranchContext);
    function setDay(day) {
        let workArr = deliveryTimelines;
        workArr[day].active = !workArr[day].active;
        setDeliveryTimelines({
            ...deliveryTimelines,
            [day]: workArr[day]
        });
    }
    function setWorkHours(day, type, time) {
        let workArr = deliveryTimelines;
        // @ts-ignore
        workArr[day][type] = time;
        // @ts-ignore
        setDeliveryTimelines({
            ...deliveryTimelines,
            [day]: workArr[day]
        });
    }
    function getSelectValue(day) {
        if (deliveryTimelines[day].from_reservation_START === '00:00:00' &&
            deliveryTimelines[day].from_reservation_END === '23:59:59') {
            return {
                opensAt: '23:59:59',
                closesAt: ''
            };
        }
        else {
            return {
                opensAt: deliveryTimelines[day].from_reservation_START,
                closesAt: deliveryTimelines[day].from_reservation_END
            };
        }
    }
    return (_jsx(OSOuterContainer, { children: _jsxs(OSInnerContainer, { children: [_jsx(Typography, { sx: {
                        paddingLeft: '0.5rem',
                        fontSize: '0.875rem',
                        marginBottom: '1rem'
                    }, children: t('timelines.descriptionDelivery') }), _jsx(OSContentBox, { children: days.map((day, index) => {
                        return day ? (
                        // row holder
                        _jsxs(OSTimelineRow, { children: [_jsxs(OSTextBox, { children: [_jsxs(OSTimelineSwitchBox, { item: deliveryTimelines[day].active, children: [_jsx(OSTimelineDay, { children: _jsx(Typography, { children: t('timelines.days', { returnObjects: true })[index] }) }), _jsx(OSTimelineSwitch, { checked: deliveryTimelines[day].active, control: _jsx(Switch, {}), value: deliveryTimelines[day].active, id: 'new-restaurant-cash-payment', label: deliveryTimelines[day].active
                                                        ? t('timelines.open')
                                                        : t('timelines.closed'), labelPlacement: 'end', onChange: () => {
                                                        setDay(day);
                                                        filterValidation('delivery_timeline_' + day);
                                                        filterValidation('delivery_timeline');
                                                    } })] }), deliveryTimelines[day].active && (_jsxs(OSTimelineRangesBox, { children: [_jsx(RoundedTextField, { fullWidth: true, select: true, id: 'time', label: t('timelines.opensAt'), value: getSelectValue(day).opensAt, onChange: (e) => {
                                                        setWorkHours(day, 'from_reservation_START', e.target.value);
                                                        setWorkHours(day, 'from_reservation_END', '');
                                                        filterValidation('delivery_timeline_' + day + '_start_time');
                                                        if (e.target.value === '23:59:59') {
                                                            setWorkHours(day, 'from_reservation_END', '');
                                                        }
                                                    }, slotProps: {
                                                        input: {
                                                            endAdornment: (_jsx(InputAdornment, { position: 'start', children: _jsx(AccessTime, {}) }))
                                                        },
                                                        select: {
                                                            IconComponent: () => null
                                                        }
                                                    }, sx: { mr: 1, maxWidth: 200, flexShrink: 0 }, children: timePickerOptions.map((option) => (_jsx(MenuItem, { value: option.value, children: option.label }, option.label))) }), _jsx(RoundedTextField, { disabled: deliveryTimelines[day].from_reservation_START ===
                                                        '23:59:59' ||
                                                        deliveryTimelines[day].from_reservation_END ===
                                                            '23:59:59', fullWidth: true, select: true, id: 'time', label: t('timelines.closesAt'), value: getSelectValue(day).closesAt, onChange: (e) => {
                                                        setWorkHours(day, 'from_reservation_END', e.target.value);
                                                        filterValidation('delivery_timeline_' + day + '_end_time');
                                                    }, slotProps: {
                                                        input: {
                                                            endAdornment: (_jsx(InputAdornment, { position: 'start', children: _jsx(AccessTime, {}) }))
                                                        },
                                                        select: {
                                                            IconComponent: () => null
                                                        }
                                                    }, sx: { maxWidth: 200, flexShrink: 0 }, children: timePickerOptions.map((option) => (_jsx(MenuItem, { value: option.value, children: option.label }, option.label))) })] }))] }), validationDiv(validationErrors['delivery_timeline_' + day]), validationDiv(validationErrors['delivery_timeline_' + day + '_start_time']), validationDiv(validationErrors['delivery_timeline_' + day + '_end_time'])] }, index)) : (_jsx(React.Fragment, {}, uuidv4()));
                    }) }), validationDiv(validationErrors?.delivery_timeline)] }) }));
}
export default DeliveryTimelines;
