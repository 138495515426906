import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { generateImageUrl } from '../UtilityFunctions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import CloseIcon from '@mui/icons-material/Close';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ToggleGroup from './ToggleGroup';
export default function RestaurantDrawerHeader(props) {
    const { onClose, selectedNetwork, status, setStatus, user, searchTerm, setSearchTerm, top = 64 } = props;
    const { t } = useTranslation('common');
    return (_jsxs(Box, { sx: {
            top,
            position: 'sticky',
            zIndex: 1,
            bgcolor: 'white',
            boxShadow: '0px 4px 10px 0px #00000026'
        }, children: [_jsx(Box, { pr: 2, pt: 2, display: 'flex', justifyContent: 'flex-end', children: _jsx(IconButton, { onClick: onClose, children: _jsx(CloseIcon, { sx: { width: 16, height: 16 } }) }) }), _jsxs(Box, { px: 2, gap: 2, display: 'flex', alignItems: 'center', children: [_jsx(Box, { component: 'img', width: 48, height: 49, border: 1, borderRadius: 3, borderColor: 'grey.200', sx: { objectFit: 'cover' }, src: selectedNetwork?.logo
                            ? generateImageUrl(selectedNetwork.logo)
                            : 'images/networks/logo/logo.png' }), _jsxs(Box, { gap: 1, display: 'flex', flexDirection: 'column', children: [_jsx(Typography, { variant: 'subtitle2', fontWeight: 600, children: selectedNetwork?.title }), _jsx(Typography, { variant: 'caption', color: 'text.secondary', children: user?.email })] })] }), _jsx(Divider, { sx: { my: 2 } }), _jsxs(Box, { px: 2, pb: 2, gap: 2, display: 'flex', alignItems: 'center', children: [_jsxs(Box, { sx: {
                            px: 2,
                            height: 36,
                            display: 'flex',
                            alignItems: 'center',
                            flexGrow: 1,
                            gap: 2,
                            backgroundColor: 'grey.100',
                            borderRadius: '18px'
                        }, children: [_jsx(SearchOutlinedIcon, { color: 'action' }), _jsx(InputBase, { placeholder: t('restaurantDrawer.searchFieldPlaceholder'), sx: { flexGrow: 1, height: '100%' }, value: searchTerm, onChange: (e) => setSearchTerm(e.target.value) })] }), _jsx(ToggleGroup, { value: status, options: [
                            {
                                label: t('restaurantDrawer.toggleOptions', {
                                    returnObjects: true
                                })[0],
                                value: 'active'
                            },
                            {
                                label: t('restaurantDrawer.toggleOptions', {
                                    returnObjects: true
                                })[1],
                                value: 'deleted'
                            }
                        ], onChange: (value) => setStatus(value) })] })] }));
}
