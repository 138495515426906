import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { NewBranchContext } from '../NewBranchProvider';
import { validationDiv, days } from './utilities';
import timePickerOptions from '../../../constants/time-picker-options';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import AccessTime from '@mui/icons-material/AccessTime';
import { RoundedTextField } from '../../components/styled';
import { OSContentBox, OSInnerContainer, OSOuterContainer, OSTextBox, OSTimelineDay, OSTimelineRangesBox, OSTimelineRow, OSTimelineSwitch, OSTimelineSwitchBox } from './OSCustomComponents';
function PickupTimelines() {
    const [t] = useTranslation('settings');
    const { pickupTimelines, setPickupTimelines, validationErrors, filterValidation } = useContext(NewBranchContext);
    function setDay(day) {
        let workArr = pickupTimelines;
        workArr[day].active = !workArr[day].active;
        setPickupTimelines({
            ...pickupTimelines,
            [day]: workArr[day]
        });
    }
    function setWorkHours(day, type, time) {
        let workArr = pickupTimelines;
        // @ts-ignore
        workArr[day][type] = time;
        setPickupTimelines({
            ...pickupTimelines,
            [day]: workArr[day]
        });
    }
    function getSelectValue(day) {
        if (pickupTimelines[day].from_reservation_START === '00:00:00' &&
            pickupTimelines[day].from_reservation_END === '23:59:59') {
            return {
                opensAt: '23:59:59',
                closesAt: ''
            };
        }
        else {
            return {
                opensAt: pickupTimelines[day].from_reservation_START,
                closesAt: pickupTimelines[day].from_reservation_END
            };
        }
    }
    return (_jsx(_Fragment, { children: _jsx(OSOuterContainer, { children: _jsxs(OSInnerContainer, { children: [_jsx(Typography, { sx: {
                            paddingLeft: '0.5rem',
                            fontSize: '0.875rem',
                            marginBottom: '1rem'
                        }, children: t('timelines.descriptionPickup') }), _jsx(OSContentBox, { children: days.map((day, index) => {
                            return day ? (
                            // row holder
                            _jsxs(OSTimelineRow, { children: [_jsxs(OSTextBox, { children: [_jsxs(OSTimelineSwitchBox, { item: pickupTimelines[day].active, children: [_jsx(OSTimelineDay, { children: _jsx(Typography, { children: t('timelines.days', { returnObjects: true })[index] }) }), _jsx(OSTimelineSwitch, { checked: pickupTimelines[day].active, control: _jsx(Switch, {}), value: pickupTimelines[day].active, id: 'new-restaurant-cash-payment', label: pickupTimelines[day].active
                                                            ? t('timelines.open')
                                                            : t('timelines.closed'), labelPlacement: 'end', onChange: () => {
                                                            setDay(day);
                                                            filterValidation('pickup_timeline_' + day);
                                                            filterValidation('pickup_timeline');
                                                        } })] }), pickupTimelines[day].active && (_jsxs(OSTimelineRangesBox, { children: [_jsx(RoundedTextField, { fullWidth: true, select: true, id: 'time', label: t('timelines.opensAt'), value: getSelectValue(day).opensAt, onChange: (e) => {
                                                            setWorkHours(day, 'from_reservation_START', e.target.value);
                                                            setWorkHours(day, 'from_reservation_END', '');
                                                            if (e.target.value === '23:59:59') {
                                                                setWorkHours(day, 'from_reservation_END', '');
                                                            }
                                                            filterValidation('pickup_timeline_' + day + '_start_time');
                                                            filterValidation('pickup_timeline_' + day);
                                                        }, slotProps: {
                                                            input: {
                                                                endAdornment: (_jsx(InputAdornment, { position: 'start', children: _jsx(AccessTime, {}) }))
                                                            },
                                                            select: {
                                                                IconComponent: () => null
                                                            }
                                                        }, sx: { mr: 1, maxWidth: 200, flexShrink: 0 }, children: timePickerOptions.map((option) => (_jsx(MenuItem, { value: option.value, children: option.label }, option.label))) }), _jsx(RoundedTextField, { disabled: pickupTimelines[day].from_reservation_START ===
                                                            '23:59:59' ||
                                                            pickupTimelines[day].from_reservation_END ===
                                                                '23:59:59', fullWidth: true, select: true, id: 'time', label: t('timelines.closesAt'), value: getSelectValue(day).closesAt, onChange: (e) => {
                                                            setWorkHours(day, 'from_reservation_END', e.target.value);
                                                            filterValidation('pickup_timeline_' + day + '_end_time');
                                                            filterValidation('pickup_timeline_' + day);
                                                        }, slotProps: {
                                                            input: {
                                                                endAdornment: (_jsx(InputAdornment, { position: 'start', children: _jsx(AccessTime, {}) }))
                                                            },
                                                            select: {
                                                                IconComponent: () => null
                                                            }
                                                        }, sx: { maxWidth: 200, flexShrink: 0 }, children: timePickerOptions.map((option) => (_jsx(MenuItem, { value: option.value, children: option.label }, option.label))) })] }))] }), validationDiv(validationErrors['pickup_timeline_' + day]), validationDiv(validationErrors['pickup_timeline_' + day + '_start_time']), validationDiv(validationErrors['pickup_timeline_' + day + '_end_time'])] }, index)) : (_jsx(React.Fragment, {}, uuidv4()));
                        }) }), validationDiv(validationErrors?.pickup_timeline)] }) }) }));
}
export default PickupTimelines;
