import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import PageHeader from '../components/PageHeader';
import ActivityLogsTable from './components/ActivityLogsTable';
export default function ActivityLogsScreen() {
    const { t } = useTranslation('store');
    return (_jsxs(Box, { px: 4, pt: 4, maxWidth: 1600, children: [_jsxs(Box, { sx: { mb: 6, display: 'flex', alignItems: 'start', gap: 2 }, children: [_jsx(IconButton, { size: 'small', onClick: () => {
                            window.location.href = `/store${window.location.search}`;
                        }, children: _jsx(ArrowBackOutlinedIcon, {}) }), _jsx("div", { children: _jsx(PageHeader, { title: t('activity.logs.page.title'), subtitle: t('activity.logs.page.subtitle') }) })] }), _jsx(ActivityLogsTable, {})] }));
}
