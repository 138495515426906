import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PageHeader from '../components/PageHeader';
import Activity from './components/Activity';
import ActivityLogs from './components/ActivityLogs';
import Overview from './components/Overview';
const Store = () => {
    const [t] = useTranslation('store');
    return (_jsxs(Box, { px: { xs: 2, sm: 4 }, py: 4, maxWidth: 1600, children: [_jsx(PageHeader, { title: t('title'), subtitle: t('subtitle') }), _jsx(Activity, {}), _jsx(ActivityLogs, {}), _jsx(Divider, { sx: { my: 4 } }), _jsx(Overview, {})] }));
};
export default Store;
