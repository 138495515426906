import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
const TaxAndOtherFeesTooltip = (props) => {
    const [t] = useTranslation('financials');
    const [open, setOpen] = useState(false);
    return (_jsx(Tooltip, { arrow: true, placement: 'top', title: _jsxs(_Fragment, { children: [_jsxs(Typography, { children: [t('drawers.orderDetails.details.bill.tax'), ": ", props.currency, props.tax.toFixed(2)] }), _jsxs(Typography, { children: [t('drawers.orderDetails.details.bill.platform'), ": ", props.currency, props.platformFee.toFixed(2)] }), props.transactionFee !== 0 && (_jsxs(Typography, { children: [t('drawers.orderDetails.details.bill.transaction'), ":", ' ', props.currency, props.transactionFee.toFixed(2)] }))] }), componentsProps: {
            tooltip: {
                sx: {
                    zIndex: 10,
                    borderRadius: '8px',
                    bgcolor: 'rgba(33,33,33,0.9)'
                }
            }
        }, slotProps: {
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -10]
                        }
                    }
                ]
            }
        }, open: open, onClose: () => setOpen(false), children: _jsx(IconButton, { size: 'small', onClick: (e) => {
                e.stopPropagation();
                setOpen(true);
            }, children: _jsx(InfoIcon, { fontSize: 'small' }) }) }));
};
export default TaxAndOtherFeesTooltip;
