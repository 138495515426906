import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
const drawerWidth = 256;
const drawerWidthClosed = 89;
const networkBarWidth = 70;
export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    boxShadow: "none",
    borderBottom: `1px solid ${theme.palette.divider}`,
    zIndex: 2,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px - ${networkBarWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(!open && {
        width: `calc(100% - ${networkBarWidth}px -  ${drawerWidthClosed}px)`,
    }),
    [theme.breakpoints.down("lg")]: {
        ...(open && {
            width: `calc(100% -  ${drawerWidth}px)`,
        }),
        ...(!open && {
            width: `calc(100% -  ${drawerWidthClosed}px)`,
        }),
    },
    [theme.breakpoints.down("md")]: {
        width: "100%",
    },
}));
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});
const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "0px",
    [theme.breakpoints.up("md")]: {
        width: "88px",
    },
});
export const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
    zIndex: 1,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": {
            ...openedMixin(theme),
            left: `${networkBarWidth}px`,
        },
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": {
            ...closedMixin(theme),
            left: `${networkBarWidth}px`,
        },
    }),
    [theme.breakpoints.down("lg")]: {
        "& .MuiDrawer-paper": {
            left: 0,
        },
    },
    [theme.breakpoints.down("md")]: {
        "& .MuiDrawer-paper": {
            width: "50%",
        },
    },
    [theme.breakpoints.down("sm")]: {
        "& .MuiDrawer-paper": {
            width: "100%",
        },
    },
}));
export const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    paddingBlock: "8px",
    paddingInline: "12px 24px",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
