import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { usePaymentsContext } from '../PaymentsContext';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
const statuses = [
    { label: 'All', value: 'all' },
    { label: 'Pending', value: 'awaiting_client' },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Rejected', value: 'reject' },
    { label: 'Canceled', value: 'cancelled' },
    { label: 'Completed', value: 'done' },
    { label: 'No show', value: 'no_show' },
    { label: 'In delivery', value: 'in_delivery' },
    { label: 'Collected by delivery', value: 'collected_by_delivery' },
    { label: 'Ready', value: 'ready' }
];
const FilterByStatus = () => {
    const [t] = useTranslation('financials');
    const { statusFilter, setStatusFilter } = usePaymentsContext();
    return (_jsxs(FormControl, { sx: (theme) => ({
            width: 300,
            [theme.breakpoints.down('md')]: { mb: 2, width: '100%' }
        }), children: [_jsx(InputLabel, { id: 'filter-status-label', children: t('orders.statusFilter.label') }), _jsx(Select, { size: 'small', labelId: 'filter-status-label', value: statusFilter, onChange: (e) => setStatusFilter(e.target.value), input: _jsx(OutlinedInput, { label: 'Filter by Order Status', sx: { borderRadius: 2 } }), renderValue: (selected) => statuses.find((status) => status.value === selected)?.label ?? 'All', MenuProps: {
                    PaperProps: { sx: { borderRadius: 3 } }
                }, children: statuses.map((status) => (_jsxs(MenuItem, { value: status.value, children: [_jsx(Radio, { disableRipple: true, tabIndex: -1, checked: status.value === statusFilter }), _jsx(ListItemText, { children: t(`orders.statusFilter.options.${status.value}`) })] }, status.value))) })] }));
};
export default FilterByStatus;
