import dayjs from "dayjs";
export const getProgressPercentage = (step) => {
    switch (step) {
        case 0:
            return "15%";
        case 1:
            return "30%";
        case 2:
            return "45%";
        case 3:
            return "60%";
        case 4:
            return "75%";
        case 5:
            return "90%";
        case 6:
            return "90%";
        default:
            return "15%";
    }
};
export const getRemainingDays = (fromDate) => {
    const currentDate = dayjs();
    const deletedAtDate = dayjs(fromDate);
    const thirtyDaysFromDeleted = currentDate.subtract(30, "day");
    const differenceInDays = deletedAtDate.diff(thirtyDaysFromDeleted, "day");
    return differenceInDays;
};
