import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { blue } from '@mui/material/colors';
import { PrimaryButton, SecondaryButton } from './styled';
const CloseDrawerDialog = forwardRef((props, ref) => {
    const { onLeave } = props;
    const [t] = useTranslation('menu');
    const [isOpen, setIsOpen] = useState(false);
    useImperativeHandle(ref, () => ({
        open: () => setIsOpen(true),
        close: () => setIsOpen(false)
    }), []);
    return (_jsxs(Dialog, { open: isOpen, fullWidth: true, maxWidth: 'sm', slotProps: { paper: { sx: { borderRadius: '12px' } } }, onClose: () => setIsOpen(false), children: [_jsx(DialogTitle, { component: 'div', display: 'flex', justifyContent: 'flex-end', children: _jsx(IconButton, { onClick: () => setIsOpen(false), children: _jsx(CloseOutlined, {}) }) }), _jsxs(DialogContent, { sx: {
                    display: 'grid',
                    justifyItems: 'center'
                }, children: [_jsx(Box, { sx: {
                            mb: '1.5rem',
                            width: '48px',
                            height: '48px',
                            borderRadius: '50%',
                            display: 'grid',
                            placeContent: 'center',
                            backgroundColor: blue[50]
                        }, children: _jsx(ErrorOutline, { color: 'info', fontSize: 'large' }) }), _jsx(Typography, { mb: '0.5rem', fontSize: '20px', fontWeight: 600, children: t('dialogs.closeConfirmation.title') }), _jsx(Typography, { color: 'text.secondary', maxWidth: '476px', textAlign: 'center', mb: 2, children: t('dialogs.closeConfirmation.description') })] }), _jsxs(DialogActions, { sx: { px: 4, pb: 4 }, children: [_jsx(SecondaryButton, { variant: 'contained', color: 'inherit', onClick: () => setIsOpen(false), children: t('dialogs.closeConfirmation.secondaryBtnLabel') }), _jsx(PrimaryButton, { variant: 'contained', onClick: () => {
                            onLeave();
                            setIsOpen(false);
                        }, children: t('dialogs.closeConfirmation.primaryBtnLabel') })] })] }));
});
export default CloseDrawerDialog;
