import { jsx as _jsx } from "react/jsx-runtime";
import { ToggleButtonGroup, ToggleButton, Typography, styled } from '@mui/material';
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    backgroundColor: 'grey.300',
    borderRadius: 32,
    // boxShadow: theme.shadows[2],
    [theme.breakpoints.down('md')]: {
    // width: '100%'
    },
    '& .MuiToggleButtonGroup-grouped': {
        margin: 2,
        border: 0,
        paddingBlock: 4,
        flexGrow: 1,
        '&.Mui-disabled': {
            border: 0
        },
        '&:not(:first-of-type)': {
            borderRadius: 32
        },
        '&:first-of-type': {
            borderRadius: 32
        },
        '&.Mui-selected': {
            color: '#fff',
            backgroundColor: theme.palette.primary.main
        },
        '&.Mui-selected:hover': {
            backgroundColor: theme.palette.primary.light
        }
    }
}));
export default function ToggleGroup(props) {
    const { value, options, onChange, fullWidth } = props;
    return (_jsx(StyledToggleButtonGroup, { exclusive: true, fullWidth: fullWidth, value: value, onChange: (_, value) => value !== null && onChange(value), children: options.map(button => (_jsx(ToggleButton, { value: button.value, sx: { width: '120px' }, children: _jsx(Typography, { fontSize: 14, fontWeight: 500, textTransform: 'none', children: button.label }) }, button.value))) }));
}
