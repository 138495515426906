import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { request } from '../../../lib/axios';
import { useLocalStorage } from '../../../hooks/use-local-storage';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
const options = {
    en_US: { primaryText: '🇺🇸 English (US)', icon: '🇺🇸' },
    sq_AL: { primaryText: '🇦🇱 Shqip (AL)', icon: '🇦🇱' }
};
export default function LanguageSelect(props) {
    const [_, i18n] = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [user, setStoredUser] = useLocalStorage('user', null);
    const [locale, setLocale] = useLocalStorage('locale', 'en_US');
    const open = Boolean(anchorEl);
    const handleMenuItemClick = async (locale) => {
        try {
            setAnchorEl(null);
            setLocale(locale);
            i18n.changeLanguage(locale);
            if (props.skipUserUpdate)
                return;
            const res = await request('/web/user/locale', 'PUT', { locale });
            setStoredUser(user ? { ...user, locale } : null);
            window.globalToast({
                type: 'success',
                title: 'Success',
                description: res.message
            });
        }
        catch (error) {
            console.log(error);
        }
    };
    return (_jsxs("div", { children: [_jsx(IconButton, { id: 'language-button', "aria-controls": open ? 'language-menu' : undefined, "aria-haspopup": 'true', "aria-expanded": open ? 'true' : undefined, onClick: (e) => setAnchorEl(e.currentTarget), sx: {
                    color: 'text.primary',
                    fontSize: '18px'
                }, children: options[locale].icon }), _jsx(Menu, { id: 'language-menu', open: open, anchorEl: anchorEl, onClose: () => setAnchorEl(null), MenuListProps: {
                    'aria-labelledby': 'language-button'
                }, slotProps: {
                    paper: {
                        sx: { borderRadius: 3 }
                    }
                }, children: Object.keys(options).map((l) => (_jsx(MenuItem, { disabled: l === locale, selected: l === locale, onClick: () => handleMenuItemClick(l), children: options[l].primaryText }, l))) })] }));
}
