import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsContext } from '../SettingsProvider';
import { TextField } from '@mui/material';
import { OSOuterContainer, OSContentBox, OSInnerContainer, OSTextBox, OSText } from './OSCustomComponents';
import { validationMessages } from './utilities';
function GeneralSettings() {
    const [t] = useTranslation('settings');
    const { generalSettings, setGeneralSettings, validationErrors, filterValidation, isLoading, setProfileImage } = useContext(SettingsContext);
    useEffect(() => {
        if (generalSettings.restaurant_cover_image) {
            toBase64(generalSettings.restaurant_cover_image).then((photoBase64) => {
                setGeneralSettings({
                    ...generalSettings,
                    restaurant_cover_image: generalSettings.restaurant_cover_image
                });
                changeImagePreview(photoBase64, 'new-restaurant-cover-image-preview');
            });
        }
    }, []);
    function toBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            if (file) {
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            }
        });
    }
    function changeImagePreview(base64Img, imgId) {
        document.getElementById(imgId)?.setAttribute('src', base64Img);
        setProfileImage(base64Img);
    }
    return generalSettings && !isLoading ? (_jsx(_Fragment, { children: _jsx(OSOuterContainer, { children: _jsx(OSInnerContainer, { children: _jsxs(OSContentBox, { children: [_jsx("input", { type: 'hidden', name: 'network_id', value: '{{ $networkId }}' }), _jsx(OSTextBox, { children: _jsx(OSText, { required: true, error: !!validationErrors?.restaurant_name?.length, id: 'outlined-required', label: t('general.name'), fullWidth: true, defaultValue: generalSettings.restaurant_name, helperText: validationMessages(validationErrors?.restaurant_name), onChange: (e) => {
                                    setGeneralSettings({
                                        ...generalSettings,
                                        restaurant_name: e.target.value
                                    });
                                    filterValidation('restaurant_name');
                                } }) }), _jsx(OSTextBox, { children: _jsx(OSText, { error: !!validationErrors?.restaurant_phone?.length, id: 'outlined-required', label: t('general.phone'), fullWidth: true, defaultValue: generalSettings.restaurant_phone, helperText: validationMessages(validationErrors?.restaurant_phone), onChange: (e) => {
                                    setGeneralSettings({
                                        ...generalSettings,
                                        restaurant_phone: e.target.value
                                    });
                                    filterValidation('restaurant_phone');
                                } }) }), _jsx(OSTextBox, { children: _jsx(TextField, { error: !!validationErrors?.restaurant_website?.length, id: 'outlined-required', label: t('general.url'), fullWidth: true, InputProps: { className: 'rounded-lg' }, defaultValue: generalSettings.restaurant_website, helperText: validationMessages(validationErrors?.restaurant_website), onChange: (e) => {
                                    setGeneralSettings({
                                        ...generalSettings,
                                        restaurant_website: e.target.value
                                    });
                                    filterValidation('restaurant_website');
                                } }) })] }) }) }) })) : (_jsx(_Fragment, {}));
}
export default GeneralSettings;
