import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { arrayMove } from '@dnd-kit/sortable';
import { useTranslation } from 'react-i18next';
import { MenuContext } from '../context';
import { ActionType } from '../context/types';
import { fetchApi } from '../../../config/Api';
import { getRestaurantId, getNetworkId, getCurrency, getErrorMessage, generateImageUrl, formatPrice } from '../../UtilityFunctions';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import AttachMoneyOutlined from '@mui/icons-material/AttachMoneyOutlined';
import TimerOutlined from '@mui/icons-material/TimerOutlined';
import CheckOutlined from '@mui/icons-material/CheckOutlined';
import AddOutlined from '@mui/icons-material/AddOutlined';
import Visibility from '@mui/icons-material/Visibility';
import Euro from '@mui/icons-material/Euro';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import DragNDrop from '../../components/DragNDrop';
import CustomTooltip from '../../components/CustomTooltip';
import AddModifierToItem from './AddModifierToItem';
import ModifierList from './ModifierList';
import ModifierTree from './ModifierTree';
import CloseDrawerDialog from './CloseDrawerDialog';
import { RoundedTextfield, PrimaryButton, SecondaryButton, RoundedButton } from './styled';
const Schema = z.object({
    status: z.string(),
    name: z.string().min(1),
    description: z.string(),
    category_id: z.string().min(1),
    price: z.string().min(1),
    show_in_ads: z.boolean(),
    duration: z.string().min(1),
    item_image: z.array(z.instanceof(File)).or(z.string().optional()),
    modifiers: z.array(z.object({ id: z.number().optional(), name: z.string().optional() })),
    contain_alcohol: z.boolean()
});
const defaultValues = {
    status: '1',
    name: '',
    description: '',
    price: '',
    duration: '',
    show_in_ads: false,
    category_id: '',
    modifiers: [],
    contain_alcohol: false
};
export default function ItemDrawer() {
    const [t] = useTranslation('menu');
    const [isSaving, setIsSaving] = useState(false);
    const [isAssignModifierOpen, setIsAssignModifierOpen] = useState(false);
    const [isModifierTreeOpen, setIsModifierTreeOpen] = useState(false);
    const { dispatch, isItemDrawerOpen, toggleDrawer, categories, draftItem, getItems, modifiers, getCategories, getModifiers } = useContext(MenuContext);
    const currency = getCurrency();
    const { watch, reset, control, register, setValue, getValues, handleSubmit, formState: { errors, isDirty } } = useForm({
        resolver: zodResolver(Schema),
        defaultValues
    });
    const closeDialogRef = useRef();
    const handleClose = () => {
        if (isDirty) {
            closeDialogRef.current?.open();
        }
        else {
            toggleDrawer('isItemDrawerOpen');
            dispatch({ type: ActionType.SET_DRAFT_ITEM, payload: null });
            reset(defaultValues);
        }
    };
    const handleLeave = () => {
        toggleDrawer('isItemDrawerOpen');
        dispatch({ type: ActionType.SET_DRAFT_ITEM, payload: null });
        reset(defaultValues);
    };
    const networkId = getNetworkId();
    const restaurantId = getRestaurantId();
    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('status', data.status);
        formData.append('name', data.name);
        formData.append('type', 'food');
        formData.append('description', data.description);
        formData.append('price', data.price);
        formData.append('duration', data.duration);
        formData.append('show_in_ads', data.show_in_ads ? '1' : '0');
        formData.append('category_id', data.category_id);
        formData.append('contain_alcohol', data.contain_alcohol.toString());
        if (data.modifiers.length) {
            data.modifiers.forEach((modifier, i) => {
                if (modifier.id) {
                    formData.append(`modifier_group_ids[${i}]`, modifier.id.toString());
                }
            });
        }
        if (data.item_image) {
            formData.append('item_image', data.item_image[0]);
        }
        else {
            formData.append('item_image', 'null');
        }
        if (draftItem) {
            formData.append('id', draftItem.id.toString());
        }
        setIsSaving(true);
        fetchApi({
            method: 'POST',
            url: `/menu/item?network=${networkId}&restaurant=${restaurantId}`,
            data: formData
        })
            .then(() => {
            window.globalToast({
                title: 'Success',
                type: 'success',
                description: 'Item updated successfully!'
            }),
                reset(defaultValues);
            dispatch({ type: ActionType.SET_DRAFT_ITEM, payload: null });
            setIsSaving(false);
            toggleDrawer('isItemDrawerOpen');
            getItems();
            getCategories();
            getModifiers();
        })
            .catch((error) => {
            setIsSaving(false);
            window.globalToast({
                type: 'error',
                title: 'Error',
                description: getErrorMessage(error.response)
            });
        });
    };
    useEffect(() => {
        if (draftItem) {
            reset({
                status: draftItem.restaurants.some((res) => res.id === Number(restaurantId))
                    ? '1'
                    : '0',
                name: draftItem.name,
                contain_alcohol: draftItem.contain_alcohol,
                description: draftItem.description ?? '',
                category_id: draftItem.category_id.toString(),
                show_in_ads: draftItem.show_in_ads ? true : false,
                price: formatPrice(draftItem.price.toString()),
                duration: draftItem.duration.toString(),
                item_image: draftItem.image_path ?? undefined,
                modifiers: draftItem.modifier_groups.map((item) => ({
                    id: item.id,
                    name: item.name
                }))
            });
        }
    }, [draftItem]);
    const itemImage = watch('item_image');
    const imgSrc = typeof itemImage === 'string'
        ? generateImageUrl(itemImage)
        : itemImage
            ? URL.createObjectURL(itemImage[0])
            : '';
    const handleAssignModifier = (modifiers) => {
        setValue('modifiers', modifiers.map((modifier) => ({ id: modifier.id, name: modifier.name })), { shouldDirty: true, shouldValidate: true });
    };
    const handleReorderModifiers = (event) => {
        const { active, over } = event;
        if (active.id !== over?.id) {
            const modifiers = getValues('modifiers');
            const oldIndex = modifiers.findIndex((item) => item.id === active.id);
            const newIndex = modifiers.findIndex((item) => item.id === over?.id);
            const sortedModifiers = arrayMove(modifiers, oldIndex, newIndex);
            setValue('modifiers', sortedModifiers);
            if (draftItem) {
                dispatch({
                    type: ActionType.UPDATE_ITEM,
                    payload: {
                        ...draftItem,
                        // @ts-ignore
                        modifier_groups: sortedModifiers
                    }
                });
            }
            fetchApi({
                method: 'POST',
                url: `/menu/items/${draftItem?.id}/modifier-groups-reorder`,
                data: {
                    modifier_groups: sortedModifiers.map((category) => category.id)
                }
            }).catch((error) => {
                window.globalToast({
                    type: 'error',
                    title: 'Error',
                    description: getErrorMessage(error.response)
                });
            });
        }
    };
    const currencyIcon = currency === '$' ? (_jsx(AttachMoneyOutlined, { color: 'action' })) : currency === '€' ? (_jsx(Euro, { color: 'action' })) : ('');
    return (_jsxs(_Fragment, { children: [_jsx(CloseDrawerDialog, { ref: closeDialogRef, onLeave: handleLeave }), _jsx(ModifierTree, { data: getValues('modifiers').map((modifier) => modifiers.find((item) => item.id === modifier.id)), isOpen: isModifierTreeOpen, setIsOpen: setIsModifierTreeOpen }), _jsx(AddModifierToItem, { defaultSelected: watch('modifiers') ?? [], isOpen: isAssignModifierOpen, setIsOpen: setIsAssignModifierOpen, onSave: handleAssignModifier }), _jsxs(Drawer, { open: isItemDrawerOpen, onClose: handleClose, anchor: 'right', variant: 'temporary', PaperProps: {
                    sx: {
                        width: '100%',
                        maxWidth: '512px'
                    }
                }, slotProps: {
                    backdrop: {
                        sx: {
                            backgroundColor: 'transparent'
                        }
                    }
                }, sx: { zIndex: 1300 }, children: [_jsxs(Box, { py: 3, px: 4, borderBottom: 1, borderColor: 'divider', sx: {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }, children: [_jsxs(Box, { children: [_jsx(Typography, { component: 'span', color: 'text.secondary', children: t('drawers.item.title') }), _jsx(Typography, { component: 'h1', fontSize: 24, fontWeight: 600, children: draftItem ? draftItem.name : t('drawers.item.subtitle') })] }), _jsx(IconButton, { onClick: handleClose, children: _jsx(CloseOutlined, {}) })] }), _jsxs("form", { onSubmit: handleSubmit(onSubmit), style: {
                            display: 'grid',
                            overflowY: 'auto',
                            gridTemplateRows: '1fr auto',
                            flexGrow: 1
                        }, children: [_jsxs(Box, { py: 3, overflow: 'auto', display: 'flex', flexDirection: 'column', gap: 3, children: [_jsxs(Box, { px: 4, display: 'flex', flexDirection: 'column', gap: 3, children: [_jsxs(Box, { children: [_jsx(Typography, { fontWeight: 600, children: t('drawers.menu.title') }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', children: t('drawers.menu.description') })] }), _jsx(Controller, { name: 'status', control: control, render: ({ field: { value, onChange } }) => (_jsxs(RoundedTextfield, { select: true, fullWidth: true, label: 'Status', value: value, onChange: (e) => onChange(e.target.value), children: [_jsxs(MenuItem, { value: '1', sx: (theme) => ({
                                                                '& .MuiSvgIcon-root': {
                                                                    marginRight: theme.spacing(1.5)
                                                                }
                                                            }), children: [_jsx(CheckOutlined, {}), ' ', t('drawers.item.form.status.options.1')] }), _jsxs(MenuItem, { value: '0', sx: (theme) => ({
                                                                '& .MuiSvgIcon-root': {
                                                                    marginRight: theme.spacing(1.5)
                                                                }
                                                            }), children: [_jsx(CloseOutlined, {}), ' ', t('drawers.item.form.status.options.2')] })] })) }), _jsx(Controller, { name: 'contain_alcohol', control: control, render: ({ field: { value, onChange } }) => (_jsx(FormControlLabel, { control: _jsx(Switch, { checked: value, onChange: (e) => onChange(e.target.checked) }), label: _jsxs(Box, { gap: 1, display: 'flex', alignItems: 'center', children: [t('drawers.item.form.alcohol.label'), _jsx(CustomTooltip, { tooltipProps: {
                                                                    placement: 'top',
                                                                    title: t('drawers.item.form.alcohol.tooltip')
                                                                }, children: _jsx(InfoOutlined, { fontSize: 'small', color: 'error' }) })] }), labelPlacement: 'start', sx: {
                                                        m: 0,
                                                        mb: 3,
                                                        px: 1.5,
                                                        border: 1,
                                                        width: '100%',
                                                        borderColor: 'grey.400',
                                                        borderRadius: 3,
                                                        paddingY: '7px',
                                                        justifyContent: 'space-between'
                                                    } })) }), _jsx(DragNDrop, { imgSrc: imgSrc, error: errors.item_image?.message, onChange: (files) => setValue('item_image', files, { shouldDirty: true }), onDelete: () => {
                                                    setValue('item_image', undefined, { shouldDirty: true });
                                                } }), _jsx(RoundedTextfield, { fullWidth: true, label: t('drawers.item.form.name.label'), error: !!errors.name, ...register('name') }), _jsxs(Box, { display: 'flex', gap: 3, children: [_jsx(RoundedTextfield, { fullWidth: true, inputMode: 'decimal', label: t('drawers.item.form.price.label'), placeholder: '0.00', slotProps: {
                                                            input: { startAdornment: currencyIcon }
                                                        }, error: !!errors.price, ...register('price', {
                                                            onBlur(e) {
                                                                setValue('price', formatPrice(e.target.value), {
                                                                    shouldValidate: true
                                                                });
                                                            },
                                                            onChange(e) {
                                                                let value = e.target.value;
                                                                value = value.replace(/[^0-9.,]/g, '');
                                                                const parts = value.split(/[,\.]/);
                                                                if (parts.length > 2) {
                                                                    value = parts[0] + '.' + parts.slice(1).join('');
                                                                }
                                                                setValue('price', value, {
                                                                    shouldDirty: true
                                                                });
                                                            }
                                                        }) }), _jsx(RoundedTextfield, { fullWidth: true, inputMode: 'decimal', label: t('drawers.item.form.perparationTime.label'), placeholder: t('drawers.item.form.perparationTime.placeholder'), slotProps: {
                                                            input: {
                                                                startAdornment: _jsx(TimerOutlined, { color: 'disabled' }),
                                                                endAdornment: 'mins'
                                                            }
                                                        }, error: !!errors.duration, ...register('duration', {
                                                            onChange(e) {
                                                                const value = e.target.value.replace(/[^0-9]/g, '');
                                                                setValue('duration', value, {
                                                                    shouldDirty: true
                                                                });
                                                            }
                                                        }) })] }), _jsx(Controller, { name: 'show_in_ads', control: control, render: ({ field: { value, onChange } }) => (_jsx(FormControlLabel, { control: _jsx(Switch, { id: 'suggest', checked: value, onChange: (e) => onChange(e.target.checked) }), label: t('drawers.item.form.suggestItem.label'), labelPlacement: 'start', sx: {
                                                        px: 1.5,
                                                        margin: 0,
                                                        border: 1,
                                                        borderColor: 'grey.400',
                                                        borderRadius: 3,
                                                        paddingY: '7px',
                                                        justifyContent: 'space-between'
                                                    } })) }), _jsx(RoundedTextfield, { fullWidth: true, multiline: true, rows: 3, label: t('drawers.item.form.description.label'), placeholder: t('drawers.item.form.description.placeholder'), slotProps: { inputLabel: { shrink: true } }, error: !!errors.description, ...register('description') }), _jsx(Controller, { name: 'category_id', control: control, render: ({ field: { value, onChange } }) => (_jsx(RoundedTextfield, { select: true, fullWidth: true, label: t('drawers.item.form.category.label'), error: !!errors.category_id, value: value, onChange: (e) => onChange(e.target.value), children: categories.map((item) => (_jsx(MenuItem, { value: item.id.toString(), sx: (theme) => ({
                                                            '& .MuiSvgIcon-root': {
                                                                marginRight: theme.spacing(1.5)
                                                            }
                                                        }), children: item.name }, item.id))) })) })] }), _jsx(Divider, { sx: {
                                            height: '8px',
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                            borderTop: 1,
                                            borderColor: 'divider'
                                        } }), _jsxs(Box, { px: 4, children: [_jsxs(Typography, { fontWeight: 600, fontSize: 22, children: [t('drawers.item.form.modifiers.title'), ' ', _jsx(IconButton, { disabled: !watch('modifiers').length, sx: { ml: 1 }, onClick: () => setIsModifierTreeOpen(true), children: _jsx(Visibility, {}) })] }), _jsx(Typography, { mb: 3, fontSize: 14, color: 'text.secondary', children: t('drawers.item.form.modifiers.description') }), !!watch('modifiers').length && (_jsx(ModifierList, { onReorder: handleReorderModifiers, canReorder: !!draftItem, modifiers: watch('modifiers'), handleDelete: (id) => {
                                                    const modifiers = getValues('modifiers');
                                                    setValue('modifiers', modifiers.filter((modifier) => modifier.id !== id), { shouldValidate: true, shouldDirty: true });
                                                } })), _jsx(RoundedButton, { variant: 'contained', startIcon: _jsx(AddOutlined, {}), onClick: () => setIsAssignModifierOpen(true), children: t('drawers.item.form.modifiers.addModifierBtnLabel') })] })] }), _jsxs(Box, { py: 2, px: 4, gap: 2, display: 'flex', justifyContent: 'flex-end', boxShadow: '0px -4px 10px 0px rgba(0, 0, 0, 0.07)', children: [_jsx(SecondaryButton, { type: 'button', variant: 'outlined', onClick: handleClose, children: t('drawers.item.secondaryBtnLabel') }), _jsx(PrimaryButton, { type: 'submit', variant: 'contained', disabled: isSaving || !isDirty, children: isSaving ? (_jsx(CircularProgress, { size: '24px' })) : (t('drawers.item.primaryBtnLabel')) })] })] })] })] }));
}
