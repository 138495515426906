import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import UploadFile from '@mui/icons-material/UploadFile';
import EditOutlined from '@mui/icons-material/EditOutlined';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
export default function DragNDrop(props) {
    const { imgSrc, onChange, error, onDelete, disableUpdate, fullImage } = props;
    const { t } = useTranslation('network');
    const onDrop = useCallback((acceptedFiles) => {
        if (!!acceptedFiles.length) {
            onChange(acceptedFiles);
        }
    }, []);
    const { open, getRootProps, getInputProps } = useDropzone({
        onDrop,
        noClick: true,
        maxSize: 3000000,
        maxFiles: 1,
        accept: {
            'image/png': ['.png'],
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg']
        }
    });
    return (_jsx(_Fragment, { children: imgSrc && !fullImage ? (_jsxs(Box, { sx: {
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                py: 2,
                border: 1,
                borderStyle: 'solid',
                borderRadius: 1,
                borderColor: 'divider'
            }, children: [_jsxs(Box, { display: 'flex', gap: 1, position: 'absolute', top: 8, right: 8, children: [!disableUpdate && (_jsx(IconButton, { size: 'small', onClick: open, children: _jsx(EditOutlined, {}) })), onDelete && (_jsx(IconButton, { size: 'small', onClick: onDelete, children: _jsx(DeleteOutline, { color: 'error' }) }))] }), _jsx("img", { width: 200, height: 150, src: imgSrc, alt: 'image' })] })) : imgSrc && fullImage ? (_jsx(Box, { component: 'img', width: '100%', height: 'auto', maxHeight: 400, borderRadius: 3, sx: { objectFit: 'cover' }, src: imgSrc, alt: 'image' })) : (_jsxs(Stack, { ...getRootProps(), py: 3, spacing: 1, alignItems: 'center', sx: {
                border: 1,
                borderStyle: 'dashed',
                borderRadius: 1,
                borderColor: error ? 'red' : 'divider'
            }, children: [_jsx("input", { ...getInputProps() }), _jsx(UploadFile, { color: 'info' }), _jsxs(Typography, { children: [_jsx(Typography, { component: 'span', color: 'primary.main', sx: { textDecoration: 'underline', cursor: 'pointer' }, onClick: open, children: t('drawers.networkDrawer.imageField.btnLabel') }), ' ', t('drawers.networkDrawer.imageField.btnLabelExtension')] }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', children: t('drawers.networkDrawer.imageField.description') }), error && (_jsx(Typography, { fontSize: 14, color: 'error', children: error }))] })) }));
}
