import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useContext, useEffect } from "react";
import { SelectNetworkContext } from "../SelectNetworkProvider";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme, useMediaQuery } from "@mui/material";
import RestaurantDrawerHeader from "../../components/RestaurantDrawerHeader";
import RestaurantList from "../../components/RestaurantList";
import CreateBranchButton from "../../components/CreateBranchButton";
const drawerWidth = 448;
export default function RestaurantDrawer() {
    const [searchTerm, setSearchTerm] = useState("");
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down("lg"));
    const { selectedNetwork, setSelectedNetwork, getRestaurants, isLoadingRestaurants, restaurants, status, setStatus, user, userCanCreateBranch, } = useContext(SelectNetworkContext);
    useEffect(() => {
        if (selectedNetwork && selectedNetwork.id) {
            getRestaurants({ networkId: selectedNetwork.id });
        }
    }, [selectedNetwork]);
    const restaurantList = status === "active" ? restaurants.active : restaurants.deleted;
    return (_jsxs(Drawer, { sx: (theme) => ({
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxShadow: "0px 4px 10px 0px #0000004D",
            },
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                "& .MuiDrawer-paper": {
                    width: "100%",
                },
            },
        }), variant: isLgDown ? "temporary" : "persistent", anchor: "right", open: Boolean(selectedNetwork), children: [_jsx(Toolbar, {}), selectedNetwork && (_jsxs(_Fragment, { children: [user && (_jsx(RestaurantDrawerHeader, { onClose: () => setSelectedNetwork(null), selectedNetwork: selectedNetwork, status: status, setStatus: setStatus, user: user, searchTerm: searchTerm, setSearchTerm: setSearchTerm })), isLoadingRestaurants ? (_jsx(Box, { display: "flex", justifyContent: "center", pt: 8, children: _jsx(CircularProgress, {}) })) : (_jsx(RestaurantList, { selectedNetwork: selectedNetwork, status: status, setStatus: setStatus, refetchRestaurants: () => getRestaurants({ networkId: Number(selectedNetwork.id) }), restaurants: restaurantList.filter((restaurant) => restaurant.name.toLocaleLowerCase().includes(searchTerm)) })), status !== "deleted" && userCanCreateBranch && user && (_jsx(Box, { sx: {
                            p: 2,
                            mt: "auto",
                            position: "sticky",
                            bottom: 0,
                            bgcolor: "white",
                            boxShadow: "0px -4px 10px 0px #00000026",
                        }, children: _jsx(CreateBranchButton, { user: user, selectedNetwork: selectedNetwork }) }))] }))] }));
}
