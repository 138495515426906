import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
export default function CustomTooltip(props) {
    const { children, tooltipProps } = props;
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);
    return (_jsx(Tooltip, { arrow: true, disableFocusListener: true, disableTouchListener: true, open: open, placement: "top", onClose: handleClose, componentsProps: {
            popper: {
                sx: { zIndex: "2000 !important" },
            },
            arrow: {
                sx: {
                    "&::before": {
                        bgcolor: "rgba(33,33,33,0.9)",
                    },
                },
            },
            tooltip: {
                sx: {
                    borderRadius: "8px",
                    bgcolor: "rgba(33,33,33,0.9)",
                },
            },
        }, slotProps: {
            popper: {
                sx: {
                    zIndex: 2000,
                },
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            },
        }, ...tooltipProps, children: _jsx("button", { onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                handleOpen();
            }, onMouseOver: handleOpen, onMouseLeave: handleOpen, children: children }) }));
}
