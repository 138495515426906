import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { useGalleryContext } from '../GalleryProvider';
import { fetchApi } from '../../../config/Api';
import { getErrorMessage, getRestaurantId, generateImageUrl } from '../../UtilityFunctions';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SideDrawer from '../../components/SideDrawer';
import CloseDrawerDialog from '../../menu/components/CloseDrawerDialog';
import DragNDrop from '../../components/DragNDrop';
import CustomTooltip from '../../components/CustomTooltip';
import { PrimaryButton, SecondaryButton, RoundedTextField } from '../../components/styled';
const Schema = z.object({
    image: z.array(z.instanceof(File)).or(z.string().optional()),
    description: z.string(),
    makeCover: z.boolean()
});
const defaultValues = {
    description: '',
    makeCover: false
};
export default function GalleryDrawer() {
    const [t] = useTranslation('gallery');
    const closeDialogRef = useRef();
    const [isSaving, setIsSaving] = useState(false);
    const { isDrawerOpen, setIsDrawerOpen, galleryId, getImages, selectedImage, setSelectedImage, deleteImage } = useGalleryContext();
    const { watch, reset, register, setValue, handleSubmit, formState: { errors, isDirty } } = useForm({
        resolver: zodResolver(Schema),
        defaultValues
    });
    const onSubmit = (data) => {
        const restaurantId = getRestaurantId();
        const formData = new FormData();
        if (galleryId === null || restaurantId === null) {
            window.globalToast({
                type: 'error',
                title: 'Error',
                description: 'No restaurant id or gallery id provided'
            });
            return;
        }
        formData.append('description', data.description);
        formData.append('galleryId', galleryId.toString());
        formData.append('restaurant', restaurantId.toString());
        if (data.makeCover)
            formData.append('makeCover', data.makeCover.toString());
        if (data.image && typeof image !== 'string')
            formData.append('image', data.image[0]);
        if (selectedImage) {
            formData.append('_method', 'PUT');
            formData.append('image_id', selectedImage.id.toString());
        }
        setIsSaving(true);
        fetchApi({
            method: 'POST',
            url: selectedImage ? '/gallery/editImage' : '/gallery/addImage',
            data: formData
        })
            .then(() => {
            window.globalToast({
                title: 'Success',
                type: 'success',
                description: selectedImage
                    ? 'Photo updated successfully'
                    : 'New photo added successfully!'
            });
            handleLeave();
            getImages();
        })
            .catch((err) => {
            window.globalToast({
                type: 'error',
                title: 'Error',
                description: getErrorMessage(err.response)
            });
        })
            .finally(() => {
            setIsSaving(false);
        });
    };
    const handleDelete = (id) => {
        if (id) {
            deleteImage(id);
            setIsDrawerOpen(false);
            reset(defaultValues);
            setSelectedImage(null);
        }
    };
    const handleClose = () => {
        if (isDirty) {
            closeDialogRef.current?.open();
        }
        else {
            setIsDrawerOpen(false);
            reset(defaultValues);
            setSelectedImage(null);
        }
    };
    const handleLeave = () => {
        setIsDrawerOpen(false);
        reset(defaultValues);
        setSelectedImage(null);
    };
    useEffect(() => {
        if (selectedImage) {
            reset({
                image: selectedImage.path,
                makeCover: selectedImage.is_cover,
                description: selectedImage.description ?? ''
            });
        }
    }, [selectedImage]);
    const image = watch('image');
    const imgSrc = typeof image === 'string'
        ? generateImageUrl(image)
        : image
            ? URL.createObjectURL(image[0])
            : '';
    return (_jsxs(_Fragment, { children: [_jsx(CloseDrawerDialog, { ref: closeDialogRef, onLeave: handleLeave }), _jsx(SideDrawer, { isOpen: isDrawerOpen, onClose: handleClose, primaryText: t('drawer.title'), secondaryText: t('drawer.subtitle'), children: _jsxs(Stack, { component: 'form', height: '100%', onSubmit: handleSubmit(onSubmit), children: [_jsxs(Box, { p: 4, display: 'grid', gap: 3, children: [_jsx(DragNDrop, { fullImage: true, disableUpdate: true, imgSrc: imgSrc, error: errors.image?.message, onChange: (files) => {
                                        setValue('image', files, { shouldDirty: true });
                                    } }), _jsxs(Box, { sx: (theme) => ({
                                        px: 2,
                                        py: 0.5,
                                        border: 1,
                                        borderRadius: 3,
                                        borderColor: 'divider',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        [theme.breakpoints.down('md')]: {
                                            mt: 3,
                                            width: '100%'
                                        }
                                    }), children: [_jsx(FormControlLabel, { control: _jsx(Switch, { checked: watch('makeCover'), ...register('makeCover') }), label: t('drawer.switch.label') }), _jsx(CustomTooltip, { tooltipProps: {
                                                title: t('drawer.switch.tooltip'),
                                                placement: 'bottom'
                                            }, children: _jsx(InfoOutlinedIcon, { color: 'action' }) })] }), _jsx(RoundedTextField, { fullWidth: true, multiline: true, rows: 2, label: t('drawer.caption.label'), placeholder: t('drawer.caption.placeholder'), helperText: t('drawer.caption.helperText'), slotProps: { inputLabel: { shrink: true } }, ...register('description') })] }), _jsxs(Box, { sx: {
                                p: 2,
                                mt: 'auto',
                                position: 'sticky',
                                bottom: 0,
                                bgcolor: 'white',
                                boxShadow: '0px -4px 10px 0px #00000026',
                                display: 'flex',
                                gap: 2,
                                zIndex: 1
                            }, children: [selectedImage && (_jsx(Button, { type: 'button', variant: 'outlined', color: 'error', sx: {
                                        textTransform: 'none',
                                        boxShadow: 'none',
                                        fontSize: 16,
                                        borderRadius: '12px'
                                    }, onClick: () => handleDelete(selectedImage?.id), children: t('drawer.deleteBtnLabel') })), _jsx(SecondaryButton, { type: 'button', variant: 'outlined', sx: { ml: 'auto' }, onClick: handleClose, children: t('drawer.secondaryBtnLabel') }), _jsx(PrimaryButton, { type: 'submit', variant: 'contained', disabled: isSaving || !isDirty, children: isSaving ? (_jsx(CircularProgress, { size: '24px' })) : (t('drawer.primaryBtnLabel')) })] })] }) })] }));
}
