import axios from 'axios';
const storedLocale = localStorage.getItem('locale');
const locale = storedLocale ? JSON.parse(storedLocale) : 'en_US';
export const axiosInstance = axios.create({
    baseURL: window.location.origin,
    headers: {
        Accept: 'application/json',
        'Content-Language': locale
    }
});
export const request = async (url, method = 'GET', data, config) => {
    try {
        const response = await axiosInstance.request({
            url,
            method,
            data,
            ...config
        });
        return response.data;
    }
    catch (error) {
        throw error;
    }
};
