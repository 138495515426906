import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState, useEffect, useCallback, useContext, } from "react";
import { fetchApi } from "../../config/Api";
import Storage from "../../config/Storage";
const PROFILE_CONTEXT_INITIAL_VALUES = {
    userInfo: null,
    getUserInfo: () => undefined,
    isLoading: false,
    setIsLoading: () => undefined,
    restaurant: null,
    setRestaurant: () => undefined,
};
// @ts-ignore
export const ProfileContext = createContext({
    ...PROFILE_CONTEXT_INITIAL_VALUES,
});
export const useProfileContext = () => {
    const context = useContext(ProfileContext);
    if (!context) {
        throw new Error("useProfileContext must be used within a ProfileProvider");
    }
    return context;
};
function ProfileProvider({ children }) {
    const [userInfo, setUserInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [restaurant, setRestaurant] = useState(null);
    const getUserInfo = useCallback(() => {
        return fetchApi({
            method: "GET",
            url: `/user?_token=${
            // @ts-ignore
            document.head.querySelector('meta[name="_token"]').content}`,
        })
            .then((r) => {
            const { data } = r;
            setUserInfo(data.user);
        })
            .catch((e) => {
            window.globalToast({
                type: "error",
                title: "Error",
                description: e.message,
            });
        });
    }, []);
    function getNetworkId() {
        var url = new URL(window.location.href);
        let urlNetworkId = url.searchParams.get("network");
        if (urlNetworkId) {
            return urlNetworkId;
        }
        if (Storage.getItem("networkId")) {
            return Storage.getItem("networkId");
        }
        if (Storage.getItem("network")) {
            return Storage.getItem("network");
        }
    }
    function getRestaurantId() {
        var url = new URL(window.location.href);
        let urlNetworkId = url.searchParams.get("restaurant");
        if (urlNetworkId) {
            return urlNetworkId;
        }
        if (Storage.getItem("restaurant")) {
            return Storage.getItem("restaurant");
        }
        if (Storage.getItem("restaurantId")) {
            return Storage.getItem("restaurantId");
        }
        return 0;
    }
    const getRestaurantInfo = useCallback(() => {
        let networkId = getNetworkId();
        let restaurantId = getRestaurantId();
        fetchApi({
            url: `/web/network/${networkId}/restaurant/${restaurantId}`,
            method: "GET",
        })
            .then((r) => {
            const { data } = r;
            Storage.setItem("currency", JSON.stringify(data.setting.currency));
            setRestaurant(data);
        })
            .catch(() => { })
            .finally(() => { });
    }, []);
    useEffect(() => {
        getUserInfo();
        getRestaurantInfo();
    }, []);
    const providerValue = {
        userInfo,
        //
        getUserInfo,
        isLoading,
        setIsLoading,
        restaurant,
        setRestaurant,
    };
    return (_jsx(ProfileContext.Provider, { value: providerValue, children: children }));
}
export default ProfileProvider;
