import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { useForm, useFormContext, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { MenuContext } from '../context';
import { fetchApi } from '../../../config/Api';
import { getCurrency } from '../../UtilityFunctions';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import CustomTooltip from '../../components/CustomTooltip';
import { RoundedTextField, PrimaryButton, SecondaryButton } from '../../components/styled';
const OptionSchema = z.object({
    name: z.string().min(1),
    price: z.string().min(1),
    contain_alcohol: z.boolean()
});
export default function EditModifierDialog(props) {
    const [t] = useTranslation('menu');
    const [isLoading, setIsLoading] = useState(false);
    const formContext = useFormContext();
    const { getModifiers, getItems } = useContext(MenuContext);
    const form = useForm({
        resolver: zodResolver(OptionSchema),
        defaultValues: {
            name: props.option?.name ?? '',
            price: +props.option?.price === 0 ? '0' : props.option.price.toString(),
            contain_alcohol: props.option?.contain_alcohol ?? false
        }
    });
    const onSubmit = (data) => {
        const option = props.option;
        const updateOption = data;
        if (option.id) {
            setIsLoading(true);
            fetchApi({
                url: `/modifier-options/${option.id}`,
                method: 'POST',
                data: updateOption
            })
                .then(() => {
                const prevOptions = formContext.getValues('options');
                const updateOptions = prevOptions.map((item) => item.id === option.id
                    ? { ...option, ...data, price: +data.price }
                    : item);
                formContext.setValue('options', updateOptions);
                form.reset();
                getModifiers();
                getItems();
                props.close();
            })
                .catch((err) => console.log(err))
                .finally(() => setIsLoading(false));
        }
        else {
            if (props.optionIndex === null)
                return;
            const prevOptions = formContext.getValues('options');
            const updatedOptions = prevOptions.map((item, index) => index !== props.optionIndex
                ? item
                : {
                    ...option,
                    name: data.name,
                    price: +data.price,
                    contain_alcohol: data.contain_alcohol
                });
            formContext.setValue('options', updatedOptions);
            form.reset();
            props.close();
        }
    };
    const currency = getCurrency();
    const currencyIcon = currency === '$' ? (_jsx(AttachMoneyOutlinedIcon, { color: 'action' })) : currency === '€' ? (_jsx(EuroOutlinedIcon, { color: 'action' })) : ('');
    return (_jsx(_Fragment, { children: _jsxs(Dialog, { onClose: props.close, open: true, component: 'form', onSubmit: form.handleSubmit(onSubmit), children: [_jsxs(DialogTitle, { children: [t('dialogs.editOption.title'), " - ", props.option.name] }), _jsx(DialogContent, { dividers: true, children: _jsxs(Stack, { gap: 2, children: [_jsxs(Box, { display: 'flex', gap: 2, children: [_jsx(RoundedTextField, { label: t('drawers.modifier.form.options.name.label'), placeholder: t('drawers.modifier.form.options.name.placeholder'), error: !!form.formState.errors.name, ...form.register('name') }), _jsx(RoundedTextField, { label: t('drawers.modifier.form.options.price.label'), placeholder: '0.00', error: !!form.formState.errors.price, slotProps: {
                                            input: { startAdornment: currencyIcon }
                                        }, ...form.register('price', {
                                            onChange(e) {
                                                let value = e.target.value;
                                                value = value.replace(/[^0-9.,]/g, '');
                                                const parts = value.split(/[,\.]/);
                                                if (parts.length > 2) {
                                                    value = parts[0] + '.' + parts.slice(1).join('');
                                                }
                                                form.setValue('price', value, {
                                                    shouldDirty: true
                                                });
                                            }
                                        }) })] }), _jsx(Controller, { name: 'contain_alcohol', control: form.control, render: ({ field }) => (_jsx(FormControlLabel, { control: _jsx(Switch, { checked: field.value, onChange: field.onChange }), label: _jsxs(Box, { gap: 1, display: 'flex', alignItems: 'center', children: [t('drawers.modifier.form.options.alcohol.label'), _jsx(CustomTooltip, { tooltipProps: {
                                                    placement: 'top',
                                                    title: t('drawers.modifier.form.options.alcohol.tooltip')
                                                }, children: _jsx(InfoOutlinedIcon, { fontSize: 'small', color: 'error' }) })] }), labelPlacement: 'start', sx: {
                                        m: 0,
                                        mb: 3,
                                        px: 1.5,
                                        border: 1,
                                        width: '100%',
                                        borderColor: 'grey.400',
                                        borderRadius: 3,
                                        paddingY: '7px',
                                        justifyContent: 'space-between'
                                    } })) })] }) }), _jsxs(DialogActions, { children: [_jsx(SecondaryButton, { variant: 'outlined', onClick: () => props.close(), children: t('dialogs.editOption.secondaryBtnLabel') }), _jsx(PrimaryButton, { variant: 'contained', type: 'submit', disabled: !form.formState.isDirty, children: isLoading ? (_jsx(CircularProgress, { size: '24px', color: 'inherit' })) : (t('dialogs.editOption.primaryBtnLabel')) })] })] }) }));
}
