import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
;
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";
const List = styled("ul")(() => ({
    px: 1,
    color: "#736974",
    listStyle: "inside",
}));
export default function PasswordRules() {
    return (_jsxs(Box, { p: 2, bgcolor: "grey.100", borderRadius: 3, children: [_jsx(Typography, { mb: 2, fontWeight: 600, color: "#736974", children: "We recommend choosing a password that:" }), _jsxs(List, { color: "GreyText", children: [_jsx("li", { children: "Is not being used by you already for another account / login" }), _jsx("li", { children: "Is at least 8 characters in length" }), _jsx("li", { children: "Uses uppercase and lowercase letters" }), _jsx("li", { children: "Uses at least one number (0-9) and special characters (!@#$%^* \u2026)" })] })] }));
}
