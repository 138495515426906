import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState, useEffect, useContext } from "react";
import { fetchApi } from "../../config/Api";
const initialState = {
    isError: false,
    isLoading: false,
    network: null,
};
const NetworkPlanContext = createContext(initialState);
export const useNetworkPlanContext = () => {
    const context = useContext(NetworkPlanContext);
    if (!context) {
        throw new Error("useNetworkPlanContext must be used within a NetworkPlanProvider");
    }
    return context;
};
export default function NetworkPlanProvider({ children }) {
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [network, setNetwork] = useState(null);
    const getNetwork = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const hasId = searchParams.has("id");
        const id = searchParams.get("id");
        if (!hasId || id === null) {
            setIsError(true);
            return;
        }
        setIsLoading(true);
        fetchApi({
            method: "GET",
            url: "/network/list",
        }).then((res) => {
            const foundNetwork = res.data.networks.find((network) => network.id === +id);
            if (!foundNetwork) {
                setIsError(true);
            }
            else {
                setNetwork(foundNetwork);
            }
        });
    };
    useEffect(() => {
        getNetwork();
    }, []);
    const value = {
        isError,
        isLoading,
        network,
    };
    return (_jsx(NetworkPlanContext.Provider, { value: value, children: children }));
}
