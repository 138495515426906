import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import z from "zod";
import moment from "moment";
import InputMask from "react-input-mask";
import { useProfileContext } from "../ProfileContext";
import { fetchApi } from "../../../config/Api";
import { getErrorMessage } from "../../UtilityFunctions";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PageHeader from "../../components/PageHeader";
import { RoundedTextField } from "../../components/styled";
const Schema = z.object({
    firstname: z.string().trim().min(1),
    lastname: z.string().trim().min(1),
    telephone: z.string().trim().min(1),
    birthday: z.date(),
    email: z.string().trim().min(1),
});
export default function AccountSettingsForm(props) {
    const { initialState } = props;
    const [isLoading, setIsLoading] = useState(false);
    const defaultValues = {
        firstname: initialState.firstname,
        lastname: initialState.lastname,
        email: initialState.email,
        telephone: initialState.telephone ?? "",
        birthday: initialState.birthday
            ? new Date(initialState.birthday)
            : undefined,
    };
    const { control, register, handleSubmit, formState: { isDirty, errors }, } = useForm({
        resolver: zodResolver(Schema),
        defaultValues,
    });
    const { getUserInfo } = useProfileContext();
    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append("firstname", data.firstname);
        formData.append("lastname", data.lastname);
        formData.append("telephone", data.telephone);
        if (data.birthday)
            formData.append("birthday", moment(data.birthday).format("yyyy-MM-DD"));
        setIsLoading(true);
        fetchApi({
            method: "POST",
            url: `/web/user/${initialState.id}/update?_token=${
            // @ts-ignore
            document.head.querySelector('meta[name="_token"]').content}`,
            data: formData,
        })
            .then(() => {
            window.globalToast({
                title: "Success",
                type: "success",
                description: "User info changed successfully!",
            });
            getUserInfo();
        })
            .catch((e) => {
            window.globalToast({
                type: "error",
                title: "Error",
                description: getErrorMessage(e.response),
            });
        })
            .finally(() => {
            setIsLoading(false);
        });
    };
    return (_jsxs(Box, { sx: (theme) => ({
            px: 2,
            pb: 2,
            [theme.breakpoints.up("md")]: {
                px: 7,
            },
        }), children: [_jsx(Box, { my: 5, children: _jsx(PageHeader, { title: "Account Settings", subtitle: "Edit your account settings here" }) }), _jsxs(Grid, { container: true, rowSpacing: 4, columnSpacing: 2, component: "form", maxWidth: 448, onSubmit: handleSubmit(onSubmit), children: [_jsx(Grid, { item: true, xs: 6, children: _jsx(RoundedTextField, { fullWidth: true, label: "First Name", error: !!errors.firstname, ...register("firstname") }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(RoundedTextField, { fullWidth: true, label: "Last Name", error: !!errors.lastname, ...register("lastname") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(RoundedTextField, { disabled: true, fullWidth: true, label: "Email", ...register("email") }) }), _jsx(Grid, { item: true, xs: 5, md: 4, children: _jsxs(RoundedTextField, { select: true, fullWidth: true, value: "+355", label: "Country", children: [_jsx(MenuItem, { value: "+355", children: "+355(AL)" }), _jsx(MenuItem, { value: "+1", children: "+1(US)" })] }) }), _jsx(Grid, { item: true, xs: 7, md: 8, children: _jsx(Controller, { name: "telephone", control: control, render: ({ field: { onChange, value } }) => (_jsx(InputMask, { mask: "999-999-9999", maskChar: "", value: value, onChange: onChange, children: (inputProps) => (_jsx(RoundedTextField, { fullWidth: true, label: "Phone Number", error: !!errors.telephone, ...inputProps })) })) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Controller, { name: "birthday", control: control, render: ({ field: { onChange, value } }) => (_jsx(LocalizationProvider, { dateAdapter: AdapterMoment, children: _jsx(DatePicker, { disableFuture: true, defaultValue: moment(value), onChange: (event) => onChange(event), slotProps: {
                                        textField: {
                                            error: !!errors.birthday,
                                        },
                                    } }) })) }) }), _jsx(Grid, { item: true, children: _jsx(Button, { type: "submit", variant: "contained", disabled: !isDirty || isLoading, sx: { borderRadius: 3 }, children: isLoading ? _jsx(CircularProgress, { size: 24 }) : "Save Changes" }) })] })] }));
}
