import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { arrayMove } from '@dnd-kit/sortable';
import { MenuContext } from '../context';
import { ActionType } from '../context/types';
import { fetchApi } from '../../../config/Api';
import { getErrorMessage } from '../../UtilityFunctions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import AddOutlined from '@mui/icons-material/AddOutlined';
import RamenDiningOutlined from '@mui/icons-material/RamenDiningOutlined';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import FilterAlt from '@mui/icons-material/FilterAlt';
import { useTheme, useMediaQuery } from '@mui/material';
import ItemTableRow from './ItemTableRow';
import Count from './Count';
import SearchField from './SearchField';
import SortableContainer from '../../components/SortableContainer';
import { RoundedButton, TableCell } from './styled';
export default function ItemsTable() {
    const [t] = useTranslation('menu');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { items, loadingItems, categories, dispatch, toggleDrawer } = useContext(MenuContext);
    function handleDragEnd(event) {
        const { active, over } = event;
        if (active.id !== over?.id) {
            const oldIndex = items.findIndex((item) => item.id === active.id);
            const newIndex = items.findIndex((item) => item.id === over?.id);
            const sortedItems = arrayMove(items, oldIndex, newIndex);
            dispatch({
                type: ActionType.GET_ITEMS,
                payload: { items: sortedItems }
            });
            fetchApi({
                method: 'POST',
                url: '/menu/items/reorder',
                data: { items: sortedItems.map(({ id }) => id) }
            }).catch((error) => {
                window.globalToast({
                    type: 'error',
                    title: 'Error',
                    description: getErrorMessage(error.response)
                });
            });
        }
    }
    useEffect(() => {
        if (categories.length) {
            setSelectedCategories(categories.map((item) => item.id));
        }
    }, [categories]);
    return (_jsx(Box, { pt: 4, px: 4, borderLeft: 1, borderColor: 'divider', sx: (theme) => ({
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            [theme.breakpoints.up('lg')]: {
                overflowY: 'auto',
                gridArea: '1 / 2 / 3 / 3'
            },
            [theme.breakpoints.down('lg')]: {
                pt: 0,
                px: 0
            }
        }), children: loadingItems ? (_jsx(Stack, { mt: 16, alignItems: 'center', children: _jsx(CircularProgress, {}) })) : !items.length ? (_jsxs(Stack, { mt: 16, alignItems: 'center', children: [_jsx(RamenDiningOutlined, { sx: { fontSize: 32 }, color: 'disabled' }), _jsx(Typography, { fontSize: 16, fontWeight: 600, mb: 1, mt: 2, children: t('overview.items.emptyText.primary') }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', mb: 3, children: t('overview.items.emptyText.secondary') }), _jsx(RoundedButton, { variant: 'contained', startIcon: _jsx(AddOutlined, {}), onClick: () => toggleDrawer('isItemDrawerOpen'), children: t('overview.items.btnLabels.newItemBtnLabel') })] })) : (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: (theme) => ({
                        [theme.breakpoints.up('md')]: {
                            overflowY: 'auto'
                        }
                    }), children: [_jsxs(Box, { px: 7, py: 3, display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap', sx: (theme) => ({
                                [theme.breakpoints.down('lg')]: {
                                    px: 2
                                }
                            }), children: [_jsx(Count, { label: t('overview.items.title'), count: items.length }), _jsx(RoundedButton, { startIcon: _jsx(FilterAlt, {}), endIcon: _jsx(KeyboardArrowDown, {}), sx: { ml: 'auto', px: 2 }, onClick: (e) => setAnchorEl(e.currentTarget), children: t('overview.items.categoryFilterBtnLabel') }), _jsx(Menu, { open: Boolean(anchorEl), anchorEl: anchorEl, onClose: () => setAnchorEl(null), slotProps: { paper: { sx: { borderRadius: 3, width: 240 } } }, children: categories.map((category) => (_jsx(Category, { category: category, selectedCategories: selectedCategories, setSelectedCategories: setSelectedCategories }, category.id))) }), _jsx(SearchField, { value: searchTerm, onChange: setSearchTerm, placeholder: t('overview.items.searchFieldPlaceholder') })] }), _jsx(SortableContainer, { items: items, onDragEnd: handleDragEnd, children: _jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { sx: {
                                                    display: 'grid',
                                                    gridTemplateColumns: '0.3fr 0.5fr 1fr 0.5fr 0.5fr 0.5fr 0.3fr'
                                                }, children: [_jsx(TableCell, {}), _jsx(TableCell, { children: t('overview.items.tableColumns.photo') }), _jsx(TableCell, { children: t('overview.items.tableColumns.name') }), _jsx(TableCell, { children: t('overview.items.tableColumns.status') }), !isMdDown && (_jsx(TableCell, { children: t('overview.items.tableColumns.category') })), _jsx(TableCell, { children: t('overview.items.tableColumns.price') }), _jsx(TableCell, {})] }) }), _jsx(TableBody, { children: items
                                                .filter((item) => selectedCategories.includes(item.category_id))
                                                .filter((item) => item.name
                                                .toLowerCase()
                                                .includes(searchTerm.toLowerCase()))
                                                .map((item) => (_jsx(ItemTableRow, { item: item }, item.id))) })] }) }) })] }), _jsx(RoundedButton, { variant: 'contained', startIcon: _jsx(AddOutlined, {}), onClick: () => toggleDrawer('isItemDrawerOpen'), sx: {
                        mx: 4,
                        my: 2,
                        alignSelf: 'flex-start'
                    }, children: t('overview.items.btnLabels.newItemBtnLabel') })] })) }));
}
const Category = (props) => {
    const { category, selectedCategories, setSelectedCategories } = props;
    const labelId = `checkbox-list-secondary-label-${category.id}`;
    const handleToggle = (value) => () => {
        const currentIndex = selectedCategories.indexOf(value);
        const newChecked = [...selectedCategories];
        if (currentIndex === -1) {
            newChecked.push(value);
        }
        else {
            newChecked.splice(currentIndex, 1);
        }
        setSelectedCategories(newChecked);
    };
    return (_jsx(ListItem, { secondaryAction: _jsx(Checkbox, { edge: 'end', checked: selectedCategories.indexOf(category.id) !== -1, inputProps: { 'aria-labelledby': labelId }, onChange: handleToggle(category.id) }), disablePadding: true, children: _jsx(ListItemButton, { onClick: handleToggle(category.id), children: _jsx(ListItemText, { id: labelId, slotProps: {
                    primary: { noWrap: true }
                }, primary: _jsx(Tooltip, { title: category.name.length > 20 ? category.name : '', children: _jsx("span", { children: category.name }) }) }) }) }));
};
