import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
export default function SearchField(props) {
    const { value, onChange, placeholder, fullWidth, sx, onClear } = props;
    return (_jsxs(Box, { sx: {
            pl: 2,
            pr: 1,
            gap: 2,
            height: 36,
            display: "flex",
            alignItems: "center",
            borderRadius: "18px",
            backgroundColor: "grey.100",
            flexGrow: fullWidth ? 1 : 0,
            ...sx,
        }, children: [_jsx(SearchOutlinedIcon, { color: "action", fontSize: "small" }), _jsx(InputBase, { value: value, placeholder: placeholder, onChange: (e) => onChange(e.target.value), sx: { height: "100%", flexGrow: 1, fontSize: 14, fontWeight: 600 } }), onClear && (_jsx("button", { onClick: onClear, style: { opacity: !!value.length ? 1 : 0 }, children: _jsx(CancelIcon, { color: "action" }) }))] }));
}
