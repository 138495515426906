import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from "react";
import { fetchApi } from "../../config/Api";
import { getNetworkId, getRestaurantId } from "../UtilityFunctions";
const initialState = {
    employees: [],
    loadingEmployees: false,
    isDrawerOpen: false,
    setIsDrawerOpen: () => { },
    selectedEmployee: null,
    setSelectedEmployee: () => { },
    getEmployees: () => { },
    getEmployeePermissions: () => { },
    selectedEmployerPermissions: null,
    userRole: null,
    getEmployeeRestaurantList: () => { },
    selectedEmployeeRestaurants: [],
    setSelectedEmployeeRestaurants: () => { },
    pagination: {
        total: 0,
        per_page: 0,
        current_page: 0,
        last_page: 0,
        from: 0,
        to: 0,
    },
    page: 1,
    setPage: () => { },
    perPage: 10,
    setPerPage: () => { },
    isFetching: false,
};
const EmployeesContext = createContext(initialState);
export const useEmployeesContext = () => {
    const context = useContext(EmployeesContext);
    if (!context) {
        throw new Error("useEmployeesContext must be used within a UserProvider");
    }
    return context;
};
export default function EmployeeProvider({ children }) {
    const [isFetching, setIsFetching] = useState(false);
    const [loadingEmployees, setLoadingEmployees] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedEmployerPermissions, setSelectedEmployerPermissions] = useState(null);
    const [selectedEmployeeRestaurants, setSelectedEmployeeRestaurants] = useState([]);
    const [userRole, setUserRole] = useState(null);
    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 10,
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 0,
    });
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    function getEmployees(query, nextPage) {
        if (nextPage) {
            setIsFetching(true);
        }
        else {
            setLoadingEmployees(true);
        }
        let networkId = getNetworkId();
        let restaurantId = getRestaurantId();
        const params = new URLSearchParams({
            network: networkId?.toString() ?? "",
            restaurant: restaurantId?.toString() ?? "",
            page: nextPage?.toString() ?? page.toString(),
            perPage: perPage.toString(),
            ...(query && { search: query }),
        });
        fetchApi({
            method: "GET",
            url: `/employees/list?${params}`,
        })
            .then((res) => {
            if (nextPage) {
                setEmployees((prev) => [...prev, ...res.data.employees_list]);
            }
            else {
                setEmployees(res.data.employees_list);
            }
            setUserRole(res.data.user_role);
            setPagination(res.data.pagination);
        })
            .catch((e) => {
            window.globalToast({
                type: "error",
                title: "Error",
                description: e.message,
            });
        })
            .finally(() => {
            setIsFetching(false);
            setLoadingEmployees(false);
        });
    }
    function getEmployeePermissions(employeeId) {
        let networkId = getNetworkId();
        let restaurantId = getRestaurantId();
        fetchApi({
            method: "GET",
            url: `/employee/permissions?restaurant=${restaurantId}&network=${networkId}&employee_id=${employeeId}`,
        })
            .then((res) => {
            setSelectedEmployerPermissions(res.data);
        })
            .catch((e) => {
            window.globalToast({
                type: "error",
                title: "Error",
                description: e.message,
            });
        });
    }
    function getEmployeeRestaurantList(employeeId) {
        let networkId = getNetworkId();
        let restaurantId = getRestaurantId();
        fetchApi({
            method: "GET",
            url: `/restaurant/manager?restaurant=${restaurantId}&network=${networkId}&userId=${employeeId}`,
        }).then((res) => {
            setSelectedEmployeeRestaurants(res.data);
        });
    }
    useEffect(() => {
        getEmployees();
    }, [page, perPage]);
    const value = {
        employees,
        loadingEmployees,
        isDrawerOpen,
        setIsDrawerOpen,
        selectedEmployee,
        setSelectedEmployee,
        getEmployees,
        getEmployeePermissions,
        selectedEmployerPermissions,
        userRole,
        getEmployeeRestaurantList,
        selectedEmployeeRestaurants,
        setSelectedEmployeeRestaurants,
        pagination,
        page,
        setPage,
        perPage,
        setPerPage,
        isFetching,
    };
    return (_jsx(EmployeesContext.Provider, { value: value, children: children }));
}
