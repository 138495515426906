import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import { DayPicker } from 'react-day-picker';
// @ts-ignore
import 'react-day-picker/dist/style.css';
import { usePaymentsContext } from '../PaymentsContext';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { styled } from '@mui/material';
import { blue } from '@mui/material/colors';
const FilterButton = styled(Button)((props) => ({
    textTransform: 'none',
    textAlign: 'start',
    borderRadius: '8px',
    fontWeight: 600,
    justifyContent: 'flex-start',
    color: props.variant === 'text' ? 'black' : 'white',
    paddingInline: '16px'
}));
export default function FilterByDate() {
    const [t] = useTranslation('financials');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedDateLabel, setSelectedDateLabel] = useState('last30Days');
    const [range, setRange] = useState({
        from: moment().subtract(30, 'days').toDate(),
        to: new Date()
    });
    const { setDateFilter } = usePaymentsContext();
    const buttonVariant = (value) => selectedDateLabel === value ? 'contained' : 'text';
    const handleClick = (filter) => {
        setSelectedDateLabel(filter);
        if (filter === 'today') {
            setRange({
                from: new Date(),
                to: new Date()
            });
            setDateFilter({
                from: moment().format('DD/MM/yyyy'),
                to: moment().format('DD/MM/yyyy')
            });
        }
        if (filter === 'yesterday') {
            setRange({
                from: moment().subtract(1, 'day').toDate(),
                to: moment().subtract(1, 'day').toDate()
            });
            setDateFilter({
                from: moment().subtract(1, 'day').format('DD/MM/yyyy'),
                to: moment().subtract(1, 'day').format('DD/MM/yyyy')
            });
        }
        if (filter === 'last7Days') {
            setRange({
                from: moment().subtract(7, 'day').toDate(),
                to: new Date()
            });
            setDateFilter({
                from: moment().subtract(7, 'day').format('DD/MM/yyyy'),
                to: moment().format('DD/MM/yyyy')
            });
        }
        if (filter === 'last30Days') {
            setRange({
                from: moment().subtract(30, 'day').toDate(),
                to: new Date()
            });
            setDateFilter({
                from: moment().subtract(30, 'day').format('DD/MM/yyyy'),
                to: moment().format('DD/MM/yyyy')
            });
        }
        if (filter === 'custom') {
            setRange(undefined);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { color: 'text.secondary', children: _jsx(Button, { fullWidth: true, color: 'inherit', variant: 'outlined', startIcon: _jsx(DateRangeOutlinedIcon, {}), endIcon: _jsx(ArrowDropDownOutlinedIcon, {}), onClick: (e) => setAnchorEl(e.currentTarget), sx: { borderRadius: 2 }, children: selectedDateLabel === 'custom'
                        ? `${moment(range?.from).format('D MMM')} - ${moment(range?.to).format('D MMM')}`
                        : _.upperCase(selectedDateLabel) }) }), _jsx(Popover, { open: Boolean(anchorEl), anchorEl: anchorEl, onClose: () => setAnchorEl(null), PaperProps: { sx: { borderRadius: 2 } }, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center'
                }, children: _jsxs(Box, { py: 2, px: 3, display: 'flex', sx: (theme) => ({
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column'
                        }
                    }), children: [_jsxs(Stack, { justifyContent: 'center', gap: '4px', children: [_jsx(FilterButton, { variant: buttonVariant('today'), onClick: () => handleClick('today'), children: t('orders.dateFilter.today') }), _jsx(FilterButton, { variant: buttonVariant('yesterday'), onClick: () => handleClick('yesterday'), children: t('orders.dateFilter.yesterday') }), _jsx(FilterButton, { variant: buttonVariant('last7Days'), onClick: () => handleClick('last7Days'), children: t('orders.dateFilter.last7Days') }), _jsx(FilterButton, { variant: buttonVariant('last30Days'), onClick: () => handleClick('last30Days'), children: t('orders.dateFilter.last30Days') }), _jsx(FilterButton, { variant: buttonVariant('custom'), onClick: () => handleClick('custom'), children: t('orders.dateFilter.custom') })] }), _jsx(Divider, { flexItem: true, orientation: 'vertical', sx: { px: 3 } }), _jsx(DayPicker, { mode: 'range', selected: range, onSelect: (e) => {
                                setRange(e);
                                setSelectedDateLabel('custom');
                                if (e && e.from && e.to) {
                                    setDateFilter({
                                        from: moment(e.from).format('DD/MM/yyyy'),
                                        to: moment(e.to).format('DD/MM/yyyy')
                                    });
                                }
                            }, modifiersStyles: { selected: { backgroundColor: blue[700] } } })] }) })] }));
}
