import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import z from "zod";
import { useProfileContext } from "../ProfileContext";
import { fetchApi } from "../../../config/Api";
import { getErrorMessage } from "../../UtilityFunctions";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PageHeader from "../../components/PageHeader";
import PasswordRules from "./PasswordRules";
import { RoundedTextField } from "../../components/styled";
const Schema = z.object({
    old_password: z.string().trim().min(1),
    new_password: z
        .string()
        .trim()
        .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character!"),
    confirm_password: z.string().trim().min(1),
});
export const SchemaWithPasswordConfirmation = Schema.refine((data) => {
    return data.new_password === data.confirm_password;
}, {
    message: "Passwords don't match",
    path: ["confirm_password"],
});
export default function ChangePasswordForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmationPassword, setShowConfirmationassword] = useState(false);
    const { register, handleSubmit, formState: { isDirty, errors }, } = useForm({
        resolver: zodResolver(SchemaWithPasswordConfirmation),
    });
    const { userInfo } = useProfileContext();
    const onSubmit = (data) => {
        setIsLoading(true);
        fetchApi({
            method: "PUT",
            url: `/web/user/${userInfo?.id}/password?_token=${
            // @ts-ignore
            document.head.querySelector('meta[name="_token"]').content}`,
            data,
        })
            .then(() => {
            window.globalToast({
                title: "Success",
                type: "success",
                description: "User password changed successfully!",
            });
        })
            .catch((e) => {
            window.globalToast({
                type: "error",
                title: "Error",
                description: getErrorMessage(e.response),
            });
        })
            .finally(() => {
            setIsLoading(false);
        });
    };
    return (_jsxs(Box, { sx: (theme) => ({
            px: 2,
            pb: 2,
            [theme.breakpoints.up("md")]: {
                px: 7,
            },
        }), children: [_jsx(Box, { my: 5, children: _jsx(PageHeader, { title: "Change Password" }) }), _jsxs(Grid, { container: true, rowSpacing: 4, columnSpacing: 2, component: "form", maxWidth: 448, onSubmit: handleSubmit(onSubmit), children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(RoundedTextField, { fullWidth: true, label: "Current Password", error: !!errors.old_password, helperText: errors.old_password?.message, ...register("old_password"), type: showCurrentPassword ? "text" : "password", InputProps: {
                                endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { edge: "end", "aria-label": "toggle password visibility", onClick: () => setShowCurrentPassword(!showCurrentPassword), children: showCurrentPassword ? (_jsx(VisibilityOffIcon, {})) : (_jsx(VisibilityIcon, {})) }) })),
                            } }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(RoundedTextField, { fullWidth: true, label: "New Password", error: !!errors.new_password, helperText: errors.new_password?.message, ...register("new_password"), type: showNewPassword ? "text" : "password", InputProps: {
                                endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { edge: "end", "aria-label": "toggle password visibility", onClick: () => setShowNewPassword(!showNewPassword), children: showNewPassword ? (_jsx(VisibilityOffIcon, {})) : (_jsx(VisibilityIcon, {})) }) })),
                            } }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(RoundedTextField, { fullWidth: true, label: "Password Confirmation", error: !!errors.confirm_password, helperText: errors.confirm_password?.message, ...register("confirm_password"), type: showConfirmationPassword ? "text" : "password", InputProps: {
                                endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { edge: "end", "aria-label": "toggle password visibility", onClick: () => setShowConfirmationassword(!showConfirmationPassword), children: showConfirmationPassword ? (_jsx(VisibilityOffIcon, {})) : (_jsx(VisibilityIcon, {})) }) })),
                            } }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(PasswordRules, {}) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(Button, { type: "submit", variant: "contained", disabled: !isDirty || isLoading, sx: { borderRadius: 3 }, children: isLoading ? _jsx(CircularProgress, { size: 24 }) : "Save Changes" }) })] })] }));
}
