import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState, useEffect, useContext, } from "react";
import { fetchApi } from "../../config/Api";
import { getRestaurantId, getErrorMessage, } from "../UtilityFunctions";
const initialState = {
    isDrawerOpen: false,
    setIsDrawerOpen: () => { },
    isLoading: false,
    images: [],
    galleryId: null,
    getImages: () => { },
    selectedImage: null,
    setSelectedImage: () => { },
    deleteImage: () => { },
};
export const GalleryContext = createContext(initialState);
export const useGalleryContext = () => {
    const context = useContext(GalleryContext);
    if (!context) {
        throw new Error("useGalleryContext must be used within a GalleryProvider");
    }
    return context;
};
function GalleryProvider({ children }) {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [galleryId, setGalleryId] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const deleteImage = (id) => {
        fetchApi({
            method: "DELETE",
            url: `/gallery/removeImage`,
            data: {
                imageId: id,
            },
        })
            .then(() => {
            window.globalToast({
                title: "Success",
                type: "success",
                description: "Image deleted successfully!",
            });
            getImages();
        })
            .catch((err) => {
            window.globalToast({
                type: "error",
                title: "Error",
                description: getErrorMessage(err.response),
            });
        });
    };
    const getImages = () => {
        let restaurantId = getRestaurantId();
        setIsLoading(true);
        fetchApi({
            method: "GET",
            url: `/web/restaurant/${restaurantId}/gallery`,
        })
            .then((res) => {
            setImages(res.data.images);
            setGalleryId(res.data.id);
        })
            .catch((err) => {
            window.globalToast({
                type: "error",
                title: "Error",
                description: getErrorMessage(err.response),
            });
        })
            .finally(() => {
            setIsLoading(false);
        });
    };
    useEffect(() => {
        getImages();
    }, []);
    const providerValue = {
        isDrawerOpen,
        setIsDrawerOpen,
        isLoading,
        images,
        getImages,
        galleryId,
        selectedImage,
        setSelectedImage,
        deleteImage,
    };
    return (_jsx(GalleryContext.Provider, { value: providerValue, children: children }));
}
export default GalleryProvider;
