import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useContext } from 'react';
import { arrayMove } from '@dnd-kit/sortable';
import { useTranslation } from 'react-i18next';
import { MenuContext } from '../context';
import { ActionType } from '../context/types';
import { fetchApi } from '../../../config/Api';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import AddOutlined from '@mui/icons-material/AddOutlined';
import GridViewOutlined from '@mui/icons-material/GridViewOutlined';
import Count from './Count';
import SearchField from './SearchField';
import ModifierTableRow from './ModifierTableRow';
import SortableContainer from '../../components/SortableContainer';
import { RoundedButton, TableCell } from './styled';
export default function ModifiersTable() {
    const [t] = useTranslation('menu');
    const [searchTerm, setSearchTerm] = useState('');
    const { toggleDrawer, modifiers, loadingModifiers, dispatch } = useContext(MenuContext);
    function handleDragEnd(event) {
        const { active, over } = event;
        if (active.id !== over?.id) {
            const oldIndex = modifiers.findIndex((item) => item.id === active.id);
            const newIndex = modifiers.findIndex((item) => item.id === over?.id);
            const sortedItems = arrayMove(modifiers, oldIndex, newIndex);
            dispatch({
                type: ActionType.GET_MODIFIERS,
                payload: { modifiers: sortedItems }
            });
            fetchApi({
                method: 'POST',
                url: '/modifier-groups-reorder',
                data: { modifier_groups: sortedItems.map(({ id }) => id) }
            }).catch(() => {
                window.globalToast({
                    type: 'error',
                    title: 'Error',
                    description: "Something went wrong. Couldn't reorder items."
                });
            });
        }
    }
    return (_jsx(Box, { sx: () => ({
            maxWidth: 1600,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto'
        }), children: loadingModifiers ? (_jsx(Stack, { mt: 16, alignItems: 'center', children: _jsx(CircularProgress, {}) })) : !modifiers.length ? (_jsxs(Stack, { mt: 16, alignItems: 'center', children: [_jsx(GridViewOutlined, { sx: { fontSize: 32 }, color: 'disabled' }), _jsx(Typography, { fontSize: 16, fontWeight: 600, mb: 1, mt: 2, children: t('modifiers.emptyText.primary') }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', mb: 3, children: t('modifiers.emptyText.secondary') }), _jsx(RoundedButton, { variant: 'contained', startIcon: _jsx(AddOutlined, {}), onClick: () => toggleDrawer('isModifierDrawerOpen'), children: t('modifiers.newModifierBtnLabel') })] })) : (_jsxs(_Fragment, { children: [_jsxs(Box, { px: 4, sx: (theme) => ({
                        [theme.breakpoints.up('md')]: {
                            overflowY: 'auto'
                        },
                        [theme.breakpoints.down('lg')]: {
                            px: 0
                        }
                    }), children: [_jsxs(Box, { sx: (theme) => ({
                                py: 3,
                                px: 7,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 2,
                                [theme.breakpoints.down('lg')]: {
                                    px: 2
                                },
                                [theme.breakpoints.down('md')]: {
                                    flexDirection: 'column',
                                    alignItems: 'flex-start'
                                }
                            }), children: [_jsx(Count, { label: t('modifiers.title'), count: modifiers.length }), _jsx(SearchField, { value: searchTerm, onChange: setSearchTerm, placeholder: t('modifiers.searchFieldPlaceholder') })] }), _jsx(SortableContainer, { items: modifiers, onDragEnd: handleDragEnd, children: _jsx(TableContainer, { sx: (theme) => ({
                                    [theme.breakpoints.down('md')]: { overflowX: 'unset' }
                                }), children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { sx: {
                                                    display: 'grid',
                                                    gridTemplateColumns: '0.5fr 1fr 1fr 1.5fr 0.5fr'
                                                }, children: [_jsx(TableCell, {}), _jsx(TableCell, { children: t('modifiers.tableColumns.1') }), _jsx(TableCell, { children: t('modifiers.tableColumns.2') }), _jsx(TableCell, { children: t('modifiers.tableColumns.3') }), _jsx(TableCell, {})] }) }), _jsx(TableBody, { children: modifiers
                                                .filter((modifier) => modifier.name
                                                .toLowerCase()
                                                .includes(searchTerm.toLowerCase()))
                                                .map((modifier) => (_jsx(ModifierTableRow, { modifier: modifier }, modifier.id))) })] }) }) })] }), _jsx(RoundedButton, { variant: 'contained', startIcon: _jsx(AddOutlined, {}), onClick: () => toggleDrawer('isModifierDrawerOpen'), sx: { mx: 4, my: 2, alignSelf: 'flex-start' }, children: t('modifiers.newModifierBtnLabel') })] })) }));
}
