import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNetworkContext } from '../SelectNetworkProvider';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import InputBase from '@mui/material/InputBase';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import NetworkCard from './NetworkItem';
export default function NetworkList() {
    const { t } = useTranslation('network');
    const [searchTerm, setSearchTerm] = useState('');
    const { isSuperAdmin, networks } = useNetworkContext();
    return (_jsxs(_Fragment, { children: [isSuperAdmin && (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: {
                            px: 2,
                            mb: 2,
                            height: 36,
                            display: 'flex',
                            alignItems: 'center',
                            flexGrow: 1,
                            gap: 2,
                            backgroundColor: 'grey.100',
                            borderRadius: '18px'
                        }, children: [_jsx(SearchOutlinedIcon, { color: 'action' }), _jsx(InputBase, { placeholder: t('searchFieldPlaceholder'), sx: { flexGrow: 1, height: '100%' }, value: searchTerm, onChange: (e) => setSearchTerm(e.target.value) })] }), _jsx(Divider, { sx: { mb: 3 } })] })), _jsx(List, { disablePadding: true, sx: { display: 'flex', flexDirection: 'column', gap: 2 }, children: networks &&
                    networks
                        .filter((network) => network.title.toLowerCase().includes(searchTerm.toLowerCase()))
                        .map((network) => _jsx(NetworkCard, { ...network }, network.id)) })] }));
}
