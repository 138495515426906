import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsContext } from './SettingsProvider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { OSTitleDesc, OSSettingsTitle, OSSettingsTitleBox } from './components/OSCustomComponents';
import { useTheme, useMediaQuery, Typography } from '@mui/material';
import comp from './components';
import SettingsNavigation from './components/SettingsNavigation';
function SettingsContainer() {
    const [t] = useTranslation('settings');
    const { hideStepperButtons, activeStep, setActiveStep, showSaveChanges, setShowSaveChanges, submitNewRestaurant } = useContext(SettingsContext);
    const handleNext = async () => {
        await submitNewRestaurant();
        setShowSaveChanges(false);
    };
    function getStepContent(step) {
        switch (step) {
            case 0:
                return _jsx(comp.GeneralSettings, {});
            case 1:
                return _jsx(comp.AddressSettings, { context: SettingsContext });
            case 2:
                return _jsx(comp.PaymentSettings, {});
            case 3:
                return _jsx(comp.TaxationCurrencySettings, {});
            case 4:
                return _jsx(comp.DeliveryPickupSettings, {});
            case 5:
                return _jsx(comp.DeliveryTimelines, {});
            case 6:
                return _jsx(comp.PickupTimelines, {});
            case 7:
                return _jsx(comp.DeleteRestaurant, {});
            default:
                return _jsx(comp.GeneralSettings, {});
        }
    }
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    return (_jsxs(Box, { sx: { width: '100%', overflowY: 'auto', p: { xs: 2, md: 3, lg: 6 } }, children: [isMdDown && activeStep !== -1 && (_jsxs(Box, { sx: (theme) => ({
                    bgcolor: '#fff',
                    position: 'fixed',
                    top: 0,
                    height: 65,
                    zIndex: 2000,
                    right: 0,
                    left: 0,
                    borderBottom: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    px: 2,
                    [theme.breakpoints.down('sm')]: {
                        height: 57
                    }
                }), onClick: () => setActiveStep(-1), children: [_jsx(ArrowBackIcon, { color: 'action' }), _jsx(Typography, { fontWeight: 600, children: t('backBtnLabel') })] })), _jsxs(OSSettingsTitleBox, { children: [_jsx(OSSettingsTitle, { children: activeStep === -1
                            ? t('title')
                            : t('steps', { returnObjects: true })[activeStep] }), (isMdDown ? activeStep === -1 : true) && (_jsx(OSTitleDesc, { children: t('description') }))] }), !isMdDown && _jsx(Divider, {}), _jsxs(Box, { sx: (theme) => ({
                    display: 'flex',
                    overflowY: 'auto',
                    flexDirection: 'row',
                    gap: 5,
                    [theme.breakpoints.up('md')]: {
                        mt: 4
                    }
                }), children: [_jsx(SettingsNavigation, {}), _jsx(Divider, { flexItem: true, orientation: 'vertical', sx: (theme) => ({
                            [theme.breakpoints.down('md')]: {
                                display: 'none'
                            }
                        }) }), (isMdDown ? activeStep !== -1 : true) && (_jsxs(Box, { width: '100%', children: [getStepContent(activeStep), !hideStepperButtons && (_jsx(Box, { sx: {
                                    display: 'flex',
                                    marginLeft: '0.5rem'
                                }, children: _jsx(Button, { variant: 'contained', disabled: !showSaveChanges, sx: { borderRadius: '0.5rem' }, component: 'label', onClick: handleNext, children: t('btnLabel') }) }))] }))] })] }));
}
export default SettingsContainer;
