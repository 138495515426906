import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { useNetworkContext } from '../SelectNetworkProvider';
import { fetchApi } from '../../../config/Api';
import { getErrorMessage, generateImageUrl } from '../../UtilityFunctions';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import SideDrawer from '../../components/SideDrawer';
import CloseDrawerDialog from '../../menu/components/CloseDrawerDialog';
import DragNDrop from '../../components/DragNDrop';
import { PrimaryButton, SecondaryButton, RoundedTextField } from '../../components/styled';
const Schema = z.object({
    name: z.string().optional(),
    logo: z.array(z.instanceof(File)).or(z.string().optional())
});
const defaultValues = {
    name: ''
};
export default function NetworkDrawer() {
    const { t } = useTranslation('network');
    const closeDialogRef = useRef();
    const [isSaving, setIsSaving] = useState(false);
    const { isNetworkDrawerOpen, setIsNetworkDrawerOpen, selectedNetwork, setSelectedNetwork, getNetworks } = useNetworkContext();
    const { watch, reset, register, setValue, handleSubmit, formState: { errors, isDirty } } = useForm({
        resolver: zodResolver(Schema),
        defaultValues
    });
    const onSubmit = (data) => {
        const formData = new FormData();
        if (data.logo && typeof data.logo !== 'string')
            formData.append('logo', data.logo[0]);
        setIsSaving(true);
        fetchApi({
            method: 'POST',
            url: `/network/${selectedNetwork?.id}/add-logo`,
            data: formData
        })
            .then(() => {
            window.globalToast({
                title: 'Success',
                type: 'success',
                description: 'New photo added successfully!'
            });
            handleLeave();
            getNetworks();
        })
            .catch((err) => {
            window.globalToast({
                type: 'error',
                title: 'Error',
                description: getErrorMessage(err.response)
            });
        })
            .finally(() => {
            setIsSaving(false);
        });
    };
    const handleClose = () => {
        if (isDirty) {
            closeDialogRef.current?.open();
        }
        else {
            handleLeave();
        }
    };
    const handleLeave = () => {
        setIsNetworkDrawerOpen(false);
        setSelectedNetwork(null);
        reset(defaultValues);
    };
    const handleDeleteImage = () => {
        fetchApi({
            method: 'DELETE',
            url: `/network/${selectedNetwork?.id}/delete-logo`
        })
            .then(() => {
            window.globalToast({
                title: 'Success',
                type: 'success',
                description: 'Photo deleted successfully!'
            });
            handleLeave();
            getNetworks();
        })
            .catch((err) => {
            window.globalToast({
                type: 'error',
                title: 'Error',
                description: getErrorMessage(err.response)
            });
        });
    };
    useEffect(() => {
        if (selectedNetwork) {
            reset({
                name: selectedNetwork.title,
                logo: selectedNetwork.logo ? selectedNetwork.logo : undefined
            });
        }
    }, [selectedNetwork]);
    const logo = watch('logo');
    const imgSrc = typeof logo === 'string'
        ? generateImageUrl(logo)
        : logo
            ? URL.createObjectURL(logo[0])
            : '';
    return (_jsxs(_Fragment, { children: [_jsx(CloseDrawerDialog, { ref: closeDialogRef, onLeave: handleLeave }), _jsx(SideDrawer, { isOpen: isNetworkDrawerOpen, onClose: handleClose, primaryText: t('drawers.networkDrawer.title'), children: _jsxs(Stack, { component: 'form', height: '100%', onSubmit: handleSubmit(onSubmit), children: [_jsxs(Box, { p: 4, display: 'grid', gap: 3, children: [_jsx(DragNDrop, { imgSrc: imgSrc, error: errors.logo?.message, onChange: (files) => setValue('logo', files, { shouldDirty: true }), onDelete: handleDeleteImage }), _jsx(RoundedTextField, { fullWidth: true, disabled: true, slotProps: {
                                        inputLabel: {
                                            shrink: true
                                        }
                                    }, ...register('name') }), _jsx(Alert, { severity: 'info', sx: { borderRadius: 2 }, children: t('drawers.networkDrawer.infoAlert') })] }), _jsxs(Box, { sx: {
                                p: 2,
                                mt: 'auto',
                                position: 'sticky',
                                bottom: 0,
                                bgcolor: 'white',
                                boxShadow: '0px -4px 10px 0px #00000026',
                                display: 'flex',
                                gap: 2,
                                zIndex: 1
                            }, children: [_jsx(SecondaryButton, { type: 'button', variant: 'outlined', sx: { ml: 'auto' }, onClick: handleClose, children: t('drawers.networkDrawer.secondaryBtnLabel') }), _jsx(PrimaryButton, { type: 'submit', variant: 'contained', disabled: isSaving || !isDirty, children: isSaving ? (_jsx(CircularProgress, { size: '24px' })) : (t('drawers.networkDrawer.primaryBtnLabel')) })] })] }) })] }));
}
