import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useEmployeesContext } from '../EmployeeProvider';
import { fetchApi } from '../../../config/Api';
import { getRestaurantId } from '../../UtilityFunctions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
const nameReplacements = {
    store: 'Store',
    menu: 'Menu',
    payout: 'Financials',
    users: 'Customers',
    employee: 'Employees',
    'edit-gallery': 'Gallery',
    settings: 'Settings'
};
const permissionsOrder = Object.keys(nameReplacements);
const EmployeePermissions = () => {
    const [t] = useTranslation('employees');
    const { userRole, selectedEmployerPermissions, selectedEmployee, getEmployeePermissions } = useEmployeesContext();
    function editEmployerPermission(permissionId) {
        if (!selectedEmployee || !selectedEmployerPermissions)
            return;
        const restaurantId = getRestaurantId();
        const prevPermissions = selectedEmployerPermissions
            ?.filter((permission) => permission['has-permission'])
            .map((permission) => permission.id);
        const newPermissions = prevPermissions.includes(permissionId)
            ? prevPermissions.filter((id) => id !== permissionId)
            : [...prevPermissions, permissionId];
        fetchApi({
            method: 'PUT',
            url: `/employee/update/permissions?employee_id=${selectedEmployee.id}&restaurant_id=${restaurantId}`,
            headers: { 'content-type': 'application/json' },
            data: {
                permission: newPermissions
            }
        })
            .then(() => {
            getEmployeePermissions(selectedEmployee.id);
            window.globalToast({
                type: 'success',
                title: 'Success',
                description: 'Employer permissions successfully changed.'
            });
        })
            .catch(() => {
            window.globalToast({
                type: 'error',
                title: 'Error',
                description: 'Could not edit employer permissions.'
            });
        });
    }
    return (_jsxs(Box, { p: 4, display: 'grid', gap: 2, children: [_jsxs(Box, { children: [_jsx(Typography, { fontSize: 22, fontWeight: 600, children: t('drawers.employee.permissions.title') }), _jsx(Typography, { mb: 1, fontSize: 14, color: 'text.secondary', children: t('drawers.employee.permissions.subtitle') })] }), userRole === 'superadmin' || userRole === 'admin'
                ? selectedEmployerPermissions &&
                    selectedEmployerPermissions
                        .sort((a, b) => {
                        return (permissionsOrder.indexOf(a.name) -
                            permissionsOrder.indexOf(b.name));
                    })
                        .map((permission) => (_jsx(FormControlLabel, { control: _jsx(Switch, { value: permission.id, checked: permission['has-permission'], onChange: (e) => editEmployerPermission(+e.target.value) }), label: startCase(
                        // @ts-ignore
                        t(`drawers.employee.permissions.options.${permission.name}`)), labelPlacement: 'start', sx: {
                            px: 1.5,
                            margin: 0,
                            border: 1,
                            borderColor: 'grey.400',
                            borderRadius: 3,
                            paddingY: '7px',
                            justifyContent: 'space-between'
                        } }, permission.id)))
                : t(`drawers.employee.permissions.message`)] }));
};
export default EmployeePermissions;
