import { createElement as _createElement } from "react";
import { jsx as _jsx } from "react/jsx-runtime";
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generateImageUrl } from '../../UtilityFunctions';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Autocomplete from '@mui/material/Autocomplete';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Placeholder from '../assets/Placeholder';
import { RoundedTextfield } from './styled';
export default function MultipleSelectChip(props) {
    const { control, error, options } = props;
    const [t] = useTranslation('menu');
    return (_jsx(Controller, { name: 'item_ids', control: control, render: ({ field: { value, onChange } }) => (_jsx(Autocomplete, { multiple: true, disableCloseOnSelect: true, filterSelectedOptions: true, value: value, options: options, isOptionEqualToValue: (option, value) => option.name === value.name, onChange: (_event, newValue) => {
                onChange(newValue);
            }, getOptionLabel: (option) => option.name, renderTags: (value, getTagProps) => value.map((option, index) => (_jsx(Chip, { label: option.name, avatar: option.image_path ? (_jsx(Avatar, { src: generateImageUrl(option.image_path) })) : (_jsx(Avatar, { children: _jsx(Placeholder, {}) })), ...getTagProps({ index }) }))), renderInput: (params) => (_jsx(RoundedTextfield, { ...params, label: t('drawers.modifier.form.usedIn.label'), placeholder: t('drawers.modifier.form.usedIn.placeholder'), error: error })), renderOption: (props, option) => (_createElement(MenuItem, { divider: true, ...props, key: option.id },
                _jsx(ListItemAvatar, { children: option.image_path ? (_jsx(Avatar, { sx: { borderRadius: 1 }, src: generateImageUrl(option.image_path) })) : (_jsx(Avatar, { sx: { borderRadius: 1 }, children: _jsx(Placeholder, {}) })) }),
                _jsx(ListItemText, { primary: option.name, secondary: option.price }))) })) }));
}
