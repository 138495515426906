import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useState, useEffect } from "react";
import { Alert, IconButton, styled } from "@mui/material";
import { MessageOutlined, KeyboardArrowDownOutlined, } from "@mui/icons-material";
const OverflowAlert = styled(Alert)(() => ({
    position: "relative",
    borderRadius: 12,
    "& .MuiAlert-message": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "100%",
        display: "block",
        cursor: "default",
    },
    "& .MuiAlert-action": {
        p: 0,
    },
}));
const ExpandableAlert = ({ description, maxWidth, }) => {
    const messageRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    useEffect(() => {
        const checkOverflow = () => {
            const element = messageRef.current;
            if (element) {
                setIsOverflowing(element.scrollWidth > element.clientWidth ||
                    element.scrollHeight > element.clientHeight);
            }
        };
        checkOverflow();
    }, [maxWidth]);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    return (_jsx("div", { style: { maxWidth }, children: _jsx(OverflowAlert, { severity: "warning", onClick: toggleExpand, icon: _jsx(MessageOutlined, {}), action: isOverflowing ? (_jsx(IconButton, { color: "inherit", size: "small", tabIndex: 0, onClick: toggleExpand, children: _jsx(KeyboardArrowDownOutlined, { sx: {
                        transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 150ms",
                    } }) })) : undefined, sx: {
                ...(isExpanded
                    ? {
                        "& .MuiAlert-message": {
                            whiteSpace: "normal",
                            overflow: "visible",
                        },
                    }
                    : {}),
            }, children: _jsx("div", { ref: messageRef, style: {
                    overflow: isExpanded ? "visible" : "hidden",
                    textOverflow: isExpanded ? "clip" : "ellipsis",
                    whiteSpace: isExpanded ? "normal" : "nowrap",
                }, children: description }) }) }));
};
export default ExpandableAlert;
