import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from 'react-i18next';
import { getCurrency } from '../../UtilityFunctions';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import GridViewOutlined from '@mui/icons-material/GridViewOutlined';
import DragIndicatorOutlined from '@mui/icons-material/DragIndicatorOutlined';
import Visibility from '@mui/icons-material/Visibility';
import DropdownMenu from './DropdownMenu';
export default function ModifierOption(props) {
    const { index, option, showDragHandle, isAssignDisabled, handleEdit, handleViewTree, openAssignModifierModal } = props;
    const [t] = useTranslation('menu');
    const [anchorEl, setAnchorEl] = useState(null);
    const { getValues, setValue } = useFormContext();
    const currency = getCurrency();
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleUnassign = (id) => {
        const updatedOption = {
            ...option,
            modifiersIds: option.modifiersIds.filter((modifier) => modifier.id !== id)
        };
        const options = getValues('options');
        const updatedOptions = options.map((item) => item.id !== option.id ? item : updatedOption);
        setValue('options', updatedOptions, {
            shouldDirty: true,
            shouldValidate: true
        });
    };
    const handleRemove = () => {
        const options = getValues('options');
        options.splice(index, 1);
        setValue('options', options, { shouldDirty: true, shouldValidate: true });
    };
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.option.id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { borderBottom: 1, borderColor: 'divider', children: [_jsxs(ListItem, { secondaryAction: _jsx(IconButton, { edge: 'end', onClick: (e) => setAnchorEl(e.currentTarget), children: _jsx(MoreVertOutlined, {}) }), ref: setNodeRef, style: style, ...attributes, children: [_jsx(Tooltip, { title: !showDragHandle
                                    ? t('drawers.modifier.form.options.dragTooltip')
                                    : '', children: _jsx("span", { children: _jsx(ListItemIcon, { sx: {
                                            cursor: 'pointer',
                                            pointerEvents: showDragHandle ? 'auto' : 'none'
                                        }, ...listeners, children: _jsx(DragIndicatorOutlined, {}) }) }) }), _jsx(ListItemAvatar, { children: _jsx(IconButton, { disabled: !option.modifiersIds?.flat().length, onClick: handleViewTree, children: _jsx(Visibility, {}) }) }), _jsx(ListItemText, { primary: option.name }), _jsx(ListItemText, { primary: `${currency} ${option.price}`, sx: { textAlign: 'end', color: 'text.secondary' } })] }, option.name), option.contain_alcohol && (_jsx(Alert, { severity: 'warning', sx: { mb: 0.5, borderRadius: 3 }, children: t('drawers.modifier.form.options.warningAlert') }))] }), !!option.modifiersIds?.length && (_jsx(List, { component: 'div', disablePadding: true, children: option.modifiersIds.map((modifier) => (_jsx(ListItem, { sx: { pl: 4 }, secondaryAction: _jsx(IconButton, { onClick: () => handleUnassign(modifier.id), children: _jsx(DeleteOutlined, {}) }), children: _jsx(ListItemText, { primary: modifier.name }) }, modifier.id))) })), _jsx(DropdownMenu, { anchorEl: anchorEl, onClose: handleClose, options: [
                    {
                        label: t('drawers.modifier.form.options.dropdownOptions.assign'),
                        icon: _jsx(GridViewOutlined, {}),
                        disabled: isAssignDisabled,
                        tooltip: t('drawers.modifier.form.options.dropdownOptions.assignTooltip'),
                        action: () => {
                            openAssignModifierModal();
                            handleClose();
                        }
                    },
                    {
                        label: t('drawers.modifier.form.options.dropdownOptions.edit'),
                        icon: _jsx(EditOutlined, {}),
                        action: () => {
                            handleEdit();
                            handleClose();
                        }
                    },
                    {
                        label: t('drawers.modifier.form.options.dropdownOptions.remove'),
                        icon: _jsx(DeleteOutlined, {}),
                        action: handleRemove
                    }
                ] })] }));
}
