import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NewBranchContext } from '../NewBranchProvider';
import { validationDiv } from './utilities';
import PickUpSettings from './PickUpSettings';
import DeliverySettings from './DeliverySettings';
import { OSOuterContainer } from './OSCustomComponents';
import { Typography } from '@mui/material';
function DeliveryPickupSettings() {
    const [t] = useTranslation('settings');
    const { validationErrors } = useContext(NewBranchContext);
    return (_jsxs(OSOuterContainer, { children: [_jsx(Typography, { sx: {
                    paddingLeft: '1.5rem',
                    fontSize: '0.875rem',
                    marginBottom: '1rem'
                }, children: t('deliveryPickup.subtitle') }), _jsx(DeliverySettings, {}), _jsx("hr", { className: 'flex mx-6 mt-2 mb-2' }), _jsx(PickUpSettings, {}), validationDiv(validationErrors?.delivery_pickup_option)] }));
}
export default DeliveryPickupSettings;
