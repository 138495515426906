import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { fetchApi } from '../../../config/Api';
import { getErrorMessage, getRestaurantId } from '../../UtilityFunctions';
import { DayPicker } from 'react-day-picker';
// @ts-ignore
import 'react-day-picker/dist/style.css';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { blue } from '@mui/material/colors';
import PageHeader from '../../components/PageHeader';
import ToggleGroup from '../../components/ToggleGroup';
import BestSellingItemsChart from './BestSellingItemsChart';
import ChartGrid from './ChartGrid';
import BestSellingItemsTable from './BestSellingItemsTable';
function Overview() {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [value, setValue] = useState('today');
    const [anchorEl, setAnchorEl] = useState(null);
    const [range, setRange] = useState();
    const [dateRange, setDateRange] = useState({
        fromDate: moment().format('DD/MM/YYYY'),
        toDate: moment().format('DD/MM/YYYY')
    });
    const [t] = useTranslation('store');
    async function fetchBestSellingItems() {
        try {
            setIsLoading(true);
            const restaurantId = getRestaurantId();
            const { fromDate, toDate } = dateRange;
            const res = await fetchApi({
                url: `/restaurant/${restaurantId}/best-selling-items?fromDate=${fromDate}&toDate=${toDate}`
            });
            setData(res.data);
        }
        catch (err) {
            window.globalToast({
                type: 'error',
                title: 'Error',
                description: getErrorMessage(err.response)
            });
        }
        finally {
            setIsLoading(false);
        }
    }
    const handleChange = (newValue, event) => {
        if (newValue !== null)
            setValue(newValue);
        setRange(undefined);
        switch (newValue) {
            case 'yesterday':
                setDateRange({
                    fromDate: moment().subtract(1, 'day').format('DD/MM/YYYY'),
                    toDate: moment().subtract(1, 'day').format('DD/MM/YYYY')
                });
                break;
            case 'today':
                setDateRange({
                    fromDate: moment().format('DD/MM/YYYY'),
                    toDate: moment().format('DD/MM/YYYY')
                });
                break;
            case 'last7Days':
                setDateRange({
                    fromDate: moment().subtract(7, 'days').format('DD/MM/YYYY'),
                    toDate: moment().format('DD/MM/YYYY')
                });
                break;
            case 'last28Days':
                setDateRange({
                    fromDate: moment().subtract(1, 'month').date(28).format('DD/MM/YYYY'),
                    toDate: moment().format('DD/MM/YYYY')
                });
                break;
            case 'lastMonth':
                setDateRange({
                    fromDate: moment()
                        .subtract(1, 'month')
                        .startOf('month')
                        .format('DD/MM/YYYY'),
                    toDate: moment()
                        .subtract(1, 'month')
                        .endOf('month')
                        .format('DD/MM/YYYY')
                });
                break;
            default:
                break;
        }
        // @ts-expect-error
        if (event.currentTarget.value === 'custom') {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleApply = () => {
        if (!range || !range.from || !range.to)
            return;
        const fromDate = moment(range.from).format('DD/MM/YYYY');
        const toDate = moment(range.to).format('DD/MM/YYYY');
        setDateRange({
            fromDate,
            toDate
        });
        setAnchorEl(null);
    };
    useEffect(() => {
        fetchBestSellingItems();
    }, [dateRange.fromDate, dateRange.toDate]);
    const showRangeLabel = range && dateRange.toDate === moment(range?.to).format('DD/MM/YYYY');
    const rangeLabel = `${range?.from ? moment(range.from).format('MMM DD') : ''}${range?.to ? ` - ${moment(range.to).format('MMM DD')}` : ''}`;
    return (_jsxs(Box, { gap: 4, display: 'flex', flexDirection: 'column', children: [_jsx("div", { children: _jsx(PageHeader, { title: t('activity.overview.title'), subtitle: t('activity.overview.subtitle') }) }), _jsxs(Box, { children: [_jsx(ToggleGroup, { value: value, onChange: handleChange, options: [
                            {
                                label: t('activity.overview.filter.yesterday'),
                                value: 'yesterday'
                            },
                            { label: t('activity.overview.filter.today'), value: 'today' },
                            {
                                label: t('activity.overview.filter.7d'),
                                value: 'last7Days'
                            },
                            ...(moment().date() > 7
                                ? [
                                    {
                                        label: t('activity.overview.filter.28d'),
                                        value: 'last28Days'
                                    }
                                ]
                                : []),
                            {
                                label: t('activity.overview.filter.lastMonth'),
                                value: 'lastMonth'
                            },
                            {
                                label: showRangeLabel
                                    ? rangeLabel
                                    : t('activity.overview.filter.custom'),
                                value: 'custom'
                            }
                        ], borderRadius: 8 }), _jsxs(Popover, { anchorEl: anchorEl, open: Boolean(anchorEl), onClose: () => setAnchorEl(null), anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: -193
                        }, slotProps: {
                            paper: {
                                sx: {
                                    p: 2,
                                    borderRadius: 3,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end'
                                }
                            }
                        }, children: [_jsx(DayPicker, { mode: 'range', selected: range, onSelect: setRange, modifiersStyles: { selected: { backgroundColor: blue[700] } } }), _jsx(Button, { size: 'small', variant: 'outlined', onClick: handleApply, disabled: !range || !range.from || !range.to, children: t('activity.overview.customFilterBtnLabel') })] })] }), _jsxs(Box, { mb: 4, pt: 4, pb: { xs: 0, sm: 4 }, px: { xs: 0, sm: 4 }, border: 1, borderRadius: 3, borderColor: 'divider', display: 'flex', flexDirection: 'column', minHeight: 470, justifyContent: 'space-between', children: [_jsxs(Box, { mb: 1, px: { xs: 2, sm: 0 }, children: [_jsxs(Typography, { fontSize: 14, children: [_jsx(Typography, { component: 'span', fontSize: 14, fontWeight: 600, children: t('activity.overview.topSellingChart.title') }), ' ', "\u2022 ", range ? rangeLabel : _.startCase(value)] }), _jsxs(Typography, { flexGrow: 1, fontSize: 20, fontWeight: 600, children: [data?.result.reduce((acc, item) => (acc += item.quantity), 0), ' ', t('activity.overview.topSellingChart.subtitle')] })] }), isLoading ? (_jsx(CircularProgress, { sx: { m: 'auto' } })) : data && data.result.length > 0 ? (_jsxs(_Fragment, { children: [_jsx(BestSellingItemsChart, { data: data }), _jsx(BestSellingItemsTable, { data: data })] })) : (_jsx(Typography, { m: 'auto', children: t('activity.emptyText') }))] }), _jsx(ChartGrid, { dateRange: dateRange })] }));
}
export default Overview;
