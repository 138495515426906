import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useContext } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { MenuContext } from '../context';
import { ActionType } from '../context/types';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import AddOutlined from '@mui/icons-material/AddOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined';
import MenuBookOutlined from '@mui/icons-material/MenuBookOutlined';
import { RoundedButton } from './styled';
const getAvailableDay = (menu) => {
    const today = moment().format('dddd').toLowerCase();
    const days = {
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 7
    };
    const stringfy = (timeline) => {
        const day = _.startCase(timeline.day).substring(0, 3);
        const startTime = moment(timeline.start_time, 'HH:mm:ss').format('h:mm A');
        const endTime = moment(timeline.end_time, 'HH:mm:ss').format('h:mm A');
        return `${today === timeline.day ? 'Today' : day}: ${startTime} - ${endTime}`;
    };
    const todayTimeline = menu.new_menu_hours.find((timeline) => timeline.day === today);
    if (todayTimeline)
        return stringfy(todayTimeline);
    const nextDayTimeline = menu.new_menu_hours.find((timeline) => days[timeline.day] > days[today]);
    if (nextDayTimeline)
        return stringfy(nextDayTimeline);
    const prevDayTimeline = menu.new_menu_hours.find((timeline) => days[timeline.day] < days[today]);
    if (prevDayTimeline)
        return stringfy(prevDayTimeline);
};
export default function MenuSelect() {
    const [t] = useTranslation('menu');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { toggleDrawer, menus, selectedMenu, dispatch, loadingMenus } = useContext(MenuContext);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleEditClick = (menu) => {
        dispatch({
            type: ActionType.SET_EDIT_MENU,
            payload: menu
        });
        setAnchorEl(null);
        toggleDrawer('isMenuDrawerOpen');
    };
    return (_jsxs(Box, { padding: 3, display: 'flex', alignItems: 'center', boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.07)', sx: (theme) => ({
            bgcolor: '#fff',
            gap: '20px',
            [theme.breakpoints.up('lg')]: {
                gridArea: '1 / 1 / 2 / 2'
            }
        }), children: [_jsx(List, { disablePadding: true, sx: { flexGrow: 1 }, children: loadingMenus ? (_jsx(Skeleton, { variant: 'rectangular', width: 310, height: 56 })) : selectedMenu ? (_jsx(ListItem, { sx: {
                        height: 56,
                        backgroundColor: 'grey.50',
                        borderRadius: '12px',
                        paddingBlock: 0,
                        display: 'flex',
                        alignItems: 'center'
                    }, disablePadding: true, secondaryAction: _jsx(IconButton, { onClick: () => handleEditClick(selectedMenu), children: _jsx(EditOutlined, { color: 'action' }) }), children: _jsxs(ListItemButton, { disableRipple: true, onClick: handleClickListItem, sx: {
                            height: 56,
                            paddingBlock: 0,
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '12px'
                        }, children: [_jsx(ArrowDropDownOutlined, { color: 'action' }), _jsx(ListItemText, { primary: selectedMenu.name, secondary: getAvailableDay(selectedMenu), slotProps: {
                                    primary: {
                                        sx: {
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            marginLeft: '8px'
                                        }
                                    },
                                    secondary: {
                                        sx: {
                                            marginLeft: '8px',
                                            fontSize: '14px',
                                            color: 'text.secondary'
                                        }
                                    }
                                } })] }) }, selectedMenu.id)) : (_jsxs(ListItem, { children: [_jsx(ListItemAvatar, { children: _jsx(MenuBookOutlined, {}) }), _jsx(ListItemText, { primary: t('overview.menuDropdown.emptyText.primary'), secondary: t('overview.menuDropdown.emptyText.secondary') })] })) }), _jsxs(Menu, { anchorEl: anchorEl, open: open, onClose: handleClose, slotProps: {
                    paper: {
                        sx: (theme) => ({
                            borderRadius: 3,
                            width: '100%',
                            maxWidth: 'calc(440px - 48px)',
                            [theme.breakpoints.down('md')]: {
                                left: '16px !important',
                                maxWidth: 'calc(100% - 32px)'
                            }
                        })
                    }
                }, anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                }, children: [menus.map((menu) => (_jsxs(MenuItem, { divider: true, selected: menu.id === selectedMenu?.id, onClick: () => {
                            dispatch({
                                type: ActionType.SET_SELECTED_MENU,
                                payload: menu
                            });
                            setAnchorEl(null);
                        }, sx: { py: 1.5, justifyContent: 'space-between', fontWeight: 600 }, children: [_jsxs(Box, { children: [_jsx(Typography, { variant: 'body1', sx: {
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            marginLeft: '8px'
                                        }, children: menu.name }), _jsx(Typography, { sx: {
                                            fontSize: '14px',
                                            color: 'text.secondary',
                                            marginLeft: '8px'
                                        }, children: getAvailableDay(menu) })] }), _jsx(IconButton, { edge: 'end', onClick: () => handleEditClick(menu), children: _jsx(EditOutlined, {}) })] }, menu.id))), _jsx(RoundedButton, { variant: 'contained', startIcon: _jsx(AddOutlined, {}), onClick: () => {
                            toggleDrawer('isMenuDrawerOpen');
                            setAnchorEl(null);
                        }, sx: {
                            mx: 4,
                            my: 2,
                            alignSelf: 'flex-start'
                        }, children: t('overview.menuDropdown.newMenuBtnLabel') })] }), _jsx(IconButton, { sx: { backgroundColor: 'grey.50' }, onClick: () => toggleDrawer('isMenuDrawerOpen'), children: _jsx(AddOutlined, {}) })] }));
}
