import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";
export default function DisplayField(props) {
    const { icon, primaryText, secondaryText } = props;
    const height = primaryText && secondaryText ? 74 : 56;
    return (_jsxs(Box, { sx: {
            px: 2,
            height,
            borderRadius: 2,
            bgcolor: grey[100],
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            gap: 2,
        }, children: [icon, _jsxs(Box, { display: "flex", flexDirection: "column", children: [secondaryText && (_jsx(Typography, { fontSize: 14, color: "text.secondary", children: secondaryText })), primaryText && _jsx(Typography, { fontWeight: 600, children: primaryText })] })] }));
}
