import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useEmployeesContext } from '../EmployeeProvider';
import { fetchApi } from '../../../config/Api';
import { getNetworkId, getRestaurantId } from '../../UtilityFunctions';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CircularProgress from '@mui/material/CircularProgress';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import TabletOutlinedIcon from '@mui/icons-material/TabletOutlined';
import DeliveryDiningOutlinedIcon from '@mui/icons-material/DeliveryDiningOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SideDrawer from '../../components/SideDrawer';
import ToggleGroup from '../../components/ToggleGroup';
import EmployeePermissions from './EmployeePermissions';
import CloseDrawerDialog from '../../menu/components/CloseDrawerDialog';
import { RoundedTextField, PrimaryButton, SecondaryButton } from '../../components/styled';
const Schema = z.object({
    status: z.string(),
    firstname: z.string().min(1),
    lastname: z.string().min(1),
    email: z.string().email(),
    phone: z.string().min(1),
    role: z.array(z.enum(['manager', 'delivery', 'online', 'pos', 'store', 'user'])),
    notes: z.string().optional()
});
const defaultValues = {
    status: 'false',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    role: [],
    notes: ''
};
export default function EmployeeDrawer() {
    const [t] = useTranslation('employees');
    const closeDialogRef = useRef();
    const [view, setView] = useState('profile');
    const [isUpdating, setIsUpdating] = useState(false);
    const { isDrawerOpen, setIsDrawerOpen, selectedEmployee, setSelectedEmployee, getEmployees, selectedEmployeeRestaurants, setSelectedEmployeeRestaurants } = useEmployeesContext();
    const { reset, control, register, handleSubmit, formState: { errors, isDirty } } = useForm({
        defaultValues,
        resolver: zodResolver(Schema)
    });
    const onSubmit = async (data) => {
        if (!selectedEmployee)
            return;
        setIsUpdating(true);
        let networkId = getNetworkId();
        let restaurantId = getRestaurantId();
        fetchApi({
            method: 'POST',
            url: `/employees/update?restaurant=${restaurantId}&network=${networkId}&employee_id=${selectedEmployee.id}&guest_notes=${data.notes}&blocked=${data.status}`
        })
            .then(() => {
            setIsDrawerOpen(false);
            setSelectedEmployeeRestaurants([]);
            getEmployees();
            window.globalToast({
                title: 'Success',
                type: 'success',
                description: 'Employee updated successfully'
            });
        })
            .catch(() => {
            window.globalToast({
                title: 'Error',
                type: 'error',
                description: 'Something went wrong. Please reload page and try again!'
            });
        })
            .finally(() => {
            setIsUpdating(false);
        });
    };
    const handleClose = () => {
        if (isDirty) {
            closeDialogRef.current?.open();
        }
        else {
            setIsDrawerOpen(false);
            setSelectedEmployee(null);
            setSelectedEmployeeRestaurants([]);
            setView('profile');
            reset(defaultValues);
        }
    };
    const handleLeave = () => {
        setIsDrawerOpen(false);
        setSelectedEmployee(null);
        setSelectedEmployeeRestaurants([]);
        setView('profile');
        reset(defaultValues);
    };
    useEffect(() => {
        if (selectedEmployee) {
            reset({
                status: selectedEmployee.blocked.toString(),
                firstname: selectedEmployee.firstname,
                lastname: selectedEmployee.lastname,
                email: selectedEmployee.email,
                phone: selectedEmployee.telephone ?? '',
                role: selectedEmployee.roles.map((role) => role.name),
                notes: selectedEmployee.guest_notes ?? ''
            });
        }
    }, [reset, selectedEmployee]);
    return (_jsxs(_Fragment, { children: [_jsx(CloseDrawerDialog, { ref: closeDialogRef, onLeave: handleLeave }), _jsxs(SideDrawer, { isOpen: isDrawerOpen, onClose: handleClose, primaryText: selectedEmployee
                    ? `${selectedEmployee.firstname} ${selectedEmployee.lastname}`
                    : 'New Employee', secondaryText: t('drawers.employee.description'), children: [selectedEmployee?.roles.some((role) => role.name === 'manager') && (_jsxs(_Fragment, { children: [_jsx(Box, { px: 4, py: 3, children: _jsx(ToggleGroup, { fullWidth: true, value: view, onChange: (val) => setView(val), options: [
                                        { label: t('drawers.employee.toggle.1'), value: 'profile' },
                                        {
                                            label: t('drawers.employee.toggle.2'),
                                            value: 'permissions'
                                        }
                                    ] }) }), _jsx(Divider, {})] })), view === 'profile' && (_jsxs("form", { onSubmit: handleSubmit(onSubmit), style: {
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column'
                        }, children: [_jsxs(Box, { p: 4, display: 'grid', gap: 3, children: [_jsxs(Box, { children: [_jsx(Typography, { fontWeight: 600, children: "Menus" }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', children: t('drawers.employee.description') })] }), _jsx(Controller, { name: 'status', control: control, render: ({ field: { value, onChange }, fieldState: { error } }) => (_jsxs(RoundedTextField, { select: true, fullWidth: true, label: t('drawers.employee.fields.status.label'), slotProps: { select: { displayEmpty: true } }, value: value, onChange: (e) => onChange(e.target.value), error: !!error, children: [_jsxs(MenuItem, { value: 'false', sx: (theme) => ({
                                                        '& .MuiSvgIcon-root': {
                                                            marginRight: theme.spacing(1.5)
                                                        }
                                                    }), children: [_jsx(CheckOutlinedIcon, {}), ' ', t('drawers.employee.fields.status.option.1')] }), _jsxs(MenuItem, { value: 'true', sx: (theme) => ({
                                                        '& .MuiSvgIcon-root': {
                                                            marginRight: theme.spacing(1.5)
                                                        }
                                                    }), children: [_jsx(CloseOutlinedIcon, {}), ' ', t('drawers.employee.fields.status.option.2')] })] })) }), _jsxs(Box, { display: 'flex', gap: 3, children: [_jsx(RoundedTextField, { disabled: true, fullWidth: true, label: t('drawers.employee.fields.firstname.label'), error: !!errors.firstname, ...register('firstname') }), _jsx(RoundedTextField, { disabled: true, fullWidth: true, label: t('drawers.employee.fields.lastname.label'), error: !!errors.lastname, ...register('lastname') })] }), _jsx(RoundedTextField, { disabled: true, fullWidth: true, label: t('drawers.employee.fields.email.label'), error: !!errors.email, ...register('email') }), _jsx(RoundedTextField, { disabled: true, fullWidth: true, label: t('drawers.employee.fields.phone.label'), error: !!errors.phone, ...register('phone') }), _jsx(Controller, { name: 'role', control: control, render: ({ field: { value, onChange }, fieldState: { error } }) => (_jsx(Autocomplete, { multiple: true, disabled: true, disableCloseOnSelect: true, filterSelectedOptions: true, value: value, options: ['manager', 'online', 'delivery', 'user'], onChange: (_event, newValue) => {
                                                onChange(newValue);
                                            }, renderTags: (value, getTagProps) => value.map((option, index) => (_jsx(Chip, { label: startCase(t(`tableRow.role.${option}`)), icon: option === 'online' ? (_jsx(TabletOutlinedIcon, {})) : option === 'delivery' ? (_jsx(DeliveryDiningOutlinedIcon, {})) : (_jsx(PersonOutlineIcon, {})), ...getTagProps({ index }) }))), renderInput: (params) => (_jsx(RoundedTextField, { ...params, disabled: true, label: t('drawers.employee.fields.role.label'), error: !!error })), renderOption: (props, option) => (_createElement(MenuItem, { divider: true, ...props, key: option },
                                                _jsx(ListItemIcon, { children: option === 'manager' ? (_jsx(PersonOutlineIcon, {})) : option === 'online' ? (_jsx(TabletOutlinedIcon, {})) : (_jsx(DeliveryDiningOutlinedIcon, {})) }),
                                                _jsx(ListItemText, { primary: option === 'online' ? 'Kitchen' : option }))) })) }), _jsx(RoundedTextField, { fullWidth: true, multiline: true, rows: 3, label: t('drawers.employee.fields.notes.label'), placeholder: t('drawers.employee.fields.notes.placeholder'), slotProps: { inputLabel: { shrink: true } }, helperText: t('drawers.employee.fields.notes.helperText'), error: !!errors.notes, ...register('notes') })] }), !!selectedEmployeeRestaurants.length && (_jsxs(_Fragment, { children: [_jsx(Divider, { sx: {
                                            height: '8px',
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                            borderTop: 1,
                                            borderColor: 'divider'
                                        } }), _jsxs(Box, { p: 4, display: 'grid', gap: 3, children: [_jsx(Typography, { fontSize: 22, fontWeight: 600, children: t('drawers.employee.restaurants.title') }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', children: t('drawers.employee.restaurants.description') }), selectedEmployeeRestaurants.map((restaurant) => (_jsxs(Box, { border: 1, borderColor: 'divider', sx: {
                                                    px: 2,
                                                    height: 92,
                                                    borderRadius: '12px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 2
                                                }, children: [_jsx(Box, { width: 56, height: 56, borderRadius: '50%', component: 'img', alt: 'restaurant image', src: restaurant.logo
                                                            ? restaurant.logo
                                                            : '/images/restaurants/logo.png' }), _jsxs(Box, { children: [_jsx(Typography, { fontWeight: 600, fontSize: 20, children: restaurant.name }), _jsxs(Box, { display: 'flex', gap: 0.5, alignItems: 'center', children: [_jsx(LocationOnIcon, { fontSize: 'small', color: 'action' }), _jsxs(Typography, { color: 'text.secondary', sx: (theme) => ({
                                                                            [theme.breakpoints.up('sm')]: {
                                                                                maxWidth: 300,
                                                                                overflow: 'hidden',
                                                                                whiteSpace: 'nowrap',
                                                                                textOverflow: 'ellipsis'
                                                                            }
                                                                        }), children: [restaurant.address.street, ",", ' ', restaurant.address.zip, ", ", restaurant.address.state] })] })] })] }, restaurant.id)))] })] })), _jsxs(Box, { sx: {
                                    p: 2,
                                    mt: 'auto',
                                    position: 'sticky',
                                    bottom: 0,
                                    bgcolor: 'white',
                                    boxShadow: '0px -4px 10px 0px #00000026',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gap: 2,
                                    zIndex: 1
                                }, children: [_jsx(SecondaryButton, { type: 'button', variant: 'outlined', sx: { ml: 'auto' }, onClick: handleClose, children: t('drawers.employee.secondaryBtnLabel') }), _jsx(PrimaryButton, { type: 'submit', variant: 'contained', disabled: !isDirty || isUpdating, children: isUpdating ? (_jsx(CircularProgress, { size: '24px' })) : (t('drawers.employee.primaryBtnLabel')) })] })] })), view === 'permissions' && _jsx(EmployeePermissions, {})] })] }));
}
