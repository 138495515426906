import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewBranchContext } from '../NewBranchProvider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import PercentIcon from '@mui/icons-material/Percent';
import { OSOuterContainer, OSInnerContainer, OSContentBox, OSTextWithSelectBox, OSText, OSInlineTooltip } from './OSCustomComponents';
import { validationMessages } from './utilities';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ClickAwayListener, useTheme } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
function TaxationCurrencySettings() {
    const [t] = useTranslation('settings');
    const { generalSettings, setGeneralSettings, validationErrors, filterValidation } = useContext(NewBranchContext);
    const theme = useTheme();
    const [tooltipItems, setTooltipItems] = useState([
    // item1: true,
    ]);
    const handleTooltipToggle = (key, bool = false) => {
        setTooltipItems({
            ...tooltipItems,
            [key]: bool
        });
    };
    const OSTooltipComponent = (key, title) => {
        return (_jsx(ClickAwayListener, { onClickAway: () => {
                handleTooltipToggle(key, false);
            }, children: _jsx(Box, { sx: {
                    height: '0 !important',
                    width: '0 !important',
                    maxHeight: '0 !important',
                    maxWidth: '0 !important'
                }, children: _jsx(OSInlineTooltip
                // @ts-ignore
                , { 
                    // @ts-ignore
                    open: !!tooltipItems[key], PopperProps: {
                        disablePortal: true
                    }, onClose: () => {
                        handleTooltipToggle(key, false);
                    }, disableFocusListener: true, disableTouchListener: true, placement: 'left', sx: {
                        [theme.breakpoints.down('sm')]: {
                            right: '0'
                        }
                    }, title: title, children: _jsx(InfoOutlined, { onClick: () => {
                            handleTooltipToggle(key, true);
                        }, onMouseOver: () => {
                            handleTooltipToggle(key, true);
                        }, onMouseLeave: () => {
                            handleTooltipToggle(key, false);
                        } }) }) }) }));
    };
    return (generalSettings && (_jsx(_Fragment, { children: _jsx(OSOuterContainer, { children: _jsxs(OSInnerContainer, { children: [_jsxs(Box, { sx: {
                            paddingLeft: '0.5rem',
                            marginBottom: '1rem',
                            position: 'relative',
                            display: 'flex'
                        }, children: [_jsx(Typography, { sx: {
                                    fontSize: '0.875rem',
                                    [theme.breakpoints.down('sm')]: {
                                        width: '90%',
                                        '.MuiSvgIcon-root MuiSvgIcon-fontSizeMedium': {
                                            right: 0
                                        }
                                    }
                                }, children: t('taxation.description') }), OSTooltipComponent('taxation_info', t('taxation.tooltip'))] }), _jsxs(OSContentBox, { children: [_jsx(OSTextWithSelectBox, { children: _jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { id: 'demo-simple-select-label', children: t('taxation.currency.label') }), _jsx(Select, { labelId: 'demo-simple-select-label', id: 'demo-simple-select', error: !!validationErrors?.restaurant_currency?.length, value: generalSettings.restaurant_currency, label: t('taxation.currency.label'), onChange: (e) => {
                                                setGeneralSettings({
                                                    ...generalSettings,
                                                    restaurant_currency: e.target.value
                                                });
                                                filterValidation('restaurant_currency');
                                            }, defaultValue: 'USD', children: t('taxation.currency.options', {
                                                returnObjects: true
                                            }).map((currency) => (_jsx(MenuItem, { value: currency, children: currency }, currency))) })] }) }), _jsx(OSTextWithSelectBox, { children: _jsx(OSText, { InputProps: {
                                        startAdornment: (_jsx(InputAdornment, { position: 'start', children: _jsx(PercentIcon, {}) }))
                                    }, required: true, error: !!validationErrors?.restaurant_sales_tax?.length, id: 'outlined-required_', label: t('taxation.tax'), fullWidth: true, defaultValue: generalSettings.restaurant_sales_tax, helperText: validationMessages(validationErrors?.restaurant_sales_tax), onChange: (e) => {
                                        setGeneralSettings({
                                            ...generalSettings,
                                            restaurant_sales_tax: e.target.value
                                        });
                                        filterValidation('restaurant_sales_tax');
                                    } }) })] })] }) }) })));
}
export default TaxationCurrencySettings;
