import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuContext } from '../context';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Table, TableBody, TableHead, TableRow, TableCell, Typography, Box, Checkbox, useTheme, useMediaQuery } from '@mui/material';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import SearchField from './SearchField';
import { PrimaryButton, SecondaryButton } from './styled';
export default function AssignMenuModal({ isOpen, setIsOpen, setValue, selectedMenus }) {
    const [t] = useTranslation('menu');
    const [searchTerm, setSearchTerm] = useState('');
    const [draftMenus, setDraftMenus] = useState(selectedMenus);
    const { menus } = useContext(MenuContext);
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const handleClose = () => {
        setIsOpen(false);
        setValue('menus', selectedMenus);
    };
    const handleCheckbox = (val) => {
        if (draftMenus.includes(val)) {
            setDraftMenus((prev) => prev.filter((item) => item !== val));
        }
        else {
            setDraftMenus((prev) => [...prev, val]);
        }
    };
    const handleSave = () => {
        setValue('menus', draftMenus, { shouldDirty: true });
        setIsOpen(false);
    };
    useEffect(() => {
        if (selectedMenus) {
            setDraftMenus(selectedMenus);
        }
    }, [selectedMenus]);
    return (_jsxs(Dialog, { fullScreen: isMdDown, open: isOpen, onClose: handleClose, PaperProps: {
            sx: (theme) => ({
                width: 800,
                [theme.breakpoints.up('md')]: {
                    borderRadius: 3
                }
            })
        }, children: [_jsx(DialogTitle, { component: 'div', display: 'flex', justifyContent: 'flex-end', children: _jsx(IconButton, { onClick: handleClose, children: _jsx(CloseOutlined, {}) }) }), _jsxs(DialogContent, { sx: { mb: 2 }, children: [_jsxs(Box, { mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', children: [_jsx(Typography, { variant: 'h1', fontSize: 24, children: t('dialogs.assignMenu.title') }), _jsx(SearchField, { placeholder: t('dialogs.assignMenu.searchFieldPlaceholder'), value: searchTerm, onChange: setSearchTerm })] }), _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, {}), _jsx(TableCell, { children: t('dialogs.assignMenu.columns.name') }), _jsx(TableCell, { children: t('dialogs.assignMenu.columns.options') })] }) }), _jsx(TableBody, { children: menus
                                    .filter((menu) => menu.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                    .map((menu) => (_jsxs(TableRow, { hover: true, onClick: () => handleCheckbox(menu.id), selected: draftMenus.includes(menu.id), children: [_jsx(TableCell, { sx: { width: 0 }, children: _jsx(Checkbox, { checked: draftMenus.includes(menu.id) }) }), _jsx(TableCell, { children: menu.name }), _jsx(TableCell, {})] }, menu.id))) })] })] }), _jsxs(DialogActions, { children: [_jsx(SecondaryButton, { variant: 'outlined', onClick: handleClose, children: t('dialogs.assignMenu.secondaryBtnLabel') }), _jsx(PrimaryButton, { variant: 'contained', onClick: handleSave, children: t('dialogs.assignMenu.primaryBtnLabel') })] })] }));
}
