import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState, createElement } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchApi } from "../../../config/Api";
export default function StripePricingTable() {
    const [clientSecret, setClientSecret] = useState(null);
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const id = searchParams.get("id");
        fetchApi({
            method: "GET",
            url: `/network/${id}/pricing_table/sessions`,
        }).then((res) => {
            if ("client_secret" in res.data) {
                setClientSecret(res.data.client_secret);
            }
        });
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return clientSecret ? (createElement("stripe-pricing-table", {
        "pricing-table-id": "prctbl_1PhJdrLdxHzziYUHmMhohie7",
        "publishable-key": "pk_test_51Mo8KELdxHzziYUHshT0SUUGMl5GKY6cUd4cY2nITIO6pDPk6IO6a9ETcGCZOwfGtYSOzcN7hdfB6epJqxXf4WNk00c8lEAgk8",
        "customer-session-client-secret": clientSecret,
    })) : (_jsx(Box, { py: 5, display: "flex", justifyContent: "center", children: _jsx(CircularProgress, {}) }));
}
