import GeneralSettings from './GeneralSettings';
import PaymentSettings from './PaymentSettings';
import PickUpSettings from './PickUpSettings';
import DeliverySettings from './DeliverySettings';
import DeliveryPickupSettings from './DeliveryPickupSettings';
import DeliveryTimelines from './DeliveryTimelines';
import PickupTimelines from './PickupTimelines';
import TaxationCurrencySettings from './TaxationCurrencySettings';
import AddressSettings from '../../components/restaurant/AddressSettings';
export default {
    GeneralSettings,
    PaymentSettings,
    PickUpSettings,
    DeliverySettings,
    DeliveryPickupSettings,
    DeliveryTimelines,
    PickupTimelines,
    AddressSettings,
    TaxationCurrencySettings
};
