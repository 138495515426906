import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuContext } from '../context';
import { ActionType } from '../context/types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddOutlined from '@mui/icons-material/AddOutlined';
import MenuBookOutlined from '@mui/icons-material/MenuBookOutlined';
import CategoryOutlined from '@mui/icons-material/CategoryOutlined';
import RamenDiningOutlined from '@mui/icons-material/RamenDiningOutlined';
import GridViewOutlined from '@mui/icons-material/GridViewOutlined';
import { styled } from '@mui/material';
import DropdownMenu from './DropdownMenu';
const Button = styled('button')(() => ({
    color: '#fff',
    backgroundColor: '#2196F3',
    borderRadius: '12px',
    width: '32px',
    height: '32px'
}));
function Header() {
    const [t] = useTranslation('menu');
    const [anchorEl, setAnchorEl] = useState(null);
    const { toggleDrawer, dispatch, selectedMenu, selectedPanel } = useContext(MenuContext);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuItemClick = (drawer) => {
        toggleDrawer(drawer);
        setAnchorEl(null);
    };
    return (_jsxs(Box, { sx: {
            bgcolor: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingX: 2,
            boxShadow: '0px 3px 0px 0px #F6F2F8',
            zIndex: 1
        }, children: [_jsxs(Tabs, { value: selectedPanel, children: [_jsx(Tab, { label: t('topbar.tabs.overview'), value: 'overview', sx: { height: 56, fontSize: '14px', textTransform: 'none' }, onClick: () => dispatch({
                            type: ActionType.SET_SELECTED_PANEL,
                            payload: 'overview'
                        }) }), _jsx(Tab, { label: t('topbar.tabs.modifiers'), value: 'modifiers', sx: { height: 56, fontSize: '14px', textTransform: 'none' }, onClick: () => dispatch({
                            type: ActionType.SET_SELECTED_PANEL,
                            payload: 'modifiers'
                        }) })] }), _jsx(Button, { onClick: handleClick, children: _jsx(AddOutlined, {}) }), _jsx(DropdownMenu, { anchorEl: anchorEl, onClose: () => setAnchorEl(null), options: [
                    {
                        label: t('topbar.addBtnDropdown.menu'),
                        icon: _jsx(MenuBookOutlined, {}),
                        action: () => handleMenuItemClick('isMenuDrawerOpen')
                    },
                    {
                        label: t('topbar.addBtnDropdown.category'),
                        icon: _jsx(CategoryOutlined, {}),
                        action: () => handleMenuItemClick('isCategoryDrawerOpen'),
                        disabled: !selectedMenu
                    },
                    {
                        label: t('topbar.addBtnDropdown.item'),
                        icon: _jsx(RamenDiningOutlined, {}),
                        action: () => handleMenuItemClick('isItemDrawerOpen')
                    },
                    {
                        label: t('topbar.addBtnDropdown.modifier'),
                        icon: _jsx(GridViewOutlined, {}),
                        action: () => handleMenuItemClick('isModifierDrawerOpen')
                    }
                ] })] }));
}
export default Header;
