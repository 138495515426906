export var ActionType;
(function (ActionType) {
    ActionType["SET_SELECTED_PANEL"] = "SET_SELECTED_PANEL";
    ActionType["TOGGLE_DRAWER"] = "TOGGLE_DRAWER";
    ActionType["GET_MENUS"] = "GET_MENUS";
    ActionType["SET_SELECTED_MENU"] = "SET_SELECTED_MENU";
    ActionType["SET_EDIT_MENU"] = "SET_EDIT_MENU";
    ActionType["GET_CATEGORIES"] = "GET_CATEGORIES";
    ActionType["SET_DRAFT_CATEGORY"] = "SET_DRAFT_CATEGORY";
    ActionType["GET_ITEMS"] = "GET_ITEMS";
    ActionType["SET_DRAFT_ITEM"] = "SET_DRAFT_ITEM";
    ActionType["SET_SELECTED_OVERVIEW_MENU"] = "SET_SELECTED_OVERVIEW_MENU";
    ActionType["GET_MODIFIERS"] = "GET_MODIFIERS";
    ActionType["SET_DRAFT_MODIFIER"] = "SET_DRAFT_MODIFIER";
    ActionType["UPDATE_ITEM"] = "SET_UPDATE_ITEM";
})(ActionType || (ActionType = {}));
