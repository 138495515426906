import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, ResponsiveContainer, Cell, Tooltip } from 'recharts';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useTheme, useMediaQuery } from '@mui/material';
function PeakHours({ data }) {
    const [t] = useTranslation('store');
    if (data.result === null) {
        return _jsx(Typography, { m: 'auto', children: t('activity.emptyText') });
    }
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const hours = isMdDown
        ? [
            '6 am',
            '7 am',
            '8 am',
            '9 am',
            '10 am',
            '11 am',
            '12 pm',
            '1 pm',
            '2 pm',
            '3 pm',
            '4 pm',
            '5 pm',
            '6 pm',
            '7 pm',
            '8 pm',
            '9 pm',
            '10 pm',
            '11 pm',
            '12 am'
        ]
        : [
            '1 am',
            '2 am',
            '3 am',
            '4 am',
            '5 am',
            '6 am',
            '7 am',
            '8 am',
            '9 am',
            '10 am',
            '11 am',
            '12 pm',
            '1 pm',
            '2 pm',
            '3 pm',
            '4 pm',
            '5 pm',
            '6 pm',
            '7 pm',
            '8 pm',
            '9 pm',
            '10 pm',
            '11 pm',
            '12 am'
        ];
    const mappedHours = hours.map((hour) => {
        const foundHour = data.result.find((item) => moment(hour, 'h a').format('HH:mm') === item.hour);
        return {
            hour,
            total_orders_count: foundHour ? foundHour.total_orders_count : 0,
            is_peak_hour: foundHour ? foundHour.is_peak_hour : false
        };
    });
    return (_jsx(_Fragment, { children: _jsx(ResponsiveContainer, { width: '100%', height: '100%', children: _jsxs(BarChart, { width: 500, height: 300, data: mappedHours, children: [_jsx(Tooltip, { content: renderToolTip, cursor: { fillOpacity: 0.3 } }), _jsx(XAxis, { dataKey: 'range', hide: true }), _jsx(XAxis, { dataKey: 'hour', scale: 'band', xAxisId: 'values', tickFormatter: (value) => ['9 am', '12 pm', '3 pm', '6 pm', '9 pm'].includes(value)
                            ? value
                            : '' }), _jsx(Bar, { radius: [12, 12, 0, 0], strokeWidth: 2, stroke: '#2196F3', dataKey: 'total_orders_count', fill: '#2196F31F', children: mappedHours.map((entry, index) => (_jsx(Cell, { fill: entry.is_peak_hour ? '#FECDD2' : '#2196F31F', stroke: entry.is_peak_hour ? '#FF1744' : '#2196F3' }, `cell-${index}`))) })] }) }) }));
}
export default PeakHours;
const renderToolTip = (props) => {
    const { payload } = props;
    const data = payload[0];
    return (_jsx(Paper, { sx: { p: 2 }, children: _jsxs(Typography, { children: [data?.payload.hour.toUpperCase(), " \u2022 ", data?.payload.total_orders_count, ' ', "Order(s)"] }) }));
};
