import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, { Marker } from 'react-map-gl/mapbox';
import { useGeocodingCore } from '@mapbox/search-js-react';
// @ts-ignore
import 'mapbox-gl/dist/mapbox-gl.css';
import { Stack, Box, Autocomplete, debounce } from '@mui/material';
import { RoundedTextField } from '../../components/styled';
import { OSOuterContainer, OSInnerContainer } from '../../settings/components/OSCustomComponents';
const accessToken = 'pk.eyJ1IjoiZW15ZnRhcmkiLCJhIjoiY20zb3RibmdwMDVzZzJrc2M0N2xjYngzNCJ9.4_OJftCUWOmot8-DbitrBw';
const getAddressComponents = (value) => {
    return {
        fullAddress: value.full_address ?? '',
        name: value.name ?? '',
        latitude: value.coordinates.latitude ?? 0,
        longitude: value.coordinates.longitude ?? 0,
        street: value.context.street?.name ?? '',
        city: value.context.place?.name ?? '',
        state: value.context.region?.name ?? '',
        zip: value.context.postcode?.name ?? '',
        country: value.context.country?.name ?? ''
    };
};
export default function AddressSettings({ context }) {
    const [t] = useTranslation('settings');
    const { restaurantId, addressSettings, setAddressSettings, validationErrors, filterValidation } = useContext(context);
    const [value, setValue] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const [fields, setFields] = useState({
        new_restaurant_zip: '',
        new_restaurant_city: '',
        new_restaurant_state: '',
        new_restaurant_street: ''
    });
    const [viewState, setViewState] = useState({
        longitude: 19.8187,
        latitude: 41.3275,
        zoom: 16
    });
    const handleFieldChange = (e) => {
        setFields((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
        setAddressSettings({
            ...addressSettings,
            [e.target.name]: e.target.value
        });
        filterValidation(e.target.name);
    };
    const geocodingCore = useGeocodingCore({
        accessToken
    });
    const handleDragEnd = async (e) => {
        const response = await geocodingCore.reverse({ lat: e.viewState.latitude, lng: e.viewState.longitude }, {
            limit: 1
        });
        const address = getAddressComponents(response.features[0].properties);
        setValue(address);
        setSuggestions([address]);
        setFields({
            new_restaurant_street: address.street,
            new_restaurant_city: address.city,
            new_restaurant_state: address.state,
            new_restaurant_zip: address.zip
        });
        setAddressSettings({
            ...addressSettings,
            new_restaurant_address: address.fullAddress,
            new_restaurant_city: address.city,
            new_restaurant_country: address.country,
            new_restaurant_latitude: address.latitude,
            new_restaurant_longitude: address.longitude,
            new_restaurant_state: address.state,
            new_restaurant_street: address.street,
            new_restaurant_zip: address.zip
        });
        filterValidation('new_restaurant_address');
    };
    const handleSearch = async (query) => {
        if (query === '') {
            setValue(null);
            setSuggestions([]);
            return;
        }
        const response = await geocodingCore.suggest(query, {
            sessionToken: 'search-address'
        });
        setSuggestions(response.features.map((feature) => getAddressComponents(feature.properties)));
    };
    const debouncedSearch = debounce(handleSearch, 500);
    const handleChange = async (value) => {
        if (value === null) {
            setValue(null);
            setSuggestions([]);
            setFields({
                new_restaurant_zip: '',
                new_restaurant_city: '',
                new_restaurant_state: '',
                new_restaurant_street: ''
            });
            setAddressSettings({
                new_restaurant_address: '',
                new_restaurant_city: '',
                new_restaurant_country: '',
                new_restaurant_latitude: 0,
                new_restaurant_longitude: 0,
                new_restaurant_state: '',
                new_restaurant_street: '',
                new_restaurant_zip: '',
                restaurant_id: restaurantId
            });
            return;
        }
        const { fullAddress, country, street, city, state, zip, latitude, longitude } = value;
        setFields({
            new_restaurant_zip: zip,
            new_restaurant_city: city,
            new_restaurant_state: state,
            new_restaurant_street: street
        });
        setAddressSettings({
            new_restaurant_address: fullAddress,
            new_restaurant_city: city,
            new_restaurant_country: country,
            new_restaurant_latitude: latitude,
            new_restaurant_longitude: longitude,
            new_restaurant_state: state,
            new_restaurant_street: street,
            new_restaurant_zip: zip,
            restaurant_id: restaurantId
        });
        filterValidation('new_restaurant_address');
        setValue(value);
        setViewState({
            zoom: 16,
            latitude,
            longitude
        });
    };
    useEffect(() => {
        if (addressSettings.new_restaurant_latitude !== 0 &&
            addressSettings.new_restaurant_longitude !== 0) {
            setViewState({
                zoom: 16,
                latitude: addressSettings.new_restaurant_latitude,
                longitude: addressSettings.new_restaurant_longitude
            });
            setFields({
                new_restaurant_street: addressSettings.new_restaurant_street,
                new_restaurant_city: addressSettings.new_restaurant_city,
                new_restaurant_state: addressSettings.new_restaurant_state,
                new_restaurant_zip: addressSettings.new_restaurant_zip
            });
            const value = {
                city: addressSettings.new_restaurant_city,
                country: addressSettings.new_restaurant_country,
                fullAddress: addressSettings.new_restaurant_address,
                latitude: addressSettings.new_restaurant_latitude,
                longitude: addressSettings.new_restaurant_longitude,
                state: addressSettings.new_restaurant_state,
                street: addressSettings.new_restaurant_street,
                zip: addressSettings.new_restaurant_zip
            };
            setValue(value);
            setSuggestions([value]);
        }
    }, []);
    return (_jsx(OSOuterContainer, { sx: { mb: 2 }, children: _jsx(OSInnerContainer, { children: _jsxs(Stack, { spacing: 2, width: '100%', children: [_jsx(Box, { sx: {
                            height: 300,
                            width: '100%',
                            overflow: 'hidden',
                            borderRadius: '12px'
                        }, children: _jsx(Map, { ...viewState, scrollZoom: false, mapboxAccessToken: accessToken, style: { width: '100%', height: '100%' }, mapStyle: 'mapbox://styles/mapbox/streets-v9', onMove: (evt) => setViewState(evt.viewState), onDragEnd: handleDragEnd, children: _jsx(Marker, { latitude: viewState.latitude, longitude: viewState.longitude }) }) }), _jsx(Autocomplete, { disablePortal: true, options: suggestions, getOptionLabel: (option) => option.fullAddress, onChange: (_, value) => handleChange(value), value: value, renderInput: (params) => (_jsx(RoundedTextField, { ...params, label: t('address.search'), slotProps: {
                                input: { ...params.InputProps }
                            }, onChange: (e) => debouncedSearch(e.target.value) })) }), _jsx(RoundedTextField, { label: t('address.street'), name: 'new_restaurant_street', value: fields.new_restaurant_street, onChange: handleFieldChange, error: !!validationErrors?.new_restaurant_street?.length, helperText: validationErrors?.new_restaurant_street?.[0] }), _jsx(RoundedTextField, { label: t('address.city'), name: 'new_restaurant_city', value: fields.new_restaurant_city, onChange: handleFieldChange, error: !!validationErrors?.new_restaurant_city?.length, helperText: validationErrors?.new_restaurant_city?.[0] }), _jsx(RoundedTextField, { label: t('address.state'), name: 'new_restaurant_state', value: fields.new_restaurant_state, onChange: handleFieldChange, error: !!validationErrors?.new_restaurant_state?.length, helperText: validationErrors?.new_restaurant_state?.[0] }), _jsx(RoundedTextField, { label: t('address.zip'), name: 'new_restaurant_zip', value: fields.new_restaurant_zip, onChange: handleFieldChange, error: !!validationErrors?.new_restaurant_zip?.length, helperText: validationErrors?.new_restaurant_zip?.[0] })] }) }) }));
}
