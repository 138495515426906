import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MenuContext } from '../context';
import { fetchApi } from '../../../config/Api';
import { getNetworkId, getRestaurantId } from '../../UtilityFunctions';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { useTheme, useMediaQuery } from '@mui/material';
import SearchField from './SearchField';
import { PrimaryButton, SecondaryButton } from './styled';
export default function AssignModifierDialog(props) {
    const { isOpen, setIsOpen, optionIndex, setOptionIndex } = props;
    const [t] = useTranslation('menu');
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoadingModifiers, setIsLoadingModifiers] = useState(false);
    const [assignedModifiers, setAssignedModifiers] = useState([]);
    const [assignableModifiers, setAssignableModifiers] = useState([]);
    const { draftModifier, modifiers: allModifiers } = useContext(MenuContext);
    const { getValues, setValue } = useFormContext();
    const handleClose = () => {
        setIsOpen(false);
        setOptionIndex(null);
    };
    const handleChange = (modifier) => {
        if (assignedModifiers?.find((item) => item.id === modifier.id)) {
            setAssignedModifiers((prev) => prev.filter((prevModifier) => prevModifier.id !== modifier.id));
        }
        else {
            setAssignedModifiers((prev) => [...prev, modifier]);
        }
    };
    const handleSave = () => {
        if (optionIndex === null)
            return;
        const options = getValues('options');
        const updatedOptions = [...options];
        const currOption = options[optionIndex];
        updatedOptions.splice(optionIndex, 1, {
            ...currOption,
            modifiersIds: assignedModifiers
        });
        setValue('options', updatedOptions, {
            shouldValidate: true,
            shouldDirty: true
        });
        setAssignedModifiers([]);
        handleClose();
    };
    const network_id = getNetworkId();
    const restaurant_id = getRestaurantId();
    useEffect(() => {
        if (draftModifier) {
            setIsLoadingModifiers(true);
            const getAssignableModifiers = async () => {
                fetchApi({
                    url: `/modifier-groups?network_id=${network_id}&restaurant=${restaurant_id}&modifier_group_id=${draftModifier.id}`,
                    method: 'GET'
                }).then((res) => {
                    setAssignableModifiers([...res.data.modifierGroups.flat()]);
                    setIsLoadingModifiers(false);
                });
            };
            getAssignableModifiers();
        }
    }, [draftModifier]);
    useEffect(() => {
        if (optionIndex !== null) {
            const options = getValues('options');
            const modifiers = options.find((_, index) => index === optionIndex)?.modifiersIds;
            setAssignedModifiers(modifiers ?? []);
        }
        if (!draftModifier) {
            setAssignableModifiers(allModifiers);
        }
    }, [optionIndex]);
    return (_jsxs(Dialog, { fullScreen: isMdDown, open: isOpen, onClose: handleClose, PaperProps: {
            sx: (theme) => ({
                width: 800,
                [theme.breakpoints.up('md')]: {
                    borderRadius: 3
                }
            })
        }, children: [_jsx(DialogTitle, { component: 'div', display: 'flex', justifyContent: 'flex-end', children: _jsx(IconButton, { onClick: handleClose, children: _jsx(CloseOutlined, {}) }) }), _jsxs(DialogContent, { sx: { mb: 2 }, children: [_jsxs(Box, { mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', children: [_jsx(Typography, { variant: 'h1', fontSize: 24, children: t('dialogs.addModifier.title') }), _jsx(SearchField, { placeholder: t('dialogs.addModifier.searchFieldPlaceholder'), value: searchTerm, onChange: setSearchTerm })] }), isLoadingModifiers ? (_jsx(Box, { display: 'flex', alignItems: 'center', justifyContent: 'center', pt: 3, children: _jsx(CircularProgress, {}) })) : (_jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, {}), _jsx(TableCell, { children: t('dialogs.addModifier.columns.1') }), _jsx(TableCell, { children: t('dialogs.addModifier.columns.2') })] }) }), _jsx(TableBody, { children: assignableModifiers
                                    .filter((modifier) => draftModifier?.id !== modifier.id)
                                    .filter((modifier) => modifier.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                    .map((modifier) => (_jsxs(TableRow, { hover: true, selected: !!assignedModifiers?.find((item) => item.id === modifier.id), onClick: () => {
                                        handleChange(modifier);
                                    }, sx: {
                                        cursor: 'pointer'
                                    }, children: [_jsx(TableCell, { sx: { width: 0 }, children: _jsx(Checkbox, { disabled: !assignableModifiers.some((item) => item.id === modifier.id), checked: !!assignedModifiers?.find((item) => item.id === modifier.id) }) }), _jsx(TableCell, { children: modifier.name }), _jsx(TableCell, { children: modifier.options
                                                .flat()
                                                .map((opt) => opt.name)
                                                .join(', ') })] }, modifier.id))) })] }))] }), _jsxs(DialogActions, { children: [_jsx(SecondaryButton, { variant: 'outlined', onClick: handleClose, children: t('dialogs.addModifier.secondaryBtnLabel') }), _jsx(PrimaryButton, { variant: 'contained', onClick: handleSave, children: t('dialogs.addModifier.primaryBtnLabel') })] })] }));
}
