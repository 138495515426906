import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGalleryContext } from './GalleryProvider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PageHeader from '../components/PageHeader';
import CustomTooltip from '../components/CustomTooltip';
import EmptyGallery from './components/EmptyGallery';
import GalleryImages from './components/GalleryImages';
import GalleryDrawer from './components/GalleryDrawer';
export default function GalleryContainer() {
    const [t] = useTranslation('gallery');
    const [showCoversOnly, setShowCoversOnly] = useState(false);
    const { images, isLoading } = useGalleryContext();
    return (_jsxs(Box, { maxWidth: 1600, children: [_jsxs(Stack, { justifyContent: 'space-between', sx: {
                    p: {
                        xs: 2,
                        md: 5
                    },
                    flexDirection: {
                        md: 'row'
                    }
                }, children: [_jsx("div", { children: _jsx(PageHeader, { title: t('title'), subtitle: t('subtitle') }) }), _jsxs(Box, { sx: (theme) => ({
                            px: 2,
                            py: 0.5,
                            border: 1,
                            borderRadius: 3,
                            borderColor: 'divider',
                            [theme.breakpoints.down('md')]: {
                                mt: 3,
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }
                        }), children: [_jsx(FormControlLabel, { control: _jsx(Switch, { onChange: (e) => setShowCoversOnly(e.target.checked) }), label: t('switch.label') }), _jsx(CustomTooltip, { tooltipProps: {
                                    title: t('switch.tooltip'),
                                    placement: 'bottom'
                                }, children: _jsx(InfoOutlinedIcon, { color: 'action' }) })] })] }), isLoading ? (_jsx(Stack, { mt: 10, alignItems: 'center', children: _jsx(CircularProgress, {}) })) : images.length ? (_jsx(GalleryImages, { showCoversOnly: showCoversOnly })) : (_jsx(EmptyGallery, {})), _jsx(GalleryDrawer, {})] }));
}
