import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchApi } from '../../../config/Api';
import { getRestaurantId, getErrorMessage } from '../../UtilityFunctions';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import { useTheme, useMediaQuery } from '@mui/material';
import PayoutRow from './PayoutRow';
import Pagination from '../../components/Pagination';
import { RoundedButton } from '../../components/styled';
export default function PayoutsTable() {
    const [t] = useTranslation('financials');
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 10,
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 0
    });
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up(1024));
    const fetchStatements = async (nextPage) => {
        try {
            if (nextPage) {
                setIsFetching(true);
            }
            else {
                setIsLoading(true);
            }
            const restaurantId = getRestaurantId();
            const params = new URLSearchParams({
                page: nextPage?.toString() ?? page.toString(),
                perPage: perPage.toString()
            });
            const res = await fetchApi({
                method: 'GET',
                url: `/restaurant/${restaurantId}/statements?${params}`
            });
            if (nextPage) {
                setData((prev) => [...prev, ...res.data.statements]);
            }
            else {
                setData(res.data.statements);
            }
            setPagination(res.data.pagination);
        }
        catch (err) {
            window.globalToast({
                type: 'error',
                title: 'Error',
                description: getErrorMessage(err.response)
            });
        }
        finally {
            setIsFetching(false);
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchStatements();
    }, []);
    const showPagination = !isLoading && !!data.length && pagination.total > 10 && isMdUp;
    const showSeeMoreBtn = !isLoading && !isMdUp && pagination.current_page !== pagination.last_page;
    return (_jsxs(_Fragment, { children: [_jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { sx: { fontWeight: 600 }, children: t('statements.tableColumns.1') }), _jsx(TableCell, { sx: () => ({
                                        fontWeight: 600,
                                        whiteSpace: 'nowrap'
                                    }), children: t('statements.tableColumns.2') }), _jsx(TableCell, { sx: { fontWeight: 600 }, children: t('statements.tableColumns.3') }), _jsx(TableCell, { sx: (theme) => ({
                                        fontWeight: 600,
                                        [theme.breakpoints.down('md')]: { px: 0 }
                                    }) })] }) }), data && data.length > 0 && (_jsx(TableBody, { children: data.map((statement) => (_jsx(PayoutRow, { data: statement }, statement.id))) }))] }), isLoading ||
                !data ||
                (!data.length && (_jsx(Box, { sx: {
                        mt: 18,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }, children: isLoading ? (_jsx(CircularProgress, {})) : (data &&
                        data.length === 0 && (_jsxs(_Fragment, { children: [_jsx(PersonSearchOutlinedIcon, { color: 'action', fontSize: 'large', sx: { mb: 2 } }), _jsx(Typography, { mb: 1, fontWeight: 600, children: t('statements.emptyText.primary') }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', children: t('statements.emptyText.secondary') })] }))) }))), showPagination && (_jsx(Pagination, { pagination: pagination, perPage: perPage, setPerPage: setPerPage, setPage: setPage })), showSeeMoreBtn && (_jsx(Box, { sx: { mx: 4, my: 2 }, children: _jsx(RoundedButton, { fullWidth: true, variant: 'contained', onClick: () => fetchStatements(pagination.current_page + 1), children: isFetching ? (_jsx(CircularProgress, { size: '24px' })) : (t('statements.moreBtnLabel')) }) }))] }));
}
