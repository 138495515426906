import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewBranchContext } from '../NewBranchProvider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import { OSInnerContainer, OSSelect, OSSwitchBox, OSTextBox, OSStepTitleBox, OSStepTitle, OSSwitch, OSInlineTooltip, OSDelPickContentBox } from './OSCustomComponents';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { ClickAwayListener, Box } from '@mui/material';
function PickUpSettings() {
    const [t] = useTranslation('settings');
    const { pickUpSettings, setPickUpSettings, validationErrors, filterValidation } = useContext(NewBranchContext);
    const [tooltipItems, setTooltipItems] = useState([
    // item1: true,
    ]);
    const handleTooltipToggle = (key, bool = false) => {
        setTooltipItems({
            ...tooltipItems,
            [key]: bool
        });
    };
    const OSTooltipComponent = (key, title) => {
        return (_jsx(ClickAwayListener, { onClickAway: () => {
                handleTooltipToggle(key, false);
            }, children: _jsx(Box, { sx: {
                    height: '0 !important',
                    width: '0 !important',
                    maxHeight: '0 !important',
                    maxWidth: '0 !important'
                }, children: _jsx(OSInlineTooltip
                // @ts-ignore
                , { 
                    // @ts-ignore
                    open: !!tooltipItems[key], PopperProps: {
                        disablePortal: true
                    }, onClose: () => {
                        handleTooltipToggle(key, false);
                    }, disableFocusListener: true, disableTouchListener: true, title: title, children: _jsx(InfoOutlined, { onClick: () => {
                            handleTooltipToggle(key, true);
                        }, onMouseOver: () => {
                            handleTooltipToggle(key, true);
                        }, onMouseLeave: () => {
                            handleTooltipToggle(key, false);
                        } }) }) }) }));
    };
    return (pickUpSettings && (_jsxs(OSInnerContainer, { children: [_jsx(OSStepTitleBox, { children: _jsx(OSStepTitle, { children: t('deliveryPickup.pickup.title') }) }), _jsxs(OSDelPickContentBox, { children: [_jsxs(OSSwitchBox, { item: pickUpSettings.new_restaurant_pickup_asap, children: [_jsx(OSSwitch, { checked: pickUpSettings.new_restaurant_pickup_asap ? true : false, 
                                // value={deliverySettings.new_restaurant_delivery_asap}
                                control: _jsx(Switch, {}), value: pickUpSettings.new_restaurant_pickup_asap, id: 'new-restaurant-cash-payment', label: t('deliveryPickup.pickup.asapSwitch.label'), labelPlacement: 'start', onChange: () => {
                                    setPickUpSettings({
                                        ...pickUpSettings,
                                        new_restaurant_pickup_asap: !pickUpSettings.new_restaurant_pickup_asap
                                    });
                                    filterValidation('delivery_pickup_option');
                                } }), OSTooltipComponent('new_restaurant_pickup_asap', t('deliveryPickup.pickup.asapSwitch.tooltip'))] }), _jsxs(OSSwitchBox, { item: pickUpSettings.new_restaurant_pickup_late, children: [_jsx(OSSwitch, { checked: pickUpSettings.new_restaurant_pickup_late ? true : false, 
                                // value={deliverySettings.new_restaurant_delivery_late}
                                control: _jsx(Switch, {}), value: pickUpSettings.new_restaurant_pickup_late, id: 'new-restaurant-cash-payment', label: t('deliveryPickup.pickup.lateSwitch.label'), labelPlacement: 'start', onChange: () => {
                                    setPickUpSettings({
                                        ...pickUpSettings,
                                        new_restaurant_pickup_late: !pickUpSettings.new_restaurant_pickup_late
                                    });
                                    filterValidation('delivery_pickup_option');
                                } }), OSTooltipComponent('new_restaurant_pickup_late', t('deliveryPickup.pickup.lateSwitch.tooltip'))] }), pickUpSettings.new_restaurant_pickup_late && (_jsx(_Fragment, { children: _jsxs(OSTextBox
                        // TODO replace sx with stepper_box_text_inputs
                        , { 
                            // TODO replace sx with stepper_box_text_inputs
                            sx: {
                                display: 'flex',
                                // marginTop: "0.5rem",
                                // marginBottom: "0.5rem",
                                // marginLeft: "1rem",
                                marginBottom: '1rem',
                                marginHorizontal: '0',
                                justifyItems: 'center',
                                alignItems: 'center',
                                width: '100%',
                                borderRadius: '0.5rem'
                            }, children: [_jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { id: 'demo-simple-select-label', children: t('deliveryPickup.pickup.dateRange.label') }), _jsx(OSSelect, { labelId: 'demo-simple-select-label', id: 'demo-simple-select', error: !!validationErrors?.pickup_date_range?.length, value: pickUpSettings.pickup_date_range, label: t('deliveryPickup.pickup.dateRange.label'), onChange: (e) => {
                                                setPickUpSettings({
                                                    ...pickUpSettings,
                                                    // @ts-ignore
                                                    pickup_date_range: e?.target?.value
                                                });
                                                filterValidation('pickup_date_range');
                                            }, defaultValue: '1', children: ['1', '2', '3', '4', '5'].map((item, i) => (_jsx(MenuItem, { value: item, children: t('deliveryPickup.pickup.dateRange.options', {
                                                    returnObjects: true
                                                })[i] }, item))) })] }), OSTooltipComponent('pickup_date_range', t('deliveryPickup.pickup.dateRange.tooltip'))] }) }))] })] })));
}
export default PickUpSettings;
