import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useState } from 'react';
import { getCurrency } from '../../UtilityFunctions';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlined from '@mui/icons-material/ArrowDropUpOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { useTheme, useMediaQuery } from '@mui/material';
export default function ModifierTree(props) {
    const { isOpen, setIsOpen, data } = props;
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    return (_jsxs(Dialog, { fullWidth: true, maxWidth: 'sm', fullScreen: isMdDown, open: isOpen, onClose: () => setIsOpen(false), PaperProps: { sx: (theme) => ({
                [theme.breakpoints.up('md')]: {
                    borderRadius: 3
                }
            }) }, children: [_jsx(DialogTitle, { component: "div", display: "flex", justifyContent: "flex-end", children: _jsx(IconButton, { onClick: () => setIsOpen(false), children: _jsx(CloseOutlined, {}) }) }), _jsx(DialogContent, { children: _jsx(List, { children: data.map((modifier, index) => (_jsx(ModifierItem, { divider: index !== data.length - 1, modifier: modifier }, modifier.id))) }) })] }));
}
const ModifierItem = ({ modifier, divider, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const hasOptions = (!!modifier.modifierGroups?.flat().length || !!modifier.options?.flat().length);
    return (_jsxs(_Fragment, { children: [_jsx(ListItem, { divider: divider, sx: { px: 1 }, secondaryAction: _jsx(IconButton, { children: _jsx(MoreVertOutlined, {}) }), children: _jsxs(ListItemButton, { onClick: () => setIsOpen((prev) => !prev), children: [hasOptions && (_jsx(ListItemIcon, { children: isOpen ? _jsx(ArrowDropUpOutlined, {}) : _jsx(ArrowDropDownOutlined, {}) })), _jsx(ListItemText, { primary: modifier.name, sx: { flexGrow: 0, mr: 1 } })] }) }, modifier.id), hasOptions && (_jsx(Collapse, { in: isOpen, children: _jsx(RecursiveList, { data: modifier.modifierGroups?.flat() || modifier.options?.flat() }) }))] }));
};
const RecursiveList = (props) => {
    const { data } = props;
    const [showNested, setShowNested] = useState({});
    const currency = getCurrency();
    const toggleNested = (name) => {
        setShowNested({ ...showNested, [name]: !showNested[name] });
    };
    const hasChildren = (item) => {
        return (!!item.modifierGroups?.flat().length || !!item.options?.flat().length);
    };
    return (_jsx(List, { sx: (theme) => ({
            px: 2,
            py: 2,
            border: '1px solid',
            borderTop: '0',
            borderColor: theme.palette.grey[300],
            backgroundColor: `rgba(169, 169, 169, 0.1)`,
        }), children: data.map((item) => (_jsxs(Fragment, { children: [_jsx(ListItem, { divider: true, sx: { px: 1, backgroundColor: '#fff' }, secondaryAction: !item.price ? (_jsx(IconButton, { children: _jsx(MoreVertOutlined, {}) })) : null, children: _jsxs(ListItemButton, { onClick: () => hasChildren(item) && toggleNested(item.name), children: [hasChildren(item) && (_jsx(ListItemIcon, { children: !!showNested[item.name] ? _jsx(ArrowDropUpOutlined, {}) : _jsx(ArrowDropDownOutlined, {}) })), _jsx(ListItemText, { primary: item.name }), item.price && (_jsx(ListItemText, { primary: `${currency} ${item.price}`, sx: { textAlign: 'end', color: 'text.secondary' } }))] }) }), hasChildren(item) && (_jsx(Collapse, { in: !!showNested[item.name], children: _jsx(RecursiveList, { data: item.modifierGroups?.flat() || item.options?.flat() }) }))] }, item.id))) }));
};
