import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
export default function DropdownMenu(props) {
    const { options, anchorEl, onClose } = props;
    return (_jsx(Menu, { open: Boolean(anchorEl), anchorEl: anchorEl, onClose: onClose, slotProps: {
            paper: { sx: { borderRadius: 3, width: 240 } },
        }, children: options.map((option) => option.disabled && option.tooltip ? (_jsx(Tooltip, { title: option.tooltip, children: _jsx("span", { children: _jsxs(MenuItem, { sx: { py: 1.5 }, disabled: option.disabled, onClick: option.action, children: [_jsx(ListItemIcon, { children: option.icon }), _jsx(ListItemText, { children: option.label })] }, option.label) }) })) : (_jsxs(MenuItem, { sx: { py: 1.5 }, disabled: option.disabled, onClick: option.action, children: [_jsx(ListItemIcon, { children: option.icon }), _jsx(ListItemText, { children: option.label })] }, option.label))) }));
}
