import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectNetworkContext } from '../SelectNetworkProvider';
import Typography from '@mui/material/Typography';
export default function WelcomeHeader() {
    const { t } = useTranslation('network');
    const { user } = useContext(SelectNetworkContext);
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { mb: 1, variant: 'h1', fontSize: 28, fontWeight: 600, textAlign: 'center', children: t('title') }), _jsxs(Typography, { mb: 3, fontSize: 14, textAlign: 'center', children: [t('subtitle'), ' ', _jsx(Typography, { component: 'span', fontWeight: 600, children: user?.email })] })] }));
}
