import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useGalleryContext } from '../GalleryProvider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { RoundedButton } from '../../components/styled';
export default function EmptyGallery() {
    const [t] = useTranslation('gallery');
    const { setIsDrawerOpen } = useGalleryContext();
    return (_jsxs(Stack, { mt: 10, gap: 2, alignItems: 'center', children: [_jsx(PhotoOutlinedIcon, { fontSize: 'large', color: 'action' }), _jsx(Typography, { fontWeight: 600, children: t('emptyText.primary') }), _jsxs(Typography, { fontSize: 14, color: 'text.secondary', children: [t('emptyText.secondary'), " ", _jsx(InfoOutlinedIcon, { fontSize: 'small' })] }), _jsx(RoundedButton, { color: 'inherit', variant: 'contained', startIcon: _jsx(AddIcon, {}), onClick: () => setIsDrawerOpen(true), children: t('addBtnLabel') })] }));
}
