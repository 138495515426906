import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useCallback } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useEmployeesContext } from './EmployeeProvider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { useTheme, useMediaQuery } from '@mui/material';
import SearchField from '../components/SearchField';
import EmployeesTable from './components/EmployeesTable';
import EmployeeDrawer from './components/EmployeeDrawer';
import NewEmployeeDrawer from './components/NewEmployeeDrawer';
import { RoundedButton } from '../components/styled';
function EmployeeContainer() {
    const [t] = useTranslation('employees');
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const { getEmployees } = useEmployeesContext();
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const debouncedSearch = useCallback(_.debounce((query) => {
        getEmployees(query);
    }, 500), []);
    const handleSearch = (value) => {
        setSearchTerm(value);
        debouncedSearch(value);
    };
    return (_jsxs(_Fragment, { children: [_jsx(EmployeeDrawer, {}), _jsx(NewEmployeeDrawer, { open: isOpen, onClose: () => setIsOpen(false) }), _jsxs(Box, { sx: {
                    px: 4,
                    pt: 4,
                    pb: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 1600
                }, children: [_jsxs(Box, { sx: (theme) => ({
                            mb: 8,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            [theme.breakpoints.down('md')]: {
                                mb: 4
                            }
                        }), children: [_jsxs("div", { children: [_jsx(Typography, { fontSize: 20, fontWeight: 600, children: t('title') }), _jsx(Typography, { mb: 'auto', fontSize: 12, color: 'text.secondary', children: t('subtitle') })] }), isMdDown && (_jsx(RoundedButton, { color: 'inherit', variant: 'contained', startIcon: _jsx(AddIcon, {}), onClick: () => setIsOpen(true), sx: { flexShrink: 0 }, children: t('btnLabel') }))] }), _jsxs(Box, { display: 'flex', justifyContent: 'space-between', children: [_jsx(SearchField, { fullWidth: isMdDown, placeholder: t('searchFieldPlaceholder'), value: searchTerm, onChange: handleSearch, onClear: () => handleSearch('') }), !isMdDown && (_jsx(RoundedButton, { color: 'inherit', variant: 'contained', startIcon: _jsx(AddIcon, {}), onClick: () => setIsOpen(true), children: t('btnLabel') }))] })] }), _jsx(Box, { sx: (theme) => ({
                    borderTop: 1,
                    borderColor: 'divider',
                    maxWidth: 1600,
                    [theme.breakpoints.up('md')]: {
                        mx: 4
                    }
                }), children: _jsx(EmployeesTable, {}) })] }));
}
export default EmployeeContainer;
