import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useTheme, useMediaQuery } from '@mui/material';
const BestSellingItemsTable = ({ data }) => {
    const [t] = useTranslation('store');
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    if (isLgUp) {
        return null;
    }
    const totalItems = data.result.reduce((acc, curr) => acc + curr.quantity, 0);
    return (_jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: t('activity.overview.topSellingChart.itemName') }), _jsx(TableCell, { children: t('activity.legend.common.volume') })] }) }), _jsx(TableBody, { children: data.result.map((item, index) => (_jsxs(TableRow, { sx: { '&:last-child td': { borderBottom: 'none' } }, children: [_jsxs(TableCell, { children: [index + 1, ".", item.name] }), _jsxs(TableCell, { sx: {
                                whiteSpace: 'nowrap'
                            }, children: [item.quantity, " ", t('activity.legend.common.sales'), " \u2022", ' ', ((item.quantity / totalItems) * 100).toFixed(1), "%"] })] }, item.name))) })] }));
};
export default BestSellingItemsTable;
