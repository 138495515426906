import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LanguageSelect from './common/LanguageSelect';
export default function Header() {
    const { t } = useTranslation('network');
    const handleLogout = async (event) => {
        event.preventDefault();
        let token = document.head
            .querySelector('meta[name="csrf-token"]')
            ?.getAttribute('content');
        const response = await fetch('/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': token // CSRF token attached here
            }
        });
        if (response.ok) {
            // Update the application state to reflect that the user is logged out
            // This will depend on how you're managing state in your application
            // For example, you might dispatch a Redux action here
            // setLoggedIn(false);
            window.location.href = '/login';
        }
        else {
            // Handle any errors
            console.error('Logout failed');
        }
    };
    return (_jsx(AppBar, { position: 'sticky', variant: 'outlined', elevation: 0, sx: { bgcolor: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }, children: _jsxs(Toolbar, { sx: { display: 'flex', justifyContent: 'space-between' }, children: [_jsx("a", { href: '/select-network', children: _jsx("img", { src: '/images/ordersmile_merchants.png', alt: '' }) }), _jsxs(Box, { display: 'flex', alignItems: 'center', ml: 'auto', gap: 2, children: [_jsx(LanguageSelect, {}), _jsx(Typography, { href: '/help', fontSize: 14, component: 'a', color: 'black', children: t('header.links.helpBtnLabel') }), _jsx(Typography, { href: '/logout', fontSize: 14, component: 'a', onClick: handleLogout, color: 'black', children: t('header.links.logoutBtnLabel') })] })] }) }));
}
