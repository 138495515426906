import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import i18next from '../../../i18n/config';
import { useTranslation } from 'react-i18next';
import { getCurrency } from '../../UtilityFunctions';
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltip from '../../components/CustomTooltip';
const mapStatus = (status) => {
    switch (status) {
        case 'awaiting_client':
            return i18next.t(`activity.logs.status.${status}`, {
                ns: 'store'
            });
        case 'confirmed':
            return i18next.t(`activity.logs.status.${status}`, {
                ns: 'store'
            });
        case 'done':
            return i18next.t(`activity.logs.status.${status}`, {
                ns: 'store'
            });
        case 'ready':
            return i18next.t(`activity.logs.status.${status}`, {
                ns: 'store'
            });
        case 'collected_by_delivery':
            return i18next.t(`activity.logs.status.${status}`, {
                ns: 'store'
            });
        case 'in_delivery':
            return i18next.t(`activity.logs.status.${status}`, {
                ns: 'store'
            });
        case 'cancelled':
            return i18next.t(`activity.logs.status.${status}`, {
                ns: 'store'
            });
        case 'reject':
            return i18next.t(`activity.logs.status.${status}`, {
                ns: 'store'
            });
        case 'no_show':
            return i18next.t(`activity.logs.status.${status}`, {
                ns: 'store'
            });
        default:
            return '';
    }
};
const TransitionStatus = ({ prevStatus, currStatus }) => (_jsxs(Box, { display: 'flex', flexWrap: 'wrap', gap: 1, children: [_jsx(Typography, { fontSize: 14, color: 'text.secondary', sx: { textDecoration: 'line-through' }, children: prevStatus }), _jsxs(Typography, { fontSize: 14, sx: { whiteSpace: 'nowrap' }, children: ["\u2B95 ", currStatus] })] }));
export default function ActivityLogRow(props) {
    const { data } = props;
    const [t] = useTranslation('store');
    const currency = getCurrency();
    let transitionText = 'N/A';
    let moreTransitionText = 'N/A';
    let prevTime = '', updatedTime;
    switch (data.activity_type) {
        case 'order_reject_with_refund':
        case 'order_no_show_with_refund':
            transitionText = (_jsx(TransitionStatus, { prevStatus: mapStatus(data.prev_value), currStatus: mapStatus(data.updated_value) }));
            moreTransitionText = 'Refunded';
            break;
        case 'order_reject_without_refund':
        case 'order_no_show_without_refund':
            transitionText = (_jsx(TransitionStatus, { prevStatus: mapStatus(data.prev_value), currStatus: mapStatus(data.updated_value) }));
            moreTransitionText = 'Not Refunded';
            break;
        case 'order_create':
            transitionText = 'N/A';
            moreTransitionText = data.updated_value.first_time_order ? (_jsxs(_Fragment, { children: [t('activity.logs.moreTransition.order_create.more'), data.updated_value.platform_type] })) : ('N/A');
            break;
        case 'order_claim':
        case 'order_in_delivery':
            transitionText = 'N/A';
            break;
        case 'order_done':
            transitionText = (_jsx(TransitionStatus, { prevStatus: mapStatus(data.prev_value) +
                    (data.prev_value === 'ready'
                        ? ` ${t('for', { ns: 'common' })} ${data.order.type}`
                        : ''), currStatus: mapStatus(data.updated_value) }));
            break;
        case 'order_ready':
            transitionText = (_jsx(TransitionStatus, { prevStatus: mapStatus(data.prev_value), currStatus: mapStatus(data.updated_value) +
                    ` ${t('for', { ns: 'common' })} ${data.order.type}` }));
            break;
        case 'order_reject':
            transitionText = (_jsx(TransitionStatus, { prevStatus: mapStatus(data.prev_value), currStatus: data.prev_value === 'awaiting_client'
                    ? t('activity.logs.moreTransition.order_reject.reject')
                    : t('activity.logs.moreTransition.order_reject.cancel') }));
            break;
        case 'order_cancel':
        case 'order_collect':
            transitionText = (_jsx(TransitionStatus, { prevStatus: mapStatus(data.prev_value) +
                    (data.prev_value === 'ready'
                        ? ` ${t('for', { ns: 'common' })} ${data.order.type}`
                        : ''), currStatus: mapStatus(data.updated_value) }));
            break;
        case 'order_accept':
            transitionText = (_jsx(TransitionStatus, { prevStatus: mapStatus(data.prev_value), currStatus: mapStatus(data.updated_value) }));
            break;
        case 'order_preparation_estimate_update':
            const prev = moment(data.prev_value.estimated_time.date).format('h:mm a');
            const curr = moment(data.updated_value.estimated_time.date).format('h:mm a');
            moreTransitionText = (_jsx(TransitionStatus, { prevStatus: prev, currStatus: curr }));
            break;
        case 'order_adjust_price':
            moreTransitionText = (_jsx(TransitionStatus, { prevStatus: currency + data.prev_value.toFixed(2), currStatus: currency + data.updated_value.toFixed(2) }));
            break;
        case 'order_done_late':
            prevTime = data.prev_value[Object.keys(data.prev_value)[0]].date;
            updatedTime = data.updated_value[Object.keys(data.prev_value)[0]].date;
            transitionText = (_jsx(TransitionStatus, { prevStatus: mapStatus(data.prev_value.status), currStatus: mapStatus(data.updated_value.status) + ' Late' }));
            moreTransitionText = (_jsx(TransitionStatus, { prevStatus: moment(prevTime).format('h:mm a'), currStatus: moment(updatedTime).format('h:mm a') }));
            break;
        case 'order_ready_late':
            prevTime = data.prev_value[Object.keys(data.prev_value)[0]].date;
            updatedTime = data.updated_value[Object.keys(data.prev_value)[0]].date;
            transitionText = (_jsx(TransitionStatus, { prevStatus: mapStatus(data.prev_value.status), currStatus: mapStatus(data.updated_value.status) +
                    ` ${t('for', { ns: 'common' })} ${data.order.type}` }));
            moreTransitionText = (_jsx(TransitionStatus, { prevStatus: moment(prevTime).format('h:mm a'), currStatus: moment(updatedTime).format('h:mm a') }));
            break;
        case 'order_collect_late':
            prevTime = data.prev_value[Object.keys(data.prev_value)[0]].date;
            updatedTime = data.updated_value[Object.keys(data.prev_value)[0]].date;
            transitionText = (_jsx(TransitionStatus, { prevStatus: mapStatus(data.prev_value.status), currStatus: mapStatus(data.updated_value.status) }));
            moreTransitionText = (_jsx(TransitionStatus, { prevStatus: moment(prevTime).format('h:mm a'), currStatus: moment(updatedTime).format('h:mm a') }));
            break;
        default:
            break;
    }
    return (_jsxs(TableRow, { children: [_jsxs(TableCell, { children: [_jsxs(Typography, { component: 'span', color: 'text.secondary', fontSize: 12, children: ["#", data.order.identifier] }), _jsx("br", {}), _jsx(Typography, { component: 'span', fontSize: 14, children: t(`activity.logs.action.${data.activity_type}`) })] }), _jsxs(TableCell, { color: 'text.secondary', children: [_jsx(Typography, { component: 'span', color: 'text.secondary', fontSize: 12, children: data.user?.roles
                            .map((role) => t(`activity.logs.role.${role.name}`))
                            .join(', ') }), _jsx("br", {}), _jsxs(Typography, { component: 'span', fontSize: 14, children: [data.user?.firstname ?? 'Deleted', " ", data.user?.lastname ?? 'User'] })] }), _jsx(TableCell, { sx: {
                    fontWeight: 600,
                    display: { xs: 'none', sm: 'table-cell' }
                }, children: moment(data.created_at).format('MM/DD/yyyy h:mm a') }), _jsx(TableCell, { sx: {
                    display: { xs: 'none', md: 'table-cell' }
                }, children: transitionText }), _jsx(TableCell, { sx: {
                    display: { xs: 'none', md: 'table-cell' }
                }, children: moreTransitionText }), _jsx(TableCell, { sx: {
                    display: { md: 'none' }
                }, children: _jsx(CustomTooltip, { tooltipProps: {
                        title: (_jsxs(Box, { sx: {
                                display: 'flex',
                                flexDirection: 'column',
                                '& p': { color: '#fff', whiteSpace: 'nowrap' },
                                '& div': { flexWrap: 'nowrap' }
                            }, children: [_jsxs("span", { children: ["Created \u2022", ' ', moment(data.created_at).format('MM/DD/yyyy h:mm a')] }), typeof transitionText !== 'string' && `Transition Status • `, typeof transitionText !== 'string' && transitionText, typeof moreTransitionText === 'string' ? (!moreTransitionText.includes('N/A') && (_jsxs("span", { children: ["More \u2022 ", moreTransitionText] }))) : (_jsxs("span", { children: ["More \u2022 ", moreTransitionText] }))] }))
                    }, children: _jsx(InfoOutlinedIcon, { color: 'action' }) }) })] }, data.id));
}
