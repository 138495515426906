import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect } from "react";
import { MenuContext } from "./context";
import Header from "./components/Header";
import OverviewPanel from "./components/OverviewPanel";
import ModifiersPanel from "./components/ModifiersPanel";
import MenuDrawer from "./components/MenuDrawer";
import CategoryDrawer from "./components/CategoryDrawer";
import ItemDrawer from "./components/ItemDrawer";
import ModifierDrawer from "./components/ModifierDrawer";
export default function MenuScreen() {
    const { selectedPanel, getMenus, getItems, getCategories, getModifiers } = useContext(MenuContext);
    useEffect(() => {
        getMenus();
        getCategories();
        getItems();
        getModifiers();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}), selectedPanel === "overview" && _jsx(OverviewPanel, {}), selectedPanel === "modifiers" && _jsx(ModifiersPanel, {}), _jsx(MenuDrawer, {}), _jsx(CategoryDrawer, {}), _jsx(ItemDrawer, {}), _jsx(ModifierDrawer, {})] }));
}
