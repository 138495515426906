import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { mapActivityData } from '../helpers';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import TabletOutlinedIcon from '@mui/icons-material/TabletOutlined';
import DeliveryDiningOutlinedIcon from '@mui/icons-material/DeliveryDiningOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import OrderDistibution from './OrderDistibution';
function LiveActivity({ data }) {
    const [t] = useTranslation('store');
    const kitchenActivity = mapActivityData(data.kitchen);
    const deliveryActivity = mapActivityData(data.delivery);
    const completedActivity = mapActivityData(data.final);
    const allData = [
        ...kitchenActivity,
        ...deliveryActivity,
        ...completedActivity
    ].filter((item) => item.count !== 0);
    return (_jsxs(_Fragment, { children: [_jsx(OrderDistibution, { data: allData }), _jsxs(Box, { px: { xs: 2, sm: 4 }, pb: 4, gap: 4, display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', children: [_jsx(LegendCategory, { title: t('activity.legend.titles.kitchen'), data: kitchenActivity, count: data.kitchen.metadata.total_orders_count, icon: _jsx(TabletOutlinedIcon, { fontSize: 'large' }) }), _jsx(LegendCategory, { title: t('activity.legend.titles.delivery'), data: deliveryActivity, count: data.delivery.metadata.total_orders_count, icon: _jsx(DeliveryDiningOutlinedIcon, { fontSize: 'large' }) }), _jsx(LegendCategory, { title: t('activity.legend.titles.completed'), data: completedActivity, count: data.final.metadata.total_orders_count, icon: _jsx(CheckCircleOutlineIcon, { fontSize: 'large' }) })] })] }));
}
export default LiveActivity;
const LegendCategory = (props) => {
    const { data, icon, title, count } = props;
    const [t] = useTranslation('store');
    return (_jsxs(Box, { flex: 1, minWidth: 270, children: [_jsxs(Box, { gap: 1, display: 'flex', alignItems: 'center', children: [icon, _jsxs(Typography, { fontSize: 14, fontWeight: 600, children: [title, " \u2022 ", count, " ", t('activity.legend.common.orders')] }), _jsx(Typography, { ml: 'auto', fontSize: 14, fontWeight: 600, children: t('activity.legend.common.volume') })] }), _jsx(Divider, { sx: { my: 2 } }), _jsx(LegendList, { children: data.map((item) => (_jsx(LegendItem, { title: item.title, count: item.count, percentage: item.percentage, color: item.color }, item.title))) })] }));
};
const LegendList = styled('ul')(({ theme }) => ({
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    listStyle: 'none'
}));
const LegendItem = (props) => {
    const { title, count, percentage, color } = props;
    const [t] = useTranslation('store');
    return (_jsxs(Box, { px: 2, py: '11px', gap: 1, component: 'li', bgcolor: grey[100], display: 'flex', alignItems: 'center', borderRadius: 2, children: [_jsx(Box, { flexShrink: 0, width: 14, height: 14, borderRadius: '50%', bgcolor: color }), _jsx(Typography, { fontSize: 14, children: title }), _jsx(Box, { px: 1.5, ml: 'auto', minHeight: 28, bgcolor: '#fff', borderRadius: 2, display: 'flex', alignItems: 'center', flexShrink: 0, children: _jsxs(Typography, { fontSize: 14, children: [_jsxs(Typography, { component: 'span', fontWeight: 600, children: [count, " ", t('activity.legend.common.orders'), " \u2022", ' '] }), percentage, "%"] }) })] }));
};
