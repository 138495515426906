import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from 'react-i18next';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import DragIndicatorOutlined from '@mui/icons-material/DragIndicatorOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import DropdownMenu from './DropdownMenu';
export default function ModifierListItem(props) {
    const { modifier, canReorder, handleDelete } = props;
    const [t] = useTranslation('menu');
    const [anchorEl, setAnchorEl] = useState(null);
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.modifier.id ?? 0 });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };
    return (_jsxs(_Fragment, { children: [_jsxs(ListItem, { divider: true, secondaryAction: _jsx(IconButton, { edge: 'end', onClick: (e) => setAnchorEl(e.currentTarget), children: _jsx(MoreVertOutlined, {}) }), ref: setNodeRef, style: style, ...attributes, children: [canReorder && (_jsx(ListItemIcon, { sx: { cursor: 'pointer' }, ...listeners, children: _jsx(DragIndicatorOutlined, {}) })), _jsx(ListItemText, { primary: modifier.name })] }), _jsx(DropdownMenu, { anchorEl: anchorEl, onClose: () => setAnchorEl(null), options: [
                    {
                        icon: _jsx(DeleteOutlined, {}),
                        label: t('drawers.item.form.modifiers.dropdownOptions.delete'),
                        action: () => modifier.id && handleDelete(modifier.id)
                    }
                ] })] }));
}
