import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { lighten } from '@mui/material';
const OrderDistibution = ({ data }) => {
    const [t] = useTranslation('store');
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorData, setAnchorData] = useState(null);
    const handlePopoverOpen = (event, data) => {
        setAnchorData(data);
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (_jsxs(_Fragment, { children: [_jsx(Box, { mb: { xs: 5, sm: 0 }, px: { xs: 2, sm: 4 }, py: 4, bgcolor: { xs: '#F5F5F5', sm: '#fff' }, children: _jsx(Box, { height: 32, borderRadius: 2, gap: '2px', display: 'flex', overflow: 'hidden', children: data.map((item) => (_jsx(Box, { bgcolor: item.color, flex: item.percentage + '%', sx: { ':hover': { bgcolor: lighten(item.color, 0.3) } }, onMouseLeave: handlePopoverClose, onMouseEnter: (e) => handlePopoverOpen(e, item), onClick: (e) => handlePopoverOpen(e, item) }, item.title))) }) }), _jsx(Popover, { id: 'mouse-over-popover', sx: { pointerEvents: 'none' }, open: open, anchorEl: anchorEl, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center'
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }, onClose: handlePopoverClose, disableRestoreFocus: true, children: _jsxs(Typography, { sx: { p: 1 }, children: [anchorData?.title, " \u2022 ", anchorData?.count, ' ', t('activity.legend.common.orders'), " (", anchorData?.percentage, "%)"] }) })] }));
};
export default OrderDistibution;
