import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { fetchApi } from '../../config/Api';
import { getRemainingDays, getProgressPercentage } from '../selectNetwork/utils/helpers';
import { generateImageUrl } from '../UtilityFunctions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { blue, green, red } from '@mui/material/colors';
const RestaurantItem = (props) => {
    const { restaurant, selectedNetwork, status, setStatus, refetchRestaurants } = props;
    const [t] = useTranslation('common');
    const chipLabel = status === 'deleted' && restaurant.deleted_at
        ? `${getRemainingDays(restaurant.deleted_at)} ${t('restaurantCard.chipLabel.deleted')}`
        : restaurant.stepper
            ? t('restaurantCard.chipLabel.draft')
            : restaurant.is_subscription_active
                ? t('restaurantCard.chipLabel.online')
                : t('restaurantCard.chipLabel.offline');
    const chipStatus = status === 'deleted'
        ? 'default'
        : restaurant.stepper
            ? 'info'
            : restaurant.is_subscription_active
                ? 'success'
                : 'error';
    const chipIcon = status === 'deleted' ? _jsx(AccessTimeIcon, { fontSize: 'small' }) : undefined;
    const handleItemClick = () => {
        if (status === 'deleted')
            return;
        if (restaurant.stepper && restaurant.stepper.current_step <= 5) {
            window.location.href = `/create-restaurant/?restaurant=${restaurant.id}&network=${selectedNetwork?.id}`;
        }
        else {
            window.location.href = `/?restaurant=${restaurant.id}&network=${selectedNetwork?.id}`;
        }
        localStorage.setItem('network', JSON.stringify(selectedNetwork));
    };
    const handleButtonClick = () => {
        if (status === 'deleted') {
            fetchApi({
                method: 'POST',
                url: '/restaurant/restore',
                data: {
                    restaurant_id: restaurant.id
                }
            }).then(() => {
                setStatus('active');
                refetchRestaurants();
            });
            return;
        }
        if (restaurant.stepper && restaurant.stepper.current_step <= 5) {
            window.location.href = `/create-restaurant/?restaurant=${restaurant.id}&network=${selectedNetwork?.id}`;
        }
        else {
            window.location.href = `/?restaurant=${restaurant.id}&network=${selectedNetwork?.id}`;
        }
    };
    return (_jsx(ListItem, { disableGutters: true, disablePadding: true, divider: true, children: _jsxs(ListItemButton, { onClick: handleItemClick, sx: {
                py: 3,
                gap: 2,
                display: 'flex',
                alignItems: 'unset',
                flexDirection: 'column'
            }, children: [_jsxs(Box, { display: 'flex', justifyContent: 'space-between', children: [_jsx(Chip, { size: 'medium', variant: 'filled', label: chipLabel, color: chipStatus, avatar: chipIcon, sx: (theme) => ({
                                height: 24,
                                fontSize: 16,
                                borderRadius: 2,
                                '&.MuiChip-colorSuccess': {
                                    backgroundColor: green[50],
                                    color: theme.palette.success.main
                                },
                                '&.MuiChip-colorError': {
                                    backgroundColor: red[50],
                                    color: theme.palette.error.main
                                },
                                '&.MuiChip-colorInfo': {
                                    backgroundColor: blue[50],
                                    color: theme.palette.info.main
                                }
                            }) }), _jsx(Button, { sx: {
                                px: 2,
                                height: 34,
                                bgcolor: 'grey.200',
                                borderRadius: '17px',
                                fontSize: 14,
                                fontWeight: 600,
                                color: 'GrayText',
                                textTransform: 'capitalize'
                            }, endIcon: _jsx(ArrowForwardIcon, { color: 'action', fontSize: 'small' }), onClick: handleButtonClick, children: status === 'deleted'
                                ? t('restaurantCard.restoreBtnLabel')
                                : t('restaurantCard.selectBtnLabel') })] }), _jsxs(Box, { gap: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', children: [_jsxs(Box, { gap: 1, display: 'flex', flexDirection: 'column', children: [_jsx(Typography, { fontSize: 18, fontWeight: 600, children: restaurant.name }), restaurant.address && (_jsxs(Box, { display: 'flex', gap: 0.5, alignItems: 'center', children: [_jsx(LocationOnIcon, { fontSize: 'small', color: 'action' }), _jsxs(Typography, { fontSize: 14, fontWeight: 600, color: 'text.secondary', sx: (theme) => ({
                                                [theme.breakpoints.up('sm')]: {
                                                    maxWidth: 300,
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis'
                                                }
                                            }), children: [restaurant.address?.street, ", ", restaurant.address?.zip, ",", ' ', restaurant.address?.state] })] }))] }), restaurant.stepper ? (_jsxs(Box, { p: '6px', border: 1, borderColor: blue[500], bgcolor: blue[50], borderRadius: 3, children: [_jsx(Typography, { variant: 'body1', children: t('restaurantCard.chipLabel.draft') }), _jsxs(Typography, { variant: 'body2', color: 'text.secondary', children: [t('restaurantCard.chipLabel.completed'), ":", ' ', _jsx(Typography, { component: 'span', color: blue[500], children: getProgressPercentage(restaurant.stepper.current_step) })] })] })) : restaurant.slider?.simages?.length > 0 ? (_jsx(Box, { src: generateImageUrl(restaurant.slider.simages[0].url_path), width: 54, height: 54, borderRadius: 3, component: 'img', sx: { objectFit: 'cover' } })) : null] })] }) }));
};
export default RestaurantItem;
