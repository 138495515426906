import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { fetchApi } from '../../../config/Api';
import { getRestaurantId, getNetworkId, getErrorMessage } from '../../UtilityFunctions';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTheme, useMediaQuery } from '@mui/material';
import ActivityLogRow from './ActivityLogRow';
import Pagination from '../../components/Pagination';
import SearchField from '../../components/SearchField';
import { RoundedButton } from '../../components/styled';
export default function ActivityLogsTable(props) {
    const { title, disableSearch, disablePagination, viewMoreButton, small } = props;
    const [t] = useTranslation('store');
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 10,
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 0
    });
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [query, setQuery] = useState('');
    const debouncedHandleValueChange = useCallback(_.debounce((newValue) => {
        setQuery(newValue);
    }, 500), []);
    useEffect(() => {
        setPage(1);
        debouncedHandleValueChange(searchTerm);
        return () => {
            debouncedHandleValueChange.cancel();
        };
    }, [searchTerm, debouncedHandleValueChange]);
    const fetchActivityLogs = async (nextPage) => {
        try {
            if (nextPage) {
                setIsFetching(true);
            }
            else {
                setIsLoading(true);
            }
            const restaurantId = getRestaurantId();
            const params = new URLSearchParams({
                page: nextPage?.toString() ?? page.toString(),
                perPage: perPage.toString(),
                ...(query.length > 0 && { identifier: query.replace('#', '') })
            });
            const res = await fetchApi({
                method: 'GET',
                url: `/restaurants/${restaurantId}/orders/activity?${params}`
            });
            if (nextPage) {
                setData((prev) => [...prev, ...res.data.ordersActivity]);
            }
            else {
                setData(res.data.ordersActivity);
            }
            setPagination(res.data.pagination);
        }
        catch (err) {
            window.globalToast({
                type: 'error',
                title: 'Error',
                description: getErrorMessage(err.response)
            });
        }
        finally {
            setIsFetching(false);
            setIsLoading(false);
        }
    };
    const handleClick = () => {
        const networkId = getNetworkId();
        const restaurantId = getRestaurantId();
        const to = `/store/activity-logs?network=${networkId}&restaurant=${restaurantId}`;
        window.location.href = to;
    };
    useEffect(() => {
        fetchActivityLogs();
    }, [query, page, perPage]);
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const showPagination = !isLoading &&
        !!data.length &&
        pagination.total > 10 &&
        isMdUp &&
        !disablePagination;
    const showSeeMoreBtn = !isLoading && !isMdUp && pagination.current_page !== pagination.last_page;
    const columns = t('activity.logs.columns', {
        returnObjects: true
    });
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { maxWidth: viewMoreButton ? 'auto' : 448, display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4, children: [!disableSearch && (_jsx(SearchField, { placeholder: t('activity.logs.page.searchFieldPlaceholder'), value: searchTerm, onChange: setSearchTerm, onClear: () => {
                            setQuery('');
                            setSearchTerm('');
                        } })), title && (_jsx(Typography, { fontSize: 14, fontWeight: 600, children: title })), viewMoreButton && (_jsx(RoundedButton, { variant: 'contained', endIcon: _jsx(ArrowForwardIosIcon, { sx: { height: '20px' } }), onClick: handleClick, children: t('activity.logs.btnLabel') }))] }), isLoading ? (_jsx(Box, { sx: {
                    mt: 18,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column'
                }, children: _jsx(CircularProgress, {}) })) : !data.length ? (_jsxs(Box, { sx: {
                    mt: 18,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column'
                }, children: [_jsx(PersonSearchOutlinedIcon, { color: 'action', fontSize: 'large', sx: { mb: 2 } }), _jsx(Typography, { mb: 1, fontWeight: 600, children: t('activity.logs.page.emptyState.title') }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', children: t('activity.logs.page.emptyState.description') })] })) : (_jsxs(Table, { size: small ? 'small' : undefined, children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { sx: { fontWeight: 600 }, children: columns[0] }), _jsxs(TableCell, { sx: { fontWeight: 600 }, children: [" ", columns[1]] }), _jsx(TableCell, { sx: {
                                        fontWeight: 600,
                                        display: { xs: 'none', sm: 'table-cell' }
                                    }, children: columns[2] }), _jsx(TableCell, { sx: {
                                        fontWeight: 600,
                                        display: { xs: 'none', md: 'table-cell' }
                                    }, children: columns[3] }), _jsx(TableCell, { sx: {
                                        fontWeight: 600,
                                        display: { xs: 'none', md: 'table-cell' }
                                    }, children: columns[4] }), _jsx(TableCell, { sx: {
                                        display: { md: 'none' }
                                    } })] }) }), _jsx(TableBody, { children: data.map((log) => (_jsx(ActivityLogRow, { data: log }, log.id))) })] })), showPagination && (_jsx(Pagination, { pagination: pagination, perPage: perPage, setPerPage: setPerPage, setPage: setPage })), showSeeMoreBtn && (_jsx(Box, { sx: { mx: 4, my: 2 }, children: _jsx(RoundedButton, { fullWidth: true, variant: 'contained', onClick: () => fetchActivityLogs(pagination.current_page + 1), children: isFetching ? _jsx(CircularProgress, { size: '24px' }) : 'See more' }) }))] }));
}
