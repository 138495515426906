import Storage from "../config/Storage";
import moment from "moment";
import _ from "lodash";
/**
 * Utility functions for general purposes.
 */
/**
 * @param input - HTML element
 * @param photoCoverId - img(html element) where to replace the path
 */
export function makeImagePreview(input, photoCoverId) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
            //  @ts-ignore
            $(photoCoverId).attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
    }
}
export function selfSetHeight() {
    let selfSetHeightElem = $(".js-self-set-height");
    if (selfSetHeightElem) {
        let height = $(selfSetHeightElem).height();
        if (height) {
            $(selfSetHeightElem).css({
                minHeight: height,
                height: "100%",
            });
        }
    }
}
export function getNetworkId() {
    var url = new URL(window.location.href);
    let urlNetworkId = url.searchParams.get("network");
    // console.log(urlNetworkId);
    if (urlNetworkId) {
        return urlNetworkId;
    }
    if (Storage.getItem("networkId")) {
        return Storage.getItem("networkId");
    }
    if (Storage.getItem("network")) {
        return Storage.getItem("network");
    }
    return 0;
}
export function getRestaurantId() {
    var url = new URL(window.location.href);
    let urlNetworkId = url.searchParams.get("restaurant");
    // console.log('restaurant id from url: ',urlNetworkId);
    if (urlNetworkId) {
        return urlNetworkId;
    }
    if (Storage.getItem("restaurant")) {
        return Storage.getItem("restaurant");
    }
    if (Storage.getItem("restaurantId")) {
        return Storage.getItem("restaurantId");
    }
    return 0;
}
export function globalToast({ type = "info", title, description, timeOut = 3000, }) {
    let toastTimeStampClass = Math.round(new Date().getTime() / 1000);
    $(".js-toast-container").append(`
    <div
        class="toast js-toast-${toastTimeStampClass}"
        role="alert" aria-live="polite" aria-atomic="true" data-delay="${timeOut}"
        style="width: 300px;"
    >
        <div role="alert" aria-live="assertive" aria-atomic="true">
                 <div class="toast-header px-3 py-1 justify-between flex items-center">
                    <div class="flex items-center">
                        <div class="h-2 w-2 rounded-full
                        ${type == "info"
        ? "bg-blue-500"
        : type == "error"
            ? "bg-red-500"
            : type == "warning"
                ? "bg-orange-500"
                : type == "success"
                    ? "bg-green-500"
                    : ""}
                        "></div>
                        <span class="ml-3 text-sm text-bold">${title}</span>
                    </div>

                    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            <div class="toast-body p-3">
                ${description}
            </div>
        </div>
    </div>
    `);
    // @ts-ignore
    $(`.js-toast-${toastTimeStampClass}`).toast("show");
    $(`.js-toast-${toastTimeStampClass}`).on("hidden.bs.toast", function () {
        $(this).remove();
    });
}
export const getCurrency = () => {
    const currencies = {
        USD: "$",
        LEK: "LEK",
        EUR: "€",
    };
    const currency = JSON.parse(Storage.getItem("currency") ?? "USD");
    return currencies[currency];
};
export const getErrorMessage = (error) => {
    if (error &&
        typeof error === "object" &&
        "message" in error &&
        typeof error.message === "string") {
        return error.message;
    }
    else if (typeof error === "object" &&
        error &&
        "data" in error &&
        typeof error.data === "object" &&
        error.data &&
        "message" in error.data &&
        typeof error.data.message === "string") {
        return error.data.message;
    }
    else if (typeof error === "object" &&
        error &&
        "data" in error &&
        typeof error.data === "object" &&
        error.data &&
        "error_message" in error.data &&
        typeof error.data.error_message === "string") {
        return error.data.error_message;
    }
    else {
        return "Something went wrong!";
    }
};
export const stringifySelectedOptions = (options) => {
    const groupedOptions = _.groupBy(options, "parentModifierGroup");
    const stringifiedOptions = Object.keys(groupedOptions)
        .map((key) => {
        const options = groupedOptions[key]
            .map((option) => option.name + (option.quantity > 1 ? ` x${option.quantity}` : ""))
            .join(", ");
        const string = `${key} - ${options}`;
        return string;
    })
        .join(" | ");
    return stringifiedOptions;
};
export const formatDate = (date) => {
    return moment(date).format("DD MMM YYYY");
};
export const generateImageUrl = (path) => {
    return `https://ordersmile.com/${path}`;
};
export const getStatusStyles = (status) => {
    let label = "", color = "info.main", severity = "info";
    switch (status) {
        case "awaiting_client":
            label = "Pending";
            color = "warning.main";
            severity = "warning";
            break;
        case "confirmed":
            label = "Confirmed";
            color = "info.main";
            severity = "info";
            break;
        case "reject":
            label = "Rejected";
            color = "error.main";
            severity = "error";
            break;
        case "cancelled":
            label = "Canceled";
            color = "error.main";
            severity = "error";
            break;
        case "done":
            label = "Completed";
            color = "success.main";
            severity = "success";
            break;
        case "ready":
            label = "Ready";
            color = "info.main";
            severity = "info";
            break;
        case "no_show":
            label = "No Show";
            color = "error.main";
            severity = "error";
            break;
        case "in_delivery":
            label = "In Delivery";
            color = "info.main";
            severity = "info";
            break;
        case "collected_by_delivery":
            label = "Collected By Delivery";
            color = "info.main";
            severity = "info";
            break;
        default:
            break;
    }
    return { label, color, severity };
};
export const formatPrice = (value) => {
    const num = parseFloat(value.replace(",", "."));
    return isNaN(num) ? "0.00" : num.toFixed(2);
};
