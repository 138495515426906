import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { SelectNetworkContext } from "../../selectNetwork/SelectNetworkProvider";
import { generateImageUrl, getNetworkId } from "../../UtilityFunctions";
import { fetchApi } from "../../../config/Api";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Popover from "@mui/material/Popover";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import RestaurantDrawerHeader from "../../components/RestaurantDrawerHeader";
import RestaurantList from "../../components/RestaurantList";
import CreateBranchButton from "../../components/CreateBranchButton";
function NetworkSidebar() {
    const [searchTerm, setSearchTerm] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [status, setStatus] = useState("active");
    const [canCreateBranch, setCanCreateBranch] = useState(false);
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [restaurants, setRestaurants] = useState({
        active: [],
        deleted: [],
    });
    const networkId = getNetworkId();
    const { user, networks } = useContext(SelectNetworkContext);
    const getRestaurants = ({ networkId }) => {
        fetchApi({
            method: "GET",
            url: `/active-deleted-restaurants/${networkId}`,
        }).then((res) => {
            setRestaurants(res.data.network.allRestaurants);
            setCanCreateBranch(res.data.userCanCreateBranch);
        });
    };
    useEffect(() => {
        if (selectedNetwork) {
            getRestaurants({ networkId: selectedNetwork.id });
        }
    }, [selectedNetwork]);
    const restaurantList = status === "active" ? restaurants.active : restaurants.deleted;
    return (_jsxs("div", { className: "bg-blueDarker min-h-screen hidden xl:block", children: [_jsxs("ul", { className: "py-6 flex flex-col items-center gap-3", style: { width: 70 }, children: [networks?.map((item) => (_jsx("li", { className: clsx("p-1", "border-2", "rounded-2xl", networkId === item.id?.toString()
                            ? "border-white"
                            : "border-transparent"), children: _jsx(Tooltip, { placement: "right", title: item.title, children: _jsx("button", { style: { display: "grid" }, onClick: (e) => {
                                    setSelectedNetwork(item);
                                    setAnchorEl(e.currentTarget);
                                }, children: _jsx(Box, { component: "img", alt: "network image", src: item.logo
                                        ? generateImageUrl(item.logo)
                                        : "images/networks/logo/logo.png", sx: {
                                        width: 38,
                                        height: 38,
                                        objectFit: "cover",
                                        borderRadius: 3,
                                    } }) }) }) }, item.id))), _jsx("li", { className: "p-1 border-2 border-transparent", children: _jsx(Box, { component: "a", href: "/select-network", sx: {
                                width: 38,
                                height: 38,
                                borderRadius: 3,
                                display: "grid",
                                placeContent: "center",
                                color: "white",
                                backgroundColor: "#ffffff33",
                            }, children: _jsx(MoreHorizOutlinedIcon, { color: "inherit" }) }) })] }), _jsx(Popover, { open: Boolean(anchorEl), onClose: () => setAnchorEl(null), anchorEl: anchorEl, anchorOrigin: { horizontal: "left", vertical: "top" }, PaperProps: { sx: { maxWidth: 448, borderRadius: 4 } }, children: selectedNetwork && user && (_jsxs(_Fragment, { children: [_jsx(RestaurantDrawerHeader, { top: 0, onClose: () => {
                                setAnchorEl(null);
                                setRestaurants({ active: [], deleted: [] });
                                setSelectedNetwork(null);
                            }, selectedNetwork: selectedNetwork, status: status, setStatus: setStatus, user: user, searchTerm: searchTerm, setSearchTerm: setSearchTerm }), _jsx(RestaurantList, { restaurants: restaurantList.filter((restaurant) => restaurant.name.toLocaleLowerCase().includes(searchTerm)), selectedNetwork: selectedNetwork, status: status, setStatus: setStatus, refetchRestaurants: () => getRestaurants({ networkId: selectedNetwork.id }) }), status !== "deleted" && canCreateBranch && (_jsx(Box, { sx: {
                                p: 2,
                                mt: "auto",
                                position: "sticky",
                                bottom: 0,
                                bgcolor: "white",
                                boxShadow: "0px -4px 10px 0px #00000026",
                            }, children: _jsx(CreateBranchButton, { user: user, selectedNetwork: selectedNetwork }) }))] })) })] }));
}
export default NetworkSidebar;
