import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useReducer } from "react";
import { reducer } from "./reducer";
import { ActionType } from "./types";
import { fetchApi } from "../../../config/Api";
import { getNetworkId, getRestaurantId } from "../../UtilityFunctions";
const initialState = {
    selectedPanel: 'overview',
    selectedOverviewMenu: 'categories',
    isItemDrawerOpen: false,
    isCategoryDrawerOpen: false,
    isModifierDrawerOpen: false,
    isMenuDrawerOpen: false,
    menus: [],
    selectedMenu: null,
    editMenu: null,
    loadingMenus: false,
    categories: [],
    loadingCategories: false,
    draftCategory: null,
    items: [],
    loadingItems: false,
    draftItem: null,
    modifiers: [],
    loadingModifiers: false,
    draftModifier: null,
    // actions
    toggleDrawer() { },
    getMenus() { },
    getCategories() { },
    getItems() { },
    dispatch() { },
    getModifiers() { },
};
export const MenuContext = createContext(initialState);
export default function MenuProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const networkId = getNetworkId();
    const restaurantId = getRestaurantId();
    const toggleDrawer = (payload) => {
        dispatch({
            type: ActionType.TOGGLE_DRAWER,
            payload
        });
    };
    const getMenus = () => {
        dispatch({
            type: ActionType.GET_MENUS,
            payload: { loadingMenus: true }
        });
        fetchApi({
            method: "GET",
            url: `/new-menus?network_id=${networkId}`,
        })
            .then((res) => {
            dispatch({
                type: ActionType.GET_MENUS,
                payload: {
                    menus: res.data.menus,
                    loadingMenus: false
                }
            });
            if (res.data.menus.length) {
                dispatch({ type: ActionType.SET_SELECTED_MENU, payload: res.data.menus[0] });
            }
            else {
                dispatch({ type: ActionType.SET_SELECTED_MENU, payload: null });
            }
        });
    };
    const getCategories = () => {
        dispatch({
            type: ActionType.GET_CATEGORIES,
            payload: { loadingCategories: true }
        });
        fetchApi({
            method: "GET",
            url: `/menu/categories?network_id=${networkId}`,
        })
            .then((res) => {
            dispatch({
                type: ActionType.GET_CATEGORIES,
                payload: {
                    categories: res.data,
                    loadingCategories: false
                }
            });
        });
    };
    const getItems = () => {
        dispatch({
            type: ActionType.GET_ITEMS,
            payload: { loadingItems: true }
        });
        fetchApi({
            method: "GET",
            url: `/menu/items?network=${networkId}&restaurant=${restaurantId}`,
        }).then((res) => {
            dispatch({
                type: ActionType.GET_ITEMS,
                payload: {
                    items: res.data,
                    loadingItems: false
                }
            });
        });
    };
    const getModifiers = () => {
        dispatch({
            type: ActionType.GET_MODIFIERS,
            payload: { loadingModifiers: true }
        });
        fetchApi({
            method: 'GET',
            url: `/modifier-groups?network_id=${networkId}&restaurant=${restaurantId}`
        }).then((res) => {
            dispatch({
                type: ActionType.GET_MODIFIERS,
                payload: {
                    modifiers: res.data.modifierGroups.flat(),
                    loadingModifiers: false
                }
            });
        });
    };
    return (_jsx(MenuContext.Provider, { value: {
            ...state,
            toggleDrawer,
            getMenus,
            getCategories,
            getItems,
            getModifiers,
            dispatch
        }, children: children }));
}
