import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoginForm from './components/LoginForm';
import Logo from './components/Logo';
import LanguageSelect from '../components/common/LanguageSelect';
export default function RootLogin() {
    const { t } = useTranslation('auth');
    return (_jsxs(Box, { display: 'grid', gridTemplateColumns: { xs: '1fr', md: '0.35fr 0.75fr' }, minHeight: '100vh', children: [_jsx(Box, { bgcolor: '#EEEEEE', display: { xs: 'none', md: 'block' } }), _jsxs(Stack, { alignItems: 'center', justifyContent: 'space-evenly', mx: 2, position: 'relative', children: [_jsx(Box, { position: 'absolute', top: 2, right: 2, children: _jsx(LanguageSelect, { skipUserUpdate: true }) }), _jsxs(Stack, { width: '100%', maxWidth: 500, alignItems: 'center', children: [_jsx(Logo, {}), _jsx(Typography, { mt: 2.5, mb: 2, component: 'h2', fontSize: 34, fontWeight: 600, children: t('title') }), _jsx(Typography, { mb: 3, children: t('subtitle') }), _jsx(LoginForm, {})] }), _jsxs(Stack, { gap: 2, width: '100%', maxWidth: 300, alignItems: 'center', children: [_jsx(Typography, { fontSize: 20, fontWeight: 600, children: t('footer.title') }), _jsx(Button, { fullWidth: true, size: 'large', color: 'inherit', variant: 'outlined', sx: {
                                    borderColor: 'divider',
                                    borderRadius: 3,
                                    textTransform: 'none'
                                }, children: t('footer.btnLabel') })] })] })] }));
}
