import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { OSOuterContainer, OSInnerContainer, OSContentBox, OSSwitchBox } from './OSCustomComponents';
import { validationDiv } from './utilities';
import { SettingsContext } from '../SettingsProvider';
function PaymentSettings() {
    const [t] = useTranslation('settings');
    const { paymentSettings, setPaymentSettings, validationErrors, filterValidation } = useContext(SettingsContext);
    return paymentSettings ? (_jsx(_Fragment, { children: _jsx(OSOuterContainer, { children: _jsxs(OSInnerContainer, { children: [_jsx(Typography, { sx: {
                            paddingLeft: '0.5rem',
                            fontSize: '0.875rem',
                            marginBottom: '1rem'
                        }, children: t('payment.description') }), _jsxs(OSContentBox, { children: [_jsx(OSSwitchBox, { children: _jsx(FormControlLabel, { checked: paymentSettings.new_restaurant_cash_payment ? true : false, value: paymentSettings.new_restaurant_cash_payment, control: _jsx(Checkbox, {}), defaultValue: 'false', id: 'new-restaurant-cash-payment', sx: {
                                        '.MuiFormControlLabel-labelPlacementStart': {
                                            justifyContent: 'space-between'
                                        },
                                        '.MuiBox-root': {
                                            width: '100%'
                                        },
                                        '.MuiCheckbox-root': {
                                            display: 'flex'
                                        }
                                    }, label: t('payment.cash'), labelPlacement: 'start', onChange: () => {
                                        setPaymentSettings({
                                            ...paymentSettings,
                                            new_restaurant_cash_payment: !paymentSettings.new_restaurant_cash_payment
                                            // TODO: Enable Later
                                            // !paymentSettings.new_restaurant_cash_payment,
                                        });
                                        filterValidation('cash');
                                    } }) }), _jsx(OSSwitchBox, { children: _jsx(FormControlLabel, { checked: paymentSettings.new_restaurant_credit_card_payment
                                        ? true
                                        : false, value: paymentSettings.new_restaurant_credit_card_payment, control: _jsx(Checkbox, {}), defaultValue: 'false', id: 'new-restaurant-credit-card-payment', label: t('payment.card'), labelPlacement: 'start', onChange: () => {
                                        setPaymentSettings({
                                            ...paymentSettings,
                                            new_restaurant_credit_card_payment: !paymentSettings.new_restaurant_credit_card_payment
                                            // TODO: Enable Later
                                            // !paymentSettings.new_restaurant_credit_card_payment,
                                        });
                                        filterValidation('cash');
                                    } }) })] }), validationDiv(validationErrors?.cash)] }) }) })) : (_jsx(_Fragment, {}));
}
export default PaymentSettings;
