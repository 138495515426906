import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuContext } from '../context';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { useTheme, useMediaQuery } from '@mui/material';
import SearchField from './SearchField';
import { PrimaryButton, SecondaryButton } from './styled';
export default function AddModifierToItem(props) {
    const { defaultSelected, isOpen, setIsOpen, onSave } = props;
    const [t] = useTranslation('menu');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedModifiers, setSelectedModifiers] = useState([]);
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { modifiers, draftModifier } = useContext(MenuContext);
    const handleClose = () => {
        setIsOpen(false);
    };
    const handleChange = (modifier) => {
        if (!selectedModifiers.some((item) => item.id === modifier.id)) {
            setSelectedModifiers((prev) => [...prev, modifier]);
        }
        else {
            setSelectedModifiers((prev) => prev.filter((item) => item.id !== modifier.id));
        }
    };
    const handleSave = () => {
        onSave(selectedModifiers);
        setIsOpen(false);
    };
    useEffect(() => {
        if (defaultSelected) {
            setSelectedModifiers(defaultSelected);
        }
    }, [defaultSelected]);
    return (_jsxs(Dialog, { fullScreen: isMdDown, open: isOpen, onClose: handleClose, PaperProps: {
            sx: (theme) => ({
                width: 800,
                [theme.breakpoints.up('md')]: {
                    borderRadius: 3
                }
            })
        }, children: [_jsx(DialogTitle, { component: 'div', display: 'flex', justifyContent: 'flex-end', children: _jsx(IconButton, { onClick: handleClose, children: _jsx(CloseOutlined, {}) }) }), _jsxs(DialogContent, { sx: { mb: 2 }, children: [_jsxs(Box, { mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', children: [_jsx(Typography, { variant: 'h1', fontSize: 24, children: t('dialogs.addModifier.title') }), _jsx(SearchField, { placeholder: t('dialogs.addModifier.searchFieldPlaceholder'), value: searchTerm, onChange: setSearchTerm })] }), _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, {}), _jsx(TableCell, { children: t('dialogs.addModifier.columns.1') }), _jsx(TableCell, { children: t('dialogs.addModifier.columns.2') })] }) }), _jsx(TableBody, { children: modifiers
                                    .filter((modifier) => draftModifier?.id !== modifier.id)
                                    .filter((modifier) => modifier.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                    .map((modifier) => (_jsxs(TableRow, { hover: true, selected: selectedModifiers.some((item) => item.id === modifier.id), onClick: () => handleChange(modifier), sx: { cursor: 'pointer' }, children: [_jsx(TableCell, { sx: { width: 0 }, children: _jsx(Checkbox, { checked: selectedModifiers
                                                    .map((item) => item.id)
                                                    .includes(modifier.id) }) }), _jsx(TableCell, { children: modifier.name }), _jsx(TableCell, { children: modifier.options
                                                .flat()
                                                .map((option) => option.name)
                                                .join(', ') })] }, modifier.id))) })] })] }), _jsxs(DialogActions, { children: [_jsx(SecondaryButton, { variant: 'outlined', onClick: handleClose, children: t('dialogs.addModifier.secondaryBtnLabel') }), _jsx(PrimaryButton, { variant: 'contained', onClick: handleSave, children: false ? (_jsx(CircularProgress, { size: '24px' })) : (t('dialogs.addModifier.primaryBtnLabel')) })] })] }));
}
