import { jsx as _jsx } from "react/jsx-runtime";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, } from "@dnd-kit/core";
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, } from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
export default function SortableContainer(props) {
    const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
    }));
    return (_jsx(DndContext, { sensors: sensors, collisionDetection: closestCenter, onDragEnd: props.onDragEnd, modifiers: [restrictToVerticalAxis], children: _jsx(SortableContext, { items: props.items, strategy: verticalListSortingStrategy, children: props.children }) }));
}
