import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNetworkContext } from '../SelectNetworkProvider';
import { generateImageUrl } from '../../UtilityFunctions';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import StorefrontOutlined from '@mui/icons-material/StorefrontOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import ConfirmationDialog from '../../components/ConfirmationDialog';
export default function NetworkItem(props) {
    const { id, title, restaurants_no, current_subscription, restaurants_current_nr, restaurants_availability } = props;
    const { t } = useTranslation('network');
    const dialogRef = useRef();
    const { user, setSelectedNetwork, setIsNetworkDrawerOpen } = useNetworkContext();
    const handleClick = () => {
        if (current_subscription === null && restaurants_no === 0) {
            dialogRef.current?.open();
            return;
        }
        setSelectedNetwork(props);
    };
    const isUserAdmin = user?.roles.some((role) => role.name === 'admin');
    return (_jsxs(_Fragment, { children: [_jsx(ConfirmationDialog, { ref: dialogRef, severity: 'info', title: t('dialogs.noActivePlan.title'), description: t('dialogs.noActivePlan.description', { name: title }), primaryButtonLabel: isUserAdmin ? t('dialogs.noActivePlan.primaryBtnLabel') : undefined, onPrimaryButtonClick: () => location.replace(`/get-your-plan?id=${id}`), secondaryButtonLabel: isUserAdmin ? t('dialogs.noActivePlan.secondaryBtnLabel') : undefined, onSecondaryButtonClick: () => dialogRef.current?.close(), icon: _jsx(UploadOutlinedIcon, { color: 'info', fontSize: 'large' }), onClose: () => dialogRef.current.close() }), _jsx(ListItem, { disableGutters: true, disablePadding: true, sx: {
                    border: 1,
                    borderColor: 'grey.300',
                    borderRadius: 4
                }, children: _jsxs(ListItemButton, { onClick: handleClick, sx: {
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: 4,
                        gap: 3,
                        py: 2,
                        px: 3
                    }, children: [_jsx(Box, { component: 'img', src: props.logo
                                ? generateImageUrl(props.logo)
                                : 'images/networks/logo/logo.png', alt: 'network logo', sx: {
                                width: 56,
                                height: 56,
                                borderRadius: '28px',
                                objectFit: 'cover'
                            } }), _jsxs(Box, { sx: {
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                flexGrow: 1
                            }, children: [_jsx(Typography, { variant: 'h6', fontWeight: 600, fontSize: 20, children: props.title }), _jsxs(Box, { sx: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1.5
                                    }, children: [_jsx(StorefrontOutlined, { color: 'action' }), _jsx(Typography, { variant: 'body1', color: 'text.secondary', children: current_subscription
                                                ? t('networkCard.planStatus.1', {
                                                    count: restaurants_current_nr,
                                                    total: restaurants_current_nr + restaurants_availability
                                                })
                                                : restaurants_current_nr > 0
                                                    ? t('networkCard.planStatus.2', {
                                                        count: restaurants_current_nr
                                                    })
                                                    : t('networkCard.planStatus.3', { count: restaurants_no }) })] })] }), _jsx(IconButton, { onClick: (e) => {
                                e.stopPropagation();
                                setSelectedNetwork(props);
                                setIsNetworkDrawerOpen(true);
                            }, children: _jsx(EditOutlinedIcon, {}) })] }) }, props.id)] }));
}
