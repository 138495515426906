import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuContext } from '../context';
import { ActionType } from '../context/types';
import Box from '@mui/material/Box';
import MenuSelect from './MenuSelect';
import CategoriesList from './CategoriesList';
import ItemsTable from './ItemsTable';
import ToggleGroup from './ToggleGroup';
import { useTheme, useMediaQuery } from '@mui/material';
function OverviewPanel() {
    const [t] = useTranslation('menu');
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const { dispatch, selectedOverviewMenu } = useContext(MenuContext);
    return (_jsx(_Fragment, { children: _jsxs(Box, { sx: (theme) => ({
                display: 'grid',
                gridTemplateColumns: 'minmax(auto, 440px) 1fr',
                gridTemplateRows: 'auto 1fr auto',
                maxWidth: 1600,
                overflowY: 'auto',
                [theme.breakpoints.down('lg')]: {
                    gridTemplateColumns: '1fr',
                    pb: 10
                }
            }), children: [_jsx(MenuSelect, {}), isLgDown ? (selectedOverviewMenu === 'categories' && _jsx(CategoriesList, {})) : (_jsx(CategoriesList, {})), isLgDown ? (selectedOverviewMenu === 'items' && _jsx(ItemsTable, {})) : (_jsx(ItemsTable, {})), isLgDown && (_jsx(Box, { sx: {
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        bgcolor: '#fff',
                        py: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        boxShadow: '0px -4px 10px 0px rgba(0, 0, 0, 0.07)'
                    }, children: _jsx(ToggleGroup, { value: selectedOverviewMenu, onChange: (value) => dispatch({
                            type: ActionType.SET_SELECTED_OVERVIEW_MENU,
                            payload: value
                        }), options: [
                            { label: t('overview.toggle.categories'), value: 'categories' },
                            { label: t('overview.toggle.items'), value: 'items' }
                        ] }) }))] }) }));
}
export default OverviewPanel;
