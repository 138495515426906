import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { SettingsContext } from '../SettingsProvider';
import { OSOuterContainer, OSInnerContainer, OSContentBox, OSTimelineTextBox, OSTimelineSwitchBox, OSTimelineDay, OSTimelineSwitch, OSTimelineRangesBox, OSTimelineRow, OSTimelinesBox } from './OSCustomComponents';
import { validationDiv, days } from './utilities';
import { MenuItem, InputAdornment } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import { RoundedTextfield } from '../../menu/components/styled';
import timePickerOptions from '../../../constants/time-picker-options';
function DeliveryTimelines() {
    const [t] = useTranslation('settings');
    const { deliveryTimelines, setDeliveryTimelines, validationErrors, filterValidation } = useContext(SettingsContext);
    function setDay(day) {
        let workArr = deliveryTimelines;
        workArr[day].active = !workArr[day].active;
        console.log('workarr: ', workArr);
        setDeliveryTimelines({
            ...deliveryTimelines,
            [day]: workArr[day]
        });
    }
    function setWorkHours(day, type, time) {
        let workArr = deliveryTimelines;
        // @ts-ignore
        workArr[day][type] = time;
        console.log('workarr: ', workArr);
        // @ts-ignore
        setDeliveryTimelines({
            ...deliveryTimelines,
            [day]: workArr[day]
        });
    }
    function getSelectValue(day) {
        if (deliveryTimelines[day].from_reservation_START === '00:00:00' &&
            deliveryTimelines[day].from_reservation_END === '23:59:59') {
            return {
                opensAt: '23:59:59',
                closesAt: ''
            };
        }
        else {
            return {
                opensAt: deliveryTimelines[day].from_reservation_START,
                closesAt: deliveryTimelines[day].from_reservation_END
            };
        }
    }
    return (_jsx(OSOuterContainer, { sx: {
            maxWidth: {
                md: 'auto'
            },
            '.div-child-min-w-0 div': {
                minWidth: 'auto'
            }
        }, children: _jsxs(OSInnerContainer, { children: [_jsx(OSContentBox, { children: _jsxs(OSTimelinesBox, { children: ["days", days.map((day, index) => {
                                return day ? (
                                // row holder
                                _jsxs(OSTimelineRow, { children: [_jsxs(OSTimelineTextBox, { children: [_jsxs(OSTimelineSwitchBox, { item: deliveryTimelines[day].active, children: [_jsx(OSTimelineDay, { children: _jsx(Typography, { children: t('timelines.days', { returnObjects: true })[index] }) }), _jsx(OSTimelineSwitch, { checked: deliveryTimelines[day].active, control: _jsx(Switch, {}), value: deliveryTimelines[day].active, id: 'new-restaurant-cash-payment', label: deliveryTimelines[day].active
                                                                ? t('timelines.open')
                                                                : t('timelines.closed'), labelPlacement: 'end', onChange: () => {
                                                                setDay(day);
                                                                validationDiv(validationErrors['delivery_timeline_' + day]);
                                                                filterValidation('delivery_timeline_' + day);
                                                                filterValidation('delivery_timeline');
                                                            } })] }), deliveryTimelines[day].active && (_jsxs(OSTimelineRangesBox, { children: [_jsx(RoundedTextfield, { fullWidth: true, select: true, id: 'time', label: t('timelines.opensAt'), value: getSelectValue(day).opensAt, onChange: (e) => {
                                                                setWorkHours(day, 'from_reservation_START', e.target.value);
                                                                setWorkHours(day, 'from_reservation_END', '');
                                                                filterValidation('delivery_timeline_' + day + '_start_time');
                                                                filterValidation('delivery_timeline_' + day);
                                                                if (e.target.value === '23:59:59') {
                                                                    setWorkHours(day, 'from_reservation_END', '');
                                                                }
                                                            }, slotProps: {
                                                                input: {
                                                                    endAdornment: (_jsx(InputAdornment, { position: 'start', children: _jsx(AccessTime, {}) }))
                                                                },
                                                                select: {
                                                                    IconComponent: () => null
                                                                }
                                                            }, sx: { mr: 1, maxWidth: 200, flexShrink: 0 }, children: timePickerOptions.map((option) => (_jsx(MenuItem, { value: option.value, children: option.label }, option.label))) }), _jsx(RoundedTextfield, { disabled: deliveryTimelines[day].from_reservation_START ===
                                                                '23:59:59' ||
                                                                deliveryTimelines[day].from_reservation_END ===
                                                                    '23:59:59', fullWidth: true, select: true, id: 'time', label: t('timelines.closesAt'), variant: 'outlined', value: getSelectValue(day).closesAt, onChange: (e) => {
                                                                setWorkHours(day, 'from_reservation_END', e.target.value);
                                                                filterValidation('delivery_timeline_' + day + '_end_time');
                                                                filterValidation('delivery_timeline_' + day);
                                                            }, slotProps: {
                                                                input: {
                                                                    endAdornment: (_jsx(InputAdornment, { position: 'start', children: _jsx(AccessTime, {}) }))
                                                                },
                                                                select: {
                                                                    IconComponent: () => null
                                                                }
                                                            }, sx: { maxWidth: 200, flexShrink: 0 }, children: timePickerOptions
                                                                .filter((_, i) => i !== 0)
                                                                .map((option) => (_jsx(MenuItem, { value: option.value, children: option.label }, option.label))) })] }))] }), validationDiv(validationErrors['delivery_timeline_' + day]), validationDiv(validationErrors['delivery_timeline_' + day + '_start_time']), validationDiv(validationErrors['delivery_timeline_' + day + '_end_time'])] }, index)) : (_jsx(React.Fragment, {}, uuidv4()));
                            })] }) }), validationDiv(validationErrors?.delivery_timeline)] }) }));
}
// @ts-ignore
export default DeliveryTimelines;
