import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import PickUpSettings from './PickUpSettings';
import DeliverySettings from './DeliverySettings';
import { SettingsContext } from '../SettingsProvider';
import { OSOuterContainer } from './OSCustomComponents';
import { validationDiv } from './utilities';
function DeliveryPickupSettings() {
    const { validationErrors } = useContext(SettingsContext);
    return (_jsxs(OSOuterContainer, { children: [_jsx(DeliverySettings, {}), _jsx("hr", { className: 'flex mx-6 mt-2 mb-2' }), _jsx(PickUpSettings, {}), validationDiv(validationErrors?.delivery_pickup_option)] }));
}
export default DeliveryPickupSettings;
