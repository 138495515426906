import { ActionType } from "./types";
export const reducer = (state, action) => {
    switch (action.type) {
        case ActionType.SET_SELECTED_PANEL: {
            return { ...state, selectedPanel: action.payload };
        }
        case ActionType.SET_SELECTED_OVERVIEW_MENU: {
            return { ...state, selectedOverviewMenu: action.payload };
        }
        case ActionType.TOGGLE_DRAWER: {
            return { ...state, [action.payload]: !state[action.payload] };
        }
        case ActionType.GET_MENUS: {
            return { ...state, ...action.payload };
        }
        case ActionType.SET_SELECTED_MENU: {
            return { ...state, selectedMenu: action.payload };
        }
        case ActionType.SET_EDIT_MENU: {
            return { ...state, editMenu: action.payload };
        }
        case ActionType.GET_CATEGORIES: {
            return { ...state, ...action.payload };
        }
        case ActionType.SET_DRAFT_CATEGORY: {
            return { ...state, draftCategory: action.payload };
        }
        case ActionType.GET_ITEMS: {
            return { ...state, ...action.payload };
        }
        case ActionType.SET_DRAFT_ITEM: {
            return { ...state, draftItem: action.payload };
        }
        case ActionType.UPDATE_ITEM: {
            return { ...state, items: state.items.map(item => item.id === action.payload.id ? action.payload : item) };
        }
        case ActionType.GET_MODIFIERS: {
            return { ...state, ...action.payload };
        }
        case ActionType.SET_DRAFT_MODIFIER: {
            return { ...state, draftModifier: action.payload };
        }
    }
};
