import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Page, Text, View, Document, StyleSheet, Svg, Path, Circle, Font, } from "@react-pdf/renderer";
import moment from "moment";
Font.register({
    family: "Roboto",
    fonts: [
        {
            fontWeight: "normal",
            src: "https://fonts.gstatic.com/s/roboto/v32/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf",
        },
        {
            fontWeight: 500,
            src: "https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmEU9vAx05IsDqlA.ttf",
        },
        {
            fontWeight: 700,
            src: "https://fonts.gstatic.com/s/roboto/v32/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf",
        },
    ],
});
// Create styles
const styles = StyleSheet.create({
    title: {
        fontSize: 22,
        fontWeight: 800,
        marginBottom: 16,
    },
    flex: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
});
// Create Document Component
const Pdf = ({ data }) => (_jsx(Document, { children: _jsxs(Page, { size: "A4", style: { padding: 24, fontFamily: "Roboto" }, children: [_jsx(Text, { style: styles.title, children: "Statement Summary" }), _jsxs(View, { style: styles.flex, children: [_jsxs(View, { children: [_jsx(Text, { style: { fontSize: 12, marginBottom: 4 }, children: data.restaurant?.name }), _jsxs(View, { style: {
                                    gap: 4,
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: 12,
                                }, children: [_jsx(LocationIcon, {}), _jsx(Text, { style: { fontSize: 10 }, children: data.restaurant?.address.street })] }), _jsxs(View, { style: { gap: 4 }, children: [_jsxs(View, { style: { flexDirection: "row", gap: 4, alignItems: "center" }, children: [_jsx(DateRangeIcon, {}), _jsxs(Text, { style: { fontSize: 10 }, children: [_jsx(Text, { style: { fontWeight: 500 }, children: "Period:" }), " ", moment(data.start_period_date).format("MMMM D, YYYY"), " to", " ", moment(data.end_period_date).format("MMMM D, YYYY")] })] }), _jsxs(View, { style: { flexDirection: "row", gap: 4, alignItems: "center" }, children: [_jsx(ReceiptIcon, {}), _jsxs(Text, { style: { fontSize: 10 }, children: [_jsx(Text, { style: { fontWeight: 500 }, children: "Statemend ID:" }), " ", data.id] })] })] })] }), _jsxs(View, { children: [_jsx(Text, { style: { fontSize: 10, fontWeight: 500, marginBottom: 8 }, children: "*Tax reminder" }), _jsxs(Text, { style: { maxWidth: 280, fontSize: 10, lineHeight: 1.4 }, children: ["We collect sales tax on each order and add it to your payouts. During this period the total was ", data.currency, data.tax_collected.toFixed(2), ". It\u2019s your responsibility to pay", " ", data.currency, data.tax_collected.toFixed(2), " to the proper tax authority."] })] })] }), _jsx(View, { style: {
                    marginVertical: 12,
                    borderBottom: "1px dashed rgba(0,0,0,0.4)",
                } }), _jsxs(View, { style: {
                    gap: 8,
                    marginBottom: 12,
                    paddingVertical: 16,
                    paddingHorizontal: 24,
                    backgroundColor: "#F5F5F5",
                }, children: [_jsxs(Text, { style: {
                            fontSize: 12,
                            fontWeight: 700,
                            textTransform: "uppercase",
                        }, children: ["AMOUNT COLLECTED IN YOUR BANK ACCOUNT: ", data.currency, data.net_total_sales_credit_card.toFixed(2)] }), _jsx(Text, { style: { fontSize: 10 }, children: "All transactions are deposited into your bank account, powered by Stripe Express." })] }), _jsxs(View, { style: {
                    paddingVertical: 16,
                    border: "1px dashed rgba(0, 0, 0, 0.4)",
                }, children: [_jsxs(View, { style: {
                            paddingHorizontal: 24,
                        }, children: [_jsx(Text, { style: {
                                    marginBottom: 8,
                                    fontSize: 10,
                                    fontWeight: 700,
                                    textTransform: "uppercase",
                                }, children: "ONLINE ORDER ACTIVITY" }), _jsxs(View, { style: { gap: 6 }, children: [_jsxs(View, { style: styles.flex, children: [_jsxs(Text, { style: { fontSize: 10 }, children: ["Sales Subtotal (", data.orders_count, " Orders)"] }), _jsxs(Text, { style: { fontSize: 10 }, children: [data.currency, data.sales_subtotal.toFixed(2)] })] }), _jsxs(View, { style: styles.flex, children: [_jsx(Text, { style: { fontSize: 10 }, children: "Tax Collected*" }), _jsxs(Text, { style: { fontSize: 10 }, children: [data.currency, data.tax_collected.toFixed(2)] })] }), _jsxs(View, { style: styles.flex, children: [_jsx(Text, { style: { fontSize: 10 }, children: "Tips" }), _jsxs(Text, { style: { fontSize: 10 }, children: [data.currency, data.tips.toFixed(2)] })] }), _jsxs(View, { style: styles.flex, children: [_jsx(Text, { style: { fontSize: 10 }, children: "In-House Delivery Fees" }), _jsxs(Text, { style: { fontSize: 10 }, children: [data.currency, data.in_house_delivery_fees.toFixed(2)] })] }), _jsxs(View, { style: styles.flex, children: [_jsxs(Text, { style: { fontSize: 10 }, children: ["Adjustments (", data.adjustment_orders_count, " Orders)"] }), _jsxs(Text, { style: { fontSize: 10 }, children: [data.price_adjustments < 0 && "-", data.currency, data.price_adjustments < 0
                                                        ? (+data.price_adjustments.toString().substring(1)).toFixed(2)
                                                        : data.price_adjustments.toFixed(2)] })] }), _jsxs(View, { style: styles.flex, children: [_jsxs(Text, { style: { fontSize: 10 }, children: ["Refunds (", data.refund_orders_count, " Orders)"] }), _jsxs(Text, { style: { fontSize: 10 }, children: ["-", data.currency, data.refund_amount.toFixed(2)] })] }), _jsxs(View, { style: styles.flex, children: [_jsx(Text, { style: { fontSize: 10 }, children: "Stripe Processing Fees" }), _jsxs(Text, { style: { fontSize: 10 }, children: ["-", data.currency, data.transaction_fees.toFixed(2)] })] }), _jsxs(View, { style: styles.flex, children: [_jsx(Text, { style: { fontSize: 10 }, children: "Technology Fees" }), _jsxs(Text, { style: { fontSize: 10 }, children: [data.currency, data.platform_fees.toFixed(2)] })] })] })] }), _jsx(Divider, {}), _jsxs(View, { style: {
                            gap: 6,
                            paddingHorizontal: 24,
                        }, children: [_jsxs(View, { style: styles.flex, children: [_jsx(Text, { style: { fontSize: 10, fontWeight: 700 }, children: "Total Sales" }), _jsxs(Text, { style: { fontSize: 10, fontWeight: 700 }, children: [data.currency, data.total_sales.toFixed(2)] })] }), _jsxs(View, { style: styles.flex, children: [_jsx(Text, { style: { fontSize: 10, fontWeight: 700 }, children: "Adjustments" }), _jsxs(Text, { style: { fontSize: 10, fontWeight: 700 }, children: [data.currency, data.total_adjustments.toFixed(2)] })] })] }), _jsx(Divider, {}), _jsx(View, { style: {
                            gap: 6,
                            paddingHorizontal: 24,
                        }, children: _jsxs(View, { style: styles.flex, children: [_jsx(Text, { style: { fontSize: 10, fontWeight: 700 }, children: "Net Total" }), _jsxs(Text, { style: { fontSize: 10, fontWeight: 700 }, children: [data.currency, data.net_total.toFixed(2)] })] }) }), _jsx(Divider, {}), _jsxs(View, { style: {
                            paddingHorizontal: 24,
                        }, children: [_jsx(Text, { style: {
                                    marginVertical: 8,
                                    fontSize: 10,
                                    fontWeight: 700,
                                    textTransform: "uppercase",
                                }, children: "Additional ACTIVITY" }), _jsxs(View, { style: styles.flex, children: [_jsxs(Text, { style: { fontSize: 10 }, children: ["Cash Order (", data.cash_orders_count, " Orders)"] }), _jsxs(Text, { style: { fontSize: 10 }, children: [data.currency, data.total_sales_cash.toFixed(2)] })] })] }), _jsx(Divider, {}), _jsx(View, { style: {
                            gap: 6,
                            paddingHorizontal: 24,
                        }, children: _jsxs(View, { style: styles.flex, children: [_jsx(Text, { style: { fontSize: 10, fontWeight: 700 }, children: "Net Total" }), _jsxs(Text, { style: { fontSize: 10, fontWeight: 700 }, children: [data.currency, data.total_sales_cash.toFixed(2)] })] }) }), _jsx(Divider, {}), _jsx(View, { style: {
                            gap: 6,
                            paddingTop: 12,
                            paddingHorizontal: 24,
                        }, children: _jsxs(View, { style: styles.flex, children: [_jsx(Text, { style: { fontSize: 10, fontWeight: 700 }, children: "TOTAL" }), _jsxs(Text, { style: { fontSize: 10, fontWeight: 700 }, children: [data.currency, data.net_total_sales_credit_card.toFixed(2)] })] }) })] }), _jsxs(View, { style: {
                    position: "absolute",
                    bottom: 28,
                    left: 0,
                    right: 0,
                    alignItems: "center",
                }, children: [_jsx(Text, { style: { fontSize: 12 }, children: "Power by" }), _jsxs(Text, { style: { fontSize: 14 }, children: ["www.", _jsx(Text, { style: { fontWeight: 600 }, children: "OrderSmile" }), ".com"] })] })] }) }));
export default Pdf;
const Divider = () => (_jsx(View, { style: {
        marginVertical: 4,
        borderBottom: "1px dashed rgba(0,0,0,0.4)",
    } }));
const LocationIcon = () => (_jsxs(Svg, { viewBox: "0 0 24 24", fill: "currentColor", style: { width: 12, height: 12 }, children: [_jsx(Path, { d: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7M7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9" }), _jsx(Circle, { cx: "12", cy: "9", r: "2.5" })] }));
const DateRangeIcon = () => (_jsx(Svg, { viewBox: "0 0 24 24", fill: "currentColor", style: { width: 12, height: 12 }, children: _jsx(Path, { d: "M7 11h2v2H7zm14-5v14c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2v2h8V2h2v2h1c1.1 0 2 .9 2 2M5 8h14V6H5zm14 12V10H5v10zm-4-7h2v-2h-2zm-4 0h2v-2h-2z" }) }));
const ReceiptIcon = () => (_jsxs(Svg, { viewBox: "0 0 24 24", fill: "currentColor", style: { width: 12, height: 12 }, children: [_jsx(Path, { d: "M19.5 3.5 18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2v14H3v3c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3V2zM15 20H6c-.55 0-1-.45-1-1v-1h10zm4-1c0 .55-.45 1-1 1s-1-.45-1-1v-3H8V5h11z" }), _jsx(Path, { d: "M9 7h6v2H9zm7 0h2v2h-2zm-7 3h6v2H9zm7 0h2v2h-2z" })] }));
