import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { usePaymentsContext } from '../PaymentsContext';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { useTheme, useMediaQuery } from '@mui/material';
import { RoundedButton } from '../../components/styled';
import OrderRow from './OrderRow';
import Pagination from '../../components/Pagination';
export default function OrdersTable() {
    const [t] = useTranslation('financials');
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up(1024));
    const { orders, isLoadingOrders, pagination, setPage, perPage, setPerPage, getOrders, isFetching } = usePaymentsContext();
    const showPagination = !isLoadingOrders && !!orders.length && pagination.total > 10;
    const showSeeMoreBtn = !isLoadingOrders &&
        !isMdUp &&
        pagination.current_page !== pagination.last_page;
    return (_jsxs(_Fragment, { children: [_jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { sx: { th: { fontWeight: 600 } }, children: [isMdUp ? (_jsx(TableCell, { children: t('orders.tableColumns.1') })) : (_jsxs(_Fragment, { children: [_jsx(TableCell, {}), _jsx(TableCell, { children: t('orders.tableColumns.10') })] })), _jsx(TableCell, { children: t('orders.tableColumns.2') }), isMdUp ? (_jsxs(_Fragment, { children: [_jsx(TableCell, { children: t('orders.tableColumns.3') }), _jsx(TableCell, { children: t('orders.tableColumns.4') }), _jsx(TableCell, { children: t('orders.tableColumns.5') }), _jsx(TableCell, { children: t('orders.tableColumns.6') }), _jsx(TableCell, { children: t('orders.tableColumns.7') }), _jsx(TableCell, { children: t('orders.tableColumns.8') }), _jsxs(TableCell, { children: [t('orders.tableColumns.9'), " "] })] })) : (_jsxs(_Fragment, { children: [_jsx(TableCell, {}), _jsx(TableCell, {})] }))] }) }), !isLoadingOrders && (_jsx(TableBody, { children: orders.map((order) => (_jsx(OrderRow, { order: order }, order.id))) }))] }), (isLoadingOrders || !orders.length) && (_jsx(Box, { sx: {
                    mt: 18,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column'
                }, children: isLoadingOrders ? (_jsx(CircularProgress, {})) : (_jsxs(_Fragment, { children: [_jsx(ReceiptLongOutlinedIcon, { color: 'action', fontSize: 'large', sx: { mb: 2 } }), _jsx(Typography, { mb: 1, fontWeight: 600, children: t('orders.emptyText.primary') }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', children: t('orders.emptyText.secondary') })] })) })), showPagination && isMdUp && (_jsx(Pagination, { pagination: pagination, perPage: perPage, setPerPage: setPerPage, setPage: setPage })), showSeeMoreBtn && (_jsx(Box, { sx: { mx: 4, my: 2 }, children: _jsx(RoundedButton, { fullWidth: true, variant: 'contained', onClick: () => getOrders({
                        mergeData: true,
                        nextPage: pagination.current_page + 1
                    }), children: isFetching ? (_jsx(CircularProgress, { size: '24px' })) : (t('orders.moreBtnLabel')) }) }))] }));
}
