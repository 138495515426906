import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from 'react-i18next';
import { MenuContext } from '../context';
import { ActionType } from '../context/types';
import { fetchApi } from '../../../config/Api';
import { generateImageUrl } from '../../UtilityFunctions';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import DragIndicatorOutlined from '@mui/icons-material/DragIndicatorOutlined';
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import ConfirmationDialog from './ConfirmationDialog';
import DropdownMenu from './DropdownMenu';
import Placeholder from '../assets/Placeholder';
export default function CategoriesListItem(props) {
    const { id, name, image, new_menus, items } = props;
    const [t] = useTranslation('menu');
    const [isDeleting, setIsDeleting] = useState(false);
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { dispatch, toggleDrawer, getCategories, getItems, draftCategory } = useContext(MenuContext);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleEditClick = () => {
        dispatch({
            type: ActionType.SET_DRAFT_CATEGORY,
            payload: props
        });
        toggleDrawer('isCategoryDrawerOpen');
        handleClose();
    };
    const handleDelete = () => {
        setIsDeleting(true);
        fetchApi({
            method: 'DELETE',
            url: `/menu/category/delete/${id}`
        }).then(() => {
            setIsDeleting(false);
            setConfirmDeleteDialog(false);
            getCategories(), getItems();
            window.globalToast({
                title: 'Success',
                type: 'success',
                description: 'Category deleted successfully!'
            });
        });
    };
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };
    return (_jsxs(_Fragment, { children: [_jsx(ConfirmationDialog, { isLoading: isDeleting, primaryText: t('dialogs.deleteCategory.title'), secondaryText: t('dialogs.deleteCategory.description'), isOpen: confirmDeleteDialog, setIsOpen: setConfirmDeleteDialog, confirmBtnLabel: t('dialogs.deleteCategory.primaryBtnLabel'), confirmBtnCallback: handleDelete }), _jsx(ListItem, { sx: { px: 1 }, divider: true, secondaryAction: _jsx(IconButton, { onClick: handleClickListItem, children: _jsx(MoreVertOutlined, {}) }), ref: setNodeRef, style: style, ...attributes, children: _jsxs(ListItemButton, { disableRipple: true, selected: draftCategory?.id === id, onClick: handleEditClick, children: [_jsx(ListItemIcon, { sx: { cursor: 'pointer' }, ...listeners, children: _jsx(DragIndicatorOutlined, {}) }), _jsx(ListItemAvatar, { children: _jsx(Avatar, { sx: { borderRadius: 1 }, children: image ? (_jsx("img", { src: generateImageUrl(image), style: {
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover'
                                    } })) : (_jsx(Placeholder, {})) }) }), _jsx(ListItemText, { primary: _jsx(Tooltip, { title: name.length > 20 ? name : '', children: _jsx("span", { children: name }) }), slotProps: {
                                primary: {
                                    noWrap: true
                                },
                                secondary: {
                                    noWrap: true
                                }
                            }, secondary: _jsx(Tooltip, { title: new_menus.map((menu) => menu.name).join(', ').length > 20
                                    ? new_menus.map((menu) => menu.name).join(', ')
                                    : '', children: _jsx("span", { children: new_menus.map((menu) => menu.name).join(', ') }) }) }), _jsx(ListItemText, { primary: items.length === 1
                                ? `1 ${t('overview.categories.secondaryText')}`
                                : `${items.length} ${t('overview.categories.secondaryText')}`, sx: {
                                mr: 1,
                                textAlign: 'end',
                                color: 'text.secondary',
                                whiteSpace: 'nowrap',
                                flexShrink: 0
                            } })] }) }), _jsx(DropdownMenu, { anchorEl: anchorEl, onClose: handleClose, options: [
                    {
                        icon: _jsx(EditOutlined, {}),
                        label: t('overview.categories.btnLabels.editBtnLabel'),
                        action: handleEditClick
                    },
                    {
                        icon: _jsx(DeleteOutlined, {}),
                        label: t('overview.categories.btnLabels.deleteBtnLabel'),
                        action: () => {
                            setAnchorEl(null);
                            setConfirmDeleteDialog(true);
                        }
                    }
                ] })] }));
}
