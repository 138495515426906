import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useCustomersContext } from '../CustomerContext';
import { getCurrency, getErrorMessage, getRestaurantId, formatDate } from '../../UtilityFunctions';
import { fetchApi } from '../../../config/Api';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CustomerOrders from './CustomerOrders';
import SideDrawer from '../../components/SideDrawer';
import DisplayField from '../../components/DisplayField';
import CustomTooltip from '../../components/CustomTooltip';
import { RoundedTextField, RoundedButton } from '../../components/styled';
export default function CustomerDrawer() {
    const [t] = useTranslation('customers');
    const [status, setStatus] = useState(false);
    const [notes, setNotes] = useState('');
    const [customerOrders, setCustomerOrders] = useState([]);
    const [pagination, setPagination] = useState({
        to: 0,
        from: 0,
        total: 0,
        per_page: 10,
        last_page: 1,
        current_page: 1
    });
    const { isDrawerOpen, setIsDrawerOpen, selectedCustomer, updateCustomer, getCustomers, thisBranchOnly } = useCustomersContext();
    const [onlyAtThisBranch, setOnlyAtThisBranch] = useState(false);
    const currency = getCurrency();
    const createdAt = selectedCustomer
        ? moment(selectedCustomer.created_at).format('MM/DD/YYYY')
        : '';
    const country_code = selectedCustomer?.country_code;
    const telephone = selectedCustomer?.telephone;
    const formattedNumber = country_code === '+355'
        ? `${country_code} ${telephone?.slice(0, 2)} ${telephone?.slice(2, 5)} ${telephone?.slice(5)}`
        : `${country_code} (${telephone?.slice(0, 3)}) ${telephone?.slice(3, 6)}-${telephone?.slice(6)}`;
    const debouncedUpdate = useCallback(_.debounce(async (notes) => {
        if (selectedCustomer) {
            await updateCustomer(selectedCustomer.id, { notes })
                .then(() => getCustomers())
                .catch(() => {
                window.globalToast({
                    title: 'Error',
                    type: 'error',
                    description: 'Something went wrong. Please reload page and try again!'
                });
            });
        }
    }, 2000), [selectedCustomer]);
    const getOrders = (page = 1, ordersAtThisBranch, prevOrders) => {
        const params = new URLSearchParams({
            perPage: '5',
            page: page.toString(),
            customer_id: selectedCustomer?.id.toString() ?? '',
            ...(ordersAtThisBranch && {
                restaurant_id: getRestaurantId()?.toString() ?? ''
            })
        });
        fetchApi({
            method: 'GET',
            url: `/user/detail?${params}`
        })
            .then((res) => {
            setCustomerOrders([...prevOrders, ...res.data.orders]);
            setPagination(res.data.pagination);
        })
            .catch((err) => {
            window.globalToast({
                type: 'error',
                title: 'Error',
                description: getErrorMessage(err)
            });
        });
    };
    const handleChange = (e) => {
        setNotes(e.target.value);
        debouncedUpdate(e.target.value);
    };
    const handleStatus = async (status) => {
        if (!selectedCustomer)
            return;
        setStatus(status);
        await updateCustomer(selectedCustomer.id, { blocked: status })
            .then(() => getCustomers())
            .catch(() => {
            window.globalToast({
                title: 'Error',
                type: 'error',
                description: 'Something went wrong!'
            });
        });
    };
    useEffect(() => {
        if (isDrawerOpen) {
            setOnlyAtThisBranch(thisBranchOnly);
            if (selectedCustomer) {
                getOrders(1, thisBranchOnly, []);
                setStatus(selectedCustomer.blocked);
                setNotes(selectedCustomer.guest_notes ?? '');
            }
        }
    }, [isDrawerOpen]);
    return (_jsxs(SideDrawer, { isOpen: isDrawerOpen, onClose: () => {
            setIsDrawerOpen(false);
            setNotes('');
            setCustomerOrders([]);
        }, primaryText: selectedCustomer
            ? `${selectedCustomer.firstname} ${selectedCustomer.lastname}`
            : '', secondaryText: t('drawer.title'), children: [_jsxs(Box, { px: 4, pt: 4, display: 'flex', flexDirection: 'column', gap: 2, children: [_jsxs(Box, { children: [_jsx(Typography, { fontWeight: 600, children: t('drawer.status.title') }), _jsx(Typography, { fontSize: 14, color: 'text.secondary', children: t('drawer.status.description') })] }), _jsxs(RoundedTextField, { select: true, fullWidth: true, label: t('drawer.status.label'), SelectProps: { displayEmpty: true }, value: status.toString(), onChange: (e) => handleStatus(e.target.value === 'true' ? true : false), children: [_jsxs(MenuItem, { value: 'false', sx: (theme) => ({
                                    '& .MuiSvgIcon-root': {
                                        marginRight: theme.spacing(1.5)
                                    }
                                }), children: [_jsx(CheckOutlinedIcon, {}), " ", t('drawer.status.options.1')] }), _jsxs(MenuItem, { value: 'true', sx: (theme) => ({
                                    '& .MuiSvgIcon-root': {
                                        marginRight: theme.spacing(1.5)
                                    }
                                }), children: [_jsx(CloseOutlinedIcon, {}), " ", t('drawer.status.options.2')] })] }), _jsx(DisplayField, { icon: _jsx(PersonOutlineOutlinedIcon, { color: 'action' }), primaryText: `${selectedCustomer?.firstname} ${selectedCustomer?.lastname}` }), _jsx(DisplayField, { icon: _jsx(CallOutlinedIcon, { color: 'action' }), secondaryText: formattedNumber }), _jsx(DisplayField, { icon: _jsx(EmailOutlinedIcon, { color: 'action' }), secondaryText: selectedCustomer?.email }), _jsxs(Box, { display: 'flex', gap: 2, sx: (theme) => ({
                            [theme.breakpoints.down('md')]: { flexDirection: 'column' }
                        }), children: [_jsx(DisplayField, { icon: _jsx(PersonAddOutlinedIcon, { color: 'action' }), secondaryText: t('drawer.createdAt'), primaryText: createdAt }), _jsx(DisplayField, { icon: _jsx(EventOutlinedIcon, { color: 'action' }), secondaryText: t('drawer.lastOrder'), primaryText: selectedCustomer?.metadata.latestOrderDate
                                    ? formatDate(selectedCustomer.metadata.latestOrderDate)
                                    : 'N/A' })] }), _jsx(RoundedTextField, { fullWidth: true, multiline: true, rows: 3, label: t('drawer.description.label'), placeholder: t('drawer.description.placeholder'), InputLabelProps: {
                            shrink: true
                        }, helperText: t('drawer.description.helperText'), sx: {
                            mt: 1
                        }, value: notes, onChange: handleChange })] }), _jsx(Divider, { sx: {
                    my: 3,
                    height: '8px',
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    borderTop: 1,
                    borderColor: 'divider'
                } }), _jsxs(Box, { px: 4, pb: 4, children: [_jsxs(Box, { sx: {
                            mb: 3,
                            px: 2,
                            py: 0.5,
                            border: 1,
                            borderRadius: 3,
                            borderColor: 'divider',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }, children: [_jsx(FormControlLabel, { control: _jsx(Switch, { checked: onlyAtThisBranch, onChange: (e) => {
                                        setOnlyAtThisBranch(e.target.checked);
                                        setCustomerOrders([]);
                                        getOrders(1, e.target.checked, []);
                                    } }), label: t('drawer.filterOrdersSwitch.label') }), _jsx(CustomTooltip, { tooltipProps: {
                                    placement: 'bottom',
                                    title: t('drawer.filterOrdersSwitch.tooltip')
                                }, children: _jsx(InfoOutlinedIcon, { color: 'action' }) })] }), _jsxs(Box, { display: 'flex', gap: 2, sx: (theme) => ({
                            [theme.breakpoints.down('md')]: { flexDirection: 'column' }
                        }), children: [_jsxs(Box, { p: 2, border: 1, borderColor: 'divider', borderRadius: 2, flexGrow: 1, children: [_jsx(Typography, { fontWeight: 600, color: 'GrayText', whiteSpace: 'nowrap', children: t('drawer.statCards.count') }), _jsx(Typography, { fontSize: 22, fontWeight: 700, children: selectedCustomer?.metadata.totalOrdersCount })] }), _jsxs(Box, { p: 2, border: 1, borderColor: 'divider', borderRadius: 2, flexGrow: 1, children: [_jsx(Typography, { fontWeight: 600, color: 'GrayText', whiteSpace: 'nowrap', children: t('drawer.statCards.average') }), _jsxs(Typography, { fontSize: 22, fontWeight: 700, children: [currency, selectedCustomer?.metadata.averageOrderSpend.toFixed(2)] })] }), _jsxs(Box, { p: 2, border: 1, borderColor: 'divider', borderRadius: 2, flexGrow: 1, children: [_jsx(Typography, { fontWeight: 600, color: 'GrayText', whiteSpace: 'nowrap', children: t('drawer.statCards.total') }), _jsxs(Typography, { fontSize: 22, fontWeight: 700, children: [currency, selectedCustomer?.metadata.totalOrdersSum.toFixed(2)] })] })] }), _jsx(Divider, { sx: { my: 3 } }), _jsxs(Stack, { gap: 3, children: [customerOrders.length > 0 ? (_jsx(CustomerOrders, { orders: customerOrders })) : (_jsx(Typography, { fontSize: 14, fontWeight: 600, textAlign: 'center', children: t('drawer.emptyText') })), pagination.current_page < pagination.last_page && (_jsx(RoundedButton, { color: 'inherit', variant: 'contained', onClick: () => getOrders(pagination.current_page + 1, onlyAtThisBranch, customerOrders), children: t('drawer.moreBtnLabel') }))] })] })] }));
}
