import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchApi } from '../../../config/Api';
import Storage from '../../../config/Storage';
import { ProfileContext } from '../../profile/ProfileContext';
import { StoreContext } from '../../layout/StoreContext';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import CustomTooltip from '../../components/CustomTooltip';
export default function OnOffSwitch() {
    const { t } = useTranslation('network');
    const [checked, setChecked] = useState(true);
    const dialogRef = useRef();
    const getYourPlanDialogRef = useRef();
    const upgradeYourPlanDialogRef = useRef();
    const { userInfo, restaurant, setRestaurant } = useContext(ProfileContext);
    const { isMenuOpen, getRestaurants } = useContext(StoreContext);
    const storedNetwork = Storage.getItem('network');
    const network = storedNetwork
        ? JSON.parse(storedNetwork)
        : null;
    const isAdmin = userInfo?.roles.some((role) => role.name === 'admin');
    const handleChange = () => {
        if (!checked) {
            if (network && network.current_subscription === null) {
                getYourPlanDialogRef.current.open();
                return;
            }
            if (network &&
                network.current_subscription !== null &&
                network.restaurants_availability !== 0) {
                dialogRef.current.open();
                return;
            }
        }
        else {
            dialogRef.current?.open();
        }
    };
    const handleClick = () => {
        if (restaurant) {
            fetchApi({
                method: 'POST',
                url: `/restaurant/toggle-active`,
                data: {
                    restaurant_id: restaurant.id,
                    toggle: !checked
                }
            })
                .then((r) => {
                if (r.status === 200) {
                    dialogRef.current?.close();
                    window.globalToast({
                        type: 'success',
                        title: 'Success',
                        description: 'Restaurant successfully changed.'
                    });
                    getRestaurants();
                    setRestaurant(r.data.restaurant);
                    Storage.setItem('network', JSON.stringify(r.data.network));
                }
            })
                .catch(() => {
                dialogRef.current?.close();
                upgradeYourPlanDialogRef.current?.open();
            });
        }
    };
    const handleConfirm = () => {
        if (isAdmin &&
            network &&
            network.current_subscription !== null &&
            network.restaurants_availability === 0) {
            const returnUrl = 'https://ordersmile.com/select-network';
            const encodedReturnUrl = encodeURIComponent(returnUrl);
            fetchApi({
                method: 'GET',
                url: `/network/${network.id}/billing_portal/sessions?return_url=${encodedReturnUrl}`
            }).then((res) => {
                if ('url' in res.data) {
                    window.location.href = res.data.url;
                }
            });
        }
        else {
            location.replace(`/get-your-plan?id=${network?.id}`);
            upgradeYourPlanDialogRef.current?.close();
        }
    };
    useEffect(() => {
        if (restaurant) {
            setChecked(restaurant.is_subscription_active);
        }
    }, [restaurant]);
    const status = checked ? 'on' : 'off';
    const tooltipText = (_jsxs(_Fragment, { children: [_jsx(Typography, { fontSize: 12, fontWeight: 600, children: t(`storeSwitch.tooltip.${status}.title`, { ns: 'common' }) }), _jsx(Typography, { fontSize: 10, children: t(`storeSwitch.tooltip.${status}.description`, { ns: 'common' }) })] }));
    return (_jsxs(_Fragment, { children: [_jsx(ConfirmationDialog, { ref: upgradeYourPlanDialogRef, severity: 'info', title: t('dialogs.upgradePlan.title'), description: t('dialogs.upgradePlan.description'), primaryButtonLabel: isAdmin
                    ? t('dialogs.upgradePlan.primaryBtnLabelAdmin')
                    : t('dialogs.upgradePlan.primaryBtnLabel'), onPrimaryButtonClick: handleConfirm, icon: _jsx(StorefrontOutlinedIcon, { color: 'info', fontSize: 'large' }), onClose: () => upgradeYourPlanDialogRef.current.close() }), _jsx(ConfirmationDialog, { ref: getYourPlanDialogRef, severity: 'info', title: t('dialogs.noActivePlan.title'), description: t('dialogs.noActivePlan.description', {
                    name: network?.title
                }), primaryButtonLabel: isAdmin ? t('dialogs.noActivePlan.primaryBtnLabel') : undefined, onPrimaryButtonClick: () => location.replace(`/get-your-plan?id=${network?.id}`), secondaryButtonLabel: isAdmin ? 'Cancel' : undefined, onSecondaryButtonClick: () => getYourPlanDialogRef.current?.close(), icon: _jsx(UploadOutlinedIcon, { color: 'info', fontSize: 'large' }), onClose: () => getYourPlanDialogRef.current.close() }), _jsx(ConfirmationDialog, { ref: dialogRef, severity: 'info', title: t(`dialogs.changeRestaurantStatus.${status}.title`, {
                    ns: 'common'
                }), description: t(`dialogs.changeRestaurantStatus.${status}.description`, {
                    ns: 'common'
                }), primaryButtonLabel: t(`dialogs.changeRestaurantStatus.${status}.primaryBtnLabel`, {
                    ns: 'common'
                }), onPrimaryButtonClick: handleClick, secondaryButtonLabel: t(`dialogs.changeRestaurantStatus.${status}.secondaryBtnLabel`, {
                    ns: 'common'
                }), onSecondaryButtonClick: () => dialogRef.current?.close() }), _jsxs(Box, { sx: {
                    m: isMenuOpen ? 2 : 0.5,
                    px: isMenuOpen ? 2 : 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: isMenuOpen ? 'space-between' : 'center',
                    height: '50px',
                    bgcolor: 'grey.50',
                    borderRadius: 3
                }, children: [_jsx(FormControlLabel, { sx: { m: isMenuOpen ? 2 : 0 }, control: _jsx(Switch, { checked: checked, onChange: handleChange, size: 'small' }), labelPlacement: isMenuOpen ? 'end' : 'top', label: `${isMenuOpen ? t('storeSwitch.label', { ns: 'common' }) : ''} ${t(`storeSwitch.status.${status}`, { ns: 'common' })}` }), isMenuOpen && (_jsx(CustomTooltip
                    // title={tooltipText}
                    , { 
                        // title={tooltipText}
                        tooltipProps: { placement: 'top', title: tooltipText }, children: _jsx(InfoOutlined, { color: 'info' }) }))] })] }));
}
