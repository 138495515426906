import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import { useTheme, useMediaQuery } from '@mui/material';
const BestSellingItemsChart = ({ data }) => {
    const [t] = useTranslation('store');
    const maxQuantity = Math.max(...data.result.map((item) => item.quantity));
    const getBarHeight = (quantity) => {
        return Math.round((quantity / maxQuantity) * 100) + '%';
    };
    const isWinnerItem = (name) => data.winner_item ? data.winner_item.name === name : false;
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const mappedData = Array(10)
        .fill(null)
        .map((_, idx) => data.result[idx] ?? { name: 'N/A', quantity: 0 });
    return (_jsxs(_Fragment, { children: [_jsx(Box, { flexGrow: 1, display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', height: { xs: 200, lg: 'auto' }, children: mappedData.map((item) => {
                    if (item.quantity === 0) {
                        return null;
                    }
                    return (_jsxs(Box, { px: { xs: 1, sm: 2 }, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', flexGrow: 1, position: 'relative', pt: 4, children: [isWinnerItem(item.name) && (_jsx(DiamondOutlinedIcon, { sx: {
                                    top: 0,
                                    position: 'absolute',
                                    color: '#F57F17'
                                } })), !isLgDown && (_jsx(Box, { mb: 2, px: 2, py: 1, borderRadius: 3, boxShadow: '0px 1px 12px 0px #00000040', children: _jsxs(Typography, { component: 'span', fontSize: 12, fontWeight: 600, children: [item.quantity, " ", t('activity.legend.common.sales')] }) })), _jsx(Box, { width: '100%', height: getBarHeight(item.quantity), border: 2, borderColor: '#2196F3', borderRadius: 3, sx: {
                                    background: isWinnerItem(item.name)
                                        ? 'linear-gradient(180deg, #ABDAFF 0%, #2196F3 100%)'
                                        : '#2196F31F'
                                } })] }, item.name));
                }) }), _jsx(Box, { display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', children: mappedData.map((item, index) => (_jsxs("div", { children: [_jsx(Divider, { flexItem: true, sx: {
                                mt: 1,
                                mb: 2,
                                borderRight: 1,
                                height: 10,
                                borderColor: 'divider'
                            } }), _jsx(Typography, { fontSize: 14, fontWeight: 600, color: 'text.secondary', textAlign: 'center', children: isLgDown ? index + 1 : item.name })] }, item.name))) })] }));
};
export default BestSellingItemsChart;
