import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { generateImageUrl } from "../../UtilityFunctions";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import StripePricingTable from "./StripePricingTable";
export default function NetworkCard({ network }) {
    return (_jsxs(Box, { pt: 10, mx: 2, gap: 2, display: "flex", alignItems: { xs: "center", sm: "flex-start" }, justifyContent: "center", flexDirection: { xs: "column", sm: "row" }, children: [_jsx(IconButton, { onClick: () => location.replace("select-network"), sx: { marginRight: { xs: "auto", sm: "unset" } }, children: _jsx(ArrowBackOutlinedIcon, {}) }), _jsxs(Box, { width: "100%", maxWidth: 600, children: [_jsx(Typography, { component: "h1", variant: "h5", fontWeight: 600, mb: 2, children: "Select the right plan for your Restaurant(s)" }), _jsx(Typography, { variant: "body2", mb: 3, color: "text.secondary", children: "It\u2019s always flexible to grow!" }), _jsxs(Box, { pb: 6, px: "2px", pt: "2px", border: 1, borderColor: "divider", borderRadius: 4, overflow: "hidden", children: [_jsxs(Box, { sx: (theme) => ({
                                    mb: 3,
                                    px: 3,
                                    py: 2,
                                    gap: 3,
                                    display: "flex",
                                    alignItems: "center",
                                    borderTopLeftRadius: 14,
                                    borderTopRightRadius: 14,
                                    backgroundColor: theme.palette.grey[100],
                                }), children: [_jsx(Box, { component: "img", src: network.logo
                                            ? generateImageUrl(network.logo)
                                            : "images/networks/logo/logo.png", alt: "network logo", sx: {
                                            width: 56,
                                            height: 56,
                                            borderRadius: "28px",
                                            objectFit: "cover",
                                        } }), _jsxs(Box, { sx: {
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                            flexGrow: 1,
                                        }, children: [_jsx(Typography, { variant: "h6", fontWeight: 600, fontSize: 20, children: network.title }), _jsxs(Box, { sx: {
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: 1.5,
                                                }, children: [_jsx(StorefrontOutlinedIcon, { color: "action" }), _jsxs(Typography, { variant: "body1", color: "text.secondary", children: [network.restaurants_no, " Location(s)"] })] })] })] }), _jsx(StripePricingTable, {})] })] })] }));
}
