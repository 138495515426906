import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateImageUrl } from '../../UtilityFunctions';
import { Box, Typography, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { BadgeOutlined, ArrowForwardIos, CancelOutlined, InsertPhotoOutlined } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
const VerificationPhoto = ({ type, path }) => {
    const [t] = useTranslation('financials');
    const [isOpen, setIsOpen] = useState(false);
    return (_jsxs("div", { children: [_jsxs(Box, { sx: {
                    height: 64,
                    px: 2,
                    gap: 1,
                    display: 'flex',
                    alignItems: 'center',
                    bgcolor: grey[100],
                    borderRadius: 2,
                    cursor: 'pointer'
                }, onClick: () => setIsOpen(true), children: [type === 'drop_off_proof_document' ? (_jsx(InsertPhotoOutlined, { color: 'action' })) : (_jsx(BadgeOutlined, { color: 'action' })), _jsx(Typography, { mr: 'auto', component: 'span', fontSize: 14, fontWeight: 600, children: t(`dialogs.verificationPhoto.${type}.btnLabel`) }), _jsx(ArrowForwardIos, { color: 'action', fontSize: 'small' })] }), _jsxs(Dialog, { open: isOpen, fullScreen: true, sx: { zIndex: 3000 }, PaperProps: { sx: { borderRadius: 0 } }, children: [_jsxs(DialogTitle, { fontWeight: 600, component: 'div', display: 'grid', gridTemplateColumns: '1fr auto 1fr', alignItems: 'center', children: [_jsx(IconButton, { onClick: () => setIsOpen(false), sx: { justifySelf: 'start' }, children: _jsx(CancelOutlined, {}) }), t(`dialogs.verificationPhoto.${type}.title`)] }), _jsx(DialogContent, { dividers: true, sx: { display: 'flex', justifyContent: 'center' }, children: _jsx(Box, { component: 'img', width: '100%', height: '100%', sx: { objectFit: 'contain' }, src: generateImageUrl(path) }) })] })] }));
};
export default VerificationPhoto;
