import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useProfileContext } from "./ProfileContext";
import ProfileTabs from "./components/ProfileTabs";
import AccountSettingsForm from "./components/AccountSettingsForm";
import ChangePasswordForm from "./components/ChangePasswordForm";
export default function ProfileContainer() {
    const [selectedTab, setSelectedTab] = useState("settings");
    const { userInfo, isLoading } = useProfileContext();
    return (_jsxs(_Fragment, { children: [_jsx(ProfileTabs, { value: selectedTab, onChange: (value) => setSelectedTab(value) }), !isLoading && userInfo && selectedTab === "settings" && (_jsx(AccountSettingsForm, { initialState: userInfo })), selectedTab === "password" && _jsx(ChangePasswordForm, {})] }));
}
