import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { fetchApi } from '../../config/Api';
import Storage from '../../config/Storage';
import { getNetworkId } from '../UtilityFunctions';
const NEW_BRANCH_CONTEXT_INITIAL_VALUES = {
    isLoading: false,
    setIsLoading: () => undefined,
    activeSetting: 'general',
    setActiveSetting: () => undefined,
    activeStep: 0,
    setActiveStep: () => undefined,
    restaurantId: 0,
    setRestaurantId: () => undefined,
    generalSettings: {
        _token: '',
        network_id: '',
        restaurant_name: '',
        restaurant_cover_image: '',
        restaurant_phone: '',
        restaurant_website: '',
        enable_events: false,
        enable_gift_card: false,
        restaurant_sales_tax: '',
        restaurant_currency: 'USD'
    },
    setGeneralSettings: () => undefined,
    paymentSettings: {
        new_restaurant_cash_payment: false,
        new_restaurant_credit_card_payment: false,
        new_restaurant_paypal_payment: false,
        new_restaurant_stripe_payment: false,
        new_restaurant_apple_payment: false,
        new_restaurant_google_payment: false
    },
    setPaymentSettings: () => undefined,
    addressSettings: {
        restaurant_id: 0,
        new_restaurant_address: '',
        new_restaurant_latitude: 0,
        new_restaurant_longitude: 0,
        new_restaurant_zip: '',
        new_restaurant_city: '',
        new_restaurant_state: '',
        new_restaurant_street: '',
        new_restaurant_country: ''
    },
    setAddressSettings: () => undefined,
    deliverySettings: {
        new_restaurant_delivery_asap: false,
        new_restaurant_delivery_late: false,
        new_restaurant_delivery_order_track: false,
        new_restaurant_delivery_data_range: 1,
        new_restaurant_delivery_range_unit: 'km',
        new_restaurant_delivery_range_value: '0',
        new_restaurant_delivery_min_order_value: '0',
        new_restaurant_delivery_fee: '0',
        delivery_fixed_price: true,
        delivery: []
    },
    setDeliverySettings: () => undefined,
    pickUpSettings: {
        new_restaurant_pickup_asap: false,
        new_restaurant_pickup_late: false,
        pickup_date_range: 1,
        pickup: []
    },
    setPickUpSettings: () => undefined,
    floorSettings: { new_restaurant_floor: [] },
    setFloorSettings: () => undefined,
    reservationSettings: {
        new_restaurant_enable_reservations: false,
        new_restaurant_order_after_reservation: false,
        new_restaurant_available_time_reservation: false,
        new_restaurant_reservation_data_range: 1,
        restaurant_table_range_min: 1,
        restaurant_table_range_max: 2,
        reservation: []
    },
    validationErrors: [],
    setValidationErrors: () => undefined,
    setReservationSettings: () => undefined,
    validateAllSettings: () => undefined,
    submitNewRestaurant: () => undefined,
    hideStepperButtons: false,
    setHideStepperButtons: () => undefined,
    deliveryTimelines: {
        monday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        tuesday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        wednesday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        thursday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        friday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        saturday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        sunday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        }
    },
    setDeliveryTimelines: () => undefined,
    pickupTimelines: {
        monday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        tuesday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        wednesday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        thursday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        friday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        saturday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        sunday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        }
    },
    setPickupTimelines: () => undefined,
    steps: [],
    setSteps: ([]) => undefined,
    profileImage: '',
    setProfileImage: () => undefined,
    skipped: 10,
    setSkipped: () => undefined,
    filterValidation: () => undefined,
    discardBranch: () => undefined,
    saveForLaterBranch: () => undefined
};
export const NewBranchContext = createContext({
    ...NEW_BRANCH_CONTEXT_INITIAL_VALUES
});
function NewBranchProvider({ children }) {
    const [activeSetting, setActiveSetting] = useState('general');
    const [activeStep, setActiveStep] = useState(0);
    const [restaurantId, setRestaurantId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [hideStepperButtons, setHideStepperButtons] = useState(false);
    const [steps, setSteps] = useState([
        'General Information',
        'Address',
        'Payment Methods',
        'Taxation & Currency',
        'Delivery & Pickup Settings',
        'Delivery Opening Hours',
        'Pickup Opening Hours'
    ]);
    const [skipped, setSkipped] = React.useState(10);
    const [profileImage, setProfileImage] = React.useState('');
    function getRestaurantIdFromUrl() {
        // if (Storage.getItem("restaurant")){
        //     return Storage.getItem("restaurant");
        // }
        if (restaurantId) {
            return restaurantId;
        }
        var url = new URL(window.location.href);
        let urlNetworkId = url.searchParams.get('restaurant');
        console.log(urlNetworkId);
        if (urlNetworkId) {
            return urlNetworkId;
        }
        return 0;
    }
    const getRestaurantSettings = useCallback(() => {
        setIsLoading(true);
        fetchApi({
            method: 'GET',
            url: `/restaurant/get-data-step/${getRestaurantIdFromUrl()}`
        })
            .then((r) => {
            const { data } = r;
            console.log('get restaurant settings data: ', data);
            if (data?.restaurant_id) {
                setRestaurantId(data.restaurant_id);
            }
            if (typeof data?.restaurant_step != 'undefined') {
                console.log('rest step: ', data?.restaurant_step, ' skipped: ', skipped);
                if (data.restaurant_step === skipped) {
                    console.log('here: ', data?.restaurant_step, ' skipped: ', skipped);
                    setActiveStep(data.restaurant_step + 2);
                }
                else {
                    setActiveStep(data.restaurant_step + 1);
                }
            }
            setGeneralSettings({
                ...generalSettings,
                restaurant_name: data.restaurant_name,
                restaurant_cover_image: data.restaurant_cover_image,
                restaurant_phone: data.restaurant_phone,
                restaurant_website: data.restaurant_website,
                enable_events: data.enable_events,
                enable_gift_card: data.enable_gift_card,
                restaurant_sales_tax: data.restaurant_sales_tax,
                restaurant_currency: data.restaurant_currency ?? 'USD'
            });
            setPaymentSettings({
                new_restaurant_cash_payment: data.new_restaurant_cash_payment,
                new_restaurant_credit_card_payment: data.new_restaurant_credit_card_payment,
                new_restaurant_paypal_payment: data.new_restaurant_paypal_payment,
                new_restaurant_stripe_payment: data.new_restaurant_stripe_payment,
                new_restaurant_apple_payment: data.new_restaurant_apple_payment,
                new_restaurant_google_payment: data.new_restaurant_google_payment
            });
            setAddressSettings({
                restaurant_id: restaurantId,
                new_restaurant_address: data.new_restaurant_address,
                new_restaurant_latitude: data.new_restaurant_latitude,
                new_restaurant_longitude: data.new_restaurant_longitude,
                new_restaurant_zip: data.new_restaurant_zip,
                new_restaurant_city: data.new_restaurant_city,
                new_restaurant_state: data.new_restaurant_state,
                new_restaurant_street: data.new_restaurant_street,
                new_restaurant_country: data.new_restaurant_country
            });
            let static_price = 0;
            if (!data.deliveryType.is_dynamic_price &&
                data.deliveryType.static_price) {
                static_price = data.deliveryType.static_price;
            }
            else if (data.deliveryType.is_dynamic_price &&
                data.deliveryType.price_per_unit) {
                static_price = data.deliveryType.price_per_unit;
            }
            setDeliverySettings({
                new_restaurant_delivery_asap: data.new_restaurant_delivery_asap,
                new_restaurant_delivery_late: data.new_restaurant_delivery_late,
                delivery_fixed_price: !data.deliveryType.is_dynamic_price,
                static_price: static_price,
                price_per_unit: data.deliveryType.price_per_unit ?? 0,
                new_restaurant_delivery_order_track: data.new_restaurant_delivery_order_track,
                new_restaurant_delivery_range_value: data.new_restaurant_delivery_range_value ?? 0,
                new_restaurant_delivery_range_unit: data.new_restaurant_delivery_range_unit
                    ? data.new_restaurant_delivery_range_unit
                    : 'km',
                new_restaurant_delivery_min_order_value: data.new_restaurant_delivery_min_order_value ?? 50,
                new_restaurant_delivery_data_range: data.new_restaurant_delivery_data_range
                    ? data.new_restaurant_delivery_data_range
                    : 1,
                delivery: []
            });
            setPickUpSettings({
                new_restaurant_pickup_asap: data.new_restaurant_pickup_asap,
                new_restaurant_pickup_late: data.new_restaurant_pickup_late,
                pickup_date_range: data.pickup_date_range ?? 1,
                pickup: []
            });
            let delivery = [];
            if (data.delivery.length) {
                let timeTemp = [];
                let totalTime;
                // @ts-ignore
                Object.fromEntries(Object.entries(data.delivery).map(([_key, item]) => {
                    // @ts-ignore
                    timeTemp[item.day] = {
                        active: true,
                        from_reservation_START: item.open_time,
                        from_reservation_END: item.closed_time
                    };
                    return timeTemp;
                }));
                // @ts-ignore
                totalTime = { ...timeTemp };
                console.log('totalTime: ', totalTime);
                // @ts-ignore
                setDeliveryTimelines({
                    ...deliveryTimelines,
                    ...totalTime
                });
                console.log('delivery? ', delivery);
            }
            console.log('deliverytimelines: ', deliveryTimelines);
            if (data.pickup.length) {
                console.log('im pickup: ', data.pickup);
                let timeTemp = [];
                let totalTime;
                // @ts-ignore
                Object.fromEntries(Object.entries(data.pickup).map(([_key, item]) => {
                    // @ts-ignore
                    timeTemp[item.day] = {
                        active: true,
                        from_reservation_START: item.open_time,
                        from_reservation_END: item.closed_time
                    };
                    return timeTemp;
                }));
                totalTime = { ...timeTemp };
                // @ts-ignore
                setPickupTimelines({
                    ...pickupTimelines,
                    ...totalTime
                });
            }
            setAddressSettings({
                restaurant_id: restaurantId,
                new_restaurant_address: data.new_restaurant_address ?? '',
                new_restaurant_latitude: data.new_restaurant_latitude ?? 0,
                new_restaurant_longitude: data.new_restaurant_longitude ?? 0,
                new_restaurant_zip: data.new_restaurant_zip ?? '',
                new_restaurant_city: data.new_restaurant_city ?? '',
                new_restaurant_state: data.new_restaurant_state ?? '',
                new_restaurant_street: data.new_restaurant_street ?? '',
                new_restaurant_country: data.new_restaurant_country ?? ''
            });
            setIsLoading(false);
        })
            .catch(() => {
            setIsLoading(false);
        })
            .finally(() => {
            setIsLoading(false);
        });
    }, []);
    useEffect(() => {
        // if () {
        console.log('it is running');
        getRestaurantSettings();
        // }
    }, []);
    function getStepRoute(step) {
        switch (step) {
            case 0:
                return '/restaurant/general-details';
            case 1:
                return '/restaurant/address-settings';
            case 2:
                return '/restaurant/payment-settings';
            case 3:
                return '/restaurant/taxation-settings';
            case 4:
                return '/restaurant/delivery-pickup-settings';
            case 5:
                return '/restaurant/delivery-timelines';
            case 6:
                return '/restaurant/pickup-timelines';
            default:
                return '/restaurant/general-details';
        }
    }
    /* prettier-ignore */
    const [generalSettings, setGeneralSettings] = useState({
        _token: document.head.querySelector('meta[name="csrf-token"]')
            // @ts-ignore
            ?.content,
        network_id: getNetworkId(),
        restaurant_name: "",
        restaurant_cover_image: "",
        restaurant_phone: "",
        restaurant_website: "",
        enable_events: false,
        enable_gift_card: false,
        restaurant_sales_tax: "",
        restaurant_currency: "USD"
    });
    const [paymentSettings, setPaymentSettings] = useState({
        new_restaurant_cash_payment: false,
        new_restaurant_credit_card_payment: false,
        new_restaurant_paypal_payment: false,
        new_restaurant_stripe_payment: false,
        new_restaurant_apple_payment: false,
        new_restaurant_google_payment: false
    });
    const [addressSettings, setAddressSettings] = useState({
        restaurant_id: 0,
        new_restaurant_address: '',
        new_restaurant_latitude: 0,
        new_restaurant_longitude: 0,
        new_restaurant_zip: '',
        new_restaurant_city: '',
        new_restaurant_state: '',
        new_restaurant_street: '',
        new_restaurant_country: ''
    });
    const [deliverySettings, setDeliverySettings] = useState({
        new_restaurant_delivery_asap: false,
        new_restaurant_delivery_late: false,
        delivery_fixed_price: true,
        delivery: []
    });
    const [pickUpSettings, setPickUpSettings] = useState({
        new_restaurant_pickup_asap: false,
        new_restaurant_pickup_late: false,
        pickup_date_range: 1,
        pickup: []
    });
    const [floorSettings, setFloorSettings] = useState({
        new_restaurant_floor: []
    });
    const [reservationSettings, setReservationSettings] = useState({
        new_restaurant_enable_reservations: false,
        new_restaurant_order_after_reservation: false,
        new_restaurant_available_time_reservation: false,
        new_restaurant_reservation_data_range: 1,
        restaurant_table_range_min: 1,
        restaurant_table_range_max: 2,
        reservation: []
    });
    const [deliveryTimelines, setDeliveryTimelines] = useState({
        monday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        tuesday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        wednesday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        thursday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        friday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        saturday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        sunday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        }
    });
    const [pickupTimelines, setPickupTimelines] = useState({
        monday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        tuesday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        wednesday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        thursday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        friday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        saturday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        },
        sunday: {
            active: false,
            from_reservation_START: '',
            from_reservation_END: ''
        }
    });
    function validateAllSettings() {
        submitNewRestaurant();
    }
    const getActiveDays = (timelines) => {
        let filteredTimelines = { ...timelines };
        for (let property in filteredTimelines) {
            if (filteredTimelines[property].active == false) {
                delete filteredTimelines[property];
            }
        }
        return filteredTimelines;
    };
    const adjustTimeline = (timelines) => {
        for (const day in timelines) {
            if (timelines[day].from_reservation_START === '23:59:59') {
                timelines[day].from_reservation_START = '00:00:00';
                timelines[day].from_reservation_END = '23:59:59';
            }
        }
        return timelines;
    };
    function filterValidation(field) {
        let validationTemp = { ...validationErrors };
        // @ts-ignore
        const valid = Object.fromEntries(Object.entries(validationTemp).filter(([key, _value]) => key !== field));
        setValidationErrors({ ...valid });
    }
    function checkIfSkipped() {
        console.log('active step: ', activeStep);
        console.log('skipped: ', skipped);
        console.log('steps length: ', steps.length);
        if (activeStep === steps.length ||
            (activeStep === steps.length - 1 && skipped === steps.length)) {
            let networkId = getNetworkId();
            window.location.replace(`/?restaurant=${restaurantId}&network=${networkId}`);
        }
        if (!deliverySettings.new_restaurant_delivery_asap &&
            !deliverySettings.new_restaurant_delivery_late) {
            console.log('im skipping delivery');
            setSkipped(5);
            if (activeStep === skipped) {
                setActiveStep(activeStep + 1);
            }
        }
        else if (!pickUpSettings.new_restaurant_pickup_asap &&
            !pickUpSettings.new_restaurant_pickup_late) {
            console.log('im here: asap ', pickUpSettings.new_restaurant_pickup_asap, ' and late: ', pickUpSettings.new_restaurant_pickup_late);
            setSkipped(6);
            if (activeStep === skipped) {
                setActiveStep(activeStep + 1);
            }
            console.log('im skipping pickup');
        }
        else {
            setSkipped(10);
            console.log('im skipping nothing');
        }
    }
    useEffect(() => {
        if (activeStep >= 4) {
            checkIfSkipped();
        }
    }, [
        isLoading,
        deliverySettings.new_restaurant_delivery_asap,
        deliverySettings.new_restaurant_delivery_late,
        pickUpSettings.new_restaurant_pickup_asap,
        pickUpSettings.new_restaurant_pickup_late
    ]);
    function getRestaurantInputs(step) {
        let token = document.head
            .querySelector('meta[name="csrf-token"]')
            ?.getAttribute('content');
        let networkId = getNetworkId();
        // general settings
        const { restaurant_name, restaurant_phone: phone, restaurant_website: website, restaurant_cover_image, restaurant_currency, restaurant_sales_tax, enable_events, enable_gift_card } = generalSettings;
        let formData = new FormData();
        formData.append('network_id', networkId);
        formData.append('restaurant_name', restaurant_name);
        formData.append('restaurant_phone', phone);
        formData.append('step', '0');
        formData.append('restaurant_website', website);
        formData.append('restaurant_cover_image', restaurant_cover_image);
        formData.append('enable_events', enable_events ? '1' : '0');
        formData.append('enable_gift_card', enable_gift_card ? '1' : '0');
        if (restaurantId) {
            formData.append('restaurant_id', restaurantId.toString());
        }
        // const general_info = {
        //     restaurant_name: restaurant_name,
        //     network_id: networkId,
        //     step: 0,
        //     restaurant_phone: phone,
        //     restaurant_website: website,
        //     restaurant_cover_image: restaurant_cover_image,
        //     enable_events: enable_events ? "1" : "0",
        //     enable_gift_card: enable_gift_card ? "1" : "0",
        //     restaurant_id: restaurantId
        // }
        // address settings
        const { 
        // restaurant_id: restaurantId,
        new_restaurant_address: address, new_restaurant_latitude: lat, new_restaurant_longitude: lng, new_restaurant_zip: zip, new_restaurant_city: city, new_restaurant_state: state, new_restaurant_street: street, new_restaurant_country: country } = addressSettings;
        const address_settings = {
            _token: token,
            network_id: networkId,
            restaurant_id: restaurantId,
            step: 1,
            new_restaurant_address: address,
            new_restaurant_latitude: lat ? lat.toString() : '0',
            new_restaurant_longitude: lng ? lng.toString() : '0',
            new_restaurant_zip: zip,
            new_restaurant_city: city,
            new_restaurant_state: state,
            new_restaurant_street: street,
            new_restaurant_country: country
        };
        // payments settings
        const { new_restaurant_cash_payment: cash, new_restaurant_credit_card_payment: creditCard, new_restaurant_paypal_payment: paypal, new_restaurant_stripe_payment: stripe } = paymentSettings;
        const payment_settings = {
            restaurant_id: restaurantId,
            step: 2,
            new_restaurant_cash_payment: cash ? '1' : '0',
            new_restaurant_credit_card_payment: creditCard ? '1' : '0',
            new_restaurant_stripe_payment: stripe ? '1' : '0',
            new_restaurant_paypal_payment: paypal ? '1' : '0'
        };
        const taxation_settings = {
            restaurant_id: restaurantId,
            step: 3,
            restaurant_sales_tax: restaurant_sales_tax,
            restaurant_currency: restaurant_currency
        };
        // pickup settings
        const { new_restaurant_pickup_asap, new_restaurant_pickup_late, pickup_date_range, pickup } = pickUpSettings;
        let pickup_settings = {
            new_restaurant_pickup_asap: new_restaurant_pickup_asap ? '1' : '0',
            new_restaurant_pickup_late: new_restaurant_pickup_late ? '1' : '0',
            pickup_date_range: pickup_date_range ? pickup_date_range : 1,
            pickup: ''
        };
        if (new_restaurant_pickup_late || new_restaurant_pickup_asap) {
            pickup_settings.pickup_date_range = pickup_date_range;
            pickup_settings.pickup = JSON.stringify(pickup);
        }
        // end pickup settings
        // delivery settings
        const { new_restaurant_delivery_asap, new_restaurant_delivery_late, new_restaurant_delivery_order_track, new_restaurant_delivery_data_range, new_restaurant_delivery_range_unit, new_restaurant_delivery_range_value, delivery_fixed_price, static_price, new_restaurant_delivery_min_order_value, delivery } = deliverySettings;
        let delivery_settings = {
            new_restaurant_delivery_asap: new_restaurant_delivery_asap ? '1' : '0',
            new_restaurant_delivery_late: new_restaurant_delivery_late ? '1' : '0',
            delivery: '',
            new_restaurant_delivery_order_track: new_restaurant_delivery_order_track
                ? new_restaurant_delivery_order_track
                : '0',
            new_restaurant_delivery_data_range: new_restaurant_delivery_data_range
                ? new_restaurant_delivery_data_range
                : '1',
            new_restaurant_delivery_range_unit: new_restaurant_delivery_range_unit
                ? new_restaurant_delivery_range_unit
                : 'km',
            new_restaurant_delivery_range_value: new_restaurant_delivery_range_value
                ? new_restaurant_delivery_range_value
                : '0',
            delivery_fixed_price: delivery_fixed_price ? '1' : '0',
            static_price: delivery_fixed_price && static_price ? static_price : '0',
            price_per_unit: !delivery_fixed_price && static_price ? static_price : '0',
            new_restaurant_delivery_min_order_value: new_restaurant_delivery_min_order_value
                ? new_restaurant_delivery_min_order_value
                : '0'
        };
        if (new_restaurant_delivery_late &&
            new_restaurant_delivery_data_range &&
            new_restaurant_delivery_range_unit &&
            new_restaurant_delivery_range_value &&
            new_restaurant_delivery_min_order_value) {
            delivery_settings.new_restaurant_delivery_order_track =
                new_restaurant_delivery_order_track ? '1' : '0';
            delivery_settings.new_restaurant_delivery_data_range =
                new_restaurant_delivery_data_range;
            delivery_settings.new_restaurant_delivery_range_value =
                new_restaurant_delivery_range_value;
            delivery_settings.delivery_fixed_price = delivery_fixed_price ? '1' : '0';
            delivery_settings.new_restaurant_delivery_min_order_value =
                new_restaurant_delivery_min_order_value;
            delivery_settings.delivery = JSON.stringify(delivery);
        }
        if (delivery_fixed_price) {
            if (static_price) {
                delivery_settings.static_price = static_price;
            }
        }
        else {
            if (static_price) {
                delivery_settings.price_per_unit = static_price;
            }
        }
        // end delivery settings
        const delivery_pickup_settings = {
            restaurant_id: restaurantId,
            step: 4,
            delivery_settings,
            pickup_settings
        };
        let filteredDeliveryTimelines = { ...deliveryTimelines };
        for (let property in filteredDeliveryTimelines) {
            if (filteredDeliveryTimelines[property].active == true) {
                delete filteredDeliveryTimelines[property];
            }
        }
        const delivery_timeline = {
            restaurant_id: restaurantId,
            step: 5,
            timelines: getActiveDays(adjustTimeline(deliveryTimelines))
        };
        const pickup_timeline = {
            restaurant_id: restaurantId,
            step: 6,
            timelines: getActiveDays(adjustTimeline(pickupTimelines))
        };
        const getStepObject = (step) => {
            switch (step) {
                case 0:
                    return formData;
                case 1:
                    return { address_settings };
                case 2:
                    return { payment_settings };
                case 3:
                    return { taxation_settings };
                case 4:
                    return { delivery_pickup_settings };
                case 5:
                    return { delivery_timeline };
                case 6:
                    return { pickup_timeline };
                default:
                    return formData;
            }
        };
        return getStepObject(step);
    }
    function discardBranch() {
        fetchApi({
            method: 'POST',
            url: '/restaurant/discard-branch',
            data: {
                restaurant_id: restaurantId,
                step: activeStep
            }
        }).then(() => {
            window.location.replace(`/select-network`);
        });
    }
    function saveForLaterBranch() {
        window.location.replace(`/select-network`);
    }
    async function submitNewRestaurant() {
        setIsLoading(true);
        // setProfileImage(generalSettings.restaurant_cover_image);
        const resp = await fetchApi({
            method: 'POST',
            url: getStepRoute(activeStep),
            data: getRestaurantInputs(activeStep)
        })
            .then((r) => {
            // @ts-ignore
            console.log('response: ', r.data);
            setRestaurantId(r.data.restaurant.id);
            Storage.setItem('restaurantId', r.data.restaurant.id);
            // if (activeStep === steps.length || (activeStep === steps.length-1 && skipped === steps.length)){
            //     let networkId = getNetworkId() as string;
            //     console.log('networkId: ', networkId)
            //     window.location.replace(`/store?restaurant=${restaurantId}&network=${networkId}`);
            // }
            if (activeStep + 1 == skipped) {
                setActiveStep(() => activeStep + 2);
            }
            else {
                setActiveStep(() => activeStep + 1);
            }
            setValidationErrors([]);
            return r.data;
            // setTimeout(() => {
            //   location.reload();
            // }, 3000);
        })
            .catch((e) => {
            setValidationErrors([]);
            if (e.response?.status == 422) {
                formatValidationErrors(e);
                if (activeStep === 0 && profileImage) {
                    document
                        .getElementById('new-restaurant-cover-image-preview')
                        ?.setAttribute('src', profileImage);
                }
                return e;
            }
            else {
                formatError(e);
                return e;
            }
        })
            .finally(() => {
            setIsLoading(false);
        });
        return resp;
    }
    function formatError(e) {
        let errorTitle = e?.response?.data?.message;
        // errorMessage = Object.values(validationErrors)[0];
        // @ts-ignore
        window.globalToast({
            title: 'Error',
            description: errorTitle,
            type: 'warning'
        });
    }
    function formatValidationErrors(e) {
        let errors = e?.response?.data?.errors;
        setValidationErrors(errors);
        console.log('validation errors: ', errors);
    }
    useEffect(() => {
        document.body.classList.add('restaurant-screen');
    }, []);
    const providerValue = {
        isLoading,
        setIsLoading,
        activeSetting,
        setActiveSetting,
        activeStep,
        setActiveStep,
        restaurantId,
        setRestaurantId,
        generalSettings,
        setGeneralSettings,
        paymentSettings,
        setPaymentSettings,
        addressSettings,
        setAddressSettings,
        deliverySettings,
        setDeliverySettings,
        pickUpSettings,
        setPickUpSettings,
        floorSettings,
        setFloorSettings,
        reservationSettings,
        validationErrors,
        setValidationErrors,
        setReservationSettings,
        validateAllSettings,
        submitNewRestaurant,
        hideStepperButtons,
        setHideStepperButtons,
        deliveryTimelines,
        setDeliveryTimelines,
        pickupTimelines,
        setPickupTimelines,
        steps,
        setSteps,
        profileImage,
        setProfileImage,
        skipped,
        setSkipped,
        filterValidation,
        discardBranch,
        saveForLaterBranch
    };
    return (_jsx(NewBranchContext.Provider, { value: providerValue, children: children }));
}
// @ts-ignore
export default NewBranchProvider;
