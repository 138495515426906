import axios from 'axios';
const baseUrl = window.location.origin;
const storedLocale = localStorage.getItem('locale');
const locale = storedLocale ? JSON.parse(storedLocale) : 'en_US';
export async function fetchApi({ method, url, data = null, headers = {}, customHeaders = {}, ...restProps }) {
    let config = {
        method: method,
        url: `${baseUrl}${url}`,
        data: data
    };
    const defaultHeaders = {
        Accept: 'application/json',
        'Content-Language': locale
    };
    let jointHeaders = null;
    jointHeaders = {
        headers: {
            ...defaultHeaders,
            ...headers,
            ...customHeaders
        }
    };
    try {
        return axios({
            ...config,
            ...jointHeaders,
            ...restProps
        });
    }
    catch (error) {
        return error;
    }
}
