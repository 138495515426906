import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enResources from './en/index';
import sqResources from './sq/index';
const storedLocale = localStorage.getItem('locale');
const locale = storedLocale ? JSON.parse(storedLocale) : null;
const lng = locale ?? 'en_US';
i18next.use(initReactI18next).init({
    lng,
    fallbackLng: 'en_US',
    resources: {
        en_US: enResources,
        sq_AL: sqResources
    }
});
export default i18next;
