import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileContext } from '../../profile/ProfileContext';
import { fetchApi } from '../../../config/Api';
import { getNetworkId, getRestaurantId } from '../../UtilityFunctions';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import DropdownMenu from '../../components/DrodownMenu';
export default function ProfileDropdown() {
    const [t] = useTranslation('common');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { userInfo } = useContext(ProfileContext);
    const networkId = getNetworkId();
    const restaurantId = getRestaurantId();
    const handleLogout = async () => {
        let token = document.head
            .querySelector('meta[name="_token"]')
            ?.getAttribute('content');
        const response = await fetch('/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': token // CSRF token attached here
            }
        });
        if (response.ok) {
            window.location.href = '/login';
        }
        else {
            console.error('Logout failed');
        }
    };
    const handleClick = () => {
        const encodedReturnUrl = encodeURIComponent(window.location.href);
        fetchApi({
            method: 'GET',
            url: `/network/${networkId}/billing_portal/sessions?return_url=${encodedReturnUrl}`
        }).then((res) => {
            if ('url' in res.data) {
                window.location.href = res.data.url;
            }
        });
    };
    const profileUrl = `/profile?network=${networkId}&restaurant=${restaurantId}`;
    const isSuperAdmin = userInfo?.roles.some((role) => role.name === 'superadmin');
    const isAdmin = userInfo?.roles.some((role) => role.name === 'admin');
    const networkInStorage = localStorage.getItem('network');
    const network = networkInStorage
        ? JSON.parse(networkInStorage)
        : null;
    const hasActiveSubscription = network?.current_subscription !== null &&
        network?.current_subscription?.status !== 'canceled';
    return (_jsxs(_Fragment, { children: [_jsxs(Button, { id: 'basic-button', "aria-haspopup": 'true', "aria-expanded": open ? 'true' : undefined, "aria-controls": open ? 'basic-menu' : undefined, onClick: (e) => setAnchorEl(e.currentTarget), sx: {
                    display: 'flex',
                    gap: '0.5rem',
                    alignItems: 'center',
                    color: 'text.primary'
                }, children: [_jsx(Avatar, { variant: 'rounded', sx: {
                            width: 24,
                            height: 24,
                            borderRadius: 2.5,
                            bgcolor: 'info.main',
                            fontSize: 12,
                            fontWeight: 600
                        }, children: userInfo?.firstname.charAt(0) }), _jsx(ExpandMoreIcon, {})] }), _jsx(DropdownMenu, { anchorEl: anchorEl, onClose: () => setAnchorEl(null), options: [
                    ...(!isSuperAdmin
                        ? [
                            {
                                icon: _jsx(AccountCircleOutlinedIcon, {}),
                                label: t('profileDropdown.accountBtnLabel'),
                                secondaryLabel: userInfo?.email,
                                action: () => (window.location.href = profileUrl)
                            }
                        ]
                        : []),
                    ...(isAdmin && hasActiveSubscription
                        ? [
                            {
                                icon: _jsx(ReceiptLongOutlinedIcon, {}),
                                label: t('profileDropdown.subscriptionBtnLabel'),
                                action: handleClick
                            }
                        ]
                        : []),
                    ...(isAdmin && !hasActiveSubscription
                        ? [
                            {
                                icon: _jsx(HistoryOutlinedIcon, {}),
                                label: t('profileDropdown.billingBtnLabel'),
                                action: handleClick
                            },
                            {
                                icon: _jsx(DiamondOutlinedIcon, {}),
                                label: 'Get Your Plan',
                                action: () => location.replace(`/get-your-plan?id=${network?.id}`)
                            }
                        ]
                        : []),
                    {
                        icon: _jsx(LogoutOutlinedIcon, {}),
                        label: t('profileDropdown.logoutBtnLabel'),
                        action: handleLogout
                    }
                ] })] }));
}
