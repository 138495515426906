import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NewBranchContext } from './NewBranchProvider';
import comp from './components';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { OSStepperBox, OSStepperTitle, OSStepperTitleBox, OSDiscardBox, OSStepperContainer, OSContentContainerBox } from './components/OSCustomComponents';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import StorefrontOutlined from '@mui/icons-material/StorefrontOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, LinearProgress } from '@mui/material';
function NewBranchContainer() {
    const [t] = useTranslation('settings');
    const { isLoading, submitNewRestaurant, hideStepperButtons, activeStep, setActiveStep, steps, skipped, discardBranch, saveForLaterBranch } = useContext(NewBranchContext);
    const [openModal, setOpenModal] = React.useState(false);
    const OptionalIcon = () => {
        let fullString = t('steps', { returnObjects: true })[skipped];
        let firstWord = fullString.split(' ')[0];
        // console.log(firstWord)
        return (_jsx(_Fragment, { children: _jsx(Tooltip, { title: fullString +
                    ' is disabled because the ' +
                    firstWord +
                    ' ASAP and ' +
                    firstWord +
                    ' Late is not enabled', placement: 'right', children: _jsx(InfoIcon, {}) }) }));
    };
    const isStepOptional = (step) => {
        return step === skipped;
    };
    const isStepSkipped = (step) => {
        return step === skipped;
    };
    const handleNext = async () => {
        await submitNewRestaurant();
    };
    const handleBack = () => {
        if (activeStep - 1 == skipped) {
            setActiveStep(() => activeStep - 2);
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };
    const handleClickOpen = () => {
        setOpenModal(true);
    };
    const handleClose = () => {
        setOpenModal(false);
    };
    function getStepContent(step) {
        switch (step) {
            case 0:
                return _jsx(comp.GeneralSettings, {});
            case 1:
                return _jsx(comp.AddressSettings, { context: NewBranchContext });
            case 2:
                return _jsx(comp.PaymentSettings, {});
            case 3:
                return _jsx(comp.TaxationCurrencySettings, {});
            case 4:
                return _jsx(comp.DeliveryPickupSettings, {});
            case 5:
                return _jsx(comp.DeliveryTimelines, {});
            case 6:
                return _jsx(comp.PickupTimelines, {});
            default:
                return _jsx(comp.GeneralSettings, {});
        }
    }
    function getStepTitle(step) {
        return (_jsx(OSStepperTitleBox, { children: _jsx(Box, { sx: {
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center'
                }, children: _jsx(OSStepperTitle, { children: t('steps', { returnObjects: true })[step] }) }) }));
    }
    return (_jsx(Box, { sx: { width: '100%' }, children: _jsxs(OSStepperContainer, { children: [_jsx(OSStepperBox, { className: activeStep === steps.length ||
                        (activeStep === steps.length - 1 && skipped === steps.length)
                        ? 'invisible '
                        : ' ', children: _jsx(Stepper, { activeStep: activeStep, orientation: 'vertical', children: t('steps', { returnObjects: true }).map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (_jsx(Step, { ...stepProps, children: isStepOptional(index) && activeStep >= 5 ? (_jsx(StepLabel, { sx: {
                                        textDecoration: 'line-through',
                                        color: '#00000061'
                                    }, StepIconComponent: OptionalIcon, children: label })) : (_jsx(StepLabel, { ...labelProps, children: label })) }, label));
                        }) }) }), _jsx(OSContentContainerBox, { children: _jsx("div", { className: 'md:min-w-112 sm:min-w-112 sm:w-min-content w-full', children: activeStep === steps.length ? (_jsx(React.Fragment, { children: _jsx(Box, { sx: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '25rem',
                                    height: '100%'
                                }, children: _jsxs(Box, { sx: { display: 'block', width: '100%' }, children: [_jsx(Typography, { sx: { mt: 2, mb: 2, textAlign: 'center' }, children: t('loadingLabel') }), _jsx(LinearProgress, {})] }) }) })) : (_jsxs(React.Fragment, { children: [_jsxs(Box, { children: [_jsxs(OSDiscardBox, { children: [_jsx(IconButton, { "aria-label": 'close', onClick: handleClickOpen, children: _jsx(CloseOutlinedIcon, {}) }), _jsxs(Dialog, { fullWidth: true, maxWidth: 'sm', open: openModal, onClose: handleClose, sx: {
                                                        '.MuiPaper-rounded': {
                                                            borderRadius: '0.5rem'
                                                        }
                                                    }, children: [_jsx(DialogContent, { sx: {
                                                                padding: '2.5rem 1.5rem 1rem'
                                                            }, children: _jsxs(Box, { sx: {
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center',
                                                                    textAlign: 'center'
                                                                }, children: [_jsx(Box, { component: 'span', sx: {
                                                                            // bgcolor: 'primary.main',
                                                                            width: 48,
                                                                            height: 48,
                                                                            borderRadius: '50%',
                                                                            bgcolor: '#2196F3',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center'
                                                                        }, children: _jsx(StorefrontOutlined, { sx: {
                                                                                color: '#e3f3ff',
                                                                                width: '1.4rem',
                                                                                height: '1.4rem'
                                                                            } }) }), _jsxs(DialogTitle, { sx: {
                                                                            fontWeight: '500',
                                                                            padding: '1.5rem 1rem 1rem'
                                                                        }, children: [t('discardDialog.title'), _jsx(IconButton, { "aria-label": 'close', onClick: handleClose, sx: {
                                                                                    position: 'absolute',
                                                                                    right: 8,
                                                                                    top: 8,
                                                                                    color: (theme) => theme.palette.grey[500]
                                                                                }, children: _jsx(CloseIcon, {}) })] }), _jsx(DialogContentText, { children: t('discardDialog.description') }), _jsx(Box, { noValidate: true, component: 'form', sx: {
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            m: 'auto',
                                                                            width: 'fit-content'
                                                                        } })] }) }), _jsxs(DialogActions, { sx: {
                                                                padding: '1rem 2rem 2rem 2rem'
                                                            }, children: [_jsx(Button, { color: 'info', variant: 'outlined', onClick: discardBranch, sx: {
                                                                        mr: 2,
                                                                        color: (theme) => theme.palette.grey[700],
                                                                        borderColor: (theme) => theme.palette.grey[700],
                                                                        borderRadius: '0.5rem',
                                                                        '&:hover': {
                                                                            borderColor: (theme) => theme.palette.grey[700]
                                                                        }
                                                                    }, children: t('discardDialog.secondaryBtnLabel') }), activeStep !== 0 ? (_jsx(Button, { variant: 'contained', sx: { borderRadius: '0.5rem' }, disabled: isLoading, component: 'label', onClick: saveForLaterBranch, children: t('discardDialog.primaryBtnLabel') })) : (_jsx(_Fragment, {}))] })] })] }), getStepTitle(activeStep)] }), getStepContent(activeStep), !hideStepperButtons ? (_jsxs(Box, { className: 'flex mx-4 sm:mx-6', children: [_jsx(Button, { color: 'inherit', variant: 'outlined', disabled: activeStep === 0, onClick: handleBack, sx: {
                                                mr: 1,
                                                borderRadius: '0.5rem'
                                            }, children: "Back" }), _jsx(Box, { sx: { flex: '1 1 auto' } }), _jsx(Button, { variant: 'contained', sx: { borderRadius: '0.5rem' }, disabled: isLoading, component: 'label', onClick: handleNext, children: activeStep === steps.length - 1 ||
                                                (skipped == steps.length - 1 &&
                                                    activeStep == steps.length - 2)
                                                ? t('finishBtnLabel')
                                                : t('nextBtnLabel') })] })) : (_jsx(_Fragment, {}))] })) }) })] }) }));
}
// @ts-ignore
export default NewBranchContainer;
