import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useNetworkPlanContext } from "./GetYourPlanContext";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Error from "./components/Error";
import Header from "../components/Header";
import NetworkCard from "./components/NetworkCard";
export default function GetYourPlanContainer() {
    const { isError, isLoading, network } = useNetworkPlanContext();
    let content;
    if (isError)
        content = _jsx(Error, {});
    if (isLoading)
        content = (_jsx(Box, { my: 10, display: "flex", justifyContent: "center", children: _jsx(CircularProgress, {}) }));
    if (network)
        content = _jsx(NetworkCard, { network: network });
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}), content] }));
}
